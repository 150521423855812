import {
	Stack,
	Typography,
	FormControl,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Tooltip,
	Button,
} from "@mui/material";
import React from "react";

import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/Buttons/LoadingButton"

const styles = {
	wrapper: {
		flexDirection: { md: "row", xs: "column" },
		gap: 2,
		justifyContent: "space-between",
		textWrap: "nowrap",
		py: 2,
		"& h1": {
			fontSize: { md: 22, xs: 18 },
			fontWeight: 600,
			color: "#1E1E1F",
			flex: 1,
		},
	},
	searchInput: {
		maxWidth: 350,
		width: "100%",
		boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
		backgroundColor: "#fff",
		borderRadius: "6px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},
		"& input": {
			py: "9px",
			px: "16px",
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	button: {
		width: 200,
		bgcolor: "#2B2A69",
		color: "#FFF",
		fontWeight: 600,
		textTransform: "capitalize",
		px: 3,
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "#FFF",
		},
	},
};

function PageHeader({
	title,
	placeholder,
	searchInput = false,
	search,
	setSearch,
	isSearch,
	handleCancleSearch,
	handleSearchClick,
	createBtn,
	createBtnText,
	url,
	btnFnc = false,
	isLoading = false,
}) {
	const navigate = useNavigate();
	return (
		<>
			<Stack sx={styles.wrapper}>
				<Typography component="h1">{title}</Typography>

				{searchInput ? (
					<FormControl variant="outlined" sx={styles.searchInput}>
						<OutlinedInput
							size="small"
							type="text"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value.replace(/\s+/g, " "));
								if (e.target.value === "") handleCancleSearch();
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									if (e.target.value !== " ") handleSearchClick();
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<Stack direction="row" alignItems="center" spacing={1}>
										<Tooltip title="Search">
											<IconButton onClick={handleSearchClick}>
												<Search />
											</IconButton>
										</Tooltip>
										{searchInput && isSearch && (
											<IconButton onClick={handleCancleSearch}>
												<Clear />
											</IconButton>
										)}
									</Stack>
								</InputAdornment>
							}
							placeholder={placeholder}
						/>
					</FormControl>
				) : null}

				{createBtn ? (
					<LoadingButton
						variant="contained"
						onClick={() => (btnFnc ? btnFnc() : navigate(url))}
						sx={styles.button}
						isLoading={isLoading}
					>
						{createBtnText}
					</LoadingButton>
				) : null}
			</Stack>
		</>
	);
}

export default PageHeader;
