import {
    Stack,
    Typography,
    FormControl,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import React from "react";

import { ReactComponent as Search } from "../../assets/svg/Search.svg";

const styles = {
    wrapper: {
        justifyContent: "space-between",
        pt: 2,
        pr: 3.75,
        pb: 1.75,
        pl: 3.625,
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        bgcolor: 'white',
        "& h1": {
            fontSize: 22,
            fontWeight: 600,
            color: "#1E1E1F",
        },
    },
    searchInput: {
        width: 350,
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2B2A69 !important",
            borderWidth: "1px !important",
        },
        "& input": {
            py: "9px",
            px: "16px",
            "&::placeholder": {
                color: "#767D90",
                fontWeight: 400,
                opacity: 1,
                fontSize: 14,
            },
        },
    },
};

function SearchHeader({ title, placeholder, searchInput = false }) {
    return (
        <>
            <Stack direction="row" sx={styles.wrapper} columnGap={1.25}>
                <Typography component="h1">{title}</Typography>
                {searchInput ? (
                    <FormControl variant="outlined" sx={styles.searchInput}>
                        <OutlinedInput
                            size="small"
                            type="text"
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                            placeholder={placeholder}
                        />
                    </FormControl>
                ) : null}
            </Stack>
        </>
    );
}

export default SearchHeader;
