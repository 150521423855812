const styles = {
	title: {
		pb: 2.5,
		flexWrap: "wrap",
		"& h1": {
			fontSize: 22,
			fontWeight: 600,
			color: "#1E1E1F",
			flex: 1,
		},
		"& button": {
			width: 150,
			bgcolor: "#2B2A69",
			color: "#FFF",
			fontWeight: 600,
			textTransform: "capitalize",
			px: 3,
			"&:hover": {
				bgcolor: "#2B2A69",
				color: "#FFF",
			},
		},
		"& h4": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 500,
			lineHeight: "36px",
			mb: 1,
		},
	},
	container: {
		bgcolor: "#FFF",
		borderRadius: "12px",
		py: 4,
		px: "30px",
		"& p": {
			color: "#0F4590",
			fontSize: 16,
			fontWeight: 500,
			mt: 1,
			cursor: "pointer",
		},
		"& h4": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 500,
			lineHeight: "36px",
			mb: 1,
		},
	},
	lessonContainer: {
		border: "2px dashed #E2E3E4",
		borderRadius: "10px",
		pl: 3,
		py: 2,
		pr: 2,
		mb: 2,
	},
	// attachmentContainer: {
	// 	bgcolor: "rgba(226, 227, 228, 0.20)",
	// 	border: "1px solid var(--light-background-color-2, #E2E3E4)",
	// 	borderRadius: "4px",
	// 	py: "9px",
	// 	pl: "15px",
	// 	pr: 1,
	// 	justifyContent: "space-between",
	// 	alignItems: "center",
	// 	"& label": {
	// 		color: "#B4B1B6",
	// 		// fontSize: 16,
	// 		overflow: "hidden",
	// 		maxWidth: 150,
	// 		whiteSpace: "nowrap",
	// 		textOverflow: "ellipsis",
	// 	},
	// 	"& button": {
	// 		bgcolor: "#2B2A69",
	// 		color: "#FFF",
	// 		fontWeight: 500,
	// 		textTransform: "capitalize",
	// 		whiteSpace: "nowrap",
	// 		"&:hover": {
	// 			bgcolor: "#2B2A69",
	// 			color: "#FFF",
	// 		},
	// 		"@media (max-width: 1080px)": {
	// 			fontSize: 10,
	// 		},
	// 	},
	// },
	attachmentContainer: {
		bgcolor: "rgba(226, 227, 228, 0.20)",
		borderRadius: "4px",
		border: "1px dashed var(--light-background-color-2, #E2E3E4)",
		fontSize: 18,
		fontWeight: 500,
		color: "#767D90",
		py: "12px",
		px: "18px",
		width: 50,
		height: 54,
		cursor: "pointer",
	},
	documentContainer: {
		bgcolor: "#FAFAFF",
		borderRadius: "4px",
		py: "10px",
		px: "10px",
		position: "relative",
		alignItems: "center",
		maxWidth: 160,
		"& h6": {
			color: "#1E1E1F",
			fontSize: 12,
			fontWeight: 500,
			lineHeight: "20px",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			maxWidth: 110,
			"@media (max-width: 1250px)": {
				maxWidth: 65,
			},
			"@media (max-width: 1050px)": {
				maxWidth: 45,
			},
			"@media (max-width: 890px)": {
				maxWidth: 100,
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 10,
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			maxWidth: 110,
			"@media (max-width: 1050px)": {
				fontSize: 8,
				maxWidth: 45,
			},
			"@media (max-width: 615px)": {
				fontSize: 8,
				maxWidth: 45,
			},
			"@media (max-width: 890px)": {
				maxWidth: 100,
			},
		},
	},
	removeIcon: {
		position: "absolute",
		right: -7,
		top: -7,
		cursor: "pointer",
	},
	removeBtn: {
		width: 40,
		height: 40,
		color: "#AC3049",
	},
	avatar: {
		// height: { lg: 160, md: 160, sm: 120, xs: 120 },
		// width: { lg: 160, md: 160, sm: 120, xs: 120 },
		height: 100,
		width: 100,
		border: { md: "5px solid #FFF", xs: "2px solid #fff" },
		bgcolor: "#E8EEF5",
		cursor: "pointer",
		"& svg": {
			height: 50,
			width: 50,
			fill: "#135099",
		},
	},
	lessonCoverImg: {
		bgcolor: "rgba(226, 227, 228, 0.20)",
		borderRadius: "10px",
		height: 80,
		width: 80,
		cursor: "pointer",
	},
	checkbox: {
		color: "#767D90",
	},
	datePicker: {
		"& .MuiOutlinedInput-root": {
			backgroundColor: "#F9F9F9",
			borderRadius: "6px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E2E3E4",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},

		"& input": {
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	autocomplete: {
		"& .MuiOutlinedInput-root": {
			backgroundColor: "#F9F9F9",
			borderRadius: "6px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E2E3E4",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},

		"& input": {
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	label: {
		color: "#767D90",
		fontWeight: 400,
		fontSize: 16,
		display: "block",
		"& > *": {
			color: "#fa5252",
		},
	},
};

export default styles