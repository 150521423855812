import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Stack,
} from "@mui/material";

const styles = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E2E3E4",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#2B2A69 !important",
    borderWidth: "1px !important",
  },
  "& input": {
    py: "13px",
    px: 2,
    "&::placeholder": {
      color: "#767D90",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
    },
  },
  "& .MuiTabs-root": {
    borderBottom: 0,
  },
  "& .MuiTabs-flexContainer": {
    borderBottom: "2px solid #fff",
  },
  select: {
    "& .MuiOutlinedInput-input": {
      py: "13px",
      px: 2,
    },
  },
  pageHeader: {
    fontSize: 24,
    fontWeight: 600,
    mb: 3,
  },
  tabHeaders: {
    borderBottom: "2px solid white",
    "& .MuiTabs-flexContainer": {
      gap: "10px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#0F4590",
    },
    "& button": {
      textTransform: "capitalize",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      color: "#333333",
    },
  },
  activeTab: {
    backgroundColor: "#0F4590",
    color: "#FFF !important",
  },
  inactiveTab: {
    bgcolor: "#fff",
  },
  tab: {
    bgcolor: "#FFF",
    p: 3,
    borderRadius: 3,
    mt: "10px",
    "& button": {
      textTransform: "capitalize",
    },
  },
  subHeader: {
    color: "#767D90",
    mb: "7px",
  },
  buttons: {
    flexDirection: "row",
    columnGap: "10px",
    "& button": {
      px: 3,
      py: 1.6,
    },
  },
  createBtn: {
    color: "#FFF",
    bgcolor: "#2B2A69",
    "&:hover": {
      bgcolor: "#2b2a91",
    },
  },
  cancelBtn: {
    color: "black",
    bgcolor: "#F2F2F2",
    "&:hover": {
      bgcolor: "#e7e5e5",
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreatePoll() {
  const [choiseForm, setChoiseForm] = useState({
    type_title: "",
    results: "",
    allowed_groups: "",
    poll_description: "",
  });
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setChoiseForm({ ...choiseForm, [name]: value });
  };

  const handleResultSelect = (e) => {
    console.log(e.target.value);
  };

  const handleGroupSelect = (e) => {
    console.log(e.target.value);
  };

  return (
    <Box sx={styles}>
      <Typography component="h1" sx={styles.pageHeader}>
        Create Poll
      </Typography>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        sx={styles.tabHeaders}
      >
        <Tab
          label="Multiple Choice"
          {...a11yProps(0)}
          sx={value === 0 ? styles.activeTab : styles.inactiveTab}
        />
        <Tab
          label="Text Box"
          {...a11yProps(1)}
          sx={value === 1 ? styles.activeTab : styles.inactiveTab}
        />
        <Tab
          label="Rating"
          {...a11yProps(2)}
          sx={value === 2 ? styles.activeTab : styles.inactiveTab}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box sx={styles.tab}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="h2" sx={styles.subHeader}>
                Type title
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="string"
                  placeholder="Type title, or paste a link here"
                  value={choiseForm.type_title}
                  name="type_title"
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h2" sx={styles.subHeader}>
                Results
              </Typography>
              <FormControl fullWidth sx={styles.select}>
                <FormControl variant="outlined" fullWidth>
                  <Select type="string" onChange={handleResultSelect}>
                    <MenuItem value={1}>Always Visible</MenuItem>
                  </Select>
                </FormControl>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography component="h2" sx={styles.subHeader}>
                Allowed groups
              </Typography>
              <FormControl fullWidth sx={styles.select}>
                <FormControl variant="outlined" fullWidth>
                  <Select type="string" onChange={handleGroupSelect}>
                    <MenuItem value={1}>Select Groups</MenuItem>
                  </Select>
                </FormControl>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography component="h2" sx={styles.subHeader}>
                Poll Description
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  placeholder="Type here"
                  multiline
                  rows={4}
                  variant="outlined"
                  type="textarea"
                  name="poll_description"
                  value={choiseForm.poll_description}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <Stack direction="row" sx={styles.buttons}>
                <Button
                  variant="contained"
                  sx={styles.createBtn}
                  disableElevation
                >
                  Create Topic
                </Button>
                <Button
                  variant="contained"
                  sx={styles.cancelBtn}
                  disableElevation
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} sx={styles.tab}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2} sx={styles.tab}>
        Item Three
      </TabPanel>
    </Box>
  );
}

export default CreatePoll;
