import {
	Avatar,
	Stack,
	Typography,
	Box,
	Button,
	IconButton,
} from "@mui/material";
import React from "react";

import { ReactComponent as Accept } from "../../../assets/svg/Accept.svg";
import { ReactComponent as Reject } from "../../../assets/svg/Reject.svg";

const styles = {
	wrapper: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		pt: 2,
		"& h2": {
			fontWeight: 600,
			color: "#1E1E1F",
			textTransform: "capitalize",
		},
		"& p": {
			fontSize: 12,
			color: "#767D90",
		},
	},
	imgWrapper: {
		flexDirection: "row",
		alignItems: "center",
		rowGap: "10px",
	},
	viewBtn: {
		bgcolor: "#F2F2F2",
		color: "#1E1E1F",
		fontSize: 12,
		minWidth: 0,
		textTransform: "capitalize",
		fontWeight: 600,
	},
	pendingWrapper: {
		columnGap: "5px",
		"& .MuiIconButton-root": {
			width: 32,
			height: 32,
			border: "1px solid rgba(118, 125, 144, 0.2)",
		},
	},
	deleteBtn: {
		fill: "red",
	},
};

function Member({
	profile_image,
	full_name = "",
	email = "",
	dataType,
	status = "",
}) {
	return (
		<>
			<Stack sx={styles.wrapper}>
				<Stack sx={styles.imgWrapper}>
					<Avatar src={profile_image} sx={{ mr: 1.5 }}></Avatar>

					<Box>
						<Typography component="h2">{full_name}</Typography>
						<Typography component="p">
							{email}
						</Typography>
					</Box>
				</Stack>

				{dataType === "student_query" ? (
					<Stack direction="row">
						{status == "pending" ? (
							<>
								<Stack direction="row" sx={styles.pendingWrapper}>
									<IconButton>
										<Accept />
									</IconButton>
									<IconButton>
										<Reject className={styles.deleteBtn} />
									</IconButton>
								</Stack>
							</>
						) : (
							<Button variant="contained" disableElevation sx={styles.viewBtn}>
								View
							</Button>
						)}
					</Stack>
				) : null}
			</Stack>
		</>
	);
}

export default Member;
