import React, { useState } from "react";
import { Avatar, Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import {
  ADMIN_GET_ALL_MEETUPS,
  ADMIN_DELETE_MEETUP_BY_ID,
} from "../data/constants/apiRoutes";
import { get, destroy } from "../server";
import BoxModal from "../components/Modals/BoxModal";
import PageHeader from "../components/ui/PageHeader";
import queryNames from "../data/constants/queryNames";
import Listing from "../components/ui/Listing";
import GoBackButton from "../components/Buttons/GoBackButton";

//SVG
// import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";

//event, eventId, handleInvalidate
const Meetups = () => {
  //USESTATE
  const [deleteModal, setDeleteModal] = useState(false);
  const [meetupId, setMeetupId] = useState(null);
  //USECLIENT
  const client = useQueryClient();

  //NAVIGATION
  const navigate = useNavigate();

  //COLUMNS FOR GRID
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "meetup_title",
      headerName: "Meetup Title",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.meetup_title,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.description,
    },
    {
      field: "meetup_address",
      headerName: "Meetup Address",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.meetup_address,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 180,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "commentCount",
      headerName: "Comment Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.commentCount,
    },
    {
      field: "membersCount",
      headerName: "Member Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.membersCount,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setMeetupId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //HANDLE INVALIDE
  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.MEETUPS);
  };

  // CLICKING ON POST TO NAVIGATE TO POSTDETAIL SCREEN
  const handleClick = (rowData) => {
    if (rowData?.field === "full_name") {
      navigate(`/meetups/detail/${rowData.id}`);
    }
  };

  //DELETE MODAL
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setMeetupId(null);
  };

  //CALLING METHOD TO GET MEETUPS
  const fetchMeetups = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_ALL_MEETUPS}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //DELETE API
  const deleteMeetup = async () => {
    const { status, message } = await destroy(
      `${ADMIN_DELETE_MEETUP_BY_ID}/${meetupId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  // MUTATION AND ITS METHODS
  const mutationDelete = useMutation(deleteMeetup);

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  //Return
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Meetups" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
        </Box>
      </Box>

      <Listing
        columns={columns}
        handleCellClick={handleClick}
        queryName={queryNames.MEETUPS}
        fetchRecords={fetchMeetups}
        className="meetups"
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this meetup"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export default Meetups;
