import React, { useRef, useState } from "react";
import RightDrawer from "../../components/ui/RightDrawer";
import Listing from "../../components/ui/Listing";
import { destroy, get } from "../../server";
import {
	ADMIN_COMPANIES_LISTING,
	ADMIN_COMPANIES_LISTING_SEARCH,
	ADMIN_COMPANY_DELETE,
	ADMIN_COMPANY_STATUS,
} from "../../data/constants/apiRoutes";
import queryNames from "../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import {
	Stack,
	IconButton,
	Typography,
	Button,
	FormControl,
	OutlinedInput,
	InputAdornment,
	Tooltip,
	Avatar,
} from "@mui/material";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import styles from "../../assets/styles/company/companyListing.styles";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import BoxModal from "../../components/Modals/BoxModal";
import { Clear } from "@mui/icons-material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import LoadingButton from "../../components/Buttons/LoadingButton";

function Companies() {
	const [modal, setModal] = useState(false);
	const [companyId, setCompanyId] = useState();
	const companyRef = useRef();

	const [search, setSearch] = useState("");
	const [finalSearch, setFinalSearch] = useState("");
	const [isSearch, setIsSearch] = useState(false);

	const navigate = useNavigate();

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const columns = [
		{
			field: "company_name",
			headerName: "Company Name",
			sortable: false,
			flex: 1,
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<Stack
							direction="row"
							alignItems="center"
							sx={{ cursor: "pointer" }}
						>
							<Avatar
								src={params.row.company_logo}
								sx={{
									bgcolor: "gray",
									mr: 1.5,
									width: 35,
									height: 35,
								}}
							>
								{params.row.company_name.charAt(0)}
							</Avatar>
							{params.row.company_name}
						</Stack>
					</>
				);
			},
		},
		{
			field: "contact_no",
			headerName: "Phone",
			sortable: false,
			width: 150,
		},
		{
			field: "owner_email",
			headerName: "Email",
			sortable: false,
			flex: 1,
		},
		{
			field: "date",
			headerName: "Date",
			sortable: false,
			width: 150,
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex: 1,
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={2}>
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => navigate(`/companies/edit/${params.row.id}`)}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						{!params.row.is_enable && (
							<Tooltip title="Enable">
								<IconButton
									sx={{ color: "green" }}
									onClick={() => {
										// setMember(params.row);
										// setModal("confirm");
										setCompanyId(params.row.id);
										setModal("enable");
									}}
								>
									<DoneOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
						{/* <Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setCompanyId(params.row);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip> */}
						{params.row.is_enable && (
							<Button
								variant="contained"
								sx={{ ...styles.button, width: 100 }}
								onClick={() => {
									setCompanyId(params.row.id);
									setModal("disable");
								}}
							>
								Disable
							</Button>
						)}
					</Stack>
				);
			},
		},
	];

	const handleCellClick = (data) => {
		if (data?.field === "company_name") {
			companyRef.current = data.row;
			setModal("detail");
		}
	};

	const fetchCompanies = async (pageNo, limit, searchParam, isSearchParam) => {
		let url;

		if (isSearchParam === false) {
			url = new URL(ADMIN_COMPANIES_LISTING);
		} else if (isSearchParam && searchParam.length) {
			url = new URL(ADMIN_COMPANIES_LISTING_SEARCH);
			url.searchParams.append("search", searchParam);
		}

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.COMPANY);
	};

	const handleClose = () => {
		setModal(false);
		setCompanyId(null);
		handleInvalidate();
	};

	const handleAction = async () => {
		const { status, message } = await get(
			`${ADMIN_COMPANY_STATUS}/${companyId}`
		);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleAction, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = (e) => {
		e.preventDefault();
		mutation.mutate();
	};

	const handleSearchClick = () => {
		if (search.length) {
			setIsSearch(true);
			setFinalSearch(search);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearch("");
	};

	return (
		<>
			<Stack direction="row" sx={styles.wrapper} gap={1.25}>
				<Typography component="h1">Manage Companies</Typography>
				{isSearch && (
					<IconButton onClick={handleCancleSearch}>
						<Clear />
					</IconButton>
				)}
				<FormControl variant="outlined" sx={styles.searchInput} fullWidth>
					<OutlinedInput
						size="small"
						type="text"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSearchClick();
							}
						}}
						endAdornment={
							<InputAdornment position="end">
								<Tooltip title="Search">
									<IconButton onClick={handleSearchClick}>
										<Search />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						}
						placeholder="Search Courses"
					/>
				</FormControl>
				<Button
					sx={styles.button}
					variant="contained"
					onClick={() => navigate("/companies/add")}
				>
					Add New Company
				</Button>
			</Stack>

			<Listing
				columns={columns}
				handleCellClick={handleCellClick}
				queryName={queryNames.COMPANY}
				fetchRecords={fetchCompanies}
				type="companies"
				search={finalSearch}
				isSearch={isSearch}
			/>
			<RightDrawer
				modal={modal === "detail"}
				setModal={setModal}
				id={companyRef.current?.id}
			/>

			<BoxModal
				// header="Are you sure?"
				title={
					modal === "disable"
						? "Do you really want to Disable this Company?"
						: "Do you really want to Enable this Company?"
				}
				open={modal === "enable" ? "confirm" : modal === "disable"}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton="Yes"
				confirmButtonColor="#2B2A69"
				cancelButtonColor="#E8EEF5"
				isLoading={mutation.isLoading}
				data={companyId}
			/>
		</>
	);
}

export default Companies;
