import Joi from "joi";

const eventDetailSchema = Joi.object({
  company_id: Joi.number().required().messages({
    "number.empty": `Please select Company`,
    "number.base": `Please select Company`,
  }),
  event_name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Event Name`,
    "string.max": `Event name must be maximum 50 characters!`,
  }),
  venue_name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Venue Name`,
    "string.max": `Venue name must be maximum 50 characters!`,
  }),
  address: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Address`,
    "string.max": `Event Address must be maximum 500 characters!`,
  }),
  event_type: Joi.string().required().messages({
    "string.empty": `Please select Event Type`,
  }),
  start_time: Joi.string().required().messages({
    "string.empty": `Please select Start Time`,
    "string.base": "Please select Start Time",
  }),
  end_time: Joi.string().required().messages({
    "string.empty": `Please select End Time`,
    "string.base": "Please select End Time",
  }),
  description: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Description`,
    "string.max": `Event Description must be maximum 500 characters!`,
  }),
  hotel_reservation_url: Joi.when("hotel", {
    is: true,
    then: Joi.string().required().messages({
      "string.empty": "Please enter hotel reservation URL",
    }),
  }),
  hotel_description: Joi.when("hotel", {
    is: true,
    then: Joi.string().required().max(50).messages({
      "string.empty": "Please enter Hotel Name",
      "string.max": "Hotel name must be maximum 50 characters!",
    }),
  }),
  hotel_reservation_end_date: Joi.when("hotel", {
    is: true,
    then: Joi.date().required().messages({
      "date.base": "Please enter Check-in Date",
    }),
  }),
});

const FAQSchema = Joi.object({
  question: Joi.string().required().max(150).messages({
    "string.empty": `Please enter Question`,
    "string.max": `Question must be maximum 150 characters!`,
  }),
  answer: Joi.string().required().max(300).messages({
    "string.empty": `Please enter Answer`,
    "string.max": `Answer must be maximum 300 characters!`,
  }),
});

const SponsorSchema = Joi.object({
  company_sponser_id: Joi.number().required().messages({
    "number.empty": `Please select Sponsor`,
    "number.base": `Please select Sponsor`,
  }),
});

const NewSponsorSchema = Joi.object({
  company_name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Company Name`,
    "string.max": `Company name must be maximum 50 characters!`,
  }),
  sponser_name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Sponsor Name`,
    "string.max": `Sponsor name must be maximum 50 characters!`,
  }),
  address: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Address`,
    "string.max": `Event Address must be maximum 500 characters!`,
  }),
  website: Joi.string().required().messages({
    "string.empty": `Please enter website URL`,
  }),
  description: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Description`,
    "string.max": `Event Description must be maximum 500 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
      "string.pattern.base": `Please enter email address`,
    }),
});
const NewEventSchema = Joi.object({
  description: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Description`,
    "string.max": `Event Description must be maximum 500 characters!`,
  })
});

const AgendaSchema = Joi.object({
  title: Joi.string().max(255).required().messages({
    "string.empty": `Please enter Title`,
    "string.base": `Please enter Title`,
    "string.max": `Title must be maximum 255 characters!`,
  }),
  // speaker_id: Joi.number().required().messages({
  //     "number.empty": `Please select Speaker`,
  //     "number.base": `Please select Speaker`,
  // }),
  start_session_time: Joi.string().required().messages({
    "string.empty": `Please select Start Time`,
    "string.base": "Please select Start Time",
  }),
  end_session_time: Joi.string().required().messages({
    "string.empty": `Please select End Time`,
    "string.base": "Please select End Time",
  }),
  description: Joi.string().max(5000).required().messages({
    "string.empty": `Please enter Description`,
    "string.base": `Please enter Description`,
    "string.max": `Description must be maximum 5000 characters!`,
  }),
});

const TicketSchema = Joi.array().items(
  Joi.object({
    title: Joi.string().max(50).required().messages({
      "string.empty": `Please enter Title`,
      "string.base": `Please enter Title`,
      "string.max": `Title must be maximum 50 characters!`,
    }),
    quantity_available: Joi.number().required().messages({
      "number.empty": `Please enter Quantity Available`,
      "number.base": `Please enter Quantity Available`,
    }),
    type: Joi.string().required().messages({
      "string.empty": `Please select Type`,
      "string.base": "Please select Type",
    }),
    price: Joi.number().required().messages({
      "number.empty": `Please enter Price`,
      "number.base": `Please enter Price`,
    }),
    ticket_sale_start: Joi.date().required().messages({
      "date.base": "Please enter Ticket Sale Start Date",
      "any.required": "Please enter Ticket Sale Start Date",
    }),
    ticket_sale_end: Joi.date().required().messages({
      "date.base": "Please enter Ticket Sale End Date",
      "any.required": "Please enter Ticket Sale End Date",
    }),
    ticket_description: Joi.string().max(300).required().messages({
      "string.empty": `Please enter Ticket Description`,
      "string.base": `Please enter Ticket Description`,
      "any.required": `Please enter Ticket Description`,
      "string.max": `Ticket Description must be maximum 300 characters!`,
    }),
    agenda_id: Joi.when("type", {
      is: "session_based",
      then: Joi.number().required().messages({
        "number.empty": "Please select Agenda",
        "number.base": "Please select Agenda",
      }),
      otherwise: Joi.number().allow("").optional(),
    }),
  })
);

const GuestSpeakerSchema = Joi.object({
  name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Speaker Name`,
    "string.max": `Speaker name must be maximum 50 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
      "string.pattern.base": `Please enter email address`,
    }),
  address: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Event Address`,
    "string.max": `Event Address must be maximum 500 characters!`,
  }),
  bio: Joi.string().required().max(150).messages({
    "string.empty": `Please enter Bio`,
    "string.max": `Bio must be maximum 150  characters!`,
  }),
});

export {
  eventDetailSchema,
  FAQSchema,
  SponsorSchema,
  NewSponsorSchema,
  AgendaSchema,
  TicketSchema,
  GuestSpeakerSchema,
  NewEventSchema
};
