import React from "react";
import { Typography, Box } from "@mui/material";
import Listing from "../../components/ui/Listing";
import { ADMIN_COURSES_POPULAR_LISTING } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";

const PopularCourses = () => {
	const columns = [
		{
			field: "name",
			headerName: "Course Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (param) => {
				return (
					<Box component="div" sx={{ cursor: "pointer" }}>
						{param.row.name}{" "}
					</Box>
				);
			},
		},
		{
			field: "created_by",
			headerName: "Created By",
			sortable: false,
			width: 200,
		},
		{
			field: "lessons",
			headerName: "Lessons",
			sortable: false,
			width: 150,
			valueGetter: (params) => params.row.lessons.length,
		},
		{
			field: "price",
			headerName: "Price",
			sortable: false,
			width: 150,
			renderCell: (param) => {
				return <Typography component="label"> $ {param.row.price} </Typography>;
			},
		},
		{
			field: "discount",
			headerName: "Discount",
			sortable: false,
			width: 120,
			renderCell: (param) => {
				return param.row.discount ? (
					<Typography component="label"> {param.row.discount} </Typography>
				) : (
					"-"
				);
			},
		},
	];

	const fetchCourses = async (pageNo, limit) => {
		let url = new URL(ADMIN_COURSES_POPULAR_LISTING);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	return (
		<>
			<PageHeader title="Popular Courses" />

			<Listing
				columns={columns}
				queryName={queryNames.COURSES}
				fetchRecords={fetchCourses}
				type="courses"
			/>
		</>
	);
};

export default PopularCourses;
