import React, { useRef, useState } from "react";
import {
	Grid,
	Avatar,
	Stack,
	Typography,
	Button,
	Box,
	FormControl,
	InputAdornment,
	IconButton,
	Divider,
	Autocomplete,
	TextField,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import queryNames from "../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../server";
import {
	ADMIN_PROFILE_DETAIL,
	ADMIN_PROFILE_UPDATE,
} from "../data/constants/apiRoutes";
import { validator } from "../utils/helpers/validator";
import UserSchema from "../utils/schemas/UserSchema";
import FormInput from "../components/Forms/FormInput";
import LoadingButton from "../components/Buttons/LoadingButton";
import styles from "../assets/styles/settings/settings.styles";
import moment from "moment/moment";
import _ from "lodash";
import LoadingOverlay from "../components/overlay/LoadingOverlay";
import axios from "axios";
import PhoneNumberInput from "../components/Forms/PhoneNumberInput";
import FormLabel from "../components/Forms/FormLabel";
import awsFileUpload from "../utils/helpers/awsFileUpload";
import { uploadImageType } from "../data/constants/uploadImageTypes";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../data/store";

const fileTypes = ["JPG", "PNG", "GIF", "SVG"];

function Settings() {
	const [settingsForm, setSettingsForm] = useState({
		full_name: "",
		email: "",
		mobile_number: "",
		date_of_birth: null,
		country: "",
		password: "",
		password_confirmation: "",
		profile_image: "",
		latitude: "",
		longitude: "",
	});
	const fileUploaderRef = useRef(null);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [counteries, setCounteries] = useState([]);
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();

	const validateForm = () => {
		let newErrors = validator(
			{
				...settingsForm,
				date_of_birth: settingsForm.date_of_birth
					? settingsForm.date_of_birth.format("YYYY-MM-DD")
					: null,
			},
			UserSchema
		);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleFileChange = (file) => {
		let newFile;
		if (file?.name) newFile = file;
		else newFile = file.target.files[0];

		setSettingsForm({ ...settingsForm, profile_image: newFile });
	};

	const handleButtonClick = () => {
		fileUploaderRef.current.click();
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setSettingsForm({ ...settingsForm, [name]: value });
	};

	const handleCountryChange = (event, newValue) => {
		if (newValue) {
			setSettingsForm({
				...settingsForm,
				country: newValue.label,
				latitude: newValue.latitude,
				longitude: newValue.longitude,
			});
		} else {
			setSettingsForm({
				...settingsForm,
				country: "",
				latitude: "",
				longitude: "",
			});
		}
	};
	const handleDelete = () => {
		setSettingsForm({ ...settingsForm, profile_image: "" });
	};

	const handleDOBChange = (e) => {
		setSettingsForm({ ...settingsForm, date_of_birth: e });
	};

	// moment(e).format("YYYY-MM-DD")

	const fetchSettings = async () => {
		const { status, message, data } = await get(ADMIN_PROFILE_DETAIL);
		if (status === true) {
			delete data.password;
			setSettingsForm({
				...data,
				date_of_birth:
					data.date_of_birth !== ""
						? moment(new Date(data.date_of_birth))
						: null,
			});
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const SettingsQuery = useQuery([queryNames.PROFILE], fetchSettings, {
		refetchOnWindowFocus: false,
	});

	const fetchCounteries = async () => {
		await axios
			.get("https://restcountries.com/v3.1/all")
			.then((res) => {
				const data = res.data.map((country, key) => ({
					id: key,
					label: country.name.common,
					latitude: country.latlng[0],
					longitude: country.latlng[1],
				}));
				setCounteries(data);
			})
			.catch((error) => enqueueSnackbar("Error", { variant: "error" }));
	};

	const CountryQuery = useQuery([queryNames.COUNTRY], fetchCounteries, {
		refetchOnWindowFocus: false,
	});

	const handleSaveSettings = async () => {
		let payload = _.cloneDeep(settingsForm);

		if (typeof payload.profile_image === "object") {
			const data = await awsFileUpload(
				payload.profile_image,
				uploadImageType.editProfile
			);
			if (data.status === true) payload.profile_image = data.location;
			else enqueueSnackbar("Error uploading", { variant: "error" });
		}

		if (typeof payload.profile_image === "string") {
			const { status, message, data } = await post(ADMIN_PROFILE_UPDATE, {
				...payload,
				date_of_birth: payload.date_of_birth?.format("YYYY-MM-DD") || null,
			});
			delete data.password;
			setLoggedUser({
				...loggedUser,
				data: {
					...loggedUser.data,
					...data,
				},
			});
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSaveSettings);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<Stack sx={styles.mainHeader}>
				<Typography component="h1">Settings</Typography>
				<LoadingButton
					variant="contained"
					disableElevation
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
					// sx={{ width: 132 }}
				>
					Save Changes
				</LoadingButton>
			</Stack>

			<LoadingOverlay
				loading={SettingsQuery.isLoading || CountryQuery.isLoading}
			/>

			<Box sx={styles.wrapper}>
				<Grid container direction="row" spacing={2}>
					<Grid item sm={12} lg={3} md={5}>
						<Stack
							direction="row"
							sx={styles.avatarWrapper}
							alignItems="center"
						>
							<Avatar
								src={
									settingsForm?.profile_image
										? typeof settingsForm?.profile_image === "string"
											? settingsForm?.profile_image
											: URL.createObjectURL(settingsForm?.profile_image)
										: null
								}
								sx={styles.avatar}
							/>
							<Stack sx={styles.btnWrapper}>
								<Button
									sx={styles.editBtn}
									disableElevation
									onClick={handleButtonClick}
								>
									Change Photo
								</Button>
								<Button
									sx={styles.deleteBtn}
									disableElevation
									onClick={handleDelete}
								>
									Delete Photo
								</Button>
							</Stack>
						</Stack>
					</Grid>

					<Grid
						item
						sm={12}
						md={7}
						lg={9}
						sx={{
							"@media (max-width: 1200px)": {
								flexBasis: "100%",
								maxWidth: "100%",
							},
						}}
					>
						<FileUploader
							handleChange={handleFileChange}
							name="file"
							types={fileTypes}
							children={
								<Box width="100%" sx={styles.fileBox} flex={1}>
									<Typography component="p" sx={styles.fileText}>
										Click to upload or drag and drop SVG, PNG, JPG or GIF (max.
										800x400px)
									</Typography>
									<Button
										variant="contained"
										disableElevation
										sx={styles.imgBtn}
										ref={fileUploaderRef}
									>
										Browse Image
									</Button>
								</Box>
							}
						/>
					</Grid>
				</Grid>

				<Divider sx={{ borderColor: "#e2e3e4", mt: 3, mb: 2 }} />

				<Typography component="h2" sx={styles.header}>
					Personal Info
				</Typography>
				<Grid container spacing={3} columnSpacing={3}>
					<Grid item xs={12} md={4}>
						<FormInput
							type="string"
							label="Full Name"
							labelSize={14}
							placeholder="Full Name"
							value={settingsForm.full_name}
							name="full_name"
							onChange={handleChange}
							inputType="small"
							error={errors?.full_name}
							helperText={errors?.full_name || ""}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						<FormInput
							type="email"
							label="Email Address"
							placeholder="Email Address"
							value={settingsForm.email}
							name="email"
							onChange={handleChange}
							inputType="small"
							labelSize={14}
							error={errors?.email}
							helperText={errors?.email || ""}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						{/* <FormInput
							type="number"
							label="Mobile Number"
							placeholder="Mobile Number"
							value={settingsForm.mobile_number}
							name="mobile_number"
							onChange={handleChange}
							inputType="small"
							labelSize={14}
							inputProps={{ min: 0 }}
							error={errors?.mobile_number}
							helperText={errors?.mobile_number || ""}
						/> */}
						<FormLabel label="Mobile Number" inputType="small" />
						<PhoneNumberInput
							value={settingsForm.mobile_number}
							error={errors?.mobile_number ? true : false}
							helperText={errors?.mobile_number || ""}
							onChange={(value) => {
								setSettingsForm({
									...settingsForm,
									mobile_number: value,
								});
							}}
						/>
					</Grid>

					<Grid item xs={12} md={4} sx={styles.relative}>
						<Typography component="h2" sx={styles.subHeader}>
							Date of Birth
						</Typography>
						<DatePicker
							variant="outlined"
							sx={{ width: "100%" }}
							value={settingsForm?.date_of_birth || null}
							onChange={handleDOBChange}
							slotProps={{
								textField: {
									helperText: errors?.date_of_birth || "",
									error: errors?.date_of_birth,
								},
							}}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						<Typography component="h2" sx={styles.subHeader}>
							Country
						</Typography>
						<FormControl variant="outlined" fullWidth sx={styles.select}>
							<Autocomplete
								options={counteries}
								size="small"
								sx={styles.searchInput}
								placeholder="Select Country"
								value={
									counteries.find(
										(country) =>
											country.latitude === Number(settingsForm.latitude) &&
											country.longitude === Number(settingsForm.longitude)
									) || null
								}
								onChange={handleCountryChange}
								getOptionLabel={(option) => option.label}
								renderInput={(params) => (
									<TextField {...params} placeholder="Select Country" />
								)}
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Divider sx={{ borderColor: "#e2e3e4", mt: 3, mb: 2 }} />

				<Typography component="h2" sx={styles.header}>
					Password
				</Typography>

				<Grid container spacing={3} columnSpacing={3}>
					<Grid item xs={12} md={4} sx={styles.relative}>
						<FormInput
							type={showPassword ? "text" : "password"}
							label="Password"
							placeholder="Password"
							value={settingsForm.password}
							name="password"
							onChange={handleChange}
							inputType="small"
							labelSize={14}
							error={errors?.password}
							helperText={errors?.password || ""}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword}>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12} md={4} sx={styles.relative}>
						<FormInput
							type={showConfirmPassword ? "text" : "password"}
							label="Confirm Password"
							placeholder="Confirm Password"
							value={settingsForm.password_confirmation}
							name="password_confirmation"
							onChange={handleChange}
							inputType="small"
							labelSize={14}
							error={errors?.password_confirmation}
							helperText={errors?.password_confirmation || ""}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowConfirmPassword}>
											{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Settings;
