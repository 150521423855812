import React, { useState } from "react";
import Listing from "../../components/ui/Listing";
import {
	ADMIN_MEMBERS_LISTING_SEARCH,
	ADMIN_MEMBERS_REQUEST_ACTION,
	ADMIN_MEMBERS_REQUEST_LISTING,
} from "../../data/constants/apiRoutes";
import { get, post } from "../../server";
import queryNames from "../../data/constants/queryNames";
import { Avatar, IconButton, Stack, Tooltip } from "@mui/material";
import PageHeader from "../../components/ui/PageHeader";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import BoxModal from "../../components/Modals/BoxModal";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const MemberRequest = () => {
	const [modal, setModal] = useState(false);
	const [member, setMember] = useState();

	const [search, setSearch] = useState("");
	const [finalSearch, setFinalSearch] = useState("");
	const [isSearch, setIsSearch] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();
	const client = useQueryClient();

	const columns = [
		{
			field: "full_name",
			headerName: "Full Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Stack direction="row" alignItems="center" sx={{cursor: "pointer"}}>
							<Avatar
								src={params.row.profile_image}
								sx={{
									bgcolor: "gray",
									mr: 1.5,
									width: 35,
									height: 35,
								}}
							>
								{params.row.full_name.charAt(0).toUpperCase()}
							</Avatar>
							{params.row.full_name}
						</Stack>
					</>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			sortable: false,
			width: 300,
		},
		{
			field: "mobile_number",
			headerName: "Mobile Number",
			sortable: false,
			width: 200,
			valueGetter: (params) => params.row.mobile_number || "-",
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.address || "-",
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row">
						<Tooltip title="Approve">
							<IconButton
								sx={{ color: "green" }}
								onClick={() => {
									setMember(params.row);
									setModal("confirm");
								}}
							>
								<DoneOutlinedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Reject">
							<IconButton
								sx={{ color: "red" }}
								onClick={() => {
									setMember(params.row);
									setModal("decline");
								}}
							>
								<CloseOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Stack>
				);
			},
		},
	];

	const fetchMembers = async (pageNo, limit, searchParam, isSearchParam) => {
		let url;

		if (isSearchParam === false) {
			url = new URL(ADMIN_MEMBERS_REQUEST_LISTING);
		} else if (isSearchParam && searchParam.length) {
			url = new URL(ADMIN_MEMBERS_LISTING_SEARCH);
			url.searchParams.append("search", searchParam);
			url.searchParams.append("type", "request");
		}

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.MEMBERS);
	};

	const handleClose = () => {
		setMember(null);
		setModal(false);
	};

	const handleAction = async () => {
		const { status, message } = await post(
			`${ADMIN_MEMBERS_REQUEST_ACTION}/${member?.id}`,
			{ is_approve: modal === "confirm" ? 1 : modal === "decline" && 0 }
		);
		if (status) {
			handleInvalidate();
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleAction, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = () => {
		mutation.mutate();
	};

	const handleSearchClick = () => {
		if (search.length) {
			setIsSearch(true);
			setFinalSearch(search);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearch("");
	};

	const handleProfileClick = (data) => {
		if (data.field === "full_name") {
			navigate(`/profile/details/${data.id}`);
		}
	};

	return (
		<>
			<PageHeader
				title="Member Requests"
				placeholder="Search Members"
				searchInput={true}
				search={search}
				setSearch={setSearch}
				isSearch={isSearch}
				handleCancleSearch={handleCancleSearch}
				handleSearchClick={handleSearchClick}
			/>

			<Listing
				columns={columns}
				queryName={queryNames.MEMBERS}
				fetchRecords={fetchMembers}
				handleCellClick={handleProfileClick}
				type="courses"
				search={finalSearch}
				isSearch={isSearch}
			/>

			<BoxModal
				// header="Are you sure?"
				header="Are you sure?"
				title={
					modal === "confirm"
						? `Do you want to Approve ${member?.full_name} as a Member!`
						: `Do you want to keep ${member?.full_name} as an Attendee!`
				}
				open={modal}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton={modal === "confirm" ? "Approve" : "Decline"}
				confirmButtonColor={modal === "confirm" ? "#2daf40" : "#E53935"}
				isLoading={mutation.isLoading}
			/>
		</>
	);
};

export default MemberRequest;
