import React from "react";
import Listing from "../../components/ui/Listing";
import { ADMIN_COURSES_LISTING, ADMIN_SALES_COURSES } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box } from "@mui/material";

const CourseSales = () => {
	const navigate = useNavigate();
	const columns = [
		
		{
			field: "name",
			headerName: "Course Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						component="div"
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.name}
					</Box>
				);
			},
		},
		{
			field: "author",
			headerName: "Author",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.author || "-",
		},
		{
			field: "price",
			headerName: "Purchase Amount",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.price}` || "-",
		},
		{
			field: "total_earning",
			headerName: "Total Earning",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.total_earning}` || "-",
		},
	];

	const fetchCourseSales = async (pageNo, limit) => {
		let url = new URL(ADMIN_COURSES_LISTING);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleCellClick = (data) => {
		if (data?.field === "name") {
			navigate(`/sales/courses/detail/${data.id}`);
		}
	};

	return (
		<>
			<PageHeader
				title="Course Sales"
				placeholder="Search Courses"
				searchInput={true}
			/>

			<Listing
				columns={columns}
				queryName={queryNames.SALES}
				fetchRecords={fetchCourseSales}
				type="courses"
				handleCellClick={handleCellClick}
			/>
		</>
	);
};

export default CourseSales;
