import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../assets/styles/events/createEvent.styles";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import FormInput from "../../../components/Forms/FormInput";
import FormLabel from "../../../components/Forms/FormLabel";
import { ReactComponent as Attachment } from "../../../assets/svg/Attachment.svg";
import Remove from "../../../assets/svg/Remove.svg";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import CropImage from "../../../components/Modals/CropImage";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMutation, useQuery } from "react-query";
import { validator } from "../../../utils/helpers/validator";
import { eventDetailSchema } from "../../../utils/schemas/EventSchema";
import { get, post } from "../../../server";
import {
  ADMIN_COMPANIES_LISTING,
  ADMIN_EVENT_CREATE,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import queryNames from "../../../data/constants/queryNames";
import FormAutoComplete from "../../../components/Forms/FormAutoComplete";
import GetAddressModal from "../../../components/Modals/GetAddressModal";
import _ from "lodash";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import LinearProgress from "@mui/material/LinearProgress";
import LocationAutoComplete from "../../../components/Forms/LocationAutoComplete";
import { useJsApiLoader } from "@react-google-maps/api";
import useGoogleMapsApi from "../../../utils/helpers/useGoogleMapsApi";

// const evenTypes = ["Business", "Meetings", "Conferences", "Estate Events"];

const eventTypes = [
  { value: "buissness", label: "Business" },
  { value: "meeting", label: "Meetings" },
  { value: "conference", label: "Conferences" },
  { value: "estate_event", label: "Estate Events" },
];

const EventDetailForm = ({
  currentStep,
  setCurrentStep,
  eventForm,
  setEventForm,
  eventId = null,
}) => {
  const documentRef = useRef();
  const photoRef = useRef();
  const coverRef = useRef();

  const [companies, setCompanies] = useState([]);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [start_time, setStartTime] = useState(null);

  const [isCoverHovered, setIsCoverHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [modal, setModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [cover_image, setCoverImage] = useState();

  const [errors, setErrors] = useState({});

  const [uploadProgress, setUploadProgress] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (eventForm?.details?.address) {
      setAddress(eventForm?.details?.address);
    }
  }, [eventForm?.details?.address]);

  const handleChange = (e) => {
    const {
      target: { name, value, checked },
    } = e;

    if (name === "hotel")
      setEventForm({
        ...eventForm,
        details: { ...eventForm.details, [name]: checked },
      });
    else
      setEventForm({
        ...eventForm,
        details: { ...eventForm.details, [name]: value },
      });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const maxSizeInBytes = 30 * 1024 * 1024; // 30MB

    const initialProgress = newFiles.reduce((progress, file) => {
      progress[file.name] = 0;
      return progress;
    }, {});

    const validFiles = newFiles.filter((file) => file.size <= maxSizeInBytes);

    if (validFiles.length < newFiles.length) {
      alert("Some files exceed the 30MB size limit.");
    }

    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      ...initialProgress,
    }));

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        document: [...eventForm.details.document, ...validFiles],
      },
    });
  };

  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = [...eventForm.details.document];
    updatedFiles.splice(fileIndex, 1);

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        document: updatedFiles,
      },
    });
  };

  const handleDocumentClick = () => {
    documentRef.current.click();
  };

  const handlePhotoChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    const initialProgress = newFiles.reduce((progress, file) => {
      progress[file.name] = 0;
      return progress;
    }, {});

    const validFiles = newFiles.filter((file) => file.size <= maxSizeInBytes);

    if (validFiles.length < newFiles.length) {
      alert("Some files exceed the 15MB size limit.");
    }

    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      ...initialProgress,
    }));

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        image: [...eventForm.details.image, ...validFiles],
      },
    });
  };

  const handleRemovePhoto = (fileIndex) => {
    const updatedFiles = [...eventForm.details.image];
    updatedFiles.splice(fileIndex, 1);

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        image: updatedFiles,
      },
    });
  };

  const handlePhotoClick = () => {
    photoRef.current.click();
  };

  const handleStartTimeChange = (e) => {
    let selectedDate = moment(e);

    setStartTime(selectedDate);

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        start_time: selectedDate,
      },
    });
  };

  const handleEndTimeChange = (e) => {
    let selectedDate = moment(e);

    // if (
    // 	eventForm.details.start_time &&
    // 	selectedDate.isSameOrAfter(
    // 		moment(eventForm.details.start_time).add(1, "day"),
    // 		"day"
    // 	)
    // ) {
    // 	selectedDate.set({ hour: 18, minute: 0, second: 0 });
    // }

    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        end_time: selectedDate,
      },
    });
  };

  const handleCheckInChange = (e) => {
    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        hotel_reservation_end_date: e,
      },
    });
  };

  const handleCoverClick = () => {
    if (coverRef.current) {
      coverRef.current.click();
    }
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    if (file.size <= maxSizeInBytes) {
      setCoverImage(URL.createObjectURL(file));
      setModal("eventDetail");
    } else {
      alert("Selected cover image exceeds the 15MB size limit.");
    }
  };

  const handleCoverMouseEnter = () => {
    setIsCoverHovered(true);
  };

  const handleCoverMouseLeave = () => {
    setIsCoverHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const handleCompanyChange = (event, newValue) => {
    if (newValue) {
      setEventForm({
        ...eventForm,
        details: {
          ...eventForm.details,
          company_id: newValue.id,
        },
      });
    } else
      setEventForm({
        ...eventForm,
        details: {
          ...eventForm.details,
          company_id: "",
        },
      });
  };

  const handleEventChange = (event, newValue) => {
    if (newValue) {
      setEventForm({
        ...eventForm,
        details: {
          ...eventForm.details,
          event_type: newValue.value,
        },
      });
    } else
      setEventForm({
        ...eventForm,
        details: {
          ...eventForm.details,
          event_type: "",
        },
      });
  };

  const handleAddressClick = () => {
    setAddressModal("address");
  };

  const saveLocation = (address, location) => {
    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        address: address,
        latitude: location.latitude,
        longitude: location.longitude,
      },
    });
  };

  const handleAddressChange = ({ address, lat, long }) => {
    setEventForm({
      ...eventForm,
      details: {
        ...eventForm.details,
        address: address,
        latitude: lat,
        longitude: long,
      },
    });
  };

  const fetchCompanies = async () => {
    let url = new URL(ADMIN_COMPANIES_LISTING);
    url.searchParams.append("limit", 50);
    const { status, message, data } = await get(url.toString());
    if (status === true) {
      const newData = data.data
        ?.filter((company) => company.is_enable)
        ?.map((company) => ({
          id: company.id,
          label: company.company_name,
        }));
      setCompanies(newData);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const { isLoading } = useQuery([queryNames.COMPANY], fetchCompanies, {
    keepPreviousData: true,
  });

  const validateForm = () => {
    let newErrors = validator(
      {
        ...eventForm.details,
        start_time: eventForm.details.start_time
          ? eventForm.details.start_time.format("YYYY-MM-DD hh-mm")
          : null,
        end_time: eventForm.details.end_time
          ? eventForm.details.end_time.format("YYYY-MM-DD hh-mm")
          : null,
        hotel_reservation_end_date: eventForm.details.hotel_reservation_end_date
          ? eventForm.details.hotel_reservation_end_date.format("YYYY-MM-DD")
          : null,
      },
      eventDetailSchema
    );

    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    // if (eventForm.details.document.length === 0) {
    // 	enqueueSnackbar("Please attach documents", { variant: "error" });
    // 	return false;
    // }
    // if (eventForm.details.image.length === 0) {
    // 	enqueueSnackbar("Please attach Images", { variant: "error" });
    // 	return false;
    // }
    // if (!eventForm.details.cover_image) {
    // 	enqueueSnackbar("Please attach Cover Image", { variant: "error" });
    // 	return false;
    // }
    return true;
  };

  const handleSaveEventDetails = async () => {
    let payload = _.cloneDeep(eventForm?.details);

    let newStatus = true;

    if (
      Array.isArray(payload.document) &&
      payload.document.some((item) => typeof item === "object")
    ) {
      for (let i = 0; i < payload.document.length; i++) {
        const document = payload.document[i];
        if (!document?.id) {
          const data = await awsFileUpload(
            document,
            uploadImageType.createEvent,
            setUploadProgress
          );
          if (data.status === true) {
            payload.document[i] = data.location;
          } else {
            enqueueSnackbar("Error uploading documents", { variant: "error" });
            return (newStatus = false);
          }
        }
      }
    }

    if (
      Array.isArray(payload.image) &&
      payload.image.some((item) => typeof item === "object")
    ) {
      for (let i = 0; i < payload.image.length; i++) {
        const image = payload.image[i];
        if (!image?.id && typeof image !== 'string') {
          const data = await awsFileUpload(
            image,
            uploadImageType.createEvent,
            setUploadProgress
          );
          if (data.status === true) {
            payload.image[i] = data.location;
          } else {
            enqueueSnackbar("Error uploading images", { variant: "error" });
            return (newStatus = false);
          }
        }
      }
    }

    if (typeof payload.cover_image === "object") {
      const data = await awsFileUpload(
        payload.cover_image,
        uploadImageType.createEvent
      );
      if (data.status === true) payload.cover_image = data.location;
      else {
        enqueueSnackbar("Error uploading cover image", { variant: "error" });
        return (newStatus = false);
      }
    }

    delete payload.hotel;

    if (newStatus) {
      const { status, message, data } = await post(ADMIN_EVENT_CREATE, {
        ...payload,
        start_time: payload.start_time.format("YYYY-MM-DD HH:mm"),
        end_time: payload.end_time.format("YYYY-MM-DD HH:mm"),
        hotel_reservation_end_date: payload.hotel_reservation_end_date
          ? payload.hotel_reservation_end_date.format("YYYY-MM-DD")
          : null,
      });
      if (status === true) {
        // enqueueSnackbar(message, { variant: "success" });
        setEventForm({
          ...eventForm,
          details: {
            ...eventForm.details,
            id: data.id,
            document: payload.document,
            image: payload.image,
            cover_image: payload.cover_image,
          },
          members: {
            ...eventForm.members,
            event_id: data.id,
          },
          agenda: {
            ...eventForm.agenda,
            event_id: data.id,
          },
          tickets: {
            ...eventForm.tickets,
            event_id: data.id,
          },
          faq: {
            ...eventForm.faq,
            event_id: data.id,
          },
        });
        setCurrentStep(currentStep + 1);
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleSaveEventDetails);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const handleSkip = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Stack direction="row" sx={styles.title}>
          <Typography component="h1">Event Details</Typography>
          {eventId && (
            <Button variant="contained" onClick={handleSkip} sx={{ mr: 2 }}>
              Skip
            </Button>
          )}
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
          >
            Continue
          </LoadingButton>
        </Stack>

        {/* fix to company id */}
        <Grid container spacing={3}>
          <Grid item md={4} sm={12}>
            <FormInput
              label="Event Name"
              placeholder="Type Event Name"
              value={eventForm?.details?.event_name}
              name="event_name"
              onChange={handleChange}
              error={errors?.event_name}
              helperText={errors?.event_name || ""}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <FormInput
              label="Venue Name"
              placeholder="Type Venue Name"
              value={eventForm?.details?.venue_name}
              name="venue_name"
              onChange={handleChange}
              error={errors?.venue_name}
              helperText={errors?.venue_name || ""}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <FormAutoComplete
              label="Company"
              placeholder="Select Event Company"
              options={companies}
              value={
                companies?.find(
                  (option) => option?.id === eventForm.details?.company_id
                ) || null
              }
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={handleCompanyChange}
              error={errors?.company_id}
              helperText={errors?.company_id || ""}
            />
          </Grid>

          <Grid item md={8} sm={12}>
            {/* <FormInput
							label="Address"
							placeholder="Address"
							value={eventForm?.details?.address}
							name="address"
							// onChange={handleChange}
							onClick={handleAddressClick}
							InputProps={{
								readOnly: true,
							}}
							sx={{
								"& input": {
									cursor: "pointer",
									bgcolor: "rgba(226, 227, 228, 0.2)",
									height: 40,
									boderRadius: "4px",
								},
							}}
							error={errors?.address}
							helperText={errors?.address || ""}
						/> */}
            <LocationAutoComplete
              label="Address"
              locationType="address"
              placeholder="Event Address"
              value={eventForm?.details?.address}
              // data={card}
              // setData={setCard}
              error={errors?.address ? true : false}
              helperText={errors?.address || ""}
              handleAddressChange={handleAddressChange}
              types={["establishment"]}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              address={address}
              setAddress={setAddress}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <FormAutoComplete
              label="Event Type"
              placeholder="Select Event Type"
              options={eventTypes}
              value={
                eventTypes?.find(
                  (option) => option?.value === eventForm.details?.event_type
                ) || null
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              onChange={handleEventChange}
              error={errors?.event_type}
              helperText={errors?.event_type || ""}
            />
          </Grid>
        </Grid>

        <Divider sx={styles.divider} />

        <Grid item md={12}>
          <FormLabel label="Documents" />
          <Stack direction="row" gap={2} flexWrap="wrap">
            <Tooltip title="Upload Documents">
              <Box
                sx={styles.attachmentContainer}
                onClick={handleDocumentClick}
              >
                +
              </Box>
            </Tooltip>
            <input
              type="file"
              multiple
              hidden
              onChange={handleFileChange}
              id={`documentUpload`}
              ref={documentRef}
            />
            {eventForm.details.document.map((file, fileIndex) => {
              const fileNameWithoutExtension =
                typeof file === "string"
                  ? (() => {
                      const parts = file.split("/");
                      const fileNameWithExtension = parts[parts.length - 1];
                      return fileNameWithExtension.replace(/\.[^/.]+$/, "");
                    })()
                  : file?.name?.replace(/\.[^/.]+$/, "");

              const parts =
                typeof file === "string"
                  ? file.split(".")
                  : file.name.split(".");
              const simplifiedContentType = parts[parts.length - 1];

              return (
                <div key={fileIndex}>
                  <Stack
                    sx={styles.documentContainer}
                    direction="row"
                    gap={1}
                    key={fileIndex}
                  >
                    <Box
                      component="img"
                      src={Remove}
                      sx={styles.removeIcon}
                      onClick={() => handleRemoveFile(fileIndex)} // Remove file
                    />
                    <Attachment overflow="visible" />
                    <Stack>
                      <Typography component="h6">
                        {fileNameWithoutExtension}
                      </Typography>
                      <Typography component="label">
                        {simplifiedContentType.toUpperCase()}
                      </Typography>
                    </Stack>
                  </Stack>
                  {uploadProgress[file.name] > 0 && (
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[file.name] || 0}
                      sx={{ mt: 1 }}
                    />
                  )}
                </div>
              );
            })}
          </Stack>
        </Grid>

        <Divider sx={styles.divider} />

        <Grid item md={12}>
          <FormLabel label="Photos" />
          <Stack direction="row" gap={2} flexWrap="wrap">
            <Tooltip title="Upload Photos">
              <Box sx={styles.addPhotoContainer} onClick={handlePhotoClick}>
                <AddPhotoAlternateOutlinedIcon sx={{ width: 30, height: 30 }} />
              </Box>
            </Tooltip>
            <input
              type="file"
              multiple
              hidden
              onChange={handlePhotoChange}
              id={`photoUpload`}
              ref={photoRef}
              accept="image/*"
              size={1}
            />
            {eventForm.details.image.map((photo, fileIndex) => {
              return (
                <div key={fileIndex}>
                  <Box position="relative" key={fileIndex}>
                    <Box
                      sx={styles.imageContainer}
                      component="img"
                      src={
                        photo?.id
                          ? photo?.name
                          : typeof photo === "string"
                          ? photo
                          : URL?.createObjectURL(photo)
                      }
                      loading="lazy"
                    />
                    <Box
                      component="img"
                      src={Remove}
                      sx={styles.photoRemoveIcon}
                      onClick={() => handleRemovePhoto(fileIndex)}
                    />{" "}
                  </Box>

                  {uploadProgress[photo.name] > 0 && (
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[photo.name] || 0}
                    />
                  )}
                </div>
              );
            })}
          </Stack>
        </Grid>

        <Divider sx={styles.divider} />

        <Grid container spacing={3}>
          <Grid item md={4} sm={12}>
            <FormLabel label="Start Time" />
            <DateTimePicker
              variant="outlined"
              sx={{ ...styles.datePicker, width: "100%" }}
              value={eventForm?.details?.start_time || null}
              onChange={handleStartTimeChange}
              // disablePast
              slotProps={{
                inputProps: {
                  input: false, // Disable keyboard input
                },
                textField: {
                  helperText: errors?.start_time || "",
                  error: errors?.start_time,
                },
              }}
              closeOnSelect={false}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <FormLabel label="End Time" />
            <DateTimePicker
              variant="outlined"
              sx={{ ...styles.datePicker, width: "100%" }}
              value={eventForm?.details?.end_time || null}
              onChange={handleEndTimeChange}
              minDateTime={start_time}
              slotProps={{
                textField: {
                  helperText: errors?.end_time || "",
                  error: errors?.end_time,
                },
              }}
              closeOnSelect={false}
            />
          </Grid>

          <Grid item md={4} sm={12}></Grid>

          <Grid item md={12} sm={12}>
            <FormInput
              label="Event Description"
              placeholder="Type Event Description"
              value={eventForm?.details?.description}
              name="description"
              onChange={handleChange}
              multiline
              rows={4}
              error={errors?.description}
              helperText={errors?.description || ""}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormLabel label="Hotel Reservation URL" />
              <Switch
                value={eventForm?.details?.hotel ? true : false}
                size="small"
                onChange={handleChange}
                name="hotel"
              />
            </Stack>
            <FormInput
              placeholder="Enter URL"
              type="url"
              value={eventForm?.details?.hotel_reservation_url}
              name="hotel_reservation_url"
              onChange={handleChange}
              error={errors?.hotel_reservation_url}
              helperText={errors?.hotel_reservation_url || ""}
              disabled={!eventForm?.details?.hotel}
            />
          </Grid>

          {eventForm?.details?.hotel && (
            <>
              <Grid item md={4} sm={12}>
                <FormInput
                  label="Hotel Name"
                  placeholder="Hotel Name"
                  value={eventForm?.details?.hotel_description}
                  name="hotel_description"
                  onChange={handleChange}
                  error={errors?.hotel_description}
                  helperText={errors?.hotel_description || ""}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <FormLabel label="Check In Date" />
                <DatePicker
                  variant="outlined"
                  sx={{ ...styles.datePicker, width: "100%" }}
                  value={eventForm?.details?.hotel_reservation_end_date || null}
                  onChange={handleCheckInChange}
                  // disablePast
                  slotProps={{
                    textField: {
                      helperText: errors?.hotel_reservation_end_date || "",
                      error: errors?.hotel_reservation_end_date,
                    },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Divider sx={styles.divider} />

        <FormLabel label="Event Image" />
        {!eventForm?.details?.cover_image ? (
          <Box sx={styles.eventImageContainer}>
            <Typography component="label">+Add Cover Image</Typography>
            <Button
              variant="outlined"
              id={`documentUpload`}
              onClick={handleCoverClick}
            >
              Browse Image
            </Button>
            <input
              type="file"
              hidden
              onChange={handleCoverChange}
              id={`documentUpload`}
              ref={coverRef}
              accept="image/*"
            />
          </Box>
        ) : (
          <Box
            position="relative"
            onMouseEnter={handleCoverMouseEnter}
            onMouseLeave={handleCoverMouseLeave}
          >
            <Box
              component="img"
              src={
                typeof eventForm?.details?.cover_image === "string"
                  ? eventForm?.details?.cover_image
                  : URL?.createObjectURL(eventForm?.details?.cover_image)
              }
              sx={{
                ...styles.coverImageContainer,
                opacity: isCoverHovered ? 0.3 : 1,
                transition: "opacity 0.3s ease",
              }}
              loading="lazy"
            />
            <Stack
              sx={{
                ...styles.coverHover,
                display: isCoverHovered ? "flex" : "none", // Control visibility
                opacity: isCoverHovered ? 1 : 0.5, // Control opacity
              }}
            >
              <Typography component="label">+Add Cover Image</Typography>
              <Button
                variant="outlined"
                onClick={handleCoverClick}
                id={`documentUpload`}
              >
                Browse Image
              </Button>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Stack>
          </Box>
        )}
      </Box>

      <Fab
        sx={{
          ...styles.scrollButtonStyles,
          ...(isVisible && styles.visibleScrollButtonStyles),
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>

      <CropImage
        modal={modal}
        setModal={setModal}
        image={cover_image}
        user={eventForm}
        setUser={setEventForm}
        ratio={16 / 9}
      />

      {/* <GetAddressModal
				modal={addressModal === "address" || false}
				setModal={setAddressModal}
				saveLocation={saveLocation}
			/> */}
    </>
  );
};

export default EventDetailForm;
