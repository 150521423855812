import React, { useEffect, useState } from "react";
import Listing from "../../components/ui/Listing";
import {
    ADMIN_COURSE_SALES_DETAIL,
	ADMIN_EVENT_SALES_DETAIL,
	ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL,
	ADMIN_SALES_COURSES,
	ADMIN_SALES_TICKETS,
} from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import Loader from "../../components/overlay/Loader";
import GoBackButton from "../../components/Buttons/GoBackButton";
import moment from "moment";

const CourseSalesDetail = () => {

	const { courseId } = useParams();

	const columns = [
		{
			field: "name",
			headerName: "Customer Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			valueGetter: (params) => params.row.user.full_name || "-",
		},
		{
			field: "course_name",
			headerName: "Course Name",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.course.name || "-",
		},
		{
			field: "author",
			headerName: "Author",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.course.author || "-",
		},
		{
			field: "price",
			headerName: "Purchase Amount",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.price}` || "-",
		},
		{
			field: "total_price",
			headerName: "Course Price",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.course.price}` || "-",
		},
		{
			field: "created_at",
			headerName: "Purchase Date",
			sortable: false,
			flex: 1,
			valueGetter: (params) => moment(params.row.created_at).format("YYYY-MM-DD") || "-",
		},
	];

	const fetchCourseDetail = async (pageNo, limit) => {
		let url = new URL(`${ADMIN_SALES_COURSES}/${courseId}`);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<PageHeader
					title={`Sales Details`}
					// placeholder="Search Events"
					// searchInput={true}
				/>

				<GoBackButton url={-1} />
			</Stack>

			<Listing
				columns={columns}
				fetchRecords={fetchCourseDetail}
				queryName={`${queryNames.SALES}_DETAIL`}
				type="courses"
			/>
		</>
	);
};

export default CourseSalesDetail;
