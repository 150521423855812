import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import FormInput from "../../components/Forms/FormInput";
import FormLabel from "../../components/Forms/FormLabel";
import CropImage from "../../components/Modals/CropImage";
import styles from "../../assets/styles/company/addEditCompany.styles";
import { get, post } from "../../server";
import {
	ADMIN_COMPANIES_DETAIL,
	ADMIN_COMPANY_ADD_OR_UPDATE,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import queryNames from "../../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import { validator } from "../../utils/helpers/validator";
import _ from "lodash";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../data/constants/routes";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import PhoneNumberInput from "../../components/Forms/PhoneNumberInput";
import GetAddressModal from "../../components/Modals/GetAddressModal";
import CompanySchema from "../../utils/schemas/CompanySchema";
import awsFileUpload from "../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../data/constants/uploadImageTypes";
import GoBackButton from "../../components/Buttons/GoBackButton";
import LocationAutoComplete from "../../components/Forms/LocationAutoComplete";

const initialState = {
	company_logo: "",
	cover_image: "",
	full_name: "",
	company_name: "",
	address: "",
	latitude: "",
	longitude: "",
	contact_no: "",
	registration_no: "",
	role: "",
	description: "",
};

const AddEditCompany = () => {
	const [company, setCompany] = useState(initialState);

	const [modal, setModal] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [address, setAddress] = useState("");
	const [selectedLocation, setSelectedLocation] = useState(null);

	const [profilePhoto, setProfilePhoto] = useState(null);
	const [coverPhoto, setCoverPhoto] = useState(null);

	const avatarRef = useRef();
	const coverImageRef = useRef();

	const [errors, setErrors] = useState({});

	const { companyId } = useParams();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [isCoverHovered, setIsCoverHovered] = useState(false);

	const isId = companyId !== null && typeof companyId !== "undefined";

	const handleChange = (e) => {
		const {
			target: { name, value },
		} = e;

		if (name === "full_name" || name === "company_name") {
			const sanitizedValue = value.replace(/\s{2,}/g, " ").slice(0, 100);
			setCompany({ ...company, [name]: sanitizedValue });
		} else if (name === "role") {
			const sanitizedValue = value.replace(/\s{2,}/g, " ").slice(0, 50);
			setCompany({ ...company, [name]: sanitizedValue });
		} else if (name === "bio") {
			const sanitizedValue = value.replace(/\s{2,}/g, " ").slice(0, 1000);
			setCompany({ ...company, [name]: sanitizedValue });
		} else {
			const sanitizedValue = value.replace(/\s{2,}/g, " ");
			setCompany({ ...company, [name]: sanitizedValue });
		}
	};

	const handleAvatarClick = () => {
		avatarRef.current.click();
	};

	const handleCoverClick = () => {
		coverImageRef.current.click();
	};

	const handleAvatarChange = (e) => {
		const selectedFile = URL.createObjectURL(e.target.files[0]);
		setProfilePhoto(selectedFile);
		setModal("company_logo");
	};

	const handleCoverChange = (e) => {
		const selectedFile = URL.createObjectURL(e.target.files[0]);
		setCoverPhoto(selectedFile);
		setModal("cover_image");
	};

	const handleCoverMouseEnter = () => {
		setIsCoverHovered(true);
	};

	const handleCoverMouseLeave = () => {
		setIsCoverHovered(false);
	};

	const handleAddressChange = ({ address, lat, long }) => {
		setCompany({
			...company,
			address: address,
			latitude: lat,
			longitude: long,
		});
	};

	const fetchCompany = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COMPANIES_DETAIL}/${companyId}`
		);
		if (status === true) {
			setCompany({
				...data.company,
				full_name: data.company.name,
			});
			setAddress(data.company.address);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const companyQuery = useQuery([queryNames.COMPANY, companyId], fetchCompany, {
		enabled: isId,
		refetchOnWindowFocus: false,
	});

	const validateForm = () => {
		const newErrors = validator(company, CompanySchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		if (company.company_logo.length === 0) {
			enqueueSnackbar("Please attach Company Logo", { variant: "error" });
			return false;
		}
		// if (company.cover_image.length === 0) {
		// 	enqueueSnackbar("Please attach Company Logo", { variant: "error" });
		// 	return false;
		// }
		return true;
	};

	const handleSaveCompany = async () => {
		let payload = _.cloneDeep(company);

		let newStatus = true;

		if (typeof payload.company_logo === "object") {
			const data = await awsFileUpload(
				payload.company_logo,
				uploadImageType.comapnyProfile
			);
			if (data.status === true) payload.company_logo = data.location;
			else enqueueSnackbar("Error uploading", { variant: "error" });
		}

		if (typeof payload.cover_image === "object") {
			const data = await awsFileUpload(
				payload.cover_image,
				uploadImageType.comapnyProfile
			);
			if (data.status === true) payload.cover_image = data.location;
			else enqueueSnackbar("Error uploading", { variant: "error" });
		}

		if (newStatus) {
			const { status, message } = await post(
				ADMIN_COMPANY_ADD_OR_UPDATE,
				payload
			);
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				navigate(routes.ADMIN_COMPANY);
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSaveCompany);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const saveLocation = (address, location, place) => {
		setCompany({
			...company,
			latitude: location.latitude,
			longitude: location.longitude,
			address: address,
		});
	};

	return (
		<>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1">{isId ? "Edit" : "Add"} Company</Typography>
				<GoBackButton url={routes.ADMIN_COMPANY} text="Cancel" />
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
					sx={styles.saveBtn}
				>
					{isId ? "Save Changes" : "Save"}
				</LoadingButton>
			</Stack>

			<LoadingOverlay loading={companyQuery.isLoading} />

			<Box sx={styles.container}>
				<FormLabel label="Logo" />
				{!company?.company_logo ? (
					<Box sx={styles.attachmentContainer} onClick={handleAvatarClick}>
						+
					</Box>
				) : (
					<Avatar
						sx={styles.avatar}
						src={
							typeof company?.company_logo === "string"
								? company?.company_logo
								: URL?.createObjectURL(company?.company_logo)
						}
						onClick={handleAvatarClick}
					/>
				)}
				<input
					type="file"
					multiple
					hidden
					onChange={handleAvatarChange}
					id={"avatarupload"}
					ref={avatarRef}
					accept="image/*"
				/>

				<FormLabel label="Cover Photo" />
				{!company?.cover_image ? (
					<Box sx={styles.eventImageContainer}>
						<Typography component="label">+Add Cover Image</Typography>
						<Button variant="outlined" onClick={handleCoverClick}>
							Browse Image
						</Button>
						<input
							type="file"
							hidden
							onChange={handleCoverChange}
							id={`documentUpload`}
							ref={coverImageRef}
							accept="image/*"
						/>
					</Box>
				) : (
					<Box
						position="relative"
						onMouseEnter={handleCoverMouseEnter}
						onMouseLeave={handleCoverMouseLeave}
					>
						<Box
							component="img"
							src={
								typeof company?.cover_image === "string"
									? company?.cover_image
									: URL.createObjectURL(company?.cover_image)
							}
							sx={{
								...styles.coverImageContainer,
								opacity: isCoverHovered ? 0.3 : 1,
								transition: "opacity 0.3s ease",
							}}
							loading="lazy"
						/>
						<Stack
							sx={{
								...styles.coverHover,
								display: isCoverHovered ? "flex" : "none",
								opacity: isCoverHovered ? 1 : 0.5,
							}}
						>
							<Typography component="label">+Add Cover Image</Typography>
							<Button variant="outlined" onClick={handleCoverClick}>
								Browse Image
							</Button>
						</Stack>
					</Box>
				)}

				<Grid container spacing={3}>
					<Grid item md={4} sm={12}>
						<FormInput
							label="Company Name"
							placeholder="Company Name"
							value={company?.company_name}
							name="company_name"
							onChange={handleChange}
							error={errors?.company_name}
							helperText={errors?.company_name || ""}
						/>
					</Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Company Registration No."
							placeholder="Company Registration No."
							value={company?.registration_no}
							name="registration_no"
							onChange={handleChange}
							error={errors?.registration_no}
							helperText={errors?.registration_no || ""}
						/>
					</Grid>

					<Grid item md={4} sm={12}>
						<PhoneNumberInput
							label="Company Contact"
							// placeholder="Company Contact"
							value={company?.contact_no}
							name="contact_no"
							onChange={(value) => {
								setCompany({
									...company,
									contact_no: value,
								});
							}}
							error={errors?.contact_no}
							helperText={errors?.contact_no || ""}
							inputClassName="bigForm"
						/>
					</Grid>

					<Grid item md={12} sm={12}>
						<FormInput
							label="Company Address"
							placeholder="Company Address"
							value={company?.address}
							error={errors?.address}
							helperText={errors?.address || ""}
							InputProps={{
								readOnly: true,
							}}
							sx={{
								"& input": {
									cursor: "pointer",
									bgcolor: "rgba(226, 227, 228, 0.2)",
									height: 35,
									boderRadius: "4px",
								},
							}}
							onClick={() => setAddressModal(true)}
						/>
						{/* <LocationAutoComplete
							label="Address"
							locationType="address"
							placeholder="Company Address"
							// data={card}
							// setData={setCard}
							handleAddressChange={handleAddressChange}
							types={["establishment"]}
							selectedLocation={selectedLocation}
							setSelectedLocation={setSelectedLocation}
							address={address}
							setAddress={setAddress}
							error={errors?.address}
							helperText={errors?.address || ""}
						/> */}
					</Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Full Name"
							placeholder="Full Name"
							value={company?.full_name}
							name="full_name"
							onChange={handleChange}
							error={errors?.full_name}
							helperText={errors?.full_name || ""}
						/>
					</Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Role"
							placeholder="Role"
							value={company?.role}
							name="role"
							onChange={handleChange}
							error={errors?.role}
							helperText={errors?.role || ""}
						/>
					</Grid>

					<Grid item md={12} sm={12}>
						<FormInput
							label="Description"
							placeholder="Description"
							value={company?.description}
							name="description"
							onChange={handleChange}
							multiline
							rows={4}
							error={errors?.description}
							helperText={errors?.description || ""}
						/>
					</Grid>
				</Grid>
			</Box>

			<CropImage
				modal={modal}
				setModal={setModal}
				image={modal === "company_logo" ? profilePhoto : coverPhoto}
				user={company}
				setUser={setCompany}
				ratio={modal === "company_logo" ? 4 / 4 : 16 / 9}
				cropShape={modal === "company_logo" ? "round" : ""}
			/>

			<GetAddressModal
				modal={addressModal}
				setModal={setAddressModal}
				saveLocation={saveLocation}
				currentAddress={address}
			/>
		</>
	);
};

export default AddEditCompany;
