import {
	Modal,
	Box,
	Stack,
	Typography,
	Button,
	IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import LoadingButton from "../Buttons/LoadingButton";
import { useEffect, useState } from "react";
import FormInput from "../Forms/FormInput";
import { validator } from "../../utils/helpers/validator";
import InviteSubAdminSchema from "../../utils/schemas/InviteSubAdminSchema";
import { useMutation } from "react-query";
import { post } from "../../server";
import { useSnackbar } from "notistack";
import {
	ADMIN_SUB_ADMIN_EDIT,
	ADMIN_SUB_ADMIN_INVITE,
} from "../../data/constants/apiRoutes";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 450,
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 15,
		p: 2,
		// "& button": {
		// 	width: 120,
		// 	boxShadow: "none",
		// 	fontWeight: 600,
		// },
	},
	topWrapper: {
		"& h1": {
			fontSize: 18,
			fontWeight: 600,
			flexGrow: 1,
			textAlign: "center",
			color: "#636363",
		},
	},
	closeIconBtn: {
		textAlign: "end",
		width: "40px !important",
		height: 40,
	},
	button: {
		mt: 2,
		width: 200,
		fontSize: 15,
		fontWeight: 500,
		textTransform: "capitalize",
		borderRadius: "4px",
		bgcolor: "#2B2A69",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
};

const initialState = {
	name: "",
	email: "",
};

const ManageSubadminModal = ({ modal, handleClose, adminData = {} }) => {
	const [invite, setInvite] = useState(initialState);

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();

	const validateForm = () => {
		let newErrors = validator(invite, InviteSubAdminSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleChange = (e) => {
		const {
			target: { name, value },
		} = e;
		setInvite({ ...invite, [name]: value });
	};

	const handleAllClose = () => {
		handleClose();
		setInvite(initialState);
		setErrors({});
	};

	const handleSubAdmin = async () => {
		const { status, message, data } = await post(
			Object.keys(adminData).length > 0
				? `${ADMIN_SUB_ADMIN_EDIT}/${invite.id}`
				: ADMIN_SUB_ADMIN_INVITE,
			invite
		);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleAllClose();
		} else {
			enqueueSnackbar(data?.email[0] || message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSubAdmin);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	useEffect(() => {
		if (Object.keys(adminData).length > 0) {
			setInvite(adminData);
		}
	}, [adminData]);

	return (
		<>
			<Modal open={modal} onClose={handleAllClose}>
				<Box sx={styles.container}>
					<Stack direction="row" sx={styles.topWrapper} alignItems="center">
						<Box component="div" />
						<Typography component="h1">
							{Object.keys(adminData).length > 0 ? "Edit" : "Invite"} Sub Admin
						</Typography>
						<IconButton onClick={handleAllClose} sx={styles.closeIconBtn}>
							<Close />
						</IconButton>
					</Stack>

					<Box px={2} pb={2}>
						<FormInput
							value={invite.name}
							type="text"
							name="name"
							placeholder="Name"
							label="Name"
							onChange={handleChange}
							error={errors?.name}
							helperText={errors?.name || ""}
							sx={{ mb: 2 }}
						/>
						<FormInput
							value={invite.email}
							type="email"
							name="email"
							placeholder="Email"
							label="Email"
							onChange={handleChange}
							error={errors?.email}
							helperText={errors?.email || ""}
							disabled={Object.keys(adminData).length > 0}
						/>
					</Box>

					<Box display="flex" justifyContent="center">
						<LoadingButton
							variant="contained"
							sx={styles.button}
							onClick={handleSubmit}
							isLoading={mutation.isLoading}
						>
							{Object.keys(adminData).length > 0 ? "Update" : "Invite"} Sub
							Admin
						</LoadingButton>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default ManageSubadminModal;
