
import Joi from "joi";


const CompanySchema = Joi.object({
    full_name: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Full name`,
        "string.max": `Full name must be maximum 50 characters!`,
    }),
    company_name: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Company name`,
        "string.max": `Company name must be maximum 50 characters!`,
    }),
    address: Joi.string().max(500).required().messages({
        "string.empty": `Please enter Address`,
        "string.max": `Address must be maximum 500 characters!`,
    }),
    contact_no: Joi.number().required().messages({
        "number.empty": `Please enter Mobile number `,
        "number.base": `Please enter Mobile number `,
    }),
    registration_no: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Registration No.`,
        "string.max": `Registration No. must be maximum 50 characters!`,
    }),
    role: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Role`,
        "string.max": `Role must be maximum 50 characters!`,
    }),
    description: Joi.string().max(500).required().messages({
        "string.empty": `Please enter Description`,
        "string.max": `Description must be maximum 500 characters!`,
    }),
});

export default CompanySchema;
