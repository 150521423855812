import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormInput from "../../../../components/Forms/FormInput";
import { enqueueSnackbar } from "notistack";
import styles from "../../../../assets/styles/events/createEvent.styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ADMIN_EVENT_GUEST_SPEAKER_DELETE,
  ADMIN_EVENT_MEMBER_USER_EXISTS,
  ADMIN_EVENT_CREATE,
} from "../../../../data/constants/apiRoutes";
import { destroy, get, post } from "../../../../server";
import { useQuery, useMutation } from "react-query";
import { Close, Delete } from "@mui/icons-material";
import LocationAutoComplete from "../../../../components/Forms/LocationAutoComplete";
import FormLabel from "../../../../components/Forms/FormLabel";
import PhoneNumberInput from "../../../../components/Forms/PhoneNumberInput";
import { validator } from "../../../../utils/helpers/validator";
import { GuestSpeakerSchema } from "../../../../utils/schemas/EventSchema";
import LoadingButton from "../../../../components/Buttons/LoadingButton";
import { useRef } from "react";
import awsFileUpload from "../../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../../data/constants/uploadImageTypes";
import _ from "lodash";

const initialState = {
  name: "",
  email: "",
  bio: "",
  image: "",
  address: "",
  phone: null,
};

const SpeakerTab = ({
  speakers,
  setSpeakers,
  loading = false,
  eventForm,
  setEventForm,
  memberStep,
	setMemberStep,
  handleSkip,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [typedEmail, setTypedEmail] = useState("");
  const [searchedEmails, setSearchedEmails] = useState([]);
  const [enableSearch, setEnableSearch] = useState(false);

  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});

  const [address, setAddress] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const fileInputRef = useRef();

  useEffect(() => {
    const newAddresses = eventForm?.members?.guest_speakers?.map((guest) => {
      return guest.address;
    });

    setAddress(newAddresses);
  }, [eventForm?.members?.guest_speakers]);

  const handleEmailAdd = (index) => {
    const selectedUser = searchedEmails[index];
    const newEmail = {
      profile_image: selectedUser.profile_image,
      full_name: selectedUser.full_name,
      email: selectedUser.email,
      id: selectedUser.id,
    };

    setSpeakers([...speakers, newEmail]);

    const updatedSearchedEmails = searchedEmails.filter(
      (user, i) => i !== index
    );
    setSearchedEmails(updatedSearchedEmails);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckboxChange = (member, checked) => {
    if (checked) {
      if (member.id) {
        setEventForm((prevForm) => ({
          ...prevForm,
          members: {
            ...prevForm.members,
            speaker_id: [...prevForm.members.speaker_id, member.id],
          },
        }));
      } else {
        setEventForm((prevForm) => ({
          ...prevForm,
          members: {
            ...prevForm.members,
            speakers_email: [...prevForm.members.speakers_email, member.email],
          },
        }));
      }
    } else {
      if (member.id) {
        // Remove the email from eventForm.members.speakers_email
        setEventForm((prevForm) => ({
          ...prevForm,
          members: {
            ...prevForm.members,
            speaker_id: prevForm.members.speaker_id.filter(
              (id) => id !== member.id
            ),
            speakers: prevForm.members.speakers.filter(
              (speaker) => speaker.id !== member.id
            ),
          },
        }));
      } else
        setEventForm((prevForm) => ({
          ...prevForm,
          members: {
            ...prevForm.members,
            speakers_email: prevForm.members.speakers_email.filter(
              (email) => email !== member.email
            ),
          },
        }));
    }
  };

  const findMember = async () => {
    let url;
    url = new URL(ADMIN_EVENT_MEMBER_USER_EXISTS);
    url.searchParams.append("email", typedEmail);

    if(enableSearch){
      const { status, message, data } = await get(url.toString());
      if (status) {
        const filteredData = data.filter(
          (newMember) =>
            !speakers.some((member) => member.email === newMember.email)
        );
        setSearchedEmails(filteredData);
      }
      if (!data.length) {
        if (isValidEmail(typedEmail)) {
          const isEmailInMembers = speakers.some(
            (member) => member.email === typedEmail
          );
          if (isEmailInMembers) {
            setSearchedEmails([]);
          } else {
            const newEmail = {
              profile_image: null,
              full_name: typedEmail,
              email: typedEmail,
              type: "not_exist",
            };
            setSearchedEmails([newEmail]);
          }
        }
      }
      setEnableSearch(false);
    }
  };

  const memberQuery = useQuery(["sponsosrsM", enableSearch], findMember, {
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...eventForm?.members?.guest_speakers];
    list[index][name] = value;
    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const handleImageAdd = (e, index) => {
    const list = [...eventForm?.members?.guest_speakers];
    list[index].image = e.target.files[0];
    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const handleRemoveImage = (index) => {
    const list = [...eventForm?.members?.guest_speakers];
    list[index].image = "";
    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const handleAddressChange = ({ address, lat, long }, index) => {
    const list = [...eventForm?.members?.guest_speakers];
    list[index].address = address;
    list[index].latitude = lat;
    list[index].longitude = long;
    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const handleMobileChange = (value, index) => {
    const list = [...eventForm?.members?.guest_speakers];
    list[index].phone = value;
    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const validate = () => {
    const newSpeaker =
      eventForm?.members?.guest_speakers[
        eventForm?.members?.guest_speakers?.length - 1
      ];
    const newErrors = validator(newSpeaker, GuestSpeakerSchema);
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAdd = () => {
    if (!validate()) return;
    const list = [...eventForm?.members?.guest_speakers];
    list.push({ ...initialState });

    setEventForm({
      ...eventForm,
      members: { ...eventForm?.members, guest_speakers: list },
    });
  };

  const handleRemove = async (guest) => {
    if (guest.id) {
      const { status, message } = await destroy(
        `${ADMIN_EVENT_GUEST_SPEAKER_DELETE}/${guest.id}`
      );
      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        const list = [...eventForm?.members?.guest_speakers];
        const newList = list.filter((item) => item.id !== guest.id);
        if (newList.length === 0) newList.push(initialState);
        setEventForm({
          ...eventForm,
          members: { ...eventForm?.members, guest_speakers: newList },
        });
      } else enqueueSnackbar(message, { variant: "error" });
    } else {
      const list = [...eventForm?.members?.guest_speakers];
      const newList = list.filter((item) => item.email !== guest.email);
      if (newList.length === 0) newList.push(initialState);
      setEventForm({
        ...eventForm,
        members: { ...eventForm?.members, guest_speakers: newList },
      });
      setAddress((prevAddress) =>
        prevAddress.filter((item) => item !== guest.address)
      );
      setSelectedLocation((prevLocation) =>
        prevLocation.filter((item) => item !== guest.address)
      );
    }

    setErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    handleClose();
  };

  const handleSaveEventMembers = async () => {
    const eventPayload = _.cloneDeep(eventForm.members);

    if (eventPayload.guest_speakers.length > 0) {
      await Promise.all(
        eventPayload.guest_speakers.map(async (speaker) => {
          if (typeof speaker.image !== "string") {
            const data = await awsFileUpload(
              speaker.image,
              uploadImageType.createSpeaker
            );

            if (data.status === true) {
              speaker.image = data.location;
            }
          }
        })
      );
    }

    if (!eventPayload.guest_speakers[0]?.name) {
      eventPayload.guest_speakers = [];
    }
    const { status, message, data } = await post(
      ADMIN_EVENT_CREATE,
      eventPayload
    );
    if (status === true) {
      const newSpeakers = data?.speakers
        ?.filter(
          (speaker) => speaker?.event_speaker?.is_guest_speaker === false
        )
        .map((speaker) => ({
          value: speaker?.id,
          id: speaker?.event_speaker?.id,
          label: speaker?.event_speaker.full_name,
          full_name: speaker?.event_speaker.full_name,
          email: speaker?.event_speaker.email,
          profile_image: speaker?.event_speaker.profile_image,
        }));
      const newGuestSpeakers = data?.speakers
        ?.filter(
          (speaker) => speaker?.event_speaker?.is_guest_speaker === true
        )
        .map((speaker) => ({
          value: speaker?.id,
          id: speaker?.event_speaker?.id,
          label: speaker?.event_speaker.full_name,
          name: speaker?.event_speaker.full_name,
          email: speaker?.event_speaker.email,
          image: speaker?.event_speaker.profile_image,
          bio: speaker?.event_speaker?.description,
          phone: speaker?.event_speaker?.mobile_number,
          address: speaker?.event_speaker?.address,
        }));
      setEventForm({
        ...eventForm,
        members: {
          ...eventForm.members,
          speakers: newSpeakers,
          guest_speakers: newGuestSpeakers,
        },
      });

      // enqueueSnackbar(message, { variant: "success" });
      setMemberStep(memberStep +1);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const mutation = useMutation(handleSaveEventMembers);

	const handleApiSubmit = (e) => {
    e.preventDefault();
		mutation.mutate();
	};

  return (
    <>
      {/* <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ ...styles.continueBtn, width: 200, mb: 1 }}
          onClick={() => setModal(true)}
        >
          Add Guest Speaker
        </Button>
      </Stack> */}
      <Stack direction="row" sx={[styles.title, { mt: 3 }]}>
				<Typography component="h1">Add Speaker</Typography>
        <Button variant="contained" onClick={handleSkip} sx={{ mr: 1 }}>
          Skip{" "}
        </Button>
				<LoadingButton
				variant="contained"
				onClick={handleApiSubmit}
        isLoading={mutation.isLoading}
				>
				Continue
				</LoadingButton>
			</Stack>

      <Box>
        <FormInput
          label="Invite Members via Link"
          placeholder="https//example@yourmail.com"
          id="invite-link-input"
          value={typedEmail}
          onChange={(e) => {
            setTypedEmail(e.target.value);
            if (e.target.value.length > 2) {
              setEnableSearch(true);
            }
            if (e.target.value.length === 0) {
              setSearchedEmails([]);
            }
          }}
        />
      </Box>

      {(memberQuery.isLoading || memberQuery.isFetching) && (
        <Box width="100%" textAlign="center">
          <LinearProgress />
        </Box>
      )}

      {searchedEmails.length > 0 && (
        <Box sx={{ border: "1px solid #eee", mt: 2, borderRadius: "10px" }}>
          {searchedEmails.map((member, index) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={index}
              sx={styles.memberFullStack}
            >
              <Stack direction="row" sx={styles.memberStack}>
                <Avatar sx={styles.memberAvatar} src={member.profile_image} />
                <Stack>
                  <Typography component="h4">{member.full_name}</Typography>
                  <Typography component="h4" flex={1}>
                    {member.email}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                sx={styles.addEmail}
                onClick={() => handleEmailAdd(index)}
              >
                {member.type === "not_exist" ? "Invite" : "Add Email"}
              </Button>
            </Stack>
          ))}
        </Box>
      )}

      <Box mt={3}>
        {speakers.length > 0 ? (
          speakers
            // .filter((member) => member.type !== "not_exist")
            .map((member, index) => (
              <Stack direction="row" sx={styles.memberStack} key={index}>
                <Avatar sx={styles.memberAvatar} src={member.profile_image}>
                  {member.full_name?.charAt(0)}
                </Avatar>
                <Stack sx={styles.memberNameStack} gap="5px">
                  <Typography component="h4">{member.full_name}</Typography>
                  <Typography component="label">
                    {member.email && member.email}
                  </Typography>
                </Stack>
                <Checkbox
                  icon={
                    <RadioButtonUncheckedIcon
                      sx={{ "& path": { color: "#E2E3E4" } }}
                    />
                  }
                  checkedIcon={
                    <CheckCircleIcon sx={{ "& path": { color: "#03989E" } }} />
                  }
                  sx={styles.memberCheckbox}
                  size="medium"
                  checked={
                    !!eventForm.members.speaker_id.find(
                      (newMember) => newMember === member.id
                    ) ||
                    !!eventForm.members.speakers_email.find(
                      (newMember) => newMember === member.email
                    ) ||
                    false
                  }
                  onChange={(e) =>
                    handleCheckboxChange(member, e.target.checked)
                  }
                />
              </Stack>
            ))
        ) : (
          <Box textAlign="center">
            {loading ? (
              <CircularProgress sx={{ textAlign: "center" }} />
            ) : (
              <Typography>No members available.</Typography>
            )}
          </Box>
        )}
      </Box>

      {/* <Box>
        <Divider sx={styles.divider} />
        <FormLabel label="Guest Speakers" />
      </Box> */}

      {eventForm?.members?.guest_speakers[
        eventForm?.members?.guest_speakers.length - 1
      ]?.name ? (
        <>
          {eventForm?.members?.guest_speakers?.map((guest, index) => (
            <Stack direction="row" sx={styles.memberStack} key={index}>
              <Avatar
                sx={styles.memberAvatar}
                src={
                  typeof guest.image === "string"
                    ? guest.image
                    : URL.createObjectURL(guest.image)
                }
              >
                {guest.name?.charAt(0)}
              </Avatar>
              <Stack sx={styles.memberNameStack} gap="5px">
                <Typography component="h4">{guest.name}</Typography>
                <Typography component="label">
                  {guest.email ? guest.email : ""}
                </Typography>
              </Stack>
              <Tooltip title="Remove Session">
                <IconButton
                  size="large"
                  sx={styles.removeBtn}
                  onClick={() => handleRemove(guest)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}
        </>
      ) : null}

      <Fab
        sx={{
          ...styles.scrollButtonStyles,
          ...(isVisible && styles.visibleScrollButtonStyles),
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>

      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2" sx={styles.guestSpeakerTitle}>
            Add Guest Speakers
          </Typography>

          {eventForm?.members?.guest_speakers?.map((guest, index) => (
            <Stack
              direction="row"
              spacing={2}
              key={index}
              sx={styles.agendaContainer}
            >
              <Grid container spacing={3}>
                <Grid item md={12} sm={12}>
                  <Stack
                    direction="row"
                    sx={styles.avatarWrapper}
                    alignItems="center"
                  >
                    <Avatar
                      src={
                        typeof guest?.image === "string"
                          ? guest?.image
                          : URL.createObjectURL(guest?.image)
                      }
                      sx={styles.avatar}
                    />
                    <Stack sx={styles.btnWrapper}>
                      <Button
                        sx={styles.editBtn}
                        disableElevation
                        onClick={() => fileInputRef.current.click()}
                      >
                        Change Photo
                      </Button>
                      <Button
                        sx={styles.deleteBtn}
                        disableElevation
                        onClick={handleRemoveImage}
                      >
                        Delete Photo
                      </Button>
                    </Stack>
                  </Stack>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageAdd(e, index)}
                    ref={fileInputRef}
                    hidden
                  />
                </Grid>

                <Grid item md={4} sm={12}>
                  <FormInput
                    label="Name"
                    placeholder="Name"
                    value={guest.name}
                    name="name"
                    onChange={(e) => handleChange(e, index)}
                    error={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.name
                        : null
                    }
                    helperText={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.name || ""
                        : null
                    }
                  />
                </Grid>

                <Grid item md={4} sm={12}>
                  <FormInput
                    label="Email"
                    placeholder="Email"
                    value={guest.email}
                    name="email"
                    onChange={(e) => handleChange(e, index)}
                    error={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.email
                        : null
                    }
                    helperText={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.email || ""
                        : null
                    }
                  />
                </Grid>

                <Grid item md={4} sm={12}>
                  <FormLabel label="Mobile Number" />
                  <PhoneNumberInput
                    value={guest.phone}
                    error={errors?.phone ? true : false}
                    helperText={errors?.phone || ""}
                    onChange={(value) => handleMobileChange(value, index)}
                    inputClassName="bigForm"
                  />
                </Grid>

                <Grid item md={8} sm={12}>
                  {/* <LocationAutoComplete
										label="Address"
										locationType="address"
										placeholder="Event Address"
										value={guest.address}
										// data={card}
										// setData={setCard}
										// error={errors?.address ? true : false}
										// helperText={errors?.address || ""}
										handleAddressChange={(e) => handleAddressChange(e, index)}
										types={["establishment"]}
										// selectedLocation={selectedLocation}
										// setSelectedLocation={setSelectedLocation}
										address={address}
										setAddress={setAddress}
									/> */}
                  <LocationAutoComplete
                    label="Address"
                    locationType="address"
                    placeholder="Event Address"
                    address={address[index]}
                    setAddress={(newAddress) => {
                      const newAddresses = [...address];
                      newAddresses[index] = newAddress;
                      setAddress(newAddresses);
                    }}
                    selectedLocation={selectedLocation[index]}
                    setSelectedLocation={(newLocation) => {
                      const newLocations = [...selectedLocation];
                      newLocations[index] = newLocation;
                      setSelectedLocation(newLocations);
                    }}
                    handleAddressChange={(e) => handleAddressChange(e, index)}
                    types={["establishment"]}
                    value={guest.address}
                  />
                </Grid>

                <Grid item md={8} sm={12}>
                  <FormInput
                    label="Bio"
                    placeholder="Bio"
                    value={guest.bio}
                    name="bio"
                    onChange={(e) => handleChange(e, index)}
                    error={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.bio
                        : null
                    }
                    helperText={
                      index === eventForm?.members?.guest_speakers.length - 1
                        ? errors?.bio || ""
                        : null
                    }
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              <Tooltip title="Remove Session">
                <IconButton
                  size="large"
                  sx={styles.removeBtn}
                  onClick={() => handleRemove(guest)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}

          <Stack direction="row" justifyContent="space-between">
            <Typography component="p" sx={styles.added} onClick={handleAdd}>
              +Add Another Guest Speaker
            </Typography>

            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              // isLoading={mutation.isLoading}
              sx={styles.continueBtn}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default SpeakerTab;
