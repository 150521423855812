import { Box, Typography, Stack, Skeleton } from "@mui/material";
import React from "react";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		p: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 16,
			fontWeight: 600,
		},
		"& p": {
			color: "#767D90",
		},
		"& h3": {
			color: "#1E1E1F",
			fontSize: 16,
			fontWeight: 600,
		},
	},
	statsWrapper: {
		pt: 2,
	},
};

function TopSelling({ data }) {
	return (
		<>
			<Box sx={styles.wrapper}>
				<Typography component="h2">Top Selling</Typography>

				{data?.length > 0 ? (
					data.map((top, index) => (
						<Box sx={styles.statsWrapper} key={index}>
							<Stack direction="row" justifyContent="space-between">
								{top.name ? (
									<Typography component="p">{top.name}</Typography>
								) : (
									<Skeleton width={200} animation="wave" />
								)}
								{top.total_price !== undefined ? (
									<Typography component="h3">${top.total_price}</Typography>
								) : (
									<Skeleton width={60} animation="wave" />
								)}
							</Stack>
						</Box>
					))
				) : (
					// Show Skeletons for name and total_price when data is empty
					<Box>
						<Stack direction="row" justifyContent="space-between" mt={2}>
							<Skeleton width={200} animation="wave" />
							<Skeleton width={60} animation="wave" />
						</Stack>
						<Stack direction="row" justifyContent="space-between" mt={2}>
							<Skeleton width={200} animation="wave" />
							<Skeleton width={60} animation="wave" />
						</Stack>
					</Box>
				)}
			</Box>
		</>
	);
}

export default TopSelling;
