import React, { useState } from "react";
import { Avatar, Box, IconButton, Stack, Tooltip } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

import {
  ADMIN_GET_ALL_POSTS,
  ADMIN_GET_POST_BY_ID,
  ADMIN_POST_CREATE,
  ADMIN_POST_UPDATE,
  ADMIN_POST_DELETE,
  ADMIN_DELETE_POST_ASSET,
} from "../../../data/constants/apiRoutes";
import { get, post, destroy } from "../../../server";
import BoxModal from "../../../components/Modals/BoxModal";
import PageHeader from "../../../components/ui/PageHeader";
import PostSchema from "../../../utils/schemas/PostSchema";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { validator } from "../../../utils/helpers/validator";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import queryNames from "../../../data/constants/queryNames";
import Listing from "../../../components/ui/Listing";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import PostDrawer from "../../../components/Drawers/PostDrawer";

//SVG
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";

const Post = ({ event, eventId, handleInvalidate }) => {
  //INITIAL STATE
  const initialState = {
    description: "",
    image: [],
  };

  //USESTATE
  const [newPost, setNewPost] = useState(initialState);
  const [postData, setPostData] = useState(null);
  // const [index, setIndex] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [deleteAssetModal, setDeleteAssetModal] = useState(false);

  //NAVIGATION
  const navigate = useNavigate();

  //COLUMNS FOR GRID
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.description,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 180,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "commentCount",
      headerName: "Comment Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.commentCount,
    },
    {
      field: "favCount",
      headerName: "Favourite Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.favCount,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Edit" arrow placement="top">
              <IconButton onClick={() => handleEditModalOpen(params?.row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setPostId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //FORM VALIDATION
  const validateForm = () => {
    const newErrors = validator(newPost, PostSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    if (!newPost?.image[0] && !isEdit) {
      enqueueSnackbar("Please attach Cover Image", { variant: "error" });
      return false;
    }
    return true;
  };

  // CLICKING ON POST TO NAVIGATE TO POSTDETAIL SCREEN
  const handleClick = (data) => {
    if (data?.field === "full_name") {
      navigate(`/events/detail/resources/${eventId}/posts/${data.id}/edit`);
    }
  };

  //MODAL OPENING AND API CALLING FOR PARTICULAR POST
  const handleEditModalOpen = async (row) => {
    setPostId(row?.id);

    setNewPost({
      description: row.description,
      image: [],
    });

    const { status, data } = await get(`${ADMIN_GET_POST_BY_ID}/${row?.id}`);
    if (status) {
      setPostData(data);
    }
    setIsEdit(true);
    setModal(true);
  };

  // MODALS OPEN/CLOSE METHODS
  const handleOpenModal = () => {
    setModal(true);
  };

  const handleClose = () => {
    setNewPost(initialState);
    setPostData(null);
    setIsEdit(false);
    setErrors({});
    setModal(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setPostId(null);
  };

  const handleDeleteAssetModalClose = () => {
    setAssetId(null);
    // setIndex(null);
    setDeleteAssetModal(false);
  };

  //CALLING METHOD TO GET POSTS OF EVENT
  const fetchPosts = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_ALL_POSTS}/${eventId}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //API METHODS

  const savePost = async () => {
    const payload = _.cloneDeep(newPost);
    let newStatus = true;
    let uploadedPhotos = [];

    for (let i = 0; i < payload.image.length; i++) {
      const photo = payload.image[i];
      const data = await awsFileUpload(
        photo,
        uploadImageType.createPost
        // setUploadProgress
      );
      if (data.status === true) {
        uploadedPhotos[i] = data.location;
      } else {
        enqueueSnackbar("Error uploading documents", { variant: "error" });
        return (newStatus = false);
      }
    }
    // const data = await awsFileUpload(payload.image, uploadImageType.createPost);
    // if (data.status === true) payload.image = data.location;
    // else {
    //   enqueueSnackbar("Error uploading", { variant: "error" });
    //   newStatus = false;
    // }

    if (newStatus) {
      let status;
      let message;
      if (uploadedPhotos.length > 0) {
        ({ status, message } = await post(ADMIN_POST_CREATE, {
          event_id: event?.id,
          description: payload?.description,
          assets: uploadedPhotos.map((asset_url) => ({
            asset_url,
            asset_type: "image",
          })),
        }));
      } else {
        ({ status, message } = await post(ADMIN_POST_CREATE, {
          event_id: event?.id,
          description: payload?.description,
        }));
      }
      // const { status, message } = await post(ADMIN_POST_CREATE, {
      //   event_id: event?.id,
      //   description: payload?.description,
      //   assets: [
      //     {
      //       asset_url: payload?.image,
      //       asset_type: "image",
      //     },
      //   ],
      // });

      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }

    // if (newStatus) {
    //   const { status, message } = await post(ADMIN_POST_CREATE, {
    //     event_id: event?.id,
    //     description: payload?.description,
    //     assets: [
    //       {
    //         asset_url: payload?.image,
    //         asset_type: "image",
    //       },
    //     ],
    //   });

    //   if (status === true) {
    //     enqueueSnackbar(message, { variant: "success" });
    //     handleInvalidate();
    //     handleClose();
    //   } else enqueueSnackbar(message, { variant: "error" });
    // }
  };

  const updatePost = async () => {
    const payload = _.cloneDeep(newPost);
    let newStatus = true;
    let uploadedPhotos = [];

    for (let i = 0; i < payload.image.length; i++) {
      const photo = payload.image[i];
      const data = await awsFileUpload(
        photo,
        uploadImageType.createPost
        // setUploadProgress
      );
      if (data.status === true) {
        uploadedPhotos[i] = data.location;
      } else {
        enqueueSnackbar("Error uploading documents", { variant: "error" });
        return (newStatus = false);
      }
    }

    // if (payload?.image) {
    //   const data = await awsFileUpload(
    //     payload.image,
    //     uploadImageType.createPost
    //   );
    //   if (data.status === true) payload.image = data.location;
    //   else {
    //     enqueueSnackbar("Error uploading", { variant: "error" });
    //     newStatus = false;
    //   }
    // }

    if (newStatus) {
      let status;
      let message;
      if (uploadedPhotos.length > 0) {
        ({ status, message } = await post(ADMIN_POST_UPDATE, {
          post_id: postId,
          event_id: event?.id,
          description: payload?.description,
          assets: uploadedPhotos.map((asset_url) => ({
            asset_url,
            asset_type: "image",
          })),
        }));
      } else {
        ({ status, message } = await post(ADMIN_POST_UPDATE, {
          post_id: postId,
          event_id: event?.id,
          description: payload?.description,
        }));
      }
      // const { status, message } = await post(ADMIN_POST_CREATE, {
      //   event_id: event?.id,
      //   description: payload?.description,
      //   assets: [
      //     {
      //       asset_url: payload?.image,
      //       asset_type: "image",
      //     },
      //   ],
      // });

      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }

    // if (newStatus) {
    //   const { status, message } = await post(ADMIN_POST_UPDATE, {
    //     post_id: postId,
    //     event_id: event.id,
    //     description: payload.description,
    //     ...(payload.image !== "" && {
    //       assets: [
    //         {
    //           asset_url: payload.image,
    //           asset_type: "image",
    //         },
    //       ],
    //     }),
    //   });

    //   if (status === true) {
    //     enqueueSnackbar(message, { variant: "success" });
    //     handleInvalidate();
    //     handleClose();
    //   } else enqueueSnackbar(message, { variant: "error" });
    // }
  };

  const deletePost = async () => {
    const { status, message } = await destroy(`${ADMIN_POST_DELETE}/${postId}`);

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const deleteAsset = async () => {
    // if (!assetId) {
    //   setNewPost((prevPost) => {
    //     const updatedAssets = [...prevPost.assets];
    //     updatedAssets.splice(index, 1);
    //     return { ...prevPost, assets: updatedAssets };
    //   });
    //   enqueueSnackbar(
    //     "Asset ID is not available. Asset may not have been submitted yet.",
    //     {
    //       variant: "warning",
    //     }
    //   );
    //   handleDeleteAssetModalClose();
    //   return;
    // }
    const { status, message } = await destroy(
      `${ADMIN_DELETE_POST_ASSET}/${postId}/asset/${assetId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      setPostData((prevPost) => {
        const updatedAssets = prevPost.assets.filter(
          (asset) => asset.id !== assetId
        );
        return { ...prevPost, assets: updatedAssets };
      });
      handleDeleteAssetModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //MUTATIONS AND ITS CALLING METHOD

  const mutation = useMutation(savePost);
  const mutationUpdate = useMutation(updatePost);
  const mutationDelete = useMutation(deletePost);
  const mutationAssetDelete = useMutation(deleteAsset);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (isEdit) {
      mutationUpdate.mutate();
    } else {
      mutation.mutate();
    }
  };

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  const handleDeleteAssetConfirm = () => {
    mutationAssetDelete.mutate();
  };

  //Return

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Posts" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
          <PageHeader
            createBtn
            createBtnText="Add Post"
            btnFnc={handleOpenModal}
          />
        </Box>
      </Box>

      <Listing
        columns={columns}
        handleCellClick={handleClick}
        queryName={queryNames.POSTS}
        fetchRecords={fetchPosts}
        className="posts"
      />

      {/* POSTDRAWER COMPONENT */}
      <PostDrawer
        anchor="right"
        text={!isEdit ? "Add new Post" : "Update Post"}
        image
        open={modal}
        onCloseDrawer={handleClose}
        isEditMode={isEdit}
        setAssetId={setAssetId}
        setDeleteAssetModal={setDeleteAssetModal}
        state={newPost}
        setState={setNewPost}
        stateData={postData}
        handleSubmit={handleSubmit}
        mutation={mutation}
        mutationUpdate={mutationUpdate}
        mutationAssetDelete={mutationAssetDelete}
        errors={errors}
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this post"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this asset"}
        open={deleteAssetModal}
        handleClose={handleDeleteAssetModalClose}
        handleConfirm={handleDeleteAssetConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export default Post;
