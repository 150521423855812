import { useEffect, useState } from "react";
import {
	AppBar,
	FormControl,
	OutlinedInput,
	InputAdornment,
	Stack,
	Box,
	Avatar,
	Menu,
	MenuItem,
	IconButton,
	Toolbar,
	Typography,
	Badge,
} from "@mui/material";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import { ReactComponent as ExpandLess } from "../../assets/svg/sidebarIcons/ExpandLess.svg";
import { ReactComponent as ExpandMore } from "../../assets/svg/sidebarIcons/ExpandMore.svg";
import { ReactComponent as Bell } from "../../assets/svg/Bell.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { Navigate, useNavigate } from "react-router-dom";
import LogoutModal from "../Modals/LogoutModal";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";

const styles = {
	appbar: {
		boxShadow: "0px 1px 0px #E6EAF2;",
		backgroundColor: "#FFFFFF",
	},
	searchInput: {
		maxWidth: 500,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: "6px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},
		"& input": {
			py: "13px",
			px: "16px",
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	menuBtn: {
		transition: "all 0.2s ease",
		cursor: "pointer",
		borderRadius: "5px",
		px: "5px",
		"& .MuiAvatar-root": {
			width: 36,
			height: 36,
		},
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
		flexDirection: "row",
		columnGap: "10px",
		alignItems: "center",
		"& h3": {
			fontSize: 14,
			fontWeight: 600,
			color: "#1E1E1F",
			textWrap: "nowrap",
		},
		"& h6": {
			fontSize: 12,
			color: "#767D90",
		},
	},
};

function Header({ drawerWidth, mobileOpen, setMobileOpen }) {
	const [loggedUser] = useAtom(loggedUserAtom);
	const [profileMenu, setProfileMenu] = useState(null);
	const [notificationMenu, setNotificationMenu] = useState(null);
	const [modal, setModal] = useState();
	const navigate = useNavigate();

	const handleOpenProfileMenu = (e) => {
		setProfileMenu(e.currentTarget);
	};

	const handleCloseProfileMenu = () => {
		setProfileMenu(null);
	};

	const handleOpenNotificationMenu = (e) => {
		// setNotificationMenu(e.currentTarget);
		navigate("/notifications");
	};

	const handleCloseNotificationMenu = () => {
		setNotificationMenu(null);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleLogout = async () => {
		setModal(true);
	};

	styles.appbar.width = { sm: `calc(100% - ${drawerWidth}px)` };
	styles.appbar.ml = { sm: `${drawerWidth}px` };

	return (
		<>
			<AppBar position="fixed" sx={styles.appbar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" }, color: "black" }}
					>
						<MenuIcon />
					</IconButton>
					<Stack direction="row" justifyContent="flex-end" width="100%">
						{/* <FormControl variant="outlined" sx={styles.searchInput}>
              <OutlinedInput
                size="small"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                placeholder="Search for groups and members"
              />
            </FormControl> */}
						<Stack direction="row" alignItems="center">
							<Box>
								<IconButton
									onClick={handleOpenNotificationMenu}
									sx={{
										mr: 1,
										"& svg": {
											height: 26,
										},
									}}
								>
									<Badge
										variant="dot"
										color="primary"
										sx={{
											"& .MuiBadge-badge": {
												bgcolor: "#0F4590",
											},
										}}
									>
										<Bell />
									</Badge>
								</IconButton>
								{/* <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={notificationMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(notificationMenu)}
                  onClose={handleCloseNotificationMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={handleCloseNotificationMenu}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu> */}
							</Box>
							<Box>
								<Stack onClick={handleOpenProfileMenu} sx={styles.menuBtn}>
									<Avatar
										alt={loggedUser?.data?.full_name}
										src={loggedUser?.data?.profile_image}
										sx={{ border: "1px solid #eee" }}
									/>
									<Box sx={{ mr: "5px" }}>
										<Typography component="h3">
											{loggedUser?.data?.full_name}
										</Typography>
										<Typography component="h6">
											{loggedUser?.data?.type === "superadmin"
												? "Super Admin"
												: "Admin"}
										</Typography>
									</Box>
									{handleOpenProfileMenu ? <ExpandLess /> : <ExpandMore />}
								</Stack>
								<Menu
									sx={{ mt: "45px" }}
									id="menu-appbar"
									anchorEl={profileMenu}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(profileMenu)}
									onClose={handleCloseProfileMenu}
								>
									<MenuItem onClick={() => navigate("/settings")}>
										<Typography textAlign="center">Profile</Typography>
									</MenuItem>

									<MenuItem onClick={() => navigate("/home")}>
										<Typography textAlign="center">Dashboard</Typography>
									</MenuItem>

									<MenuItem onClick={handleLogout}>
										<Typography textAlign="center">Logout</Typography>
									</MenuItem>
								</Menu>
							</Box>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
			<LogoutModal modal={modal} setModal={setModal} />
		</>
	);
}

export default Header;
