const styles = {
  errorMessage: {
    color: "#d32f2f",
    fontSize: 14,
    ml: "14px"
  },
  input: {
    height: "52px !important",
    border: "1px solid #eee !important",
    borderRadius: "4px !important"
  }
};
export default styles;
