import { useState } from "react";
import { Box, Stack, Typography, Grid, SwipeableDrawer } from "@mui/material";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";
import CompanyLogo from "../../assets/svg/companyLogo.svg";
// import CustomTabs from "./CustomTabs";
import MemberTab from "../pageComponents/courses/MembersTab";
import { get } from "../../server";
import {
	ADMIN_COURSES_DETAIL,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import queryNames from "../../data/constants/queryNames";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import CustomTabs from "../ui/CustomTabs";

const styles = {
	modal: {
		width: 800,
		px: { md: 3, xs: 2 },
		py: 2,
		"& h3": {
			fontSize: 22,
			color: "#333333",
			letterSpacing: 0,
			pb: 3,
			textTransform: "capitalize",
		},
		"& p": {
			color: "#767D90",
			pb: 1,
		},
		"& h6": {
			color: "#000",
			letterSpacing: 0,
			pb: 1,
		},
	},
	logoHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		pt: 3,
		pb: 2,
	},
	logo: {
		width: { md: 80, xs: 60 },
		height: { md: 80, xs: 60 },
		borderRadius: "10px",
	},
	iconWrapper: {
		flexDirection: "row",
		alignItems: "center",
		columnGap: 1,
		"& svg": {
			width: 22,
			height: 22,
		},
	},
};

const CourseDrawer = ({ modal, handleClose, courseId }) => {
	const [course, setCourse] = useState();

	const handleAllClose = () => {
		handleClose();
		setCourse();
	};

	const details = [
		{ label: "Created By:", value: course?.created_by },
		{ label: "Author:", value: course?.author },
		{ label: "Price:", value: `$${course?.price}` },
		{ label: "Start Time:", value: course?.start_time },
		course?.end_time && { label: "End Time:", value: course?.end_time },
		{ label: "About:", value: course?.about },
		// { label: "Address:", value: course?.company.address },
	];

	const tabList = [
		{
			label: "Members",
			component: <MemberTab members={course?.members} />,
		},
	];

	const { enqueueSnackbar } = useSnackbar();

	const fetchCourseDetails = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COURSES_DETAIL}/${courseId}`
		);
		if (status === true) {
			setCourse(data);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const CourseQuery = useQuery(
		[queryNames.COURSES, courseId],
		fetchCourseDetails,
		{
			enabled: modal && courseId !== null,
			refetchOnWindowFocus: false,
		}
	);

	return (
		<>
			<SwipeableDrawer
				open={modal}
				onClose={handleAllClose}
				anchor="right"
				sx={styles.modal}
			>
				<Box sx={styles.modal} role="presentation">
					<Close className="closeIcon" onClick={handleAllClose} />
					<LoadingOverlay loading={CourseQuery.isLoading} />
					<Stack sx={styles.logoHeader}>
						<Box
							component="img"
							src={
								course?.cover_image
									? course?.cover_image
									: CompanyLogo
							}
							alt="Company Logo"
							sx={styles.logo}
						/>
					</Stack>

					<Typography component="h3">{course?.name}</Typography>

					<Grid container spacing={1}>
						{details.map(
							(detail, index) =>
								detail?.value && (
									<Grid key={index} item md={12}>
										<Grid container spacing={0}>
											<Grid item md={2}>
												<Typography
													variant="h6"
													sx={{ color: "#767D90 !important" }}
												>
													{detail.label}
												</Typography>
											</Grid>

											<Grid item md={10}>
												<Typography variant="h6">{detail.value}</Typography>
											</Grid>
										</Grid>
									</Grid>
								)
						)}
					</Grid>
					<CustomTabs tabList={tabList} />
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default CourseDrawer;
