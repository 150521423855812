import React, { useState } from "react";
import PageHeader from "../components/ui/PageHeader";
import SubscriptionChart from "../components/Subscription/SubscriptionChart";
import MemberTab from "../components/Subscription/MemberTab";
import CompanyTab from "../components/Subscription/CompanyTab";
import PropTypes from "prop-types";
import {
    Tabs,
    Tab,
    Box,
} from "@mui/material";

const styles = {
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F9F9F9",
        borderRadius: "6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E2E3E4",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2B2A69 !important",
        borderWidth: "1px !important",
    },
    "& input": {
        py: "13px",
        px: 2,
        "&::placeholder": {
            color: "#767D90",
            fontWeight: 400,
            opacity: 1,
            fontSize: 14,
        },
    },
    tabHeaders: {
        mb: 2.375,
        borderBottom: "2px solid white",
        "& .MuiTabs-flexContainer": {
            gap: "10px",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#0F4590",
        },
        "& button": {
            textTransform: "capitalize",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            color: "#333333",
            mt: 3.875,
        },
    },
    activeTab: {
        backgroundColor: "#0F4590",
        color: "#FFF !important",
    },
    inactiveTab: {
        bgcolor: "#fff",
    },
    tab: {
        bgcolor: "#FFF",
        p: 3,
        borderRadius: 3,
        mt: "10px",
        "& button": {
            textTransform: "capitalize",
        },
    },
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function Subscription() {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <PageHeader title="Subscription" />
        <SubscriptionChart />

        <Box sx={styles} >
            <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={styles.tabHeaders}
            >
                <Tab
                    label="Companies"
                    {...a11yProps(0)}
                    sx={value === 0 ? styles.activeTab : styles.inactiveTab}
                />
                <Tab
                    label="Members"
                    {...a11yProps(1)}
                    sx={value === 1 ? styles.activeTab : styles.inactiveTab}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <CompanyTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MemberTab />
            </TabPanel>
        </Box>
    </>

}

export default Subscription;