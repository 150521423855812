import Joi from "joi";



const lessonSchema = Joi.object({
    name: Joi.string().required().max(50).messages({
        "string.empty": `Please enter Lesson Name`,
        "string.max": `Lesson name must be maximum 50 characters!`,
    }),
    title: Joi.string().max(50).messages({
        "string.empty": `Please enter Lesson Title`,
        "string.max": `Lesson Title must be maximum 50 characters!`,
    }),
    files: Joi.when('type', {
        is: 'link',
        then: Joi.array().items(
            Joi.object({
                file: Joi.string()
                    .required()
                    .uri({ scheme: ['http', 'https'] })
                    .messages({
                        "string.empty": `Please enter a valid Link URL`,
                        "string.uri": `Invalid URL format. Please enter a valid URL.`,
                    }),
            })
        ).min(1).required(),
        otherwise: Joi.optional(),
    }),
});


const CourseSchema = Joi.object({
    name: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Course Name`,
        "string.max": `Course name must be maximum 50 characters!`,
    }),

    price: Joi.number().min(0).max(999999).required().messages({
        "number.base": `Please enter Price`,
        "number.empty": `Please enter Price`,
        "number.min": `Price must be minimum 0!`,
        "number.max": `Price must be maximum 999999!`,
    }),

    about: Joi.string().max(500).required().messages({
        "string.empty": `Please enter About Course`,
        "string.max": `About Course must be maximum 500 characters!`,
    }),

    all_time_available: Joi.boolean().optional().messages({

    }),

    start_time: Joi.date()
        .when("all_time_available", {
            is: true,
            then: Joi.required().messages({
                "date.base": `Please enter Start Date`,
                "any.required": `Please enter Start Date`,
            }),
            otherwise: Joi.optional().allow("", null),
        }),

    end_time: Joi.date()
        .when("all_time_available", {
            is: true,
            then: Joi.optional().allow("", null).messages({
                "date.base": `Please enter End Date`,
                "any.required": `Please enter End Date`,
            }),
            otherwise: Joi.optional().allow("", null),
        }),

    author: Joi.string().max(25).required().messages({
        "string.empty": `Please enter Author Name`,
        "string.max": `Author name must be maximum 25 characters!`,
    }),

    lessons: Joi.array().items(lessonSchema).min(1).required(),
})

export default CourseSchema;