import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Events } from "../../../data/constant";

const localizer = momentLocalizer(moment);

const styles = {
  backgroundColor: "#fff",
  padding: 20,
  borderRadius: 12,
};

const EventsCalendar = ({ props }) => (
  <div>
    <Calendar
      localizer={localizer}
      events={Events}
      startAccessor="start"
      endAccessor="end"
      drilldownView="agenda"
      selectable
      style={styles}
    />
  </div>
);

export default EventsCalendar;
