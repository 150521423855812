import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as Student } from "../../../assets/svg/Student.svg";
import { ReactComponent as Lesson } from "../../../assets/svg/Lesson.svg";
import CourseImg from "../../../assets/images/CourseImg.png";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: 3,
		p: 1.5,
		mt: 2,
		"& img": {
			width: 80,
			height: 80,
			borderRadius: "5px",
		},
	},
	category: {
		color: "#03989E",
		fontSize: 12,
		fontWeight: 600,
	},
	title: {
		color: "#1E1E1F",
		fontWeight: 600,
		pb: 0.5,
		textTransform: "capitalize",
	},
	name: {
		color: "#767D90",
		fontSize: 12,
	},
	owner: {
		color: "#767D90",
	},
	price: {
		color: "#2B2A69",
		fontWeight: 600,
	},
};

function CoursesCard({
	cover_image,
	name,
	members,
	lessons,
	created_by,
	price,
}) {
	return (
		<>
			<Box sx={styles.wrapper}>
				<Stack direction="row" columnGap={2}>
					<Box component="img" src={cover_image ? cover_image : CourseImg} />
					<Box sx={{ width: "100%" }}>
						{/* <Typography sx={styles.category}>{category}</Typography> */}
						<Typography sx={styles.title}>{name}</Typography>
						<Stack direction="row" columnGap={1}>
							<Stack direction="row" columnGap={1} alignItems="center">
								<Student />
								<Typography sx={styles.name}>
									{members?.length} students
								</Typography>
							</Stack>
							<Stack direction="row" columnGap={1} alignItems="center">
								<Lesson />
								<Typography sx={styles.name}>
									{lessons?.length} Lessons
								</Typography>
							</Stack>
						</Stack>
						<Stack direction="row" justifyContent="space-between" mt="15px">
							<Typography sx={styles.owner}>By {created_by}</Typography>
							<Typography sx={styles.price}>$ {price}</Typography>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</>
	);
}

export default CoursesCard;
