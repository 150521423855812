import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	AvatarGroup,
	Box,
	Button,
	Divider,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../../../assets/styles/events/createEvent.styles";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import BannerImg from "../../../assets/images/EventsBanner.jpg";
import { ReactComponent as Ticket } from "../../../assets/svg/eventIcons/TicketPrice.svg";
import MemberTab from "./PreviewMemberTabs/MemberTab";
import EventTabs from "../../../components/Tabs/EventTabs";
import SpeakerTab from "./PreviewMemberTabs/SpeakerTab";
import SponsorTab from "./PreviewMemberTabs/SponsorTab";
import { Add, Remove } from "@mui/icons-material";
import PublishedEventModal from "../../../components/Modals/PublishedEventModal";
import moment from "moment";
import { useMutation } from "react-query";
import { get } from "../../../server";
import { enqueueSnackbar } from "notistack";
import { ADMIN_EVENT_PUBLISH } from "../../../data/constants/apiRoutes";

const EventPreview = ({
	currentStep,
	setCurrentStep,
	eventForm,
	setEventForm,
	handleGoBack,
	finalEvent,
}) => {
	const [expanded, setExpanded] = useState(false);
	const [modal, setModal] = useState(false);

	const tabList = [
		{
			label: "Members",
			component: <MemberTab members={finalEvent?.members} />,
		},
		{
			label: "Speakers",
			component: <SpeakerTab speakers={finalEvent?.speakers} />,
		},
		{
			label: "Sponsors",
			component: <SponsorTab sponsors={finalEvent?.sponsors} />,
		},
	];

	const handleExpand = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	// Function to get the lowest price
	function getLowestPrice(tickets) {
		return Math.min(...tickets.map((ticket) => ticket.price));
	}

	// Function to get the highest price
	function getHighestPrice(tickets) {
		return Math.max(...tickets.map((ticket) => ticket.price));
	}

	const publishEvent = async () => {
		const { status, message } = await get(
			`${ADMIN_EVENT_PUBLISH}/${finalEvent.id}`
		);
		if (status) {
			setModal(true);
			enqueueSnackbar(message, { variant: "success" });
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const mutation = useMutation(publishEvent);

	const handleSubmit = (e) => {
		e.preventDefault();
		mutation.mutate();
	};

	return (
		<>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1" fontSize="22px !important">
					Preview Event
				</Typography>
				<Button variant="contained" sx={{ mr: "10px" }} onClick={handleGoBack}>
					Edit
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
				>
					Publish Event
				</LoadingButton>
			</Stack>
			<Box sx={styles.container}>
				<Typography component="h1" sx={styles.Previewheader}>
					Finish! You’re ready to Publish!
				</Typography>

				<Box
					component="img"
					src={finalEvent?.cover_image ? finalEvent?.cover_image : BannerImg}
					sx={styles.banner}
					loading="lazy"
				/>

				<Box sx={styles.detail}>
					<Typography component="h2">{finalEvent?.event_name}</Typography>
					<Stack direction="row" justifyContent="space-between">
						<Stack direction="row" gap="10px" mt={1} alignItems="center">
							<Ticket />
							{finalEvent?.tickets?.length > 1 ? (
								<>
									<Typography component="label" sx={styles.eventPrice}>
										${getLowestPrice(finalEvent.tickets)} - $
										{getHighestPrice(finalEvent.tickets)}
									</Typography>
									{/* <Typography
										component="label"
										sx={styles.eventType}
										textTransform="uppercase"
									>
										({getTicketTypeRange(finalEvent.tickets)})
									</Typography> */}
								</>
							) : finalEvent?.tickets?.length === 1 ? (
								<>
									<Typography component="label" sx={styles.eventPrice}>
										${finalEvent.tickets[0].price}
									</Typography>
									{/* <Typography
										component="label"
										sx={styles.eventType}
										textTransform="uppercase"
									>
										({finalEvent.tickets[0].type})
									</Typography> */}
								</>
							) : (
								<Typography component="label" sx={styles.eventPrice}>
									No tickets available
								</Typography>
							)}
						</Stack>

						<Stack direction="row" gap="10px" alignItems="center">
							<AvatarGroup max={4}>
								{finalEvent?.members?.map((member, index) => (
									<Tooltip title={member?.event_member?.full_name}>
										<Avatar
											src={member?.event_member?.profile_image}
											alt={member?.event_member?.full_name}
											sx={{ bgcolor: "gray" }}
											key={index}
										>
											{member?.event_member?.full_name?.charAt(0)}
										</Avatar>
									</Tooltip>
								))}
							</AvatarGroup>
							{finalEvent?.members?.length > 4 && (
								<Typography component="label" sx={styles.eventType}>
									{`+${finalEvent?.members?.length - 4} Going`}
								</Typography>
							)}
						</Stack>
					</Stack>
				</Box>

				<Divider sx={styles.divider} />

				<Stack direction="row" gap={20}>
					<Stack gap="5px">
						<Typography component="label" sx={styles.eventType}>
							Start Date / End Date
						</Typography>
						<Typography component="label" sx={styles.headerValue}>
							{moment(finalEvent?.start_time).format("MMMM, DD")} -{" "}
							{moment(finalEvent?.end_time).format("MMMM, DD")}
						</Typography>
					</Stack>

					<Stack gap="5px">
						<Typography component="label" sx={styles.eventType}>
							Location
						</Typography>
						<Typography component="label" sx={styles.headerValue}>
							{finalEvent?.address}
						</Typography>
					</Stack>
				</Stack>

				<Box>
					<EventTabs tabList={tabList} variant="preview" />
				</Box>

				<Divider sx={styles.divider} />

				<Box>
					<Typography component="h2" sx={styles.faqHeader}>
						Frequently Asked Questions
					</Typography>

					{!finalEvent?.faqs?.length && (
						<Typography component="label" sx={styles.eventType}>
							no FAQs
						</Typography>
					)}

					{finalEvent?.faqs?.map((faq, index) => (
						<Accordion
							expanded={expanded === index}
							onChange={handleExpand(index)}
							sx={styles.accordion}
							key={index}
						>
							<AccordionSummary
								expandIcon={expanded === index ? <Remove /> : <Add />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography sx={styles.accordianSummary}>
									{faq.question}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography sx={styles.accordianDetail}>
									{faq.answer}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			</Box>

			<PublishedEventModal
				modal={modal}
				setModal={setModal}
				id={finalEvent?.id}
				qrCode={finalEvent?.qrcode_link}
				featured={finalEvent.is_featured}
			/>
		</>
	);
};

export default EventPreview;
