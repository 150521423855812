import { Button, CircularProgress } from "@mui/material";

const styles = {
  button: {
    textTransform: "capitalize",
  },
  loader: {
    width: "25px !important",
    height: "25px !important",
    "& svg":{
      color:"#2B2A69"
    }
  },
};


const LoadingButton = ({ isLoading, disable, children, sx, ...other }) => {
  return (
    <Button {...other} disabled={isLoading || disable} sx={[sx, styles.button]}>
      {isLoading ? <CircularProgress sx={styles.loader} /> : children}
    </Button>
  );
};

export default LoadingButton;
