import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
	Avatar,
	Box,
	Button,
	Drawer,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import CalenderIcon from "../../../assets/svg/eventIcons/Calender.svg";
import { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as AgendaIcon } from "../../../assets/svg/eventIcons/stepperIcons/Agenda.svg";
import { ReactComponent as Location } from "../../../assets/svg/eventIcons/Location.svg";
import { Clear } from "@mui/icons-material";

const localizer = momentLocalizer(moment);

const styles = {
	backgroundColor: "#fff",
	// paddingTop: 20,
	padding: 20,
	borderRadius: 12,
	agendaContainer: {
		bgcolor: "#FFF",
		border: "1px solid #EAECED",
		boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.05)",
		borderRadius: "5px",
		pt: "10px",
		px: "15px",
		pb: "12px",
	},
	timeBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		pr: "11px",
		mr: "20px",
		borderRight: "1px solid #EAECED",
	},
	time: {
		width: 45,
		color: "var(--pregaph-text-color, #767D90)",
		fontSize: 9,
	},
	line: {
		mr: "20px",
		height: "100%",
		width: "1px",
		borderRight: "1px solid #EAECED",
	},
	createdBy: {
		color: "#929497",
		fontSize: 11,
	},
	title: {
		color: "#1E1E1F",
		fontSize: 14,
		fontWeight: 500,
		lineHeight: "20px",
	},
	btmTitle: {
		color: "#929497",
		fontSize: 12,
		display: "block",
	},
	iconsNum: {
		color: "#929497",
		fontSize: 12,
	},
	calendar: {
		alignSelf: "flex-start",
	},
	disclaimer: {
		color: "#929497",
		fontSize: 12,
	},
	customToolbar: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
	},
	todayButton: {
		color: "#293277",
		textTransform: "capitalize",
		border: "1px solid #293277",
		bgcolor: "#FFF",
		"&:hover": {
			color: "#293277",
			bgcolor: "#FFF",
		},
	},
	container: {
		position: "relative",
		width: 500,
		bgcolor: "#fff",
		borderTopLeftRadius: "10px",
		borderBottomLeftRadius: "10px",
		p: 2,
		"& h2": {
			fontSize: 20,
			fontWeight: 600,
			color: "#000",
			mt: 1,
			mb: 2,
			textAlign: "center",
			textTransform: "capitalize",
		},
		"& h3": {
			fontSize: 16,
			fontWeight: 600,
			color: "#000",
			mb: 1,
		},
	},
	description: {
		width: "100%",
		bgcolor: "#eee",
		p: 2,
		borderRadius: "10px",
		mb: 2,
		"& p": {
			fontSize: 14,
			fontWeight: 600,
			color: "#000",
		},
	},
	sponsor: {
		width: "100%",
		bgcolor: "#eee",
		p: 2,
		borderRadius: "10px",
		alignItems: "center",
		gap: 2,
		mb: 2,
		"& img": {
			width: 100,
			height: 100,
			objectFit: "cover",
			borderRadius: "10px",
		},
		"& p": {
			fontSize: 14,
			fontWeight: 600,
			color: "#000",
		},
	},
	speaker: {
		"& p": {
			fontSize: 15,
			fontWeight: 600,
			color: "#000",
		},
		"& label": {
			fontSize: 13,
			fontWeight: 400,
			color: "gray",
		},
	},
	iconContainer: {
		minWidth: 50,
		height: 50,
		bgcolor: "#eee",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #F1F1F1",
	},
	details: {
		"& h4": {
			fontSize: 15,
			fontWeight: 500,
			color: "#000",
		},
		"& p": {
			fontSize: 14,
			fontWeight: 400,
			color: "gray",
			wordBreak: "break-all",
		},
	},
};

const Agenda = ({ agendas, loading = false }) => {
	const [events, setEvents] = useState();
	const [selectedDayEvents, setSelectedDayEvents] = useState([]);
	const [currentDate, setCurrentDate] = useState(moment());
	const [open, setOpen] = useState(false);
	const [selectedAgenda, setSelectedAgenda] = useState();

	useEffect(() => {
		if (agendas) {
			const newAgenda = agendas.map((agenda) => ({
				id: agenda?.id,
				title: agenda?.title,
				start: agenda?.start_session_time,
				end: agenda?.end_session_time,
				desc: agenda?.description,
			}));
			setEvents(newAgenda);
		}
	}, [agendas]);

	const BulletPointEvent = ({ event }) => {
		return <Box sx={{ color: "#FFDE59" }}>&#8226;</Box>;
	};

	const handleDayClick = (date) => {
		const selectedDate = moment(date.start);
		const selectedDayEvents = events.filter((event) =>
			moment(event.start).isSame(selectedDate, "day")
		);
		setSelectedDayEvents(selectedDayEvents);
	};

	const handleNavigate = (date) => {
		setCurrentDate(moment(date));
	};

	const CustomToolbar = ({ date, onNavigate }) => {
		const navigateToday = () => {
			onNavigate("TODAY");
		};

		const navigatePrev = () => {
			onNavigate("PREV");
		};

		const navigateNext = () => {
			onNavigate("NEXT");
		};

		return (
			<Box sx={styles.customToolbar}>
				<Box component="div" />
				<Stack direction="row" justifyContent="center" alignItems="center">
					<Button
						onClick={navigatePrev}
						sx={{ fontSize: 20, color: "#293277" }}
					>
						&lt;
					</Button>
					<Typography variant="h6">
						{moment(date).format("MMMM YYYY")}
					</Typography>
					<Button
						onClick={navigateNext}
						sx={{ fontSize: 20, color: "#293277" }}
					>
						&gt;
					</Button>
				</Stack>
				<Button onClick={navigateToday} sx={styles.todayButton}>
					Today
				</Button>
			</Box>
		);
	};

	return (
		<>
			<Grid container spacing="30px">
				<Grid item md={8} sm={12}>
					<Calendar
						localizer={localizer}
						events={events}
						startAccessor="start"
						endAccessor="end"
						// drilldownView="agenda"
						selectable
						style={styles}
						monthHeaderFormat
						components={{
							// event: BulletPointEvent,
							toolbar: CustomToolbar, // Use a custom toolbar component
						}}
						views={["month"]} // Set the available views to only "month"
						defaultView="month" // Set the default view to "month"
						onSelectSlot={handleDayClick} // Handle day selection
						onNavigate={handleNavigate} // Handle navigation
					/>
				</Grid>

				<Grid item md={4} sm={12}>
					{selectedDayEvents.length > 0 && (
						<Box display="flex" gap="10px" flexDirection="column">
							{agendas?.map((agenda, index) => (
								<Box
									sx={styles.agendaContainer}
									key={index}
									onClick={() => {
										setSelectedAgenda(agenda);
										setOpen(true);
									}}
								>
									<Stack direction="row">
										<Box sx={styles.timeBox}>
											<Typography component="label" sx={styles.time}>
												{moment(
													agenda?.start_session_time,
													"YYYY-MM-DD HH:mm"
												).format("h:mm A")}
												<br />
												to
												<br />
												{moment(
													agenda?.end_session_time,
													"YYYY-MM-DD HH:mm"
												).format("h:mm A")}
											</Typography>
										</Box>

										{/* <Box sx={styles.line} /> */}

										<Box>
											<Typography component="h6" sx={styles.title}>
												{agenda?.title}
											</Typography>

											<Typography component="label" sx={styles.btmTitle}>
												Speaker: {agenda?.agenda_speaker?.user?.full_name}
											</Typography>

											<Typography component="label" sx={styles.btmTitle}>
												Address: {agenda?.agenda_speaker?.user?.address}
											</Typography>

											{/* <Stack direction="row" alignItems="center" gap="9px" mt={1}>
											<Stack direction="row" alignItems="center" gap="4px">
												<Members />
												<Typography component="label" sx={styles.iconsNum}>
													{agenda.favourite_count}
												</Typography>
											</Stack>

											<Stack direction="row" alignItems="center" gap="4px">
												<Likes />
												<Typography component="label" sx={styles.iconsNum}>
													{agenda.comments_count}
												</Typography>
											</Stack>

											<Stack direction="row" alignItems="center" gap="4px">
												<Comments />
												<Typography component="label" sx={styles.iconsNum}>
													5
												</Typography>
											</Stack>
										</Stack> */}
										</Box>
									</Stack>
								</Box>
							))}
							<Typography component="label" sx={styles.disclaimer}>
								This Agenda is Uploaded by your event organizers.
							</Typography>
						</Box>
					)}
				</Grid>
			</Grid>

			<Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
				<Box sx={styles.container}>
					<Typography component="h2">{selectedAgenda?.title}</Typography>

					<Box sx={styles.description}>
						<Typography component="p">{selectedAgenda?.description}</Typography>
					</Box>

					<Stack direction="row" gap={1} sx={styles.details} mb={2}>
						<Box sx={styles.iconContainer}>
							<AgendaIcon />
						</Box>
						<Stack>
							<Typography component="h4">
								{moment(selectedAgenda?.start_session_time).format(
									"MMMM DD, YYYY"
								)}{" "}
								-{" "}
								{moment(selectedAgenda?.end_session_time).format(
									"MMMM DD, YYYY"
								)}
							</Typography>
							<Typography component="p">
								{moment(selectedAgenda?.start_session_time).format(
									"ddd, h:mm a"
								)}{" "}
								-{" "}
								{moment(selectedAgenda?.end_session_time).format("ddd, h:mm a")}
							</Typography>
						</Stack>
					</Stack>

					<Stack direction="row" gap={1} sx={styles.details} mb={2}>
						<Box sx={styles.iconContainer}>
							<Location />
						</Box>
						<Stack>
							<Typography component="h4">Location</Typography>
							<Typography component="p">
								{selectedAgenda?.event?.address}
							</Typography>
						</Stack>
					</Stack>

					<Typography component="h3">Session Sponsored By</Typography>
					<Stack direction="row" sx={styles.sponsor}>
						<Box component="img" src={selectedAgenda?.agenda_sponsor?.image} />
						<Typography component="p">
							{selectedAgenda?.agenda_sponsor?.company_name}
						</Typography>
					</Stack>

					<Typography component="h3">Session With Speaker</Typography>
					<Stack direction="row" sx={styles.speaker} gap={2}>
						<Avatar
							src={selectedAgenda?.agenda_speaker?.user?.profile_image}
							sx={styles.avatar}
						>
							{selectedAgenda?.agenda_speaker?.user?.full_name.charAt(0)}
						</Avatar>
						<Stack gap={1}>
							<Typography component="p">
								{selectedAgenda?.agenda_speaker?.user?.full_name}{" "}
							</Typography>
							<Typography component="label">
								<Typography component="label" textTransform="capitalize">
									{selectedAgenda?.agenda_speaker?.user?.type},{" "}
								</Typography>
								{selectedAgenda?.agenda_speaker?.user?.address}{" "}
							</Typography>
						</Stack>
					</Stack>
					<IconButton
						sx={{
							position: "absolute",
							top: 20,
							left: 10,
						}}	
						onClick={() => setOpen(false)}
					>
						<Clear />
					</IconButton>
				</Box>
			</Drawer>
		</>
	);
};

export default Agenda;
