import React, { useState } from "react";
import PageHeader from "../components/ui/PageHeader";
import { Box } from "@mui/material";
import NotificationRow from "../components/Notifications/NotificationRow";
import { ADMIN_NOTIFICATIONS_LISTING } from "../data/constants/apiRoutes";
import { get } from "../server";
import { useQuery } from "react-query";
import queryNames from "../data/constants/queryNames";
import CustomPaginations from "../components/ui/CustomPaginations";
import Loader from "../components/overlay/Loader";
import { enqueueSnackbar } from "notistack";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
	},
};

function Notification() {
	const [notifications, setNotifications] = useState([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		limit: 10,
	});
	const [totalCount, setTotalCount] = useState(0);

	const fetchNotifications = async () => {
		const url = new URL(ADMIN_NOTIFICATIONS_LISTING);

		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.limit);

		const { status, message, data } = await get(url.toString());
		if (status) {
			setTotalCount(data.total_count);
			setNotifications(data.notifications);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const notificationQuery = useQuery(
		[queryNames.NOTIFICATION, paginationModel.page],
		fetchNotifications,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (notificationQuery.isLoading || notificationQuery.isFetching)
		return <Loader />;

	return (
		<>
			<PageHeader title="Notifications" />

			<Box sx={styles.wrapper}>
				{notifications.map((notification) => (
					<NotificationRow {...notification} key={notification.id}/>
				))}
			</Box>

			{totalCount > 0 && (
				<CustomPaginations
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					totalCount={totalCount}
				/>
			)}
		</>
	);
}

export default Notification;
