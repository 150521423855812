import React from "react";
import PageHeader from "../components/ui/PageHeader";
import EventDetailListings from "../components/ui/EventDetailListings";
import Listing from "../components/ui/Listing";

import {
	Avatar,
	Box,
	Button,
	Drawer,
	FormLabel,
	Grid,
	Stack,
	Typography,
	IconButton, Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import _ from "lodash";

import { ADMIN_REPORTEDPOST, } from "../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";
import { useMutation , useQuery ,  useQueryClient } from "react-query";
import FormInput from "../components/Forms/FormInput";
import { useRef } from "react";
import queryNames from "../data/constants/queryNames";
import { useSnackbar } from "notistack";
import { post, destroy, get } from "../server";
import { useNavigate } from "react-router-dom";
import Loader from "../components/overlay/Loader";
import GoBackButton from "../components/Buttons/GoBackButton";
const styles = {
	container: {
		position: "relative",
		width: 500,
		bgcolor: "#fff",
		borderTopLeftRadius: "10px",
		borderBottomLeftRadius: "10px",
		p: 2,
		"& h2": {
			fontSize: 20,
			fontWeight: 600,
			color: "#000",
			mt: 1,
			mb: 2,
			textAlign: "center",
			textTransform: "capitalize",
		},
		"& h3": {
			fontSize: 16,
			fontWeight: 600,
			color: "#000",
			mb: 1,
		},
	},
	eventImageContainer: {
		borderRadius: "4px",
		border: "1px dashed var(--light-background-color-2, #E2E3E4)",
		bgcolor: "#FBFCFF",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: 2,
		p: 3,
		height: 200,
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},

	coverImageContainer: {
		borderRadius: "4px",
		border: "1px solid #eee",
		height: 200,
		width: "100%",
		objectFit: "cover",
	},
	button: {
		bgcolor: "#fff",
		color: "#000",
		textTransform: "capitalize",
		border: "1px solid #000",
		"&:hover": {
			bgcolor: "#fff",
			color: "#000",
		},
	},
	coverHover: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		gap: "10px",
		transition: "display 0.3s ease",
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
};
const ReportedPost = () => {
    const navigate = useNavigate();


    const navigateToDetailPage = (rowData) => {
		navigate(`/reported_post/detail/${rowData.post_id}`, { state: { forumData: rowData } });
	};
    const formatDate = (date) => {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
		let hour = date.getHours().toString().padStart(2, '0');
		let minute = date.getMinutes().toString().padStart(2, '0');

		return `${year}-${month}-${day} ${hour}:${minute}`;
	}
    const columns = [
		{
			// field: "commentCount",
			field: "eventTitle",
			headerName: "Event Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						onClick={() => navigateToDetailPage(params.row)}
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.eventTitle}
					</Box>
				);
			},
			// valueGetter: (params) => params.row.company_sponsor.website,
		},
		{
			field: "description",
			headerName: "Description",
			sortable: false,
			flex: 1,
		},
	
		{
			field: "postTitle",
			headerName: "PostTitle",
			sortable: false,
			width: 180,
			// valueGetter: (params) => params.row.company_sponsor.email,
		},
		{
			field: "reportedBy",
			headerName: "ReportedBy",
			sortable: false,
			flex: 1,
			// valueGetter: (params) => params.row.company_sponsor.website,
		},
		{
			field: "postOwner",
			// field: "postOwner",
			headerName: "PostOwner",
			sortable: false,
			flex: 1,
			// valueGetter: (params) => params.row.company_sponsor.website,
		},
		{
			field: "created_at",
			headerName: "Created Date",
			sortable: false,
			flex: 1,
			valueFormatter: (params) => formatDate(new Date(params.value)),
		},
        {
			field: "status",
			headerName: "Status",
			sortable: false,
			flex: 1,
		},
        {
			// field: "commentCount",
			field: "action",
			headerName: "Actions",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Button
                    variant="contained"
					sx={styles.button}
						onClick={() => navigateToDetailPage(params.row)}
						style={{
							cursor: "pointer",
						}}
					>
						View Detail
					</Button>
				);
			},
			// valueGetter: (params) => params.row.company_sponsor.website,
		},

		// {
		// 	field: "actions",
		// 	headerName: "Actions",
		// 	sortable: false,
		// 	width: 180,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Stack direction="row">
		// 				<Tooltip title="Edit" arrow placement="top">
		// 					<IconButton
		// 						onClick={() => {
		// 							handleEdit(params.row);
		// 							setModal("Edit");
		// 						}}

		// 					>
		// 						<Edit />
		// 					</IconButton>
		// 				</Tooltip>
		// 				<Tooltip title="Delete" arrow placement="top">
		// 					<IconButton
		// 						onClick={() => {
		// 							setDeleteItem(params.row);
		// 							setModal("delete");
		// 						}}
		// 					>
		// 						<Delete />
		// 					</IconButton>
		// 				</Tooltip>
		// 				{/* <Tooltip title="View Details" arrow placement="top">
		// 					<IconButton
		// 						onClick={() => navigateToDetailPage(params.row)}
		// 					>
		// 						<Edit />

		// 					</IconButton>
		// 				</Tooltip> */}
		// 			</Stack>
		// 		);
		// 	},
		// },

		


	];
    const fetchForums = async (pageNo,limit, direction = null, id) => {
		let url = new URL(ADMIN_REPORTEDPOST);
	
		url.searchParams.append(direction, id);
		url.searchParams.append("page", pageNo + 1);
		
	
		return await post(url.toString());
	  };


    return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ textAlign: 'left',fontSize:"22px",fontWeight:"600",lineHeight:"33px",color:"#1E1E1F",marginBottom : "20px" }}>
            Reported Post 
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GoBackButton url={-1} />
            {/* <PageHeader
            createBtn
            createBtnText="Create Forums"
            btnFnc={handleOpenModal}
            /> */}
        </Box>
        </Box>
			<Listing
				// rows={forumDetail}
				columns={columns}
				// handleCellClick={handleCellClick}
				fetchRecords={fetchForums}
				// loading={commentsQuery.isLoading }
				// queryName={queryNames.FORUMS}
			/>
			

		</>
	);
};
export default ReportedPost;