const styles = {
    title: {
        pb: 2.5,
        flexWrap: "wrap",
        "& h1": {
            fontSize: 22,
            fontWeight: 600,
            color: "#1E1E1F",
            flex: 1,
        },
        "& h4": {
            color: "#1E1E1F",
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "36px",
            mb: 1,
        },
    },
    saveBtn: {
        width: 150,
        bgcolor: "#2B2A69",
        color: "#FFF",
        fontWeight: 600,
        textTransform: "capitalize",
        px: 3,
        "&:hover": {
            bgcolor: "#2B2A69",
            color: "#FFF",
        },
    },
    container: {
        bgcolor: "#FFF",
        borderRadius: "12px",
        py: 4,
        px: "30px",
    },
    attachmentContainer: {
        bgcolor: "rgba(226, 227, 228, 0.20)",
        border: "1px dashed var(--light-background-color-2, #E2E3E4)",
        fontSize: 18,
        fontWeight: 500,
        color: "#767D90",
        width: 90,
        height: 90,
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        borderRadius: "50%",
        mb: 2,
    },
    eventImageContainer: {
        borderRadius: "4px",
        border: "1px dashed var(--light-background-color-2, #E2E3E4)",
        bgcolor: "#FBFCFF",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        height: 200,
        mb: 2,
        "& button": {
            borderRadius: "4px",
            border: "1px solid #2B2A69",
            bgcolor: "#FFF",
            color: "#2B2A69",
            fontWeight: 500,
            textTransform: "capitalize",
            "&:hover": {
                bgcolor: "#FFF",
                border: "1px solid #2B2A69",
            }
        },
        "& label": {
            color: "#767D90",
            fontSize: 14,
        }
    },
    avatar: {
        height: 90,
        width: 90,
        mb: 2,
        cursor: 'pointer'
    },
    coverImageContainer: {
        borderRadius: "4px",
        border: "1px solid #eee",
        height: 200,
        width: "100%",
        objectFit: "cover",
        mb: 2
    },
    coverHover: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        gap: '10px',
        transition: "display 0.3s ease",
        "& button": {
            borderRadius: "4px",
            border: "1px solid #2B2A69",
            bgcolor: "#FFF",
            color: "#2B2A69",
            fontWeight: 500,
            textTransform: "capitalize",
            "&:hover": {
                bgcolor: "#FFF",
                border: "1px solid #2B2A69",
            }
        },
        "& label": {
            color: "#767D90",
            fontSize: 14,
        }
    },
}

export default styles