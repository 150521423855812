import {
	Modal,
	Box,
	Stack,
	Typography,
	Button,
	IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import LoadingButton from "../Buttons/LoadingButton";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import { useNavigate } from "react-router-dom";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 450,
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 15,
		p: 2,
		// "& button": {
		// 	width: 120,
		// 	boxShadow: "none",
		// 	fontWeight: 600,
		// },
	},
	topWrapper: {
		"& h1": {
			fontSize: 18,
			fontWeight: 600,
			flexGrow: 1,
			textAlign: "center",
			color: "#636363",
		},
	},
	closeIconBtn: {
		textAlign: "end",
		width: "40px !important",
		height: 40,
	},
	logout: {
		mt: 2,
		width: 150,
		fontSize: 15,
		fontWeight: 500,
		textTransform: "capitalize",
		borderRadius: "4px",
		bgcolor: "#E53935",
		"&:hover": {
			bgcolor: "#E53935",
		},
	},
	cancel: {
		mt: 2,
		width: 150,
		fontSize: 15,
		fontWeight: 500,
		textTransform: "capitalize",
		borderRadius: "4px",
		bgcolor: "#c1c1c1",
		"&:hover": {
			bgcolor: "#c1c1c1",
		},
	},
};

const LogoutModal = ({ modal, setModal }) => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => setModal(false);

	const handleLogout = async () => {
		setLoggedUser({});
		enqueueSnackbar("Logged Out!!", { variant: "success" });
		navigate("/login");
	};

	return (
		<>
			<Modal open={modal} onClose={handleClose}>
				<Box sx={styles.container}>
					<Stack direction="row" sx={styles.topWrapper} alignItems="center">
						<Box component="div" />
						<Typography component="h1">Warning</Typography>
						<IconButton onClick={handleClose} sx={styles.closeIconBtn}>
							<Close />
						</IconButton>
					</Stack>

					<Typography
						id="modal-modal-description"
						py={3.5}
						fontSize={17}
						textAlign="center"
						sx={styles.confirmTitle}
					>
						Are you sure you want to Logout?
					</Typography>

					<Stack direction="row" gap="10px" justifyContent="center">
						<Button
							variant="contained"
							sx={styles.cancel}
							onClick={handleClose}
						>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							sx={styles.logout}
							onClick={handleLogout}
						>
							Logout
						</LoadingButton>
					</Stack>
				</Box>
			</Modal>
		</>
	);
};

export default LogoutModal;
