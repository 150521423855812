import {
	Autocomplete,
	Avatar,
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	LinearProgress,
	Radio,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import FormInput from "../../components/Forms/FormInput";
import FormLabel from "../../components/Forms/FormLabel";
import { ReactComponent as Attachment } from "../../assets/svg/Attachment.svg";
import Remove from "../../assets/svg/Remove.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import CropImage from "../../components/Modals/CropImage";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import styles from "../../assets/styles/course/addEditCourse.styles";
import { get, post } from "../../server";
import {
	ADMIN_COURSES_ADD_UPDATE,
	ADMIN_COURSES_DETAIL,
	ADMIN_PROMO_CODE_LISTING,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import queryNames from "../../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import { validator } from "../../utils/helpers/validator";
import CourseSchema from "../../utils/schemas/CourseSchema";
import _ from "lodash";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../data/constants/routes";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import moment from "moment";
import { Add } from "@mui/icons-material";
import awsFileUpload from "../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../data/constants/uploadImageTypes";
import GoBackButton from "../../components/Buttons/GoBackButton";

const initialState = {
	name: "",
	price: "",
	about: "",
	discount: null,
	promo_code: "",
	author: "",
	author_image: "",
	cover_image: "",
	all_time_available: false,
	start_time: null,
	end_time: null,
	lessons: [
		{
			name: "",
			title: "",
			cover_image: "",
			files: [],
			delete_files: [],
			type: "attachment",
		},
	],
	delete_lesson: [],
};

const AdEditCourse = () => {
	const [course, setCourse] = useState(initialState);
	const [modal, setModal] = useState(false);
	const [promoCodes, setPromoCodes] = useState([]);

	const [profilePhoto, setProfilePhoto] = useState(null);
	const [lessonCoverPhoto, setLessonCoverPhoto] = useState(null);
	const [coverPhoto, setCoverPhoto] = useState(null);
	const [lessonIdex, setLessonIndex] = useState(null);
	const [fixedIndex, setFixedIndex] = useState(null);

	const [errors, setErrors] = useState({});

	const [uploadProgress, setUploadProgress] = useState({});

	const fileRef = useRef();
	const avatarRef = useRef();
	const coverImageRef = useRef();
	const lessonCoverImageRef = useRef();

	const { courseId } = useParams();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const isId = courseId !== null && typeof courseId !== "undefined";

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (name === "all_time_available") {
			setCourse({ ...course, [name]: checked });
		} else if (name === "price") {
			const sanitizedValue = value.replace(/\D/g, "").slice(0, 6);
			setCourse({ ...course, [name]: sanitizedValue });
		} else {
			const sanitizedValue = value.replace(/\s{2,}/g, " ");
			setCourse({ ...course, [name]: sanitizedValue });
		}
	};

	const handlePromoCodeChange = (event, newValue) => {
		if (newValue) {
			setCourse({
				...course,
				promo_code: newValue.id,
				promo_code_name: newValue.name,
			});
		} else {
			setCourse({
				...course,
				discount: null,
				promo_code: "",
			});
		}
	};

	const handleStartDateChange = (e) => {
		const selectedDate = e.toDate();
		const currentDate = new Date();

		if (
			selectedDate.getDate() === currentDate.getDate() &&
			selectedDate.getMonth() === currentDate.getMonth() &&
			selectedDate.getFullYear() === currentDate.getFullYear()
		) {
			selectedDate.setHours(currentDate.getHours());
			selectedDate.setMinutes(currentDate.getMinutes());
		}

		const updatedMomentDate = moment(selectedDate);
		setCourse({ ...course, start_time: updatedMomentDate });
	};

	const handleEndDateChange = (e) => {
		setCourse({ ...course, end_time: e });
	};

	const handleLessonChange = (e, lessonIndex) => {
		const name = e.target.name;
		const value = e.target.value;
		const newLessons = [...course.lessons];
		newLessons[lessonIndex] = {
			...newLessons[lessonIndex],
			[name]: value,
		};
		setCourse({ ...course, lessons: newLessons });
	};

	const handleAttachmentTypeChange = (e, lessonIndex) => {
		const type = e.target.value;

		const updatedLessons = [...course.lessons];
		const updatedLesson = { ...updatedLessons[lessonIndex], type };

		if (type === "attachment") {
			if (updatedLesson.files.length) {
				updatedLesson.files.forEach((link) => {
					if (link?.id) updatedLesson.delete_files.push(link?.id);
				});
			}
			updatedLesson.files = [];
		} else if (type === "link") {
			if (updatedLesson.files.length) {
				updatedLesson.files.forEach((file) => {
					if (file?.id) updatedLesson.delete_files.push(file?.id);
				});
			}
			updatedLesson.files = [{ file: "" }];
		}

		updatedLessons[lessonIndex] = updatedLesson;

		setCourse({
			...course,
			lessons: updatedLessons,
		});
	};

	const handleButtonClick = (index) => {
		setFixedIndex(index);
		fileRef.current.click();
	};

	const handleFileChange = (e, lessonIndex) => {
		const files = Array.from(e.target.files);
		const newLessons = [...course.lessons];

		const initialProgress = files.reduce((progress, file) => {
			progress[file.name] = 0;
			return progress;
		}, {});

		setUploadProgress((prevProgress) => ({
			...prevProgress,
			...initialProgress,
		}));

		newLessons[lessonIndex].files = [
			...newLessons[lessonIndex].files,
			...files,
		];
		setCourse({ ...course, lessons: newLessons });
	};

	const handleAddLesson = () => {
		setCourse({
			...course,
			lessons: [
				...course.lessons,
				{
					name: "",
					title: "",
					files: [],
					cover_image: "",
					type: "attachment",
				},
			],
		});
	};

	const handleRemoveLesson = (lessonIndex, lesson) => {
		let newLessons = [...course.lessons];
		if (course.lessons.length === 1) {
			newLessons = [
				{
					name: "",
					title: "",
					files: [],
					cover_image: "",
					type: "attachment",
				},
			];
		} else {
			newLessons.splice(lessonIndex, 1);
		}

		setCourse({
			...course,
			lessons: newLessons,
			delete_lesson: lesson.id
				? [...course.delete_lesson, lesson.id]
				: course.delete_lesson,
		});
	};

	const handleRemoveFile = (lessonIndex, fileIndex, file) => {
		const newLessons = [...course.lessons];
		newLessons[lessonIndex].files.splice(fileIndex, 1);

		const updatedLesson = {
			...newLessons[lessonIndex],
			delete_files: file.id
				? [...newLessons[lessonIndex].delete_files, file.id]
				: newLessons[lessonIndex].delete_files,
		};

		newLessons[lessonIndex] = updatedLesson;

		setCourse({ ...course, lessons: newLessons });
	};

	const handleAvatarClick = () => {
		avatarRef.current.click();
	};

	const handleAvatarChange = (e) => {
		const selectedFile = URL?.createObjectURL(e.target.files[0]);
		setProfilePhoto(selectedFile);
		setModal("avatar");
	};

	const handleCoverImageClick = (index) => {
		coverImageRef.current.click();
		setLessonIndex(index);
	};

	const handleCoverImageChange = (e) => {
		const selectedFile = URL?.createObjectURL(e.target.files[0]);
		setCoverPhoto(selectedFile);
		setModal("cover_image");
	};

	const handleLessonCoverImageClick = (index) => {
		lessonCoverImageRef.current.click();
		setLessonIndex(index);
	};

	const handleLessonCoverImageChange = (e) => {
		const selectedFile = URL?.createObjectURL(e.target.files[0]);
		setLessonCoverPhoto(selectedFile);
		setModal("lesson");
	};

	const handleLinkChange = (e, lessonIndex, linkIndex) => {
		const value = e.target.value;
		const newLessons = [...course.lessons];
		newLessons[lessonIndex].files[linkIndex].file = value;
		newLessons[lessonIndex].files[linkIndex].type = "link";
		setCourse({ ...course, lessons: newLessons });
	};

	const handleAddLink = (lessonIndex) => {
		const newLessons = [...course.lessons];
		newLessons[lessonIndex].files.push({ file: "" });
		setCourse({ ...course, lessons: newLessons });
	};

	const handleRemoveLink = (lessonIndex, linkIndex) => {
		const newLessons = [...course.lessons];
		const lesson = newLessons[lessonIndex];
		const fileToDelete = lesson.files[linkIndex];

		if (lesson.files.length === 1 && fileToDelete.id) {
			const delete_files = [fileToDelete.id];
			setCourse({
				...course,
				lessons: newLessons.map((l, idx) =>
					idx === lessonIndex
						? {
								...l,
								files: [{ file: "" }],
								delete_files,
						  }
						: l
				),
			});
		} else {
			lesson.files.splice(linkIndex, 1);

			if (fileToDelete.id) {
				lesson.delete_files = lesson.delete_files || [];
				lesson.delete_files.push(fileToDelete.id);
			}

			setCourse({ ...course, lessons: newLessons });
		}
	};

	const fetchPromoCodes = async () => {
		let url = new URL(ADMIN_PROMO_CODE_LISTING);

		url.searchParams.append("limit", 50);
		const { status, message, data } = await get(url.toString());
		if (status === true) {
			const newData = data.data.data;
			const finalData = newData.map((data) => {
				const discount = data.amount_off
					? `${data.amount_off} ${data.currency.toUpperCase()}`
					: `${data.percent_off} %`;
				return {
					id: data.id,
					label: `${data.name} | ${discount}`,
					name: data.name,
					amount_off: data.amount_off,
					percent_off: data.percent_off,
				};
			});
			setPromoCodes(finalData);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const promoCodeQuery = useQuery([queryNames.COURSES], fetchPromoCodes, {
		refetchOnWindowFocus: false,
	});

	const fetchCourse = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COURSES_DETAIL}/${courseId}`
		);
		if (status === true) {
			const modifiedData = {
				...data,
				start_time: data.start_time ? moment(data.start_time) : null,
				end_time: data.end_time ? moment(data.end_time) : null,
				lessons: data.lessons.map((lesson) => ({
					...lesson,
					delete_files: [],
				})),
				delete_lesson: [],
				all_time_available: data.all_time_available ? false : true,
			};
			setCourse(modifiedData);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const courseQuery = useQuery([queryNames.COURSES, courseId], fetchCourse, {
		enabled: isId,
		refetchOnWindowFocus: false,
	});

	const validateForm = () => {
		let newErrors = validator(
			{
				...course,
				start_time: course.start_time
					? course.start_time.format("YYYY-MM-DD HH:mm")
					: null,
				end_time: course.end_time
					? course.end_time.format("YYYY-MM-DD HH:mm")
					: null,
			},
			CourseSchema
		);

		setErrors(newErrors);

		if (Object.keys(newErrors).length) return false;
		if (!course.author_image || !course.cover_image) {
			const message = course.author_image
				? "Please upload Course Cover Image"
				: course.cover_image
				? "Please upload Author Image"
				: "Please upload All Images";

			enqueueSnackbar(message, {
				variant: "error",
			});
			return false;
		}

		let hasErrors = false;

		course.lessons.forEach((lesson, index) => {
			if (!lesson.cover_image) {
				enqueueSnackbar(`Please Upload Cover Image for Lesson ${index + 1}`, {
					variant: "error",
				});
				hasErrors = true;
			}
			if (lesson.files.length < 1) {
				enqueueSnackbar(`Please add Lesson Files for Lesson ${index + 1}`, {
					variant: "error",
				});
				hasErrors = true;
			}
			if (lesson.type === "link")
				lesson.files.forEach((file, fileIndex) => {
					if (!file.file) {
						enqueueSnackbar(
							`Please add Link address for Link ${fileIndex + 1} of Lesson ${
								index + 1
							}`,
							{
								variant: "error",
							}
						);
						hasErrors = true;
					}
				});
		});

		return !hasErrors;
	};

	const handleSaveSettings = async () => {
		let payload = _.cloneDeep(course);

		let newStatus = true;

		if (typeof payload.author_image === "object") {
			const data = await awsFileUpload(
				payload.author_image,
				uploadImageType.createCourse
			);
			if (data.status === true) payload.author_image = data.location;
			else {
				enqueueSnackbar("Error uploading Author Image", { variant: "error" });
				newStatus = false;
			}
		}
		if (typeof payload.cover_image === "object") {
			const data = await awsFileUpload(
				payload.cover_image,
				uploadImageType.createCourse
			);
			if (data.status === true) payload.cover_image = data.location;
			else {
				enqueueSnackbar("Error uploading Cover Image", { variant: "error" });
				newStatus = false;
			}
		}
		await Promise.all(
			payload.lessons.map(async (lesson) => {
				if (typeof lesson.cover_image === "object") {
					const data = await awsFileUpload(
						lesson.cover_image,
						uploadImageType.createCourse
					);
					if (data.status === true) {
						lesson.cover_image = data.location;
					} else {
						enqueueSnackbar("Error uploading lesson cover image", {
							variant: "error",
						});
						newStatus = false;
					}
				}

				if (
					lesson.files.length > 0 &&
					lesson.type === "attachment" &&
					lesson.files.some((file) => !file.id || !file.file)
				) {
					for (let i = 0; i < lesson.files.length; i++) {
						if (lesson.files[i].name) {
							const data = await awsFileUpload(
								lesson.files[i], // Use lesson.files[i] here to process individual files
								uploadImageType.createCourse,
								setUploadProgress
							);
							if (data.status === true) {
								const fileName = data.location;
								const parts = fileName.split(".");
								const fileExtension = parts[parts.length - 1];
								let fileType = "file"; // Default to 'file'
								if (
									["jpg", "jpeg", "png", "gif"].includes(
										fileExtension.toLowerCase()
									)
								) {
									fileType = "image";
								} else if (
									["mp4", "avi", "mov"].includes(fileExtension.toLowerCase())
								) {
									fileType = "video";
								}
								lesson.files[i] = {
									file: fileName,
									type: fileType,
								};
							} else {
								enqueueSnackbar("Error uploading file", { variant: "error" });
								newStatus = false;
							}
						}
					}
				}
			})
		);

		const promoCode = promoCodes.find((code) => code.id === payload.promo_code);
		const discount = promoCode?.amount_off
			? promoCode?.amount_off
			: course.price * (promoCode?.percent_off / 100);

		if (newStatus) {
			const { status, message } = await post(ADMIN_COURSES_ADD_UPDATE, {
				...payload,
				start_time:
					payload.all_time_available === true
						? payload.start_time?.format("YYYY-MM-DD HH:mm")
						: null,
				end_time:
					payload.all_time_available === true
						? payload.end_time?.format("YYYY-MM-DD HH:mm")
						: null,
				all_time_available: payload.all_time_available === true ? 0 : 1,
				discount: payload.promo_code && discount,
			});
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				navigate(routes.ADMIN_ALL_COURSE);
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSaveSettings);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1">{isId ? "Edit" : "Add"} Courses</Typography>
				<GoBackButton url={-1} />
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
				>
					Save Changes
				</LoadingButton>
			</Stack>

			<LoadingOverlay loading={courseQuery.isLoading} />

			<Box sx={styles.container}>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						<FormInput
							value={course.name}
							label="Course Name"
							placeholder="Course Name"
							name="name"
							type="text"
							onChange={handleChange}
							error={errors?.name}
							helperText={errors?.name || ""}
						/>
					</Grid>

					<Grid item md={4} xs={12}>
						<FormInput
							value={course.price}
							label="Price"
							placeholder="Price"
							name="price"
							type="number"
							inputProps={{ min: 0, max: 999999 }}
							onChange={handleChange}
							error={errors?.price}
							helperText={errors?.price || ""}
						/>
					</Grid>

					<Grid item md={4} xs={12}>
						<FormLabel label="Promo Code" />
						<Autocomplete
							options={promoCodes}
							value={
								promoCodes.find((option) => option.id === course.promo_code)
									?.label || null
							}
							onChange={handlePromoCodeChange}
							renderInput={(params) => (
								<TextField {...params} placeholder="Select Promo Code" />
							)}
							isOptionEqualToValue={(option, value) => option?.label === value}
							sx={styles.autocomplete}
						/>
					</Grid>

					<Grid item md={8} xs={12}>
						<FormInput
							value={course.about}
							label="About"
							placeholder="About"
							name="about"
							type="text"
							multiline
							rows={2}
							onChange={handleChange}
							error={errors?.about}
							helperText={errors?.about || ""}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3} mt={0}>
					<Grid item md={4} xs={12} sx={{ placeSelf: "end" }}>
						<FormControlLabel
							sx={styles.checkbox}
							control={
								<Checkbox
									name="all_time_available"
									checked={course.all_time_available}
									onChange={handleChange}
								/>
							}
							label="Schedule course"
						/>
					</Grid>

					{course?.all_time_available && (
						<>
							<Grid item md={4} xs={12}>
								<FormLabel label="Start Date/Time" />
								<DateTimePicker
									variant="outlined"
									sx={{ ...styles.datePicker, width: "100%" }}
									value={course?.start_time}
									onChange={handleStartDateChange}
									disablePast
									slotProps={{
										textField: {
											helperText: errors?.start_time || "",
											error: errors?.start_time,
										},
									}}
									closeOnSelect={false}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<FormLabel label="End Date/Time" />
								<DateTimePicker
									variant="outlined"
									sx={{ ...styles.datePicker, width: "100%" }}
									value={course?.end_time}
									onChange={handleEndDateChange}
									disablePast
									minDateTime={course?.start_time || moment()}
									slotProps={{
										textField: {
											helperText: errors?.end_time || "",
											error: errors?.end_time,
										},
									}}
									closeOnSelect={false}
								/>
							</Grid>
						</>
					)}
				</Grid>

				<Grid container spacing={3} mt={0}>
					<Grid item md={4} xs={12}>
						<FormLabel label="Course Cover Image" />
						{course.cover_image ? (
							<Box
								component="img"
								sx={styles.lessonCoverImg}
								src={
									course?.cover_image
										? typeof course?.cover_image === "string"
											? course?.cover_image
											: URL?.createObjectURL(course?.cover_image)
										: null
								}
								onClick={handleCoverImageClick}
								loading="lazy"
							/>
						) : (
							<Box
								sx={{
									...styles.lessonCoverImg,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									"& svg": {
										height: 40,
										width: 40,
									},
								}}
								onClick={handleCoverImageClick}
							>
								<ImageOutlinedIcon />
							</Box>
						)}
						<input
							type="file"
							hidden
							accept="image/*"
							onChange={handleCoverImageChange}
							id="coverImage"
							ref={coverImageRef}
						/>
					</Grid>
				</Grid>

				<Divider sx={{ color: "#E2E3E4", mt: 5, mb: 4 }} />

				<Grid container spacing={3}>
					<Grid item md={2} xs={12}>
						<FormLabel label="Author Avatar" />
						<Avatar
							sx={styles.avatar}
							onClick={handleAvatarClick}
							src={
								course?.author_image
									? typeof course?.author_image === "string"
										? course?.author_image
										: URL?.createObjectURL(course?.author_image)
									: null
							}
						>
							<CameraAltOutlinedIcon />
						</Avatar>
						<input
							type="file"
							accept="image/*"
							hidden
							onChange={handleAvatarChange}
							id="avatarUpload"
							ref={avatarRef}
						/>
					</Grid>

					<Grid item md={4} xs={12}>
						<FormInput
							value={course.author}
							label="Author Name"
							placeholder="Author Name"
							name="author"
							type="text"
							onChange={handleChange}
							error={errors?.author}
							helperText={errors?.author || ""}
						/>
					</Grid>
				</Grid>

				<Divider sx={{ color: "#E2E3E4", mt: 5, mb: 4 }} />

				<Typography component="h4">Add New Lessons</Typography>

				{course.lessons.map((lesson, lessonIndex) => (
					<Stack
						direction="row"
						sx={styles.lessonContainer}
						alignItems="center"
						key={lessonIndex}
					>
						<Grid container spacing={3} key={lessonIndex}>
							<Grid item md={4} xs={12}>
								<FormInput
									value={lesson.name}
									label="Lesson Name"
									placeholder="Lesson Name"
									name="name"
									type="text"
									onChange={(e) => handleLessonChange(e, lessonIndex)}
									error={errors[`lessons[${lessonIndex}].name`]}
									helperText={errors[`lessons[${lessonIndex}].name`] || ""}
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<FormInput
									value={lesson.title}
									label="Title"
									placeholder="Title Name"
									name="title"
									type="text"
									onChange={(e) => handleLessonChange(e, lessonIndex)}
									error={errors[`lessons[${lessonIndex}].title`]}
									helperText={errors[`lessons[${lessonIndex}].title`] || ""}
								/>
							</Grid>
							<Grid item md={4} xs={12}></Grid>

							<Grid item md={4} xs={12}>
								<FormLabel label="Cover Image" />
								{lesson.cover_image ? (
									<Box
										component="img"
										sx={styles.lessonCoverImg}
										src={
											lesson?.cover_image
												? typeof lesson?.cover_image === "string"
													? lesson?.cover_image
													: URL?.createObjectURL(lesson?.cover_image)
												: null
										}
										onClick={() => handleLessonCoverImageClick(lessonIndex)}
										loading="lazy"
									/>
								) : (
									<Box
										sx={{
											...styles.lessonCoverImg,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											"& svg": {
												height: 40,
												width: 40,
											},
										}}
										onClick={() => handleLessonCoverImageClick(lessonIndex)}
									>
										<ImageOutlinedIcon />
									</Box>
								)}
								<input
									type="file"
									hidden
									accept="image/*"
									onChange={(e) => handleLessonCoverImageChange(e, lessonIndex)}
									id={`coverImage-${lessonIndex}`}
									ref={lessonCoverImageRef}
								/>
							</Grid>

							<Grid item md={8} xs={12}>
								{/* <FormLabel label="Attachment" /> */}

								<Stack direction="row" gap="10px" alignItems="center" mb="10px">
									<Typography component="label" sx={styles.label}>
										Type:
									</Typography>

									<Stack direction="row" alignItems="center" gap="5px">
										<Radio
											checked={lesson.type === "attachment"}
											onChange={(e) =>
												handleAttachmentTypeChange(e, lessonIndex)
											}
											value="attachment"
											sx={{ p: 0 }}
										/>
										<Typography component="label" sx={styles.label}>
											Attachment
										</Typography>
									</Stack>

									<Stack direction="row" alignItems="center" gap="5px">
										<Radio
											checked={lesson.type === "link"}
											onChange={(e) =>
												handleAttachmentTypeChange(e, lessonIndex)
											}
											value="link"
											sx={{ p: 0 }}
										/>
										<Typography component="label" sx={styles.label}>
											Link
										</Typography>
									</Stack>
								</Stack>

								{lesson.type === "attachment" ? (
									<Stack direction="row" gap={2} flexWrap="wrap">
										<Box
											sx={styles.attachmentContainer}
											onClick={() => handleButtonClick(lessonIndex)}
										>
											+
										</Box>
										<input
											type="file"
											multiple
											hidden
											onChange={(e) => {
												handleFileChange(e, fixedIndex);
											}}
											id={`fileUpload-${lessonIndex}`}
											ref={fileRef}
										/>
										{lesson.files.map((file, fileIndex) => {
											const fileNameWithoutExtension = file.file
												? (() => {
														const parts = file.file.split("/");
														const fileNameWithExtension =
															parts[parts.length - 1];
														return fileNameWithExtension.replace(
															/\.[^/.]+$/,
															""
														);
												  })()
												: file.name
												? file.name.replace(/\.[^/.]+$/, "")
												: "Unnamed";

											const parts = file.file
												? file.file.split(".") || ""
												: file.name
												? file.name.split(".") || ""
												: "";

											const simplifiedContentType = parts[parts.length - 1];

											return (
												<div key={fileIndex}>
													<Stack
														sx={styles.documentContainer}
														direction="row"
														gap={1}
													>
														<Box
															component="img"
															src={Remove}
															sx={styles.removeIcon}
															onClick={() =>
																handleRemoveFile(lessonIndex, fileIndex, file)
															}
															loading="lazy"
														/>
														<Attachment overflow="visible" />
														<Stack>
															<Typography component="h6">
																{fileNameWithoutExtension}
															</Typography>
															<Typography component="label">
																{simplifiedContentType.toUpperCase()}
															</Typography>
														</Stack>
													</Stack>
													{uploadProgress[file.name] > 0 && (
														<LinearProgress
															variant="determinate"
															value={uploadProgress[file.name] || 0}
															sx={{ mt: 1 }}
														/>
													)}
												</div>
											);
										})}
									</Stack>
								) : (
									<Box>
										<Stack gap="10px">
											{lesson.files?.map((link, linkIndex) => (
												<Stack
													key={linkIndex}
													direction="row"
													gap={2}
													alignItems="center"
													justifyContent="space-between"
													sx={{ marginBottom: 2 }}
												>
													<FormInput
														value={link.file}
														placeholder="Link"
														name={`links[${lessonIndex}][${linkIndex}]`}
														type="url"
														fullWidth
														onChange={(e) =>
															handleLinkChange(e, lessonIndex, linkIndex)
														}
													/>
													{lesson.files.length > 1 && link && (
														<IconButton
															aria-label="delete"
															size="large"
															onClick={() =>
																handleRemoveLink(lessonIndex, linkIndex)
															}
														>
															<DeleteIcon />
														</IconButton>
													)}
												</Stack>
											))}
										</Stack>
										<Tooltip title="Add new Link">
											<IconButton
												aria-label="add"
												size="large"
												onClick={() => handleAddLink(lessonIndex)}
											>
												<Add />
											</IconButton>
										</Tooltip>
									</Box>
								)}
							</Grid>
						</Grid>

						<Tooltip title="Clear Lesson">
							<IconButton
								aria-label="delete"
								size="large"
								sx={styles.removeBtn}
								onClick={() => handleRemoveLesson(lessonIndex, lesson)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Stack>
				))}

				<Typography component="p" onClick={handleAddLesson}>
					+Add more Lessons
				</Typography>
			</Box>

			<CropImage
				modal={modal}
				setModal={setModal}
				image={
					modal === "avatar"
						? profilePhoto
						: modal === "cover_image"
						? coverPhoto
						: modal === "lesson" && lessonCoverPhoto
				}
				setUser={setCourse}
				lessonIndex={lessonIdex}
				cropShape={modal === "avatar" ? "round" : ""}
			/>
		</>
	);
};

export default AdEditCourse;
