import Joi from "joi";

const PostCommentSchema = Joi.object({
  description: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Description`,
    "string.max": `Description must be maximum 500 characters!`,
  }),
});

export default PostCommentSchema;
