import Joi from "joi"

const SponsorSchema = Joi.object({
    company_name: Joi.string().required().max(50).messages({
        "string.empty": `Please enter Company Name`,
        "string.max": `Company name must be maximum 50 characters!`,
    }),
    sponser_name: Joi.string().required().max(50).messages({
        "string.empty": `Please enter Sponsor Name`,
        "string.max": `Sponsor name must be maximum 50 characters!`,
    }),
    address: Joi.string().max(500).required().messages({
        "string.empty": `Please enter Address`,
        "string.max": `Address must be maximum 500 characters!`,
    }),
    website: Joi.string().required().messages({
        "string.empty": `Please enter website URL`,
    }),
    description: Joi.string().max(500).required().messages({
        "string.empty": `Please enter Description`,
        "string.max": `Description must be maximum 500 characters!`,
    }),
    email: Joi.string()
        .max(50)
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": `Please enter email address`,
            "string.email": `Please enter valid email address`,
            "string.max": `Email must be maximum 50 characters!`,
            "string.pattern.base": `Please enter email address`,
        }),
})

export default SponsorSchema