

import Joi from "joi";


const EmailSchema = Joi.object({
    send_to: Joi.string().required().messages({
        "string.empty": `Please select Email to`,
    }),
    subject: Joi.string().max(100).required().messages({
        "string.empty": `Please enter Subject`,
        "string.max": `Subject must be maximum 100 characters!`,
    }),
    message: Joi.string().max(500).required().messages({
        "string.empty": `Please enter Message`,
        "string.max": `Message must be maximum 500 characters!`,
    }),
});

export default EmailSchema;
