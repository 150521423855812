import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import forgotImg from "../../assets/svg/ForgetPassword.svg";
import { ReactComponent as Email } from "../../assets/svg/Email.svg";
import SplashForm from "../../components/ui/SplashForm";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { validator } from "../../utils/helpers/validator";
import { ForgetPasswordSchema } from "../../utils/schemas/AuthSchema";
import { post } from "../../server";
import { ADMIN_FORGET_PASSWORD } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import routes from "../../data/constants/routes";

function ForgotPassword() {
	const [forgetPassword, setForgetPassword] = useState({
		email: "",
	});

	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		value = value.replace(/\s/g, '');
		setForgetPassword({ ...forgetPassword, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(forgetPassword, ForgetPasswordSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleForgotPassword = async () => {
		const { status, message, data } = await post(
			ADMIN_FORGET_PASSWORD,
			{
				email: loggedUser.email,
				...forgetPassword,
			}
		);
		if (status === true) {
			setLoggedUser(forgetPassword);
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.ADMIN_VERIFY_OTP);
		} else {
			enqueueSnackbar(data.email[0], { variant: "error" });
		}
	};

	const mutation = useMutation(handleForgotPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<SplashForm
				image={forgotImg}
				title="Forgot Password?"
				tagline="Please enter your email address to request a password reset"
				btnText="Send OTP"
				// linkTaglinePlainText="Not registered yet?"
				// linkTaglineText="Create an Account"
				// linkTaglineHref="/signup"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<>
					<TextField
						fullWidth
						variant="outlined"
						size="small"
						type="email"
						name="email"
						value={forgetPassword.email}
						onChange={handleChange}
						placeholder="Email"
						error={errors?.email}
						helperText={errors?.email || ""}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Email />
								</InputAdornment>
							),
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>
				</>
			</SplashForm>
		</>
	);
}

export default ForgotPassword;
