const styles = {
  label: {
    color: "#767D90",
    fontWeight: 400,
    fontSize: 16,
    marginBottom: "7px",
    display: "block",
    "& > *": {
      color: "#fa5252",
    },
  },
  smallLabel: {
    fontSize: 13
  }
};

export default styles;
