import { useState } from "react";
import {
	Box,
	Stack,
	Modal,
	Typography,
	Grid,
	IconButton,
	Button,
	Drawer,
	LinearProgress,
	Avatar,
} from "@mui/material";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import CompanyLogo from "../../assets/svg/companyLogo.svg";
import CustomTabs from "./CustomTabs";
import MemberTab from "../pageComponents/companies/MembersTab";
import SubscriptionTab from "../pageComponents/companies/SubscriptionTab";
import { get, post } from "../../server";
import {
	ADMIN_COMPANIES_DETAIL,
	ADMIN_COMPANY_INVITE_MEMBERS,
	ADMIN_EVENT_MEMBER_USER_EXISTS,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../../data/constants/queryNames";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import FormInput from "../Forms/FormInput";
import LoadingButton from "../Buttons/LoadingButton";

const styles = {
	modal: {
		// position: "absolute",
		// bgcolor: "#fff",
		// top: 0,
		// right: 0,
		maxWidth: 800,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		transition: "all 0.3s ease",
		px: { md: 3, xs: 2 },
		py: 2,
		"& h3": {
			fontSize: 22,
			color: "#333333",
			letterSpacing: 0,
			pb: 3,
		},
		"& p": {
			color: "#767D90",
			pb: 1,
		},
		"& h6": {
			color: "#000",
			letterSpacing: 0,
			pb: 1,
		},
	},
	logoHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		pt: 3,
		pb: 2,
	},
	logo: {
		width: { md: 80, xs: 60 },
		height: { md: 80, xs: 60 },
		borderRadius: "50%",
	},
	iconWrapper: {
		flexDirection: "row",
		alignItems: "center",
		columnGap: 1,
		"& svg": {
			width: 22,
			height: 22,
		},
	},
	inviteBtn: {
		position: "absolute",
		right: 0,
		top: 20,
		width: 100,
		bgcolor: "#0F4590",
		color: "#fff",
		fontWeight: 600,
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#0F4590",
		},
	},
	inviteModal: {
		width: 450,
		pt: 5,
		px: 2,
		pb: 2,
	},
	memberFullStack: {
		borderBottom: "1px solid #E2E3E4",
		"&:last-child": {
			borderBottom: "none", // Remove border for the last item
		},
		px: 1.5,
	},
	memberStack: {
		alignItems: "center",
		gap: 1.5,
		py: "12px",
		"& h4": {
			fontSize: 13,
			wordBreak: "break-all",
			maxWidth: 200,
		},
	},
	memberAvatar: {
		width: 40,
		height: 40,
		bgcolor: "gray",
	},
	addEmail: {
		bgcolor: "#2B2A69",
		color: "#FFF",
		fontWeight: 600,
		textTransform: "capitalize",
		px: 3,
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "#FFF",
		},
	},
	button: {
		mt: 2,
		width: 100,
		bgcolor: "#2B2A69",
		color: "#fff",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
};

export default function RightDrawer({ modal, setModal, id = null }) {
	const [companyData, setCompanyData] = useState();
	const [newModal, setNewModal] = useState(false);

	const [members, setMembers] = useState([]);
	const [typedEmail, setTypedEmail] = useState("");
	const [searchedEmails, setSearchedEmails] = useState([]);
	const [enableSearch, setEnableSearch] = useState(false);
	const client = useQueryClient();

	const isValidEmail = (email) => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailRegex.test(email);
	};

	const handleClose = () => {
		setModal(false);
		setCompanyData();
	};

	const handleEmailAdd = (index) => {
		const selectedUser = searchedEmails[index];
		const newEmail = {
			profile_image: selectedUser.profile_image,
			full_name: selectedUser.full_name,
			email: selectedUser.email,
			id: selectedUser.id,
		};

		setMembers([...members, newEmail]);

		const updatedSearchedEmails = searchedEmails.filter(
			(user, i) => i !== index
		);
		setSearchedEmails(updatedSearchedEmails);
	};

	const { enqueueSnackbar } = useSnackbar();

	const fetchCompanyDetails = async () => {
		const { status, message, data } = await get(
			`${ADMIN_COMPANIES_DETAIL}/${id}`
		);
		if (status === true) {
			setCompanyData(data);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const CompanyQuery = useQuery([queryNames.COMPANY, id], fetchCompanyDetails, {
		enabled: modal && id !== null,
		refetchOnWindowFocus: false,
	});

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.COMPANY);
	};

	const details = [
		{ label: "Owner:", value: companyData?.created_by },
		{ label: "Phone:", value: companyData?.company.contact_no },
		// { label: "Email:", value: companyData?.company.email },
		{ label: "Address:", value: companyData?.company.address },
	];

	const tabList = [
		{
			label: "Members",
			component: <MemberTab members={companyData?.company.members} />,
		},
	];

	const findMember = async () => {
		let url;
		url = new URL(ADMIN_EVENT_MEMBER_USER_EXISTS);
		url.searchParams.append("email", typedEmail);

		const { status, message, data } = await get(url.toString());
		if (status) {
			const filteredData = data.filter(
				(newMember) =>
					!companyData?.company.members.some(
						(member) => member.email === newMember.email
					) || !members.some((member) => member.email === newMember.email)
			);
			setSearchedEmails(filteredData);
		}
		if (!data.length) {
			if (isValidEmail(typedEmail)) {
				const isEmailInGlobalMembers = companyData?.company.members.some(
					(member) => member.email === typedEmail
				);

				const isEmailInMembers = members.some(
					(member) => member.email === typedEmail
				);

				if (isEmailInMembers || isEmailInGlobalMembers) {
					setSearchedEmails([]);
				} else {
					const newEmail = {
						profile_image: null,
						full_name: typedEmail,
						email: typedEmail,
						type: "not_exist",
					};
					setSearchedEmails([newEmail]);
				}
			}
		}
		setEnableSearch(false);
	};

	const memberQuery = useQuery(["membersM", enableSearch], findMember, {
		refetchOnWindowFocus: false,
	});

	const saveSpeaker = async () => {
		const { status, message } = await post(ADMIN_COMPANY_INVITE_MEMBERS, {
			company_id: id,
			attendee_email: members.map((member) => ({
				id: member.id,
				email: member.email,
			})),
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			handleClose();
			setNewModal(false);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const mutation = useMutation(saveSpeaker);

	const handleSubmit = (e) => {
		e.preventDefault();
		mutation.mutate();
	};

	return (
		<>
			<Drawer anchor="right" open={modal} onClose={handleClose}>
				<LoadingOverlay loading={CompanyQuery.isLoading} />
				<Box sx={styles.modal} role="presentation">
					<Close className="closeIcon" onClick={handleClose} />
					<Stack sx={styles.logoHeader}>
						<Box
							component="img"
							src={companyData?.company.company_logo || CompanyLogo}
							alt="Company Logo"
							sx={styles.logo}
						/>
					</Stack>

					<Typography component="h3">
						{companyData?.company.company_name}
					</Typography>

					<Grid container spacing={1}>
						{details.map((detail, index) => (
							<Grid key={index} item md={12}>
								<Grid container spacing={0}>
									<Grid item md={2}>
										<Typography
											variant="h6"
											sx={{ color: "#767D90 !important" }}
										>
											{detail.label}
										</Typography>
									</Grid>

									<Grid item md={10}>
										<Typography variant="h6">{detail.value}</Typography>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
					<Box position="relative">
						<CustomTabs tabList={tabList} />
						<Button
							variant="contained"
							sx={styles.inviteBtn}
							onClick={() => setNewModal(true)}
						>
							Invite
						</Button>
					</Box>
				</Box>
			</Drawer>

			<Drawer anchor="right" open={newModal} onClose={() => setNewModal(false)}>
				<Box sx={styles.inviteModal}>
					<Close className="closeIcon" onClick={() => setNewModal(false)} />
					<FormInput
						label="Invite Members"
						placeholder="example@yourmail.com"
						id="invite-link-input"
						value={typedEmail}
						onChange={(e) => {
							setTypedEmail(e.target.value);
							if (e.target.value.length > 2) {
								setEnableSearch(true);
							}
							if (e.target.value.length === 0) {
								setSearchedEmails([]);
							}
						}}
					/>

					{(memberQuery.isLoading || memberQuery.isFetching) && (
						<Box width="100%" textAlign="center">
							<LinearProgress />
						</Box>
					)}

					{searchedEmails.length > 0 && (
						<Box sx={{ border: "1px solid #eee", mt: 2, borderRadius: "10px" }}>
							{searchedEmails.map((member, index) => (
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									key={index}
									sx={styles.memberFullStack}
								>
									<Stack direction="row" sx={styles.memberStack}>
										<Avatar
											sx={styles.memberAvatar}
											src={member.profile_image}
										/>
										<Stack>
											<Typography component="h4">{member.full_name}</Typography>
											<Typography component="h4" flex={1}>
												{member.email}
											</Typography>
										</Stack>
									</Stack>
									<Button
										variant="contained"
										sx={styles.addEmail}
										onClick={() => handleEmailAdd(index)}
									>
										{member.type === "not_exist" ? "Invite" : "Add Email"}
									</Button>
								</Stack>
							))}
						</Box>
					)}

					{members.length > 0 &&
						members.map((member, index) => (
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								key={index}
								sx={{
									...styles.memberFullStack,
									borderBottom: "1px solid #E2E3E4",
									"& last-child": { borderBottom: "none" },
								}}
							>
								<Stack direction="row" sx={styles.memberStack}>
									<Avatar sx={styles.memberAvatar} src={member.profile_image} />
									<Stack>
										<Typography component="h4">{member.full_name}</Typography>
										<Typography component="h4" flex={1}>
											{member.email}
										</Typography>
									</Stack>
								</Stack>

								<IconButton
									onClick={() => {
										const updatedMembers = members.filter(
											(member, i) => i !== index
										);
										setMembers(updatedMembers);
									}}
								>
									<Delete />
								</IconButton>
							</Stack>
						))}

					<LoadingButton
						variant="contained"
						onClick={handleSubmit}
						isLoading={mutation.isLoading}
						sx={styles.button}
					>
						Submit
					</LoadingButton>
				</Box>
			</Drawer>
		</>
	);
}
