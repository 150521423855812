import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  ADMIN_GET_POST_BY_ID,
  ADMIN_GET_POST_COMMENTS,
  ADMIN_ADD_POST_COMMENT,
  ADMIN_DELETE_POST_COMMENT,
} from "../../../data/constants/apiRoutes";
import { get, post, destroy } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import Listing from "../../../components/ui/Listing";
import PageHeader from "../../../components/ui/PageHeader";
import { validator } from "../../../utils/helpers/validator";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";
import styles from "../../../assets/styles/events/eventDetails.styles";
import PostCommentSchema from "../../../utils/schemas/PostCommentSchema";
import BoxModal from "../../../components/Modals/BoxModal";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import PostDrawer from "../../../components/Drawers/PostDrawer";
import CommentBoxModal from "../../../components/Modals/CommentBoxModal";

//SVG
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";

//INITIAL STATE
const initialState = {
  description: "",
};

export default function PostDetails() {
  const { postId } = useParams();
  const client = useQueryClient();
  const navigate = useNavigate();

  //USESTATE
  const [newComment, setNewComment] = useState(initialState);
  const [postData, setPostData] = useState({});
  const [commentId, setCommentId] = useState(null);
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [descriptionPopupVisible, setDescriptionPopupVisible] = useState(false);
  const [descriptionPopupData, setDescriptionPopupData] = useState("");

  //COLUMN
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const handleDescriptionClick = () => {
          setDescriptionPopupData(params.row.description);
          setDescriptionPopupVisible(true);
        };
  
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={handleDescriptionClick}
          >
            {params.row.description}
          </div>
        );
      },
      // valueGetter: (params) => params?.row?.description,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
      width: 180,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "favCount",
      headerName: "Favourite Count",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.favCount,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Edit" arrow placement="top">
              <IconButton onClick={() => handleEditModalOpen(params?.row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setCommentId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //GET DATA OF POST
  const fetchPostDetail = async () => {
    const { status, data, message } = await get(
      `${ADMIN_GET_POST_BY_ID}/${postId}`
    );
    if (status === true) {
      setPostData(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const postDetailQuery = useQuery([queryNames.POSTS], fetchPostDetail, {
    keepPreviousData: true,
  });

  //FORM VALIDATION
  const validateForm = () => {
    const newErrors = validator(newComment, PostCommentSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  //PROFILE DETAIL SCREEN
  const handleProfileClick = (data) => {
    let id;
    if(data?.row?.user_id && data?.field === "full_name"){
      id = data?.row?.user_id;
      navigate(`/profile/details/${id}`);
    } else if(data?.user_id){
      id = data?.user_id;
      navigate(`/profile/details/${id}`);
    }
  };

  //MODAL OPENING AND API CALLING FOR PARTICULAR POST
  const handleEditModalOpen = async (row) => {
    setCommentId(row?.id);

    setNewComment({
      description: row?.description,
    });
    setIsEdit(true);
    setModal(true);
  };

  //MODAL OPENING AND CLOSING

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleClose = () => {
    setNewComment(initialState);
    setCommentId(null);
    setIsEdit(false);
    setErrors({})
    setModal(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setCommentId(null);
  };

  //DATA UPDATING AFTER API IS CALLED
  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.POSTS);
  };

  //CALLING METHOD TO GET COMMENTS OF POST
  const fetchPosts = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_POST_COMMENTS}/${postId}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //API METHODS
  const saveComment = async () => {
    const payload = _.cloneDeep(newComment);
    const { status, message } = await post(
      `${ADMIN_ADD_POST_COMMENT}/${postId}`,
      {
        description: payload.description,
      }
    );
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const updateComment = async () => {
    const payload = _.cloneDeep(newComment);
    const { status, message } = await post(
      `${ADMIN_ADD_POST_COMMENT}/${postId}`,
      {
        comment_id: commentId,
        description: payload.description,
      }
    );
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const deleteComment = async () => {
    const { status, message } = await destroy(
      `${ADMIN_DELETE_POST_COMMENT}/${commentId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //MUTATION AND ITS METHOD
  const mutation = useMutation(saveComment);
  const mutationUpdate = useMutation(updateComment);
  const mutationDelete = useMutation(deleteComment);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (isEdit) {
      mutationUpdate.mutate();
    } else {
      mutation.mutate();
    }
  };

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Post Details" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
          <PageHeader
            createBtn
            createBtnText="Add Comment"
            btnFnc={handleOpenModal}
          />
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <LoadingOverlay
          loading={postDetailQuery.isLoading || postDetailQuery.isFetching}
        />
        <Box sx={{ ...styles.wrapper, mb: 0 }}>
        <Stack direction="row" alignItems="center" onClick={() => handleProfileClick(postData)} sx= {{cursor: "pointer"}}>
          <Avatar
            src={postData?.owner?.profile_image}
            sx={{
              bgcolor: "gray",
              mt: 2,
              ml: 1.5,
              mr: 1.5,
              width: 35,
              height: 35,
            }}
          >
            {postData?.owner?.full_name.charAt(0).toUpperCase()}
          </Avatar>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              {postData?.owner?.full_name}
            </Typography>
            <Typography variant="body2">
              {(() => {
                const createdAt = moment(postData?.created_at);
                const currentTime = moment();
                const timeDifference = moment.duration(
                  currentTime.diff(createdAt)
                );

                if (timeDifference.days() > 5) {
                  return moment(postData?.created_at).format(
                    "D-MMM-YYYY | h:mm A"
                  );
                } else {
                  let formattedTime = "";

                  if (timeDifference.days() > 0) {
                    formattedTime += `${timeDifference.days()}d `;
                  }

                  if (
                    timeDifference.hours() > 0 ||
                    timeDifference.days() > 0
                  ) {
                    formattedTime += `${timeDifference.hours()}h `;
                  }

                  formattedTime += `${timeDifference.minutes()}m ago`;

                  return formattedTime;
                }
              })()}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ mt: 2, ml: 5 }}>
          <Typography variant="body2">
            {postData?.description}
          </Typography>
        </Box>

        {postData?.assets?.length ? (
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            adaptiveHeight={true}
          >
            {postData?.assets?.map((asset, index) => (
                <Grid container>
                  <Grid md={12} item sx={styles.leftWrapper}>
                    <Box>
                      {
                        <Box
                          key={index} // Add a unique key for each image
                          component={asset.asset_type == "image" ? "img" : "iframe" }
                          src={asset.asset_url}
                          loading="lazy"
                          sx={{
                            maxWidth: "100%",
                            maxHeight: 500,
                            margin: "0 auto",
                            borderRadius: 3,
                          }}
                        />
                      }
                    </Box>
                  </Grid>
                </Grid>

            ))}
          </Slider>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
              minHeight: "10vh",
            }}
          >
            <Typography variant="h6">Post has no Assets</Typography>
          </Box>
        )}
        </Box>
      </Box>

      <Listing
        columns={columns}
        queryName={queryNames.POSTS}
        handleCellClick={handleProfileClick}
        fetchRecords={fetchPosts}
        className="posts"
      />
      
      {/* POSTDRAWER COMPONENT */}
      <PostDrawer
        anchor="right"
        open={modal}
        text={!isEdit ? "Add new Comment" : "Update Comment"}
        onCloseDrawer={handleClose}
        isEditMode={isEdit}
        state={newComment}
        setState={setNewComment}
        handleSubmit={handleSubmit}
        mutation={mutation}
        mutationUpdate={mutationUpdate}
        errors={errors}
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this comment"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
      {descriptionPopupVisible && (
        <CommentBoxModal
          header="Comment"
          title={descriptionPopupData}
          open={descriptionPopupVisible}
          handleClose={() => setDescriptionPopupVisible(false)}
        />
      )}
    </>
  );
}
