import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import FormInput from "../../components/Forms/FormInput";
import CropImage from "../../components/Modals/CropImage";
import LoadingButton from "../../components/Buttons/LoadingButton";

//SVG
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";

//STYLES
const styles = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& h3": {
      fontSize: 16,
      fontWeight: 600,
      color: "#000",
      mb: 1,
    },
  },
  eventImageContainer: {
    borderRadius: "4px",
    border: "1px dashed var(--light-background-color-2, #E2E3E4)",
    bgcolor: "#FBFCFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 2,
    p: 3,
    height: 200,
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
  coverImageContainer: {
    borderRadius: "4px",
    border: "1px solid #eee",
    height: 200,
    width: "100%",
    objectFit: "cover",
  },
  boxContainer: {
    borderRadius: "4px",
    border: "1px solid #eee",
    height: 220,
    width: "90%",
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
  coverHover: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    gap: "10px",
    transition: "display 0.3s ease",
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
};

const PostDrawer = ({
  anchor = "right",
  text,
  image,
  open = false,
  onCloseDrawer,
  isEditMode = false,
  setAssetId,
  setDeleteAssetModal,
  state = {},
  setState,
  stateData = {},
  handleSubmit,
  mutation = {isLoading: false},
  mutationUpdate = {isLoading: false},
  mutationAssetDelete = {isLoading: false},
  errors,
}) => {
  //USESTATE
  const [isCoverHovered, setIsCoverHovered] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [cover_image, setCoverImage] = useState(null);

  //USEREF
  const coverRef = useRef(null);

  //ANY CHANGE IN FORM HANDLED BY THIS METHOD
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };


  //ADDING IMAGE METHOD AFTER WHICH CROPIMAGE MODAL OPENS
  const handleCoverChange = (e) => {
    const file = e?.target?.files[0];
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    if (file?.size <= maxSizeInBytes) {
      setCoverImage(URL.createObjectURL(file));
      setCropModal("eventPost");
    } else {
      alert("Selected cover image exceeds the 15MB size limit.");
    }
  };

  //MOUSE HOVERING
  const handleCoverMouseEnter = () => {
    setIsCoverHovered(true);
  };

  const handleCoverMouseLeave = () => {
    setIsCoverHovered(false);
  };

  //RETURN
  return (
    <Drawer anchor={anchor} open={open} onClose={onCloseDrawer}>
      <Box sx={styles.container}>
        <Close className="closeIcon" onClick={onCloseDrawer} />
        <Typography variant="h2">
          {text}
        </Typography>
        <Grid container spacing={3} mb={2}>
          <Grid item md={12} sm={12}>
            <FormInput
              label="Description"
              placeholder="Description"
              value={state.description}
              name="description"
              onChange={handleChange}
              multiline
              rows={4}
              error={errors?.description}
              helperText={errors?.description || ""}
            />
          </Grid>
        </Grid>
        {image ? (
          <FormLabel label="Cover Image" />
        ) : null}

        {image && (!state?.image?.length && !stateData?.assets?.length ? (
          <Box sx={styles.eventImageContainer}>
            <Typography component="label">+Add Cover Image</Typography>
            <Button variant="outlined" onClick={() => coverRef.current.click()}>
              Browse Image
            </Button>
            <input
              type="file"
              hidden
              onChange={handleCoverChange}
              id={`documentUpload`}
              ref={coverRef}
              accept="image/*"
            />
          </Box>
        ) : (
          <Box position="relative">
            {Array.isArray(stateData?.assets) &&
              stateData?.assets.length > 0 &&
              stateData?.assets.map((asset, index) => (
                <Box key={index} position="relative">
                  {isEditMode && (
                    <Tooltip title="Delete" arrow placement="top">
                      <IconButton
                        onClick={() => {
                          setAssetId(asset?.id);
                          // setIndex(index);
                          setDeleteAssetModal(true);
                        }}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: "red", // Adjust the color as needed
                        }}
                      >
                        <Delete sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <img
                    src={
                      typeof asset?.asset_url === "string"
                        ? asset?.asset_url
                        : URL.createObjectURL(asset?.asset_url)
                    }
                    style={{
                      ...styles.coverImageContainer,
                    }}
                    loading="lazy"
                  />
                </Box>
              ))}
              {state.image.length > 0 ? (
                <Box position="relative">
                  {state?.image.map((image, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    sx={{
                      ...styles.coverImageContainer,
                    }}
                    loading="lazy"
                  />
                ))}
                </Box>
              ) : null}
            {/* {state.image !== "" ? (
              <Box
                component="img"
                src={
                  typeof state.image === "string"
                    ? state.image
                    : URL.createObjectURL(state.image)
                }
                sx={{
                  ...styles.coverImageContainer,
                }}
                loading="lazy"
              />
            ) : null} */}
            <Stack>
              <Typography component="label">+Add Cover Image</Typography>
              <Button
                variant="outlined"
                onClick={() => coverRef.current.click()}
              >
                Browse Image
              </Button>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Stack>
          </Box>
        ))}

        {/* Submit Button */}
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          isLoading={
            mutation.isLoading ||
            mutationUpdate.isLoading ||
            mutationAssetDelete.isLoading
          }
          sx={styles.button}
        >
          {!isEditMode ? "Submit" : "Update"}
        </LoadingButton>
      </Box>

      {/* Crop Image Component */}
      <CropImage
        modal={cropModal}
        setModal={setCropModal}
        image={cover_image}
        user={state}
        setUser={setState}
        ratio={16 / 9}
      />
    </Drawer>
  );
};

export default PostDrawer;
