import React from "react";
import PageHeader from "../components/ui/PageHeader";
import { Box } from "@mui/material";
import StudentQueryRow from "../components/StudentQueries/StudentQueryRow";

const styles = {
    wrapper: {
        bgcolor: "#fff",
        borderRadius: "12px",
    },
};

const queries = [
    {
        type: "waitList",
        message: "Campus Class List ",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "Just now",
        by: "John William"
    },
    {
        type: "info",
        message: "Campus Course Information",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "5 Min ago",
        by: "James Adams"
    },
    {
        type: "info",
        message: "Campus EAP Student Information",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "Just now",
        by: "John William"
    },
    {
        type: "waitList",
        message: "Campus Waitlist",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "5 Min ago",
        by: "James Adams"
    },
    {
        type: "info",
        message: "ECourse Drilldown",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "Just now",
        by: "John William"
    },
    {
        type: "star",
        message: "Campus EAP Student Information",
        detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        time: "Just now",
        by: "James Adams"
    },
]

function StudentQueries() {
    return <>
        <PageHeader
            title="Student Queries"
            placeholder="Search Queries"
            searchInput={true}
        />
        <Box sx={styles.wrapper}>
            {
                queries.map((query) => (
                    <StudentQueryRow {...query} />
                ))
            }
        </Box>
    </>
}

export default StudentQueries;