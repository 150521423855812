import React from "react";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Download } from "../../../assets/svg/Download.svg";
import { subscriptionColors } from "../../../data/constant";
import Listing from "../../ui/Listing";

const rows = [
  {
    id: 1,
    invoiceId: "0245468522",
    status: "inactive",
    paymentMethod: "Mastercard Card *4545",
    amount: "$67.15",
    date: "09/13/2023",
  },
  {
    id: 2,
    invoiceId: "0245468522",
    status: "active",
    paymentMethod: "Mastercard Card *4545",
    amount: "$67.15",
    date: "09/13/2023",
  },
  {
    id: 3,
    invoiceId: "12156448953",
    status: "active",
    paymentMethod: "Visa Card *4564",
    amount: "$67.15",
    date: "09/13/2023",
  },
  {
    id: 4,
    invoiceId: "3545664889",
    status: "stopped",
    paymentMethod: "Mastercard Card *4545",
    amount: "$67.15",
    date: "09/13/2023",
  },
  {
    id: 5,
    invoiceId: "12156448953",
    status: "active",
    paymentMethod: "Visa Card *4564",
    amount: "$67.15",
    date: "09/13/2023",
  },
  {
    id: 6,
    invoiceId: "0245468522",
    status: "stopped",
    paymentMethod: "Mastercard Card *4545",
    amount: "$67.15",
    date: "09/13/2023",
  },
];

const columns = [
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    sortable: false,
    flex: 1,
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    sortable: false,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            bgcolor: subscriptionColors[params.row.status].bgcolor,
            color: subscriptionColors[params.row.status].color,
            textTransform: "capitalize",
            width: 80,
            height: 30,
            lineHeight: "30px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          {params.row.status}
        </Box>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: false,
    flex: 1,
  },
  {
    field: "date",
    headerName: "Date",
    sortable: false,
    flex: 1,
  },
  {
    sortable: false,
    flex: 1,
    renderCell: () => {
      return (
        <>
          <IconButton>
            <Download />
          </IconButton>
        </>
      );
    },
  },
];

function SubscriptionTab() {
  return (
    <>
      <Listing rows={rows} columns={columns} />
    </>
  );
}

export default SubscriptionTab;
