const styles = {
  chipWrapper: {
    border: "1px solid #AEAEAE",
    p: 1,
    minHeight: 45,
    width: "100%",
  },
  chip: {
    textTransform: "capitalize",
  },
  inputWrapper: { position: "relative" },
  list: {
    position: "absolute",
    zIndex: 2,
    bgcolor: "#fff",
    // bgcolor: "#EAEEFA",
    width: "100%",
    boxShadow: " 0 2px 2px rgba(0,0,0,0.5)",
    borderRadius: 3,
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    height: "25px !important",
    width: "25px !important",
  },
};
export default styles;
