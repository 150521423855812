import React, { useRef, useState } from "react";
import PageHeader from "../../components/ui/PageHeader";
import {
	Grid,
	Stack,
	IconButton,
	Typography,
	Button,
	Tooltip,
	Box,
	Skeleton,
} from "@mui/material";
import TopSelling from "../../components/pageComponents/courses/TopSelling";
import TopBuyer from "../../components/pageComponents/courses/TopBuyer";
import Listing from "../../components/ui/Listing";

import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import EnrolledMember from "../../components/pageComponents/courses/EnrolledMember";
import { useNavigate } from "react-router-dom";
import {
	ADMIN_COURSES_DELETE,
	ADMIN_MANAGE_COURSES,
} from "../../data/constants/apiRoutes";
import { destroy, get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import BoxModal from "../../components/Modals/BoxModal";
import CourseDrawer from "../../components/Drawers/CourseDrawer";
import styles from "../../assets/styles/course/courses.styles";


function Courses() {
	const navigate = useNavigate();

	const [modal, setModal] = useState(false);
	const [courseId, setCourseId] = useState(null);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const courseRef = useRef();

	const columns = [
		{
			field: "name",
			headerName: "Course  Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (param) => {
				return (
					<Box component="div" sx={{ cursor: "pointer" }}>
						{" "}
						{param.row.name}{" "}
					</Box>
				);
			},
		},
		{
			field: "created_by",
			headerName: "Created By",
			sortable: false,
			width: 200,
		},
		{
			field: "lessons",
			headerName: "Lessons",
			sortable: false,
			width: 150,
			valueGetter: (params) => params.row.lessons.length,
		},
		{
			field: "price",
			headerName: "Price",
			sortable: false,
			width: 150,
			renderCell: (param) => {
				return <Typography component="label"> $ {param.row.price} </Typography>;
			},
		},
		{
			field: "discount",
			headerName: "Discount",
			sortable: false,
			width: 120,
			renderCell: (param) => {
				return param.row.discount ? (
					<Typography component="label"> {param.row.discount} </Typography>
				) : (
					"-"
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={2}>
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => navigate(`/courses/edit/${params.row.id}`)}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setCourseId(params.row.id);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</Stack>
				);
			},
		},
	];

	const fetchCourses = async () => {
		const { status, message, data } = await get(ADMIN_MANAGE_COURSES);
		if (status) {
			return data;
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const { isLoading, data } = useQuery([queryNames.COURSES], fetchCourses, {
		keepPreviousData: true,
	});

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.COURSES);
	};

	const handleClose = () => {
		setModal(false);
		setCourseId(null);
		handleInvalidate();
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_COURSES_DELETE}/${courseId}`
		);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleDelete, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = () => {
		mutation.mutate();
	};

	const handleCellClick = (data) => {
		if (data?.field === "name") {
			courseRef.current = data.row;
			setModal("detail");
		}
	};

	return (
		<>
			<PageHeader title="Course Statistics" />

			<Grid container spacing={3}>
				<Grid item xs={12} lg={8}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TopSelling data={data?.top_courses} />

							<Grid container spacing={3} sx={{ pt: 3 }}>
								<Grid item xs={12} sm={6} md={6}>
									<Box sx={styles.wrapper}>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Box>
												<Typography component="p">Total Revenue</Typography>
												{data?.statistics?.totalRevenue ? (
													<Typography component="h6">
														${data?.statistics?.totalRevenue}
													</Typography>
												) : (
													<Skeleton width={100} animation="wave" />
												)}
											</Box>
										</Stack>
									</Box>
								</Grid>

								<Grid item xs={12} sm={6} md={6}>
									<Box sx={styles.wrapper}>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Box>
												<Typography component="p">Total Students</Typography>
												{data?.statistics?.totalStudents !== undefined ? (
													<Typography component="h6">
														{data?.statistics?.totalStudents}
													</Typography>
												) : (
													<Skeleton width={100} animation="wave" />
												)}
											</Box>
										</Stack>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={6}>
							<TopBuyer buyers={data?.topBuyers} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={4} flex={2}>
					<EnrolledMember members={data?.members} />
				</Grid>
			</Grid>

			<Stack sx={styles.header}>
				<Typography component="h2">Courses</Typography>
				<Button variant="outlined" onClick={() => navigate("/allCourses")}>
					View All
				</Button>
			</Stack>

			<Listing
				rows={data?.courses}
				columns={columns}
				className="courses"
				loading={isLoading}
				mode="client"
				handleCellClick={handleCellClick}
			/>

			<BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>

			<CourseDrawer
				modal={modal === "detail"}
				courseId={courseRef.current?.id}
				handleClose={handleClose}
			/>
		</>
	);
}

export default Courses;
