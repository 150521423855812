import React from "react";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import GoBackButton from "../Buttons/GoBackButton";
import PageHeader from "./PageHeader";

//SVG
import { ReactComponent as Email } from "../../assets/svg/Email.svg";
import { ReactComponent as Union } from "../../assets/svg/Union.svg";
import { ReactComponent as Location } from "../../assets/svg/Location.svg";

//Styles
const styles = {
  container: {
    bgcolor: "#FFF",
    py: 2,
    px: "28px",
    borderRadius: "6px",
  },
  interestContainer: {
    cursor: "pointer",
    position: "relative",
    bgcolor: "transparent",
    border: "1px solid var(--light-background-color-2, #E2E3E4)",
    borderRadius: "6px",
    py: "10px",
    px: "28px",
    "& label": {
      color: "#1E1E1F",
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  interests: {
    mt: 1,
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
  },
};

//UI
const ViewProfile = ({ receivedData }) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={`Profile Detail`} />
        <GoBackButton url={-1} />
      </Stack>

      <Stack sx={styles.container}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <Avatar
              src={receivedData?.data?.profile_image}
              sx={{
                bgcolor: "gray",
                mr: 3,
                width: 100,
                height: 100,
              }}
            >
              {receivedData?.data?.full_name.charAt(0).toUpperCase()}
            </Avatar>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Union />
              <Typography component="h2" sx={{ ml: 2 }}>
                {receivedData?.data?.full_name
                  ? receivedData?.data?.full_name
                  : "Name not added yet"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Email />
              <Typography component="h2" sx={{ ml: 2 }}>
                {receivedData?.data?.email
                  ? receivedData?.data?.email
                  : "Email not added yet"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Location />
              <Typography component="h2" sx={{ ml: 2 }}>
                {receivedData?.data?.address
                  ? receivedData?.data?.address
                  : "Address Not added yet"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 5, mb: 2 }}>
          <Typography variant="h6">BIO</Typography>
          <Typography variant="body1">
            {receivedData?.data?.description
              ? receivedData?.data?.description
              : "User has no Bio"}
          </Typography>
        </Box>

        <Box>
          <Box>
            <Typography variant="h6">INTEREST</Typography>
            <Box sx={{ ...styles.interests, backgroundColor: "white" }}>
              {receivedData?.data?.interests?.length > 0 ? (
                receivedData?.data?.interests?.map((interest, index) => (
                  <Box>
                    <Box sx={styles.interestContainer} key={index}>
                      <Typography component="label">{interest.name}</Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1">User has no interest</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Stack>

      <Divider sx={{ borderColor: "#e2e3e4", mt: 5, mb: 3 }} />
    </>
  );
};

export default ViewProfile;
