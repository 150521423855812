
const styles = {
	email: {
		display: "flex",
		justifyContent: "flex-end",
		"& label": {
			color: "#0F4590",
			cursor: "pointer",
			fontWeight: 500,
		},
	},
};

export default styles;