import React, { useRef } from "react";
import PageHeader from "../../../components/ui/PageHeader";
import EventDetailListings from "../../../components/ui/EventDetailListings";
import {
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import FormInput from "../../../components/Forms/FormInput";
import {
  ADMIN_ADD_EVENT_GUEST_SPEAKER,
  ADMIN_EVENT_GUEST_SPEAKER_DELETE,
} from "../../../data/constants/apiRoutes";
import { destroy, post } from "../../../server";
import { useMutation } from "react-query";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { enqueueSnackbar } from "notistack";
import BoxModal from "../../../components/Modals/BoxModal";
import { validator } from "../../../utils/helpers/validator";
import { GuestSpeakerSchema } from "../../../utils/schemas/EventSchema";
import FormLabel from "../../../components/Forms/FormLabel";
import PhoneNumberInput from "../../../components/Forms/PhoneNumberInput";
import LocationAutoComplete from "../../../components/Forms/LocationAutoComplete";
import _ from "lodash";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import imageStyles from "../../../assets/styles/events/createEvent.styles";

const styles = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
  memberFullStack: {
    borderBottom: "1px solid #E2E3E4",
    "&:last-child": {
      borderBottom: "none", // Remove border for the last item
    },
    px: 1.5,
  },
  memberStack: {
    alignItems: "center",
    gap: 1.5,
    py: "12px",
    "& h4": {
      fontSize: 13,
      wordBreak: "break-all",
      maxWidth: 200,
    },
  },
  memberAvatar: {
    width: 40,
    height: 40,
    bgcolor: "gray",
  },
  addEmail: {
    bgcolor: "#2B2A69",
    color: "#FFF",
    fontWeight: 600,
    textTransform: "capitalize",
    px: 3,
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "#FFF",
    },
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
};

const initialState = {
  name: "",
  email: "",
  bio: "",
  image: "",
  address: "",
  phone: null,
};

const GuestSpeakers = ({ modal, setModal, handleInvalidate, event }) => {
  const [guestSpeaker, setGuestSpeaker] = useState(initialState);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [errors, setErrors] = useState({});

  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fileInputRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuestSpeaker({ ...guestSpeaker, [name]: value });
  };

  const handleAddressChange = ({ address }) => {
    setGuestSpeaker({
      ...guestSpeaker,
      address: address,
    });
  };

  const validate = () => {
    const newErrors = validator(guestSpeaker, GuestSpeakerSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleClose = () => {
    setModal(false);
  };

  const saveSpeaker = async () => {
    const payload = _.cloneDeep(guestSpeaker);

    if (payload.image && typeof payload.image !== "string") {
      const data = await awsFileUpload(
        payload.image,
        uploadImageType.createSpeaker
      );

      if (data.status === true) {
        payload.image = data.location;
      }
    }

    const { status, message } = await post(ADMIN_ADD_EVENT_GUEST_SPEAKER, {
      event_id: event.id,
      guest_speakers: [payload],
    });

    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      handleClose();
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const mutation = useMutation(saveSpeaker);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    mutation.mutate();
  };

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${ADMIN_EVENT_GUEST_SPEAKER_DELETE}/${id}`
    );
    if (status === true) {
      enqueueSnackbar("Speaker Removed Successfully", { variant: "success" });
      handleInvalidate();
      setDeleteModal();
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const deleteMutation = useMutation(handleDelete);

  const handleConfirm = () => {
    deleteMutation.mutate();
  };

  const handleImageAdd = (e) => {
    setGuestSpeaker({
      ...guestSpeaker,
      image: e.target.files[0],
    });
  };

  const handleRemoveImage = () => {
    setGuestSpeaker({
      ...guestSpeaker,
      image: "",
    });
  };

  return (
    <>
      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2">Add new Guest Speaker</Typography>

          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              <Stack
                direction="row"
                sx={imageStyles.avatarWrapper}
                alignItems="center"
              >
                <Avatar
                  src={
                    typeof guestSpeaker?.image === "string"
                      ? guestSpeaker?.image
                      : URL.createObjectURL(guestSpeaker?.image)
                  }
                  sx={imageStyles.avatar}
                />
                <Stack sx={imageStyles.btnWrapper}>
                  <Button
                    sx={imageStyles.editBtn}
                    disableElevation
                    onClick={() => fileInputRef.current.click()}
                  >
                    Change Photo
                  </Button>
                  <Button
                    sx={imageStyles.deleteBtn}
                    disableElevation
                    onClick={handleRemoveImage}
                  >
                    Delete Photo
                  </Button>
                </Stack>
              </Stack>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageAdd(e)}
                ref={fileInputRef}
                hidden
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormInput
                label="Name"
                placeholder="Name"
                value={guestSpeaker.name}
                name="name"
                onChange={handleChange}
                error={errors?.name}
                helperText={errors?.name || ""}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormInput
                label="Email"
                placeholder="Email"
                value={guestSpeaker.email}
                name="email"
                onChange={handleChange}
                error={errors?.email}
                helperText={errors?.email || ""}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormLabel label="Mobile Number" />
              <PhoneNumberInput
                value={guestSpeaker.phone}
                error={errors?.phone ? true : false}
                helperText={errors?.phone || ""}
                onChange={(value) =>
                  setGuestSpeaker({ ...guestSpeaker, phone: value })
                }
                inputClassName="bigForm"
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <LocationAutoComplete
                label="Address"
                locationType="address"
                placeholder="Address"
                value={guestSpeaker.address}
                handleAddressChange={handleAddressChange}
                types={["establishment"]}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                address={address}
                setAddress={setAddress}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormInput
                label="Bio"
                placeholder="Bio"
                value={guestSpeaker.bio}
                name="bio"
                onChange={handleChange}
                error={errors?.bio}
                helperText={errors?.bio || ""}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
            sx={styles.button}
          >
            Submit
          </LoadingButton>
        </Box>
      </Drawer>
    </>
  );
};

export default GuestSpeakers;
