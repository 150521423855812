import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const drawerWidth = 240;
const styles = {
	main: {
		backgroundColor: "#F5F5F5",
		minHeight: "calc(100vh - 64px)",
		p: { md: 3, xs: "25px 16px" },
		mt: { md: 8, xs: 7 },
	},
};

function DashboardLayout() {
	const [mobileOpen, setMobileOpen] = useState(false);
	return (
		<>
			<Sidebar
				drawerWidth={drawerWidth}
				mobileOpen={mobileOpen}
				setMobileOpen={setMobileOpen}
			/>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: "auto",
				}}
			>
				<Header
					drawerWidth={drawerWidth}
					mobileOpen={mobileOpen}
					setMobileOpen={setMobileOpen}
				/>
				<Box sx={styles.main}>
					<Outlet />
					<Footer drawerWidth={drawerWidth} />
				</Box>
			</Box>
		</>
	);
}

export default DashboardLayout;
