import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Marker } from "../../../assets/svg/eventIcons/Marker.svg";
import { GoogleMap, OverlayViewF, OverlayView } from "@react-google-maps/api";
import useGoogleMapsApi from "../../../utils/helpers/useGoogleMapsApi";
import { ReactComponent as Location } from "../../../assets/svg/eventIcons/Location.svg";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		px: 3,
		py: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 600,
			mb: 2,
		},
		"& h3": {
			color: "#333333",
			fontSize: 16,
			lineHeight: "24px",
		},
	},
	direction: {
		height: 150,
		width: "100%",
		mb: 1.5,
	},
	starter: {
		color: "#767D90 !important",
	},
	iconContainer: {
		minWidth: 50,
		height: 50,
		bgcolor: "#eee",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #F1F1F1",
	},
	details: {
		"& h4": {
			fontSize: 15,
			fontWeight: 500,
			color: "#000",
		},
		"& p": {
			fontSize: 14,
			fontWeight: 400,
			color: "gray",
			wordBreak: "break-all",
		},
	},
};

function Directions({ event = {} }) {
	const center = {
		lat: 31.481033187357202,
		lng: 74.30333024485032,
	};

	const isLoaded = useGoogleMapsApi();

	const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`;

	return (
		<>
			<Box sx={styles.wrapper}>
				<Typography component="h2">Get Direction</Typography>
				<Box sx={styles.direction}>
					{/* <Direction width={"298px"} /> */}
					{isLoaded && Object.keys(event).length > 0 ? (
						<GoogleMap
							center={center}
							zoom={12}
							mapContainerStyle={styles.direction}
							options={{
								disableDefaultUI: true, // Hide all default controls
								draggable: false, // Disable map dragging
								zoomControl: false, // Hide zoom control
								clickableIcons: false,
							}}
						>
							<OverlayViewF
								position={{
									lat: event?.latitude,
									lng: event?.longitude,
								}}
								// position={{
								// 	lat: center?.lat,
								// 	lng: center?.lng,
								// }}
								getPixelPositionOffset={(width, height) => ({
									x: -(width / 2),
									y: -height,
								})}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
								zIndex={1}
							>
								<a
									href={googleMapsUrl}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Marker style={{ cursor: "pointer" }} />
								</a>
							</OverlayViewF>
						</GoogleMap>
					) : (
						// <Loader />
						<></>
					)}
				</Box>
				<Stack direction="row" spacing={1}>
					<Typography component="h3" sx={styles.starter}>
						Venue:{" "}
					</Typography>
					<Typography component="h3">{event?.venue_name}</Typography>
				</Stack>
				{/* <Stack direction="row" spacing={1}>
					<Typography component="h3" sx={styles.starter}>
						Price:{" "}
					</Typography>
					<Typography component="h3">$200.00</Typography>
				</Stack> */}
				{/* <Stack direction="row" spacing={1}>
					<Typography component="h3" sx={styles.starter}>
						Address:{" "}
					</Typography>
					<Typography component="h3">{event?.address}</Typography>
				</Stack> */}

				<Stack direction="row" gap={1} sx={styles.details} mt={1}>
					<Box sx={styles.iconContainer}>
						<Location />
					</Box>
					<Stack>
						<Typography component="h4">Location</Typography>
						<Typography component="p">{event?.address}</Typography>
					</Stack>
				</Stack>
			</Box>
		</>
	);
}

export default Directions;
