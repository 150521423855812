import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import styles from "../../../assets/styles/events/createEvent.styles";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import FormInput from "../../../components/Forms/FormInput";
import { Add, Remove } from "@mui/icons-material";
import { validator } from "../../../utils/helpers/validator";
import { FAQSchema } from "../../../utils/schemas/EventSchema";
import { useMutation } from "react-query";
import { post } from "../../../server";
import { ADMIN_EVENT_CREATE } from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";

const initialState = {
	question: "",
	answer: "",
};

const EventFAQs = ({
	currentStep,
	setCurrentStep,
	eventForm,
	setEventForm,
	handleGoBack,
	setFinalEvent,
}) => {
	const [faq, setFaq] = useState(initialState);
	const [errors, setErrors] = useState({});

	const [expanded, setExpanded] = useState(false);

	const [newFaq, setNewFaq] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (e) => {
		const { name, value } = e?.target;

		setFaq({ ...faq, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(faq, FAQSchema);

		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleSave = () => {
		if (!validateForm()) return;
		setNewFaq(false);
		const newFaq = { ...eventForm };
		newFaq?.faq?.event_FAQs?.push(faq);
		setEventForm(newFaq);
		setFaq(initialState);
	};

	const handleExpand = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleNewFaq = () => {
		setNewFaq(true);
	};

	const handleCancel = () => {
		setNewFaq(false);
	};

	const handleSaveFAQs = async () => {
		const { status, message, data } = await post(
			ADMIN_EVENT_CREATE,
			eventForm.faq
		);
		if (status === true) {
			setEventForm({
				...eventForm,
				faq: {
					...eventForm.faq,
					event_FAQs: data?.faqs,
				},
			});
			// enqueueSnackbar(message, { variant: "success" });
			setFinalEvent(data);
			setFaq(initialState);
			setCurrentStep(currentStep + 1);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const mutation = useMutation(handleSaveFAQs);

	const handleSubmit = (e) => {
		e.preventDefault();
		mutation.mutate();
	};

	return (
		<Box sx={styles.container}>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1">
					{!newFaq ? "Frequently Asked Questions" : "Add FAQ"}
				</Typography>

				<Button
					variant="contained"
					sx={{ ...styles.goBackBtn, mr: 2 }}
					onClick={handleGoBack}
				>
					Go Back
				</Button>

				{newFaq && eventForm?.faq?.length > 0 && (
					<Button variant="contained" onClick={handleCancel} sx={{ mr: 2 }}>
						Cancel
					</Button>
				)}

				<LoadingButton
					variant="contained"
					onClick={(e) =>
						newFaq && JSON.stringify(initialState) !== JSON.stringify(faq)
							? handleSave()
							: handleSubmit(e)
					}
					isLoading={mutation.isLoading}
				>
					{!newFaq
						? "Preview Event"
						: JSON.stringify(initialState) === JSON.stringify(faq)
						? "Skip"
						: "Save"}
				</LoadingButton>
			</Stack>

			{newFaq ? (
				<Grid container spacing={3}>
					<Grid item md={6} sm={12}>
						<FormInput
							label="Question"
							placeholder="Type here"
							value={faq?.question}
							name="question"
							onChange={handleChange}
							error={errors?.question}
							helperText={errors?.question || ""}
						/>
					</Grid>

					<Grid item md={12} sm={12}>
						<FormInput
							label="Answer"
							placeholder="Type answer"
							value={faq?.answer}
							name="answer"
							onChange={handleChange}
							multiline
							rows={4}
							error={errors?.answer}
							helperText={errors?.answer || ""}
						/>
					</Grid>
				</Grid>
			) : (
				<Box>
					{eventForm?.faq?.event_FAQs?.map((faq, index) => (
						<Accordion
							expanded={expanded === index}
							onChange={handleExpand(index)}
							sx={styles.accordion}
							key={index}
						>
							<AccordionSummary
								expandIcon={expanded === index ? <Remove /> : <Add />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography sx={styles.accordianSummary}>
									{faq.question}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography sx={styles.accordianDetail}>
									{faq.answer}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
					<Typography
						component="label"
						sx={styles.added}
						onClick={handleNewFaq}
					>
						+Add New FAQ
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default EventFAQs;
