// const BASE_URL = import.meta.env.API_BASE_URL
// const BASE_URL = "https://nareblive.iambrands.com/api"
const BASE_URL = (window.location.origin.indexOf("localhost") > -1) ? "http://nareb.local/api" : ( window.location.origin + "/api");
console.log("BASE_URL", BASE_URL);
const PUBLIC_URL = "https://nareeb.iambrands.com";

const ADMIN = "admin";
const USER = "user";
const LOGIN = "login";
const FORGET = "forget";
const PASSWORD = "password";
const RESET = "reset";
const VERIFY = "verify";
const OTP = "otp";
const DETAIL = "detail";
const UPDATE = "update";
const PROFILE = "profile";
const UPLOAD_FILE = "upload-file";
const PROMO_CODE = "promocode";
const ADD_UPDATE = "addOrUpdate";
const LISTING = "listing";
const DELETE = "delete";
const COMPANY = "company";
const COURSES = "courses";
const MANAGE_COURSES = "manageCourses";
const INTEREST = "interest";
const EVENT = "event";
const FEATURED = "featured";
const SCHEDULE = "schedule";
const MEMBER = "member";
const ATTENDEE = "attendee";
const SUB_ADMIN = "sub-admin";
const INVITE = "invite";
const EDIT = "edit";
const SIGNUP = "signup";
const EMAIL = "email";
const SEND = "send";
const V2 = "v2";
const DASHBOARD = "dashboard";
const PAST = "past";
const REQUESTS = "requests";
const REQUEST = "request";
const ACTION = "action";
const CHANGE = "change";
const TYPE = "type";
const CREATE = "create";
const MEMBERS = "members";
const SPONSORS = "sponsors";
const SPEAKERS = "speakers";
const SPEAKER = "speaker";
const ALL = "all";
const SALES = "sales";
const SEARCH = "search";
const POPULAR = "popular";
const STATUS = "status";
const SPONSERS = "sponsers";
const SPONSOR = "sponsor";
const ACTIVE = "active";
const NOTIFICATIONS = "notifications";
const GUEST = "guest";
const TICKET = "ticket";
const SESSION = "session";
const FORUM = "forum";
const FORUMS = "forums";
const FORUMCOMMENTS = "forumComments";
const FORUMCOMMENTSDELETE = "deleteForumComment";
const GETALLFORUMS = "allforumns";
const POSTS = "posts";
const POST = "post";
const POSTCOMMENTS = "postComments";
const POSTCOMMENT = "postComment";
const DELETEPOSTCOMMENT = "deletePostComment";
const REPORTEDPOST = "reportedPosts";
const MEETUPS = "meetups";
const MEETUP = "meetup";
const MEETUPCOMMENTS = "meetupComments";
const MEETUPCOMMENT = "meetupcomment";
const FLIPBOOKS = "flipbooks";
const FLIPBOOK = "flipbook";

// UPLOAD AND GET IMAGE
export const UPLOAD_IMAGE = `${BASE_URL}/${UPLOAD_FILE}`;

// ADMIN AUTH

export const ADMIN_LOGIN = `${BASE_URL}/${ADMIN}/${LOGIN}`;

export const ADMIN_FORGET_PASSWORD = `${BASE_URL}/${ADMIN}/${FORGET}/${PASSWORD}`;

export const ADMIN_VERIFY_OTP = `${BASE_URL}/${ADMIN}/${VERIFY}/${OTP}`;

export const ADMIN_RESET_PASSWORD = `${BASE_URL}/${ADMIN}/${RESET}/${PASSWORD}`;

export const ADMIN_PROFILE_DETAIL = `${BASE_URL}/${ADMIN}/${PROFILE}`;

export const ADMIN_PROFILE_UPDATE = `${BASE_URL}/${ADMIN}/${UPDATE}/${PROFILE}`;

export const ADMIN_TOKEN_REFRESH = `${BASE_URL}/${ADMIN}/refreshToken`;

// PROMO CODES

export const ADMIN_PROMO_CODE_LISTING = `${BASE_URL}/${ADMIN}/${PROMO_CODE}/${LISTING}`;

export const ADMIN_PROMO_CODE_ADD = `${BASE_URL}/${ADMIN}/${PROMO_CODE}/${ADD_UPDATE}`;

export const ADMIN_PROMO_CODE_DETAIL = `${BASE_URL}/${ADMIN}/${PROMO_CODE}/${DETAIL}`;

export const ADMIN_PROMO_CODE_DELETE = `${BASE_URL}/${ADMIN}/${PROMO_CODE}/${DELETE}`;

// COMPANIES

export const ADMIN_COMPANIES_LISTING = `${BASE_URL}/${ADMIN}/${COMPANY}/${LISTING}`;

export const ADMIN_COMPANIES_LISTING_SEARCH = `${BASE_URL}/${ADMIN}/${COMPANY}/${SEARCH}`;

export const ADMIN_COMPANIES_DETAIL = `${BASE_URL}/${ADMIN}/${COMPANY}/${DETAIL}`;

export const ADMIN_COMPANY_MEMBERS_LISTING = `${BASE_URL}/${ADMIN}/${COMPANY}/${MEMBERS}`;
//
export const ADMIN_COMPANY_SPONSORS_LISTING = `${BASE_URL}/${ADMIN}/${COMPANY}/${SPONSORS}`;
//
export const ADMIN_COMPANY_SPEAKERS_LISTING = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/${SPEAKERS}`;
//
export const ADMIN_COMPANY_ADD_OR_UPDATE = `${BASE_URL}/${ADMIN}/${COMPANY}/${ADD_UPDATE}`;

export const ADMIN_COMPANY_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${DELETE}`;

export const ADMIN_COMPANY_STATUS = `${BASE_URL}/${ADMIN}/${COMPANY}/${STATUS}`;

export const ADMIN_COMPANY_INVITE_MEMBERS = `${BASE_URL}/${ADMIN}/${COMPANY}/${INVITE}/${MEMBERS}`;

// COURSES

export const ADMIN_MANAGE_COURSES = `${BASE_URL}/${ADMIN}/${COURSES}/${MANAGE_COURSES}`;

export const ADMIN_COURSES_LISTING = `${BASE_URL}/${ADMIN}/${COURSES}/${LISTING}`;

export const ADMIN_COURSES_LISTING_SEARCH = `${BASE_URL}/${ADMIN}/${COURSES}/${SEARCH}`;

export const ADMIN_COURSES_POPULAR_LISTING = `${BASE_URL}/${ADMIN}/${COURSES}/${POPULAR}`;

export const ADMIN_COURSES_DETAIL = `${BASE_URL}/${ADMIN}/${COURSES}/${DETAIL}`;

export const ADMIN_COURSES_ADD_UPDATE = `${BASE_URL}/${ADMIN}/${COURSES}/${ADD_UPDATE}`;

export const ADMIN_COURSES_DELETE = `${BASE_URL}/${ADMIN}/${COURSES}/${DELETE}`;

export const ADMIN_COURSES_ENROLLED_MEMBERS = `${BASE_URL}/${ADMIN}/${COURSES}/${MEMBERS}`;

// INTERESTS

export const ADMIN_INTEREST_ADD_UPDATE = `${BASE_URL}/${ADMIN}/${INTEREST}/${ADD_UPDATE}`;

export const ADMIN_INTEREST_LISTING = `${BASE_URL}/${ADMIN}/${INTEREST}/${LISTING}`;

export const ADMIN_INTEREST_DELETE = `${BASE_URL}/${ADMIN}/${INTEREST}`;

// EVENTS

export const ADMIN_ALL_EVENTS_LISTING = `${BASE_URL}/${ADMIN}/${EVENT}/${ALL}`;

export const ADMIN_EVENTS_LISTING_SEARCH = `${BASE_URL}/${ADMIN}/${EVENT}/${SEARCH}`;

export const ADMIN_FEATURED_EVENTS_LISTING = `${BASE_URL}/${ADMIN}/${EVENT}/${FEATURED}`;

export const ADMIN_SCHEDULE_EVENTS_LISTING = `${BASE_URL}/${ADMIN}/${EVENT}/${SCHEDULE}`;

export const ADMIN_PAST_EVENTS_LISTING = `${BASE_URL}/${ADMIN}/${EVENT}/${PAST}`;

export const ADMIN_EVENT_DETAIL = `${BASE_URL}/${ADMIN}/${EVENT}/${DETAIL}`;

export const ADMIN_EVENT_CREATE = `${BASE_URL}/${V2}/${ADMIN}/${EVENT}/${CREATE}`;

export const ADMIN_EVENT_MEMBER_USER_EXISTS = `${BASE_URL}/${USER}/exists`;

export const ADMIN_EVENT_DRAFT_CHECK = `${BASE_URL}/${ADMIN}/${EVENT}/draftEvent`;

export const ADMIN_EVENT_DELETE = `${BASE_URL}/${ADMIN}/${EVENT}/${DELETE}`;

export const ADMIN_EVENT_PUBLISH = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/publish`;

export const ADMIN_EVENT_FEATURE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/featured`;

export const ADMIN_EVENT_FILE_UPLOAD = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/upload-file`;

export const ADMIN_EVENT_DOCUMENT_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/document`;

export const ADMIN_EVENT_IMAGE_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/image`;

export const ADMIN_EVENT_AGENDA_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/agenda`;

export const ADMIN_EVENT_TICKET_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/ticket`;

export const ADMIN_EVENT_GUEST_SPEAKER_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/guest/${SPEAKERS}`;

// MEMBERS

export const ADMIN_MEMBERS_REQUEST_LISTING = `${BASE_URL}/${ADMIN}/${MEMBER}/${REQUESTS}`;

export const ADMIN_MEMBERS_LISTING = `${BASE_URL}/${ADMIN}/${MEMBER}/${LISTING}`;

export const ADMIN_MEMBERS_LISTING_SEARCH = `${BASE_URL}/${ADMIN}/${MEMBER}/${SEARCH}`;

export const ADMIN_MEMBERS_ATTENDEE_LISTING = `${BASE_URL}/${ADMIN}/${MEMBER}/${ATTENDEE}`;

export const ADMIN_MEMBERS_REQUEST_ACTION = `${BASE_URL}/${ADMIN}/${MEMBER}/${REQUEST}/${ACTION}`;

export const ADMIN_MEMBER_TYPE_CHANGE = `${BASE_URL}/${ADMIN}/${MEMBER}/${CHANGE}/${TYPE}`;

// SUB ADMINS

export const ADMIN_SUB_ADMIN_LISTING = `${BASE_URL}/${ADMIN}/${SUB_ADMIN}/${LISTING}`;

export const ADMIN_SUB_ADMIN_INVITE = `${BASE_URL}/${ADMIN}/${SUB_ADMIN}/${INVITE}`;

export const ADMIN_SUB_ADMIN_EDIT = `${BASE_URL}/${ADMIN}/${SUB_ADMIN}/${EDIT}`;

export const ADMIN_SUB_ADMIN_SIGN_UP = `${BASE_URL}/${ADMIN}/${SUB_ADMIN}/${SIGNUP}`;

export const ADMIN_SUB_ADMIN_DELETE = `${BASE_URL}/${ADMIN}/${SUB_ADMIN}/${DELETE}`;

export const ADMIN_SUB_ADMIN_STATUS = `${BASE_URL}/${ADMIN}/${USER}/${ACTIVE}`;

//BULK EMAILS

export const ADMIN_EMAIL_LISTING = `${BASE_URL}/${ADMIN}/${EMAIL}/${LISTING}`;

export const ADMIN_EMAIL_SEND = `${BASE_URL}/${ADMIN}/${EMAIL}/${SEND}`;

// DASHBOARD

export const ADMIN_DASHBOARD = `${BASE_URL}/${ADMIN}/${DASHBOARD}`;

export const ADMIN_DASHBOARD_EVENT_STATS = `${BASE_URL}/${ADMIN}/${EVENT}`;

// SALES

export const ADMIN_SALES_COURSES = `${BASE_URL}/${V2}/${ADMIN}/${COURSES}/${SALES}`;

export const ADMIN_SALES_TICKETS = `${BASE_URL}/${ADMIN}/${EVENT}/sale`;

export const ADMIN_EVENT_SALES_DETAIL = `${BASE_URL}/${ADMIN}/${EVENT}/${TICKET}/${DETAIL}`;

export const ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL = `${BASE_URL}/${ADMIN}/${EVENT}/${TICKET}/${SESSION}/${DETAIL}`;

export const ADMIN_COURSE_SALES_DETAIL = `${BASE_URL}/${ADMIN}/${COURSES}/${TICKET}/${DETAIL}`;

// SPONSOR

export const ADMIN_SPONSOR_LISTING = `${BASE_URL}/${ADMIN}/${COMPANY}/${SPONSERS}/${LISTING}`;

export const ADMIN_SPONSOR_LISTING_SEARCH = `${BASE_URL}/${ADMIN}/${COMPANY}/${SEARCH}/${SPONSORS}`;

export const ADMIN_SPONSOR_ADD_OR_UPDATE = `${BASE_URL}/${ADMIN}/${COMPANY}/add/${SPONSORS}`;

export const ADMIN_SPONSOR_DETAIL = `${BASE_URL}/${ADMIN}/${COMPANY}/${SPONSORS}/${DETAIL}`;

export const ADMIN_SPONSOR_DELETE = `${BASE_URL}/${ADMIN}/${COMPANY}/${SPONSOR}`;

// NOTIFICATIONS

export const ADMIN_NOTIFICATIONS_LISTING = `${BASE_URL}/${ADMIN}/${NOTIFICATIONS}/${LISTING}`;

// EVENT IMAGES

export const ADMIN_EVENT_IMAGES = `${BASE_URL}/${ADMIN}/${EVENT}/images`;

export const ADMIN_EVENT_IMAGES_STATUS = `${BASE_URL}/${ADMIN}/${EVENT}/image/${STATUS}`;

// GUEST SPEAKERS

export const ADMIN_ADD_EVENT_GUEST_SPEAKER = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/${GUEST}/${SPEAKERS}`;

export const ADMIN_DELETE_EVENT_SPEAKER = `${BASE_URL}/${ADMIN}/${COMPANY}/${EVENT}/${SPEAKER}`;


// forums
export const ADMIN_FORUM_ADD = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUM}`;
export const ADMIN_FORUM_UPDATE = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUM}`;
export const ADMIN_FORUM_BY_ID = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUM}`;
export const ADMIN_FORUM_DELETE = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUM}`;
export const ADMIN_FORUM_DETAIL = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUMS}`;
// forum comments
export const ADMIN_FORUM_COMMENTS = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUMCOMMENTS}`;
export const ADMIN_FORUM_COMMENTS_DELETE = `${BASE_URL}/${ADMIN}/${EVENT}/${FORUMCOMMENTSDELETE}`;
// get All forums
export const ADMIN_ALLFORUM_GET = `${BASE_URL}/${ADMIN}/${EVENT}/${GETALLFORUMS}`;

//POSTS
export const ADMIN_GET_ALL_POSTS = `${BASE_URL}/${ADMIN}/${EVENT}/${POSTS}`;

export const ADMIN_POST_CREATE = `${BASE_URL}/${ADMIN}/${EVENT}/${POST}`;

export const ADMIN_POST_DELETE = `${BASE_URL}/${ADMIN}/${EVENT}/${POST}`;

export const ADMIN_POST_UPDATE = `${BASE_URL}/${ADMIN}/${EVENT}/${POST}`;

export const ADMIN_GET_POST_BY_ID = `${BASE_URL}/${ADMIN}/${EVENT}/${POST}`;

//POST COMMENTS
export const ADMIN_GET_POST_COMMENTS = `${BASE_URL}/${ADMIN}/${EVENT}/${POSTCOMMENTS}`;

export const ADMIN_ADD_POST_COMMENT = `${BASE_URL}/${ADMIN}/${EVENT}/${POSTCOMMENT}`;

export const ADMIN_DELETE_POST_COMMENT = `${BASE_URL}/${ADMIN}/${EVENT}/${DELETEPOSTCOMMENT}`;

//DELETE POST ASSERT
export const ADMIN_DELETE_POST_ASSET = `${BASE_URL}/${ADMIN}/${EVENT}/${POST}`;

//GET REPORTED POST
export const ADMIN_REPORTEDPOST = `${BASE_URL}/${ADMIN}/${EVENT}/${REPORTEDPOST}`;

//MEETUPS
export const ADMIN_GET_ALL_MEETUPS = `${BASE_URL}/${ADMIN}/${EVENT}/${MEETUPS}`;

export  const ADMIN_GET_MEETUP_BY_ID = `${BASE_URL}/${ADMIN}/${EVENT}/${MEETUP}`;

export const ADMIN_DELETE_MEETUP_BY_ID = `${BASE_URL}/${ADMIN}/${EVENT}/${MEETUP}`;

//MEETUP COMMENTS
export const ADMIN_GET_MEETUP_COMMENT = `${BASE_URL}/${ADMIN}/${EVENT}/${MEETUPCOMMENTS}`;

//DELETE MEETUP COMMENT
export const ADMIN_DELETE_MEETUP_COMMENT = `${BASE_URL}/${ADMIN}/${EVENT}/${MEETUPCOMMENT}`;

//EXTERNAL RESOURCES
export const ADMIN_GET_ALL_RESOURCES = `${BASE_URL}/${ADMIN}/${FLIPBOOKS}`;

export const ADMIN_ADD_EDIT_RESOURCE = `${BASE_URL}/${ADMIN}/${FLIPBOOK}`;

//DELETE RESOURCES
export const ADMIN_DELETE_RESOURCE_BY_ID = `${BASE_URL}/${ADMIN}/${FLIPBOOK}`;

//GET USER PROFILE
export const ADMIN_GET_USER_PROFILE = `${BASE_URL}/${ADMIN}/${MEMBER}/${PROFILE}`;
