const styles = {
	wrapper: {
		pb: 2.5,
		flexWrap: "wrap",
		"& h1": {
			fontSize: 22,
			fontWeight: 600,
			color: "#1E1E1F",
			flex: 1,
		},
	},
	searchInput: {
		maxWidth: 350,
		width: "auto",
		boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
		backgroundColor: "#fff",
		borderRadius: "6px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},
		"& input": {
			py: "9px",
			px: "16px",
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	button: {
		width: 200,
		bgcolor: "#2B2A69",
		color: "#FFF",
		fontWeight: 600,
		textTransform: "capitalize",
		px: 3,
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "#FFF",
		},
	},
};

export default styles;