import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/events/createEvent.styles";
import {
	Box,
	Button,
	Divider,
	Fab,
	Grid,
	IconButton,
	Stack,
	Switch,
	Tooltip,
	Typography,
} from "@mui/material";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import FormInput from "../../../components/Forms/FormInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import FormLabel from "../../../components/Forms/FormLabel";
import moment from "moment";
import { validator } from "../../../utils/helpers/validator";
import { TicketSchema } from "../../../utils/schemas/EventSchema";
import { useMutation } from "react-query";
import _ from "lodash";
import { destroy, post } from "../../../server";
import {
	ADMIN_EVENT_CREATE,
	ADMIN_EVENT_TICKET_DELETE,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import FormAutoComplete from "../../../components/Forms/FormAutoComplete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Delete } from "@mui/icons-material";

const initialState = {
	ticket_type: "whole_event",
	title: "",
	quantity_available: "",
	type: "",
	price: "",
	generate_qr_code: false,
	ticket_sale_start: null,
	ticket_sale_end: null,
	ticket_description: "",
	agenda_id: "",
};

const EventTickets = ({
	currentStep,
	setCurrentStep,
	eventForm,
	setEventForm,
	handleGoBack,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();

	const ticketTypes = [
		{ id: "whole_event", label: "Whole Event" },
		{ id: "session_based", label: "Session Based" },
	];

	const memberTypes = [
		{ id: "member", label: "Member" },
		{ id: "non_member", label: "Non Member" },
	];

	const handleTicketChange = (e, index) => {
		const {
			target: { name, value, checked },
		} = e;

		const updatedTickets = [...eventForm.tickets.ticket_sessions];
		const updatedTicket = { ...updatedTickets[index] };

		if (name === "generate_qr_code") {
			updatedTicket[name] = checked;
		} else if (name === "price") {
			// Ensure the "price" is limited to 6 digits
			const newValue = value.substring(0, 6); // Limit to 6 characters
			updatedTicket[name] = newValue;
		} else {
			updatedTicket[name] = value;
		}

		updatedTickets[index] = updatedTicket;

		setEventForm({
			...eventForm,
			tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
		});
	};

	const handleStartTimeChange = (value, index) => {
		const updatedTickets = [...eventForm.tickets.ticket_sessions];
		const updatedTicket = { ...updatedTickets[index] };
		updatedTicket.ticket_sale_start = value;
		updatedTickets[index] = updatedTicket;

		setEventForm({
			...eventForm,
			tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
		});
	};

	const handleEndTimeChange = (value, index) => {
		const updatedTickets = [...eventForm.tickets.ticket_sessions];
		const updatedTicket = { ...updatedTickets[index] };
		updatedTicket.ticket_sale_end = value;
		updatedTickets[index] = updatedTicket;

		setEventForm({
			...eventForm,
			tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
		});
	};

	const handleTicketTypeChange = (newValue, index) => {
		if (newValue) {
			const updatedTickets = [...eventForm.tickets.ticket_sessions];
			updatedTickets[index] = {
				...updatedTickets[index],
				ticket_type: newValue.id,
			};
			setEventForm({
				...eventForm,
				tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
			});
		} else {
			const updatedTickets = [...eventForm.tickets.ticket_sessions];
			updatedTickets[index] = {
				...updatedTickets[index],
				ticket_type: "",
			};
			setEventForm({
				...eventForm,
				tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
			});
		}
	};

	const handleAgendaChange = (newValue, index) => {
		const updatedTickets = [...eventForm.tickets.ticket_sessions];
		updatedTickets[index] = {
			...updatedTickets[index],
			agenda_id: newValue ? newValue?.id : "",
		};
	};

	const handleMemberTypeChange = (newValue, index) => {
		if (newValue) {
			const updatedTickets = [...eventForm.tickets.ticket_sessions];
			updatedTickets[index] = {
				...updatedTickets[index],
				type: newValue.id,
			};
			setEventForm({
				...eventForm,
				tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
			});
		} else {
			const updatedTickets = [...eventForm.tickets.ticket_sessions];
			updatedTickets[index] = {
				...updatedTickets[index],
				type: "",
			};
			setEventForm({
				...eventForm,
				tickets: { ...eventForm.tickets, ticket_sessions: updatedTickets },
			});
		}
	};

	const handleAddTicket = () => {
		if (eventForm.tickets.ticket_sessions?.length > 0 && !validateForm())
			return;

		setEventForm((prevEventForm) => ({
			...prevEventForm,
			tickets: {
				...prevEventForm.tickets,
				ticket_sessions: [
					...prevEventForm.tickets.ticket_sessions,
					initialState,
				],
			},
		}));
	};

	const handleRemoveTicket = (index) => {
		const updatedTickets = [...eventForm.tickets.ticket_sessions];
		updatedTickets.splice(index, 1); // Remove the ticket at the specified index
		setEventForm({
			...eventForm,
			tickets: {
				...eventForm.tickets,
				ticket_sessions: updatedTickets,
			},
		});
		deleteMutation.mutate(eventForm.tickets.ticket_sessions[index].id);
	};

	const validateForm = () => {
		const payload = eventForm.tickets.ticket_sessions.map((ticket) => ({
			...ticket,
			ticket_sale_start: ticket.ticket_sale_start
				? ticket.ticket_sale_start.format("YYYY-MM-DD HH:mm")
				: null,
			ticket_sale_end: ticket.ticket_sale_end
				? ticket.ticket_sale_end.format("YYYY-MM-DD HH:mm")
				: null,
		}));

		const newErrors = validator(payload, TicketSchema);

		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const removeTicket = async (id) => {
		const { status, message, data } = await destroy(
			`${ADMIN_EVENT_TICKET_DELETE}/${id}`
		);
		if (status) {
			enqueueSnackbar(message, { variant: "success" });
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const deleteMutation = useMutation(removeTicket);

	const handleSaveTickets = async () => {
		const payload = _.cloneDeep(eventForm.tickets);

		payload.ticket_sessions = payload.ticket_sessions?.map((ticket) => ({
			...ticket,
			ticket_sale_start: ticket.ticket_sale_start
				? ticket.ticket_sale_start.format("YYYY-MM-DD HH:mm")
				: null,
			ticket_sale_end: ticket.ticket_sale_end
				? ticket.ticket_sale_end.format("YYYY-MM-DD HH:mm")
				: null,
		}));
		payload.ticket_sale_start = null;
		payload.ticket_sale_end = null;
		payload.ticket_description = null;

		const { status, message, data } = await post(ADMIN_EVENT_CREATE, payload);
		if (status === true) {
			const newTickets = data.tickets?.map((ticket) => ({
				...ticket,
				generate_qr_code: ticket.generate_qr_code === 1 ? true : false,
				ticket_sale_end: moment(ticket.ticket_sale_end),
				ticket_sale_start: moment(ticket.ticket_sale_start),
				id: ticket.id,
				quantity_available: ticket.quantity_available,
				ticket_description: ticket.ticket_description,
				ticket_type: ticket.ticket_type,
				title: ticket.title,
				type: ticket.type,
				price: ticket.price,
			}));
			setEventForm({
				...eventForm,
				tickets: {
					...eventForm.tickets,
					ticket_sessions: [...newTickets],
				},
			});
			setCurrentStep(currentStep + 1);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSaveTickets);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		if (eventForm?.tickets?.ticket_sessions?.length > 0) mutation.mutate();
		else setCurrentStep(currentStep + 1);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		// Remove the event listener when the component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // Smooth scrolling animation
		});
	};

	const handleSkip = () => {
		setCurrentStep(currentStep + 1);
	};

	return (
		<Box sx={styles.container}>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1">Create Tickets</Typography>
				<Button
					variant="contained"
					sx={{ ...styles.goBackBtn, mr: 2 }}
					onClick={handleGoBack}
				>
					Go Back
				</Button>
				<Button
					variant="contained"
					sx={{ ...styles.goBackBtn, mr: 2 }}
					onClick={handleSkip}
				>
					Skip
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
				>
					Continue
				</LoadingButton>
			</Stack>

			<Typography component="label" sx={styles.totalSessions}>
				Total {eventForm.agenda.agenda.length} Sessions
			</Typography>

			{eventForm.tickets.ticket_sessions?.map((ticket, index) => (
				<Box key={index}>
					<Box display="flex" justifyContent="flex-end">
						<Tooltip title="Remove Ticket">
							<IconButton
								aria-label="remove-ticket"
								sx={{ color: "#d32f2f" }}
								onClick={() => handleRemoveTicket(index)}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</Box>

					<Grid container spacing={3}>
						<Grid item md={4} sm={12}>
							<FormAutoComplete
								label="Ticket Type"
								placeholder="Select Ticket Type"
								options={ticketTypes}
								value={ticketTypes.find(
									(option) => option.id === ticket.ticket_type
								)}
								disableClearable
								isOptionEqualToValue={(option, value) =>
									option?.id === value?.id
								}
								onChange={(event, newValue) =>
									handleTicketTypeChange(newValue, index)
								}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<FormInput
								label="Ticket Name"
								placeholder="Ticket Name"
								value={ticket.title}
								name="title"
								onChange={(e) => handleTicketChange(e, index)}
								error={errors[`${index}.title`]}
								helperText={errors[`${index}.title`] || ""}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<FormInput
								label="Quantity Available"
								placeholder="Quantity Available"
								value={ticket.quantity_available}
								name="quantity_available"
								onChange={(e) => handleTicketChange(e, index)}
								type="number"
								inputProps={{ min: 0, max: 100000 }}
								error={errors[`${index}.quantity_available`]}
								helperText={errors[`${index}.quantity_available`] || ""}
							/>
						</Grid>

						{ticket?.ticket_type === "session_based" && (
							<Grid item md={4} sm={12}>
								<FormAutoComplete
									label="Agenda"
									placeholder="Select Agenda"
									options={eventForm?.agenda?.agenda || []}
									value={eventForm?.agenda?.agenda?.find(
										(option) => option.id === ticket.type
									)}
									isOptionEqualToValue={(option, value) =>
										option?.id === value?.id
									}
									onChange={(event, newValue) =>
										handleAgendaChange(newValue, index)
									}
									error={errors[`${index}.agenda_id`]}
									helperText={errors[`${index}.agenda_id`] || ""}
								/>
							</Grid>
						)}
						<Grid item md={4} sm={12}>
							<FormAutoComplete
								label="Select Type"
								placeholder="Select Type"
								options={memberTypes}
								value={memberTypes.find((option) => option.id === ticket.type)}
								disableClearable
								isOptionEqualToValue={(option, value) =>
									option?.id === value?.id
								}
								onChange={(event, newValue) =>
									handleMemberTypeChange(newValue, index)
								}
								error={errors[`${index}.type`]}
								helperText={errors[`${index}.type`] || ""}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<FormInput
								label="Price"
								placeholder="Price"
								value={ticket.price}
								name="price"
								onChange={(e) => handleTicketChange(e, index)}
								type="number"
								inputProps={{ min: 0, max: 100000 }}
								error={errors[`${index}.price`]}
								helperText={errors[`${index}.price`] || ""}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={3} mt={0}>
						<Grid item md={4} sm={12}>
							<FormLabel label="Start Time" />
							<DateTimePicker
								variant="outlined"
								sx={{ ...styles.datePicker, width: "100%" }}
								value={ticket.ticket_sale_start || null}
								// disablePast
								onChange={(value) => handleStartTimeChange(value, index)}
								slotProps={{
									textField: {
										helperText: errors[`${index}.ticket_sale_start`] || "",
										error: errors[`${index}.ticket_sale_start`],
									},
								}}
								closeOnSelect={false}
								maxDateTime={eventForm.details.start_time}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<FormLabel label="End Time" />
							<DateTimePicker
								variant="outlined"
								sx={{ ...styles.datePicker, width: "100%" }}
								value={ticket.ticket_sale_end || null}
								// disablePast
								minDateTime={ticket.ticket_sale_start || moment()}
								onChange={(value) => handleEndTimeChange(value, index)}
								slotProps={{
									textField: {
										helperText: errors[`${index}.ticket_sale_end`] || "",
										error: errors[`${index}.ticket_sale_end`],
									},
								}}
								closeOnSelect={false}
								maxDateTime={eventForm.details.end_time}
							/>
						</Grid>
					</Grid>

					{/* <Stack direction="row" justifyContent="space-between" py={3}>
						<FormLabel label="Generate QR code for this Ticket?" />
						<Switch
							sx={styles.switch}
							value={ticket.generate_qr_code === 1 ? true : false}
							checked={ticket.generate_qr_code}
							name="generate_qr_code"
							onChange={(e) => handleTicketChange(e, index)}
						/>
					</Stack> */}

					<FormInput
						label="Ticket Description"
						placeholder="Ticket Description"
						value={ticket.ticket_description}
						name="ticket_description"
						onChange={(e) => handleTicketChange(e, index)}
						multiline
						rows={4}
						fullwidth
						error={errors[`${index}.ticket_description`]}
						helperText={errors[`${index}.ticket_description`] || ""}
					/>

					<Divider sx={styles.divider} />
				</Box>
			))}

			<Typography component="p" sx={styles.added} onClick={handleAddTicket}>
				+ Add Another Ticket
			</Typography>

			<Fab
				sx={{
					...styles.scrollButtonStyles,
					...(isVisible && styles.visibleScrollButtonStyles),
				}}
				onClick={scrollToTop}
			>
				<KeyboardArrowUpIcon />
			</Fab>
		</Box>
	);
};

export default EventTickets;
