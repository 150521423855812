export const subscriptionColors = {
  Active: {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
  Inactive: {
    bgcolor: "#FCF9EC",
    color: "#F2D048",
  },
  Stopped: {
    bgcolor: "#FFEEEE",
    color: "#FF5757",
  },
  active: {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
  inactive: {
    bgcolor: "#FCF9EC",
    color: "#F2D048",
  },
  stopped: {
    bgcolor: "#FFEEEE",
    color: "#FF5757",
  },
  "on going": {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
  pending: {
    bgcolor: "#FFEEEE",
    color: "#FF5757",
  },
  approved: {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
};

export const statusColors = {
  Active: {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
  Inactive: {
    bgcolor: "#FCF9EC",
    color: "#F2D048",
  },
  Stopped: {
    bgcolor: "#FFEEEE",
    color: "#FF5757",
  },
  "on going": {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
  pending: {
    bgcolor: "#FFEEEE",
    color: "#FF5757",
  },
  approved: {
    bgcolor: "#E3F2F3",
    color: "#03989E",
  },
};

const now = new Date();

export const Events = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2023, 6, 0),
    end: new Date(2023, 6, 1),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2023, 3, 7),
    end: new Date(2023, 3, 10),
  },
  {
    id: 2,
    title: "Meeting",
    start: new Date(2023, 5, 16, 10, 30, 0, 0),
    end: new Date(2023, 5, 16, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 3,
    title: "Lunch",
    start: new Date(2023, 5, 16, 12, 0, 0, 0),
    end: new Date(2023, 5, 16, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 4,
    title: "Meeting",
    start: new Date(2023, 5, 16, 14, 0, 0, 0),
    end: new Date(2023, 5, 16, 15, 0, 0, 0),
  },
  {
    id: 5,
    title: "Happy Hour",
    start: new Date(2023, 5, 16, 17, 0, 0, 0),
    end: new Date(2023, 5, 16, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 6,
    title: "Dinner",
    start: new Date(2023, 5, 20, 20, 0, 0, 0),
    end: new Date(2023, 5, 20, 21, 0, 0, 0),
  },
  {
    id: 7,
    title: "Point in Time Event",
    start: now,
    end: now,
  },
];
