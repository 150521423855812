import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Collapse } from "@mui/material";
import { Link } from "react-router-dom";

import { Divider, Drawer } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as Logout } from "../../assets/svg/sidebarIcons/Logout.svg";
import { ReactComponent as ExpandLess } from "../../assets/svg/sidebarIcons/ExpandLess.svg";
import { ReactComponent as ExpandMore } from "../../assets/svg/sidebarIcons/ExpandMore.svg";

import { routes } from "../../data/sidebarRoutes";

import { useLocation } from "react-router-dom";

import Logo from "../../assets/svg/Logo.svg";
import LogoutModal from "../Modals/LogoutModal";

const styles = {
	"&.MuiListItemIcon-root": {
		minWidth: 40,
	},
	"& svg": {
		width: 22,
		height: 22,
	},
	listItem: {
		borderLeft: "4px solid transparent",
		py: "12px",
		"& span": {
			color: "#1E1E1F",
			letterSpacing: 0,
			fontSize: 14,
		},
	},
	logOutText: {
		color: "#AC3049",
		fontWeight: 600,
	},
	collapse: {
		backgroundColor: "#EAEBF2",
	},
	activeList: {
		bgcolor: "#eaebf1",
		borderLeft: "4px solid #0F4590",
		"& span": {
			color: "#0F4590",
			fontWeight: 600,
		},
		"& svg": {
			"& path": {
				fill: "#0F4590",
				fontWeight: 600,
			},
		},
		"&:hover": {
			bgcolor: "#eaebf1",
		},
	},
	subItem: {
		bgcolor: "#0F4590",
		borderRadius: "12px",
		mx: 2.5,
		my: 1.5,
		pl: 0,
		py: 0.5,
		"& span": {
			color: "white",
		},
		"&:hover": {
			bgcolor: "#0F4590",
		},
	},
};

const CustomListItem = ({ route, keepOpen }) => {
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleToggle = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (!pathname.includes(route.path)) {
			setOpen(open ? !open : false);
		}
	}, [pathname]);

	return route.subCategories && route.subCategories.length > 0 ? (
		<>
			<ListItemButton
				onClick={handleToggle}
				sx={[
					styles.listItem,
					pathname.includes(route.path) ? styles.activeList : "",
				]}
			>
				<ListItemIcon sx={styles}>{route.icon}</ListItemIcon>
				<ListItemText primary={route.label} />
				{open ? <ExpandMore /> : <ExpandLess />}
			</ListItemButton>

			<Collapse in={open} timeout="auto" unmountOnExit sx={styles.collapse}>
				{route.subCategories.map((category, index) => (
					<CustomListItem
						route={category}
						key={`${category.label}-${index}`}
						keepOpen={true}
					/>
				))}
			</Collapse>
		</>
	) : (
		<ListItem disablePadding>
			<ListItemButton
				sx={[
					styles.listItem,
					pathname === route.path && route.type === 1
						? styles.subItem
						: pathname === route.path && styles.activeList,
				]}
				onClick={() => navigate(route.path)}
			>
				<ListItemIcon sx={styles}>{route.icon}</ListItemIcon>
				<ListItemText primary={route.label} />
			</ListItemButton>
		</ListItem>
	);
};

export default function Sidebar(props) {
	const [modal, setModal] = useState();

	const { window, drawerWidth, mobileOpen, setMobileOpen } = props;

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleLogout = async () => {
		setModal(true);
	};

	const drawer = (
		<>
			<Link to="/home" className="logoWrapper">
				<Box component="img" src={Logo} alt="Logo" width="100%" />
			</Link>
			<Divider />

			<List disablePadding>
				{routes.map((route, index) => (
					<CustomListItem route={route} key={`${route.label}=${index}`} />
				))}
			</List>
			<Box sx={{ flexGrow: 0, mt: "auto" }}>
				<Divider />
				<ListItemButton
					sx={[styles.listItem, { height: 78 }]}
					onClick={handleLogout}
				>
					<ListItemIcon sx={styles}>
						<Logout />
					</ListItemIcon>
					<ListItemText sx={styles.logOutText}>Logout</ListItemText>
				</ListItemButton>
			</Box>
		</>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Box
					component="nav"
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label="mailbox folders"
				>
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
					>
						{drawer}
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
								"&::-webkit-scrollbar-thumb": {
									background: "rgba(0, 0, 0, 0.1)",
								},
							},
						}}
						open
					>
						{drawer}
					</Drawer>
				</Box>
			</Box>
			<LogoutModal modal={modal} setModal={setModal} />
		</>
	);
}
