import React, { useRef } from "react";
import PageHeader from "../../../components/ui/PageHeader";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import EventDetailListings from "../../../components/ui/EventDetailListings";
import { ReactComponent as Document } from "../../../assets/svg/eventIcons/Document.svg";
import { ReactComponent as PDF } from "../../../assets/svg/eventIcons/PDF.svg";
import { ReactComponent as Photo } from "../../../assets/svg/eventIcons/Photo.svg";
import { useMutation, useQueryClient } from "react-query";
import { destroy, post } from "../../../server";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { enqueueSnackbar } from "notistack";
import {
	ADMIN_EVENT_DOCUMENT_DELETE,
	ADMIN_EVENT_FILE_UPLOAD,
} from "../../../data/constants/apiRoutes";
import { Delete } from "@mui/icons-material";
import { useState } from "react";
import BoxModal from "../../../components/Modals/BoxModal";
import queryNames from "../../../data/constants/queryNames";

const styles = {
	icon: {
		mr: 1.5,
		width: 45,
		height: 45,
		bgcolor: "#fff",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #F1F1F1",
	},
};

const Documents = ({ documents, loading, eventId, handleInvalidate }) => {
	const [id, setId] = useState(null);
	const [modal, setModal] = useState(false);
	const documentRef = useRef();

	const client = useQueryClient();

	const getDocumentTypeIcon = (url) => {
		if (url.endsWith(".pdf")) {
			return PDF;
		} else if (
			url.endsWith(".jpg") ||
			url.endsWith(".jpeg") ||
			url.endsWith(".png") ||
			url.endsWith(".mp4")
		) {
			return Photo;
		} else {
			return Document;
		}
	};

	const getDocumentDetails = (url) => {
		const parts = url.split("/");
		const fileName = parts[parts.length - 1];
		const extension = fileName.split(".").pop();
		const fileSizeKB = 1024; // Some example size in KB, you should replace this with actual size retrieval logic
		return `${extension.toUpperCase()} / ${fileSizeKB.toFixed(2)} KB`;
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: true,
			flex: 1,
			renderCell: (params) => {
				const DocumentTypeIcon = getDocumentTypeIcon(params.row.name);
				const parts = params.row.name.split("/");
				const documentName = parts[parts.length - 1].split(".")[0];
				return (
					<>
						<Stack
							direction="row"
							alignItems="center"
							sx={{ cursor: "pointer" }}
						>
							<Box sx={styles.icon}>
								<DocumentTypeIcon />
							</Box>
							{documentName}
						</Stack>
					</>
				);
			},
		},
		{
			field: "details",
			headerName: "Details",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				const documentDetails = getDocumentDetails(params.row.name);
				return <>{documentDetails}</>;
			},
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Tooltip title="Delete" arrow placement="top">
						<IconButton
							onClick={() => {
								setId(params.row.id);
								setModal("delete");
							}}
						>
							<Delete sx={{ color: "red" }} />
						</IconButton>
					</Tooltip>
				);
			},
		},
	];

	const handleFileChange = (e) => {
		const files = e.target.files;
		const newFiles = Array.from(files);
		const maxSizeInBytes = 30 * 1024 * 1024; // 30MB

		// const initialProgress = newFiles.reduce((progress, file) => {
		// 	progress[file.name] = 0;
		// 	return progress;
		// }, {});

		const validFiles = newFiles.filter((file) => file.size <= maxSizeInBytes);

		if (validFiles.length < newFiles.length) {
			alert("Some files exceed the 30MB size limit.");
		}

		// setUploadProgress((prevProgress) => ({
		// 	...prevProgress,
		// 	...initialProgress,
		// }));

		// setEventForm({
		// 	...eventForm,
		// 	details: {
		// 		...eventForm.details,
		// 		document: [...eventForm.details.document, ...validFiles],
		// 	},
		// });

		mutation.mutate(validFiles);
	};

	const uploadDocuments = async (files) => {
		let newStatus = true;
		let uploadedDocuments = [];

		for (let i = 0; i < files.length; i++) {
			const document = files[i];
			const data = await awsFileUpload(
				document,
				uploadImageType.createEvent
				// setUploadProgress
			);
			if (data.status === true) {
				uploadedDocuments[i] = data.location;
			} else {
				enqueueSnackbar("Error uploading documents", { variant: "error" });
				return (newStatus = false);
			}
		}

		if (newStatus) {
			const { status, message, data } = await post(ADMIN_EVENT_FILE_UPLOAD, {
				event_id: eventId,
				file: uploadedDocuments,
				type: "document",
			});
			if (status === true) {
				handleInvalidate();
			}
		}
	};

	const mutation = useMutation(uploadDocuments);

	const handleAddDocument = () => {
		documentRef.current.click();
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_EVENT_DOCUMENT_DELETE}/${id}`
		);
		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			setModal(false);
			client.invalidateQueries([queryNames.EVENTS])
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const deleteMutation = useMutation(handleDelete)

	const handleConfirm = () => {
		deleteMutation.mutate();
	};

	return (
		<>
			<PageHeader
				title="Document"
				createBtn
				createBtnText="Upload Document"
				btnFnc={handleAddDocument}
				isLoading={mutation.isLoading}
			/>

			<input
				type="file"
				multiple
				hidden
				onChange={handleFileChange}
				id={`documentUpload`}
				ref={documentRef}
			/>

			<EventDetailListings
				rows={documents}
				columns={columns}
				loading={loading}
			/>

			<BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={() => setModal(false)}
				handleConfirm={handleConfirm}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>
		</>
	);
};

export default Documents;
