import React, { useState } from "react";
import {
	Button,
	Stack,
	Typography,
	IconButton,
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	Box,
	Pagination,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import EmailRow from "../../components/Emails/EmailRow";
import EmailDrawer from "../../components/ui/EmailDrawer";
import { ADMIN_EMAIL_LISTING } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useQuery, useQueryClient } from "react-query";
import queryNames from "../../data/constants/queryNames";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import { get } from "../../server";
import styles from "../../assets/styles/email/emails.styles";

function Emails() {
	const [emails, setEmails] = useState([]);
	const [modal, setModal] = useState(false);
	const [check, setCheck] = useState(false);

	const [selectedEmails, setSelectedEmails] = useState([]);

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	});

	const { enqueueSnackbar } = useSnackbar();
	const client = useQueryClient();

	const fetchData = async () => {
		let url = new URL(ADMIN_EMAIL_LISTING);

		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.pageSize);

		const { status, message, data } = await get(url.toString());
		if (status === true) {
			setEmails(data);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const { isLoading, isFetching } = useQuery(
		[queryNames.EMAILS, paginationModel.page],
		fetchData,
		{ keepPreviousData: true }
	);

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.EMAILS);
	};

	const handleSelectAll = () => {
		if (!check) {
			const allEmailIds = emails.map((email) => email.id);
			setSelectedEmails(allEmailIds);
		} else {
			setSelectedEmails([]);
		}
		setCheck(!check);
	};

	const handleSelect = (emailId) => {
		if (emailId === "all") {
			if (!check) {
				const allEmailIds = emails.map((email) => email.id);
				setSelectedEmails(allEmailIds);
			} else {
				// If "Select All" is checked, deselect all emails
				setSelectedEmails([]);
			}
			setCheck(!check); // Toggle the state of "Select All" checkbox
		} else {
			// Toggle the selection state of individual emails
			if (selectedEmails.includes(emailId)) {
				setSelectedEmails(selectedEmails.filter((id) => id !== emailId));
			} else {
				setSelectedEmails([...selectedEmails, emailId]);
			}
		}
	};

	return (
		<>
			<Stack direction="row" sx={styles.wrapper}>
				<LoadingOverlay loading={isLoading || isFetching} />
				<Typography component="h1">Emails</Typography>
				<Stack direction="row" spacing={1.25}>
					{/* <Checkbox
						checked={check}
						onChange={() => handleSelect("all")}
						inputProps={{ "aria-label": "controlled" }}
						sx={styles.checkbox}
					/> */}
					{/* <FormControl variant="standard" sx={styles.select}>
						<Select value="all">
							<MenuItem value="all">All</MenuItem>
							<MenuItem>Something</MenuItem>
						</Select>
					</FormControl> */}
					<IconButton sx={styles.refreshButton} onClick={handleInvalidate}>
						<Refresh />
					</IconButton>
					<Button
						variant="contained"
						disableElevation
						sx={styles.compose}
						onClick={() => setModal(true)}
					>
						Compose
					</Button>
				</Stack>
			</Stack>

			<LoadingOverlay loading={isLoading} />

			<Box sx={styles.box}>
				{emails.map((email, key) => (
					<EmailRow
						{...email}
						key={key}
						isSelected={selectedEmails.includes(email.id)}
						onSelect={() => handleSelect(email.id)}
					/>
				))}
			</Box>
			<Pagination sx={styles.pagination} />

			<EmailDrawer
				modal={modal}
				setModal={setModal}
				handleInvalidate={handleInvalidate}
			/>
		</>
	);
}

export default Emails;
