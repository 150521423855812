

import { Typography, Button, Box, Stack, MenuItem, FormControl, Select } from "@mui/material";
import React from "react";
import ReactApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'


const styles = {
    wrapper: {
        bgcolor: "#fff",
        borderRadius: "12px",
        p: 3,
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F9F9F9",
            borderRadius: "6px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #E2E3E4",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2B2A69 !important",
            borderWidth: "1px !important",
        },

        "& input": {
            py: "13px",
            px: 2,
            "&::placeholder": {
                color: "#767D90",
                fontWeight: 400,
                opacity: 1,
                fontSize: 14,
            },
        },
        "& h4": {
            fontSize: 12,
            color: '#333333'
        }
    },
    header: {
        pb: 1,
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        rowGap: '10px',
        // alignItems: 'center',
        "& h3": {
            color: "#1E1E1F",
            fontSize: 16,
            fontWeight: 600,
        },
        "& button": {
            color: "#0F4590",
            bgcolor: "white",
            border: "1px solid #0F4590",
            fontWeight: 500,
            "&:hover": {
                bgcolor: "white",
            }
        },
    },
    select: {
        width: 150,
        height: 40,
        mr: 1.25,
        ml: 2.25,
        "& .MuiOutlinedInput-input": {
            py: 1.25,
            px: 2,
        },
    },
    cancelation: {
        backgroundColor: '#E5D442',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
    },
    activations: {
        backgroundColor: '#293277',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
    },

};
const series = [
    {
        name: 'Activations',
        color: "#293277",
        width: "10px",
        data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
        name: 'Net Cancellation',
        color: "#E5D442",
        data: [11, 32, 45, 32, 34, 52, 41]
    }
]

const options = {
    chart: {
        height: 250,
        type: 'area',
        toolbar: { show: false },
        zoom: { enabled: false },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    xaxis: {
        type: 'number',
        categories: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    },
    tooltip: {
        shared: false,
        intersect: false,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {

            // const xValue = series[seriesIndex].data[dataPointIndex][0];
            // const yValue = series[seriesIndex].data[dataPointIndex][1];
            // const tooltipStyle = {
            //   position: "absolute",
            //   top: `${w.globals.plotSize.height - yValue - 20}px`,
            //   left: `${w.globals.seriesX[dataPointIndex]}px`,
            //   backgroundcolor: "rgba(0, 0, 0, 0.8)",
            //   color: "#fff",
            //   padding: "8px",
            //   borderradius: "4px",
            //   pointerevents: "none",
            // }
            ;

            return renderToString(
                <div className="container" >
                    <p className="month">March</p>
                    <h3 className="monthValue">192k</h3>
                </div>
            )
        },
    },
    legend: {
        show: false,
        // position: 'top',
        // horizontalAlign: 'center',
        // fontSize: 12,
        // // floating: true,
        // markers: {
        //     radius: 12
        // },
        // labels: {
        //     colors: ' #333333'
        // },
        // itemMargin: {
        //     horizontal: 5,
        //     vertical: 0
        // }
    },
    fill: {
        opacity: 1,
        gradient: {
            type: 'vertical',
            shadeIntensity: 0,
            opacityFrom: 0.6,
            opacityTo: 0,
            stops: [0, 100]
        }
    },
}

function SubscriptionChart() {
    return <>
        <Box sx={styles.wrapper}>
            <Stack
                direction="row"
                sx={styles.header}
            >
                <Typography component="h3" flex={1}>Events</Typography>
                <Stack direction="row">
                    <Stack direction="row" spacing={0.875} mr={3.75} alignItems="center">
                        <Box sx={styles.activations} />
                        <Typography component="h4">Activations</Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.875} alignItems="center">
                        <Box sx={styles.cancelation} />
                        <Typography component="h4">Net Cancellation</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row">
                    <FormControl variant="outlined" fullWidth sx={styles.select}>
                        <Select
                            value={1}
                            type="string"
                        //  onChange={}
                        >
                            <MenuItem value={1}>This Year</MenuItem>
                            <MenuItem value={2}>This Month</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" disableElevation>View All</Button>
                </Stack>
            </Stack>

            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={250} />

        </Box>
    </>

}

export default SubscriptionChart;