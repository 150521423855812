import {
	Box,
	IconButton,
	InputAdornment,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../../components/ui/PageHeader";
import FormInput from "../../components/Forms/FormInput";
import LoadingButton from "../../components/Buttons/LoadingButton";
import InterestRemove from "../../assets/svg/InterestRemove.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { destroy, get, post } from "../../server";
import {
	ADMIN_INTEREST_ADD_UPDATE,
	ADMIN_INTEREST_DELETE,
	ADMIN_INTEREST_LISTING,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { validator } from "../../utils/helpers/validator";
import InterestSchema from "../../utils/schemas/InterestSchema";
import queryNames from "../../data/constants/queryNames";
import { Clear } from "@mui/icons-material";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import styles from "../../assets/styles/interests/interest.styles";

const initialState = {
	newInterest: "",
	interests: [],
};
const initialUpdateState = {
	id: "",
	name: "",
};

const Interest = () => {
	const [interest, setInterest] = useState(initialState);
	const [updateInterest, setUpdatedInterest] = useState(initialUpdateState);

	const [interestId, setInteresId] = useState();

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();
	const client = useQueryClient();

	const validateForm = () => {
		let newErrors = validator(
			{ name: updateInterest?.id ? updateInterest.name : interest.newInterest },
			InterestSchema
		);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;

		value = value.replace(/\s{2,}/g, " ");

		if (updateInterest?.id) {
			setUpdatedInterest({ ...updateInterest, [name]: value });
		} else {
			setInterest({ ...interest, [name]: value });
		}
	};

	const fetchInterests = async () => {
		const { status, message, data } = await get(ADMIN_INTEREST_LISTING);
		if (status === true) {
			setInterest({ ...interest, interests: data.data });
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const interestQuery = useQuery([queryNames.INTEREST], fetchInterests, {
		keepPreviousData: true,
	});

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.INTEREST);
	};

	const handleSaveInterest = async () => {
		const { status, message } = await post(
			ADMIN_INTEREST_ADD_UPDATE,
			updateInterest?.id
				? updateInterest
				: {
						name: interest.newInterest,
				  }
		);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			setUpdatedInterest(initialUpdateState);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const submitMutation = useMutation(handleSaveInterest);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		const newInterest = updateInterest?.id
			? updateInterest.name
			: interest.newInterest;
		if (newInterest !== " ") submitMutation.mutate();
	};

	// const handleRemoveInterest = async () => {
	// 	const { status, message, data } = await post(
	// 		`${ADMIN_INTEREST_DELETE}/${interestId}`
	// 	);
	// 	// if (status === true) {
	// 	// 	enqueueSnackbar(message, { variant: "success" });
	// 	// 	handleInvalidate();
	// 	// } else enqueueSnackbar(message, { variant: "error" });
	// };

	// const deleteMutation = useMutation(handleRemoveInterest);

	const handleRemove = async (e, id) => {
		e.preventDefault();
		handleCancel();
		const { status, message } = await destroy(`${ADMIN_INTEREST_DELETE}/${id}`);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const handleClick = (newInterest) => {
		setUpdatedInterest({
			id: newInterest.id,
			name: newInterest.name,
		});
	};

	const handleCancel = () => setUpdatedInterest(initialUpdateState);

	return (
		<>
			<PageHeader title="Interest" />
			<Box sx={styles.container}>
				<LoadingOverlay loading={interestQuery.isLoading} />
				<Stack direction="row" gap="10px">
					<FormInput
						value={
							updateInterest?.id ? updateInterest.name : interest.newInterest
						}
						placeholder="Enter interest"
						name={updateInterest?.id ? "name" : "newInterest"}
						onChange={handleChange}
						sx={styles.input}
						inputType
						error={errors?.name}
						helperText={errors?.name || ""}
						InputProps={{
							endAdornment: updateInterest?.id && (
								<InputAdornment position="end" sx={{ ml: 0, px: "5px" }}>
									<IconButton sx={styles.icon} onClick={handleCancel}>
										<Clear />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<LoadingButton
						variant="contained"
						onClick={handleSubmit}
						isLoading={submitMutation.isLoading}
						sx={styles.addButton}
					>
						{updateInterest?.id ? "Update" : "Add"}
					</LoadingButton>
				</Stack>

				<Box sx={styles.interests}>
					{interest.interests.map((interest, index) => (
						<Tooltip title="Click to update" key={index}>
							<Box position="relative">
								<Box
									sx={styles.interestContainer}
									key={index}
									onClick={() => handleClick(interest)}
								>
									<Typography component="label">{interest.name}</Typography>
								</Box>
								<Box
									component="img"
									src={InterestRemove}
									sx={styles.removeIcon}
									onClick={(e) => handleRemove(e, interest.id)}
								/>
							</Box>
						</Tooltip>
					))}
				</Box>
			</Box>
		</>
	);
};

export default Interest;
