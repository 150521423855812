import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/events/createEvent.styles";
import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Fab,
  Button,
} from "@mui/material";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import FormInput from "../../../components/Forms/FormInput";
import FormAutoComplete from "../../../components/Forms/FormAutoComplete";
import { DateTimePicker } from "@mui/x-date-pickers";
import FormLabel from "../../../components/Forms/FormLabel";
import { Delete } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { validator } from "../../../utils/helpers/validator";
import { AgendaSchema } from "../../../utils/schemas/EventSchema";
import { useMutation, useQuery } from "react-query";
import {
  ADMIN_COMPANY_SPONSORS_LISTING,
  ADMIN_EVENT_AGENDA_DELETE,
  ADMIN_EVENT_CREATE,
} from "../../../data/constants/apiRoutes";
import _ from "lodash";
import { destroy, get, post } from "../../../server";
import { useSnackbar } from "notistack";
import moment from "moment";
import queryNames from "../../../data/constants/queryNames";

const initialState = {
  title: "",
  speaker_id: "",
  start_session_time: null,
  end_session_time: null,
  description: "",
};

const EventAgenda = ({
  currentStep,
  setCurrentStep,
  eventForm,
  setEventForm,
  handleGoBack,
}) => {
  const [agendas, setAgendas] = useState(
    eventForm.agenda.agenda.length ? eventForm.agenda.agenda : [initialState]
  );
  const [isVisible, setIsVisible] = useState(false);

  const [errors, setErrors] = useState(Array(agendas.length).fill({}));
  const [sponsors, setSponsors] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const validate = () => {
    let isValid = true;
    const newErrors = agendas.map((agenda) => {
      const agendaErrors = validator(
        {
          ...agenda,
          start_session_time: agenda.start_session_time
            ? agenda.start_session_time.format("HH:mm")
            : null,
          end_session_time: agenda.end_session_time
            ? agenda.end_session_time.format("HH:mm")
            : null,
        },
        AgendaSchema
      );
      if (Object.keys(agendaErrors).length > 0) {
        isValid = false;
      }
      return agendaErrors;
    });
    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const newAgendas = [...agendas];
    newAgendas[index] = {
      ...newAgendas[index],
      [name]: value,
    };
    const newError =[...errors];
    newError[index] = {
      ...errors[index],
      [name]: ""
    }
    setErrors(newError);
    setAgendas(newAgendas);
  };

  const handleSpeakerChange = (event, newValue, index) => {
    const newAgendas = [...agendas];
    if (newValue) {
      newAgendas[index] = {
        ...newAgendas[index],
        speaker_id: newValue.value,
      };

      setAgendas(newAgendas);
    } else {
      newAgendas[index] = {
        ...newAgendas[index],
        speaker_id: "",
      };

      setAgendas(newAgendas);
    }
  };

  const handleSponsorChange = (event, newValue, index) => {
    const newAgendas = [...agendas];

    if (newValue) {
      newAgendas[index] = {
        ...newAgendas[index],
        sponser_id: newValue.id,
      };

      setAgendas(newAgendas);
    } else {
      newAgendas[index] = {
        ...newAgendas[index],
        sponser_id: "",
      };

      setAgendas(newAgendas);
    }
  };

  const handleStartTimeChange = (e, index) => {
    const newAgendas = [...agendas];
    newAgendas[index] = {
      ...newAgendas[index],
      start_session_time: e,
    };
    const newError =[...errors];
    newError[index] = {
      ...errors[index],
      start_session_time: ""
    }
    setErrors(newError);
    setAgendas(newAgendas);
  };

  const handleEndTimeChange = (e, index) => {
    const newAgendas = [...agendas];
    newAgendas[index] = {
      ...newAgendas[index],
      end_session_time: e,
    };
    const newError =[...errors];
    newError[index] = {
      ...errors[index],
      end_session_time: ""
    }
    setErrors(newError);
    setAgendas(newAgendas);
  };

  const handleStartTimeFocus = (index) => {
    setAgendas((prevAgenda) => {
      return prevAgenda.map((agendaItem, i) => {
        if (i === index) {
          return {
            ...agendaItem,
            start_session_time: eventForm.details.start_time,
          };
        }
        return agendaItem;
      });
    });
  };

  const handleEndTimeFocus = (index) => {
    setAgendas((prevAgenda) => {
      return prevAgenda.map((agendaItem, i) => {
        if (i === index) {
          return {
            ...agendaItem,
            end_session_time: eventForm.details.end_time,
          };
        }
        return agendaItem;
      });
    });
  };

  const handleAddAgenda = () => {
    if (!validate()) return;
    setAgendas([...agendas, initialState]);
  };

  const handleRemoveAgenda = (index) => {
    if (
      agendas.length === 1 &&
      Object.values(agendas[0]).every((value) => !value)
    ) {
      return;
    }

    const newAgendas = [...agendas];
    newAgendas.splice(index, 1);
    setAgendas(newAgendas);
    if (agendas[index].id) deleteMutation.mutate(agendas[index].id);
  };

  useEffect(() => {
    // Add a scroll event listener to check if the user has scrolled down
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const fetchSponsors = async () => {
    const { status, message, data } = await get(
      `${ADMIN_COMPANY_SPONSORS_LISTING}/${eventForm.details.company_id}`
    );
    if (status === true) {
      let newData = data.map((sponsor) => ({
        id: sponsor.id,
        label: sponsor.company_name,
      }));
      setSponsors(newData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const { isLoading: newLoading } = useQuery(
    queryNames.SPONSOR,
    fetchSponsors,
    {
      refetchOnWindowFocus: false,
    }
  );

  const removeAgenda = async (id) => {
    const { status, message, data } = await destroy(
      `${ADMIN_EVENT_AGENDA_DELETE}/${id}`
    );
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const deleteMutation = useMutation(removeAgenda);

  const handleSaveAgendas = async () => {
    let payload = _.cloneDeep(agendas);

    let eventPayload = _.cloneDeep(eventForm.agenda);
    payload.forEach((agenda) => {
      agenda.start_session_time = agenda.start_session_time
        ? agenda.start_session_time.format("YYYY-MM-DD HH:mm")
        : null;
      agenda.end_session_time = agenda.end_session_time
        ? agenda.end_session_time.format("YYYY-MM-DD HH:mm")
        : null;
      if (agenda.hasOwnProperty("label")) {
        delete agenda.label;
      }
    });

    eventPayload.agenda = payload;

    const { status, message, data } = await post(
      ADMIN_EVENT_CREATE,
      eventPayload
    );
    if (status === true) {
      let newAgenda = data.agendas.map((agenda) => ({
        id: agenda.id,
        start_session_time: agenda.start_session_time
          ? moment(agenda.start_session_time)
          : null,
        end_session_time: agenda.end_session_time
          ? moment(agenda.end_session_time)
          : null,
        speaker_id: agenda.agenda_speaker?.id,
        title: agenda.title,
        description: agenda.description,
        label: agenda.title,
      }));
      setEventForm({
        ...eventForm,
        agenda: {
          ...eventForm.agenda,
          agenda: newAgenda,
        },
      });
      // enqueueSnackbar(message, { variant: "success" });
      setCurrentStep(currentStep + 1);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const mutation = useMutation(handleSaveAgendas);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate()) return;
    mutation.mutate();
  };

  const handleSkip = (e) => {
    setCurrentStep(currentStep + 1);
    // e.preventDefault();
    // mutation.mutate();
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="row" sx={styles.title}>
        <Typography component="h1">Create Agenda</Typography>
        <Button
          variant="contained"
          sx={{ ...styles.goBackBtn, mr: 2 }}
          onClick={handleGoBack}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          sx={{ ...styles.goBackBtn, mr: 2 }}
          onClick={handleSkip}
        >
          Skip
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Continue
        </LoadingButton>
      </Stack>

      {agendas.map((agenda, index) => (
        <Stack direction="row" key={index} sx={styles.agendaContainer}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={12}>
              <FormInput
                label="Title"
                placeholder="Title of Agenda"
                value={agenda.title}
                name="title"
                onChange={(e) => handleChange(e, index)}
                error={errors[index]?.title}
                helperText={errors[index]?.title || ""}
              />
            </Grid>

            <Grid item md={4} sm={12}>
              <FormAutoComplete
                label="Speaker"
                placeholder="Select Speaker"
                options={eventForm?.members?.speakers}
                value={
                  eventForm?.members?.speakers?.find(
                    (option) => option.value === agenda.speaker_id
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleSpeakerChange(event, newValue, index)
                }
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                error={errors?.speaker_id}
                helperText={errors?.speaker_id || ""}
              />
            </Grid>

            <Grid item md={4} sm={12}>
              <FormAutoComplete
                label="Sponsor"
                placeholder="Select Company"
                options={sponsors}
                value={
                  sponsors?.find(
                    (option) => option?.id === agenda.sponser_id
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleSponsorChange(event, newValue, index)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                error={errors?.sponser_id}
                helperText={errors?.sponser_id || ""}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormLabel label="Session Time" />
              <Stack direction="row" gap="10px" alignItems="center">
                <DateTimePicker
                  variant="outlined"
                  sx={{ ...styles.datePicker, width: "100%" }}
                  value={agenda.start_session_time || null}
                  // onOpen={() => handleStartTimeFocus(index)}
                  onChange={(e) => handleStartTimeChange(e, index)}
                  // disablePast
                  slotProps={{
                    textField: {
                      helperText: errors[index]?.start_session_time || "",
                      error: errors[index]?.start_session_time,
                    },
                  }}
                  closeOnSelect={false}
                  maxDateTime={eventForm.details.end_time}
                />

                <Typography component="label" sx={styles.sessionToLabel}>
                  To
                </Typography>

                <DateTimePicker
                  variant="outlined"
                  sx={{ ...styles.datePicker, width: "100%" }}
                  value={agenda.end_session_time || null}
                  // onOpen={() => handleEndTimeFocus(index)}
                  onChange={(e) => handleEndTimeChange(e, index)}
                  // disablePast
                  minDateTime={agenda.start_session_time || null}
                  slotProps={{
                    textField: {
                      helperText: errors[index]?.end_session_time || "",
                      error: errors[index]?.end_session_time,
                    },
                  }}
                  closeOnSelect={false}
                  maxDateTime={eventForm.details.end_time}
                />
              </Stack>
            </Grid>

            <Grid item md={12} sm={12}>
              <FormInput
                label="Description"
                placeholder="Description"
                value={agenda.description}
                name="description"
                onChange={(e) => handleChange(e, index)}
                multiline
                rows={4}
                error={errors[index]?.description}
                helperText={errors[index]?.description || ""}
              />
            </Grid>
          </Grid>

          {agendas.length > 1 && (
            <Tooltip title="Remove Session">
              <IconButton
                size="large"
                sx={styles.removeBtn}
                onClick={() => handleRemoveAgenda(index)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ))}

      <Typography component="p" sx={styles.added} onClick={handleAddAgenda}>
        +Add Another Session
      </Typography>

      <Fab
        sx={{
          ...styles.scrollButtonStyles,
          ...(isVisible && styles.visibleScrollButtonStyles),
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );
};

export default EventAgenda;
