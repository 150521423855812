import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		py: 3,
		px: 2,
		borderRadius: 3,
		"& h2": {
			fontSize: 16,
			fontWeight: 600,
			color: "#1E1E1F",
		},
	},
	circleWrapper: {
		position: "relative",
		width: 230,
		m: "20px auto",
	},
	circle: {
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		"& h3": {
			fontWeight: 700,
			fontSize: 22,
			color: "#fff",
		},
		"& p": {
			fontSize: 12,
			color: "#fff",
			width: 52,
			textAlign: "center",
		},
	},
	totalMember: {
		width: 150,
		height: 150,
		bgcolor: "#2B2A69",
		position: "relative",
	},
	newMember: {
		width: 110,
		height: 110,
		bgcolor: "#03989E",
		border: "2px solid #fff",
		position: "absolute",
		top: -10,
		right: 0,
		"& h3": {
			fontSize: "18px !important",
		},
	},
	traffic: {
		width: 80,
		height: 80,
		bgcolor: "#FF914D",
		border: "2px solid #fff",
		position: "absolute",
		bottom: -15,
		right: 30,
		"& h3": {
			fontSize: "16px !important",
		},
	},
	colorWrapper: {
		display: "flex",
		flexDirection: "row",
		columnGap: "5px",
		alignItems: "center",
		"& p": {
			fontSize: 12,
			color: "#333",
		},
	},
	color: {
		width: 10,
		height: 10,
		borderRadius: "50%",
	},
	blue: {
		bgcolor: "#293277",
	},
	sky: {
		bgcolor: "#45BABC",
	},
	orange: {
		bgcolor: "#FF914D",
	},
};

function MembersCard({ members }) {
	const formatNumberWithCommas = (number) => {
		return number?.toLocaleString();
	};

	return (
		<>
			<Box sx={styles.wrapper}>
				<Typography component="h2">Members</Typography>
				<Box sx={styles.circleWrapper}>
					<Box sx={[styles.totalMember, styles.circle]}>
						<Typography component="p">Total Members</Typography>
						{members?.total ? (
							<Typography component="h3">
								{formatNumberWithCommas(members?.total)}
							</Typography>
						) : (
							<Skeleton
								animation="wave"
								variant="text"
								width={50}
								height={35}
							/>
						)}
					</Box>
					<Box sx={[styles.newMember, styles.circle]}>
						<Typography component="p">New Members</Typography>
						{members?.new_member ? (
							<Typography component="h3">
								{formatNumberWithCommas(members?.new_member)}
							</Typography>
						) : (
							<Skeleton
								animation="wave"
								variant="text"
								width={50}
								height={35}
							/>
						)}
					</Box>
					<Box sx={[styles.traffic, styles.circle]}>
						<Typography component="p">Traffic</Typography>
						{members?.new_member ? (
							<Typography component="h3">5,000</Typography>
						) : (
							<Skeleton
								animation="wave"
								variant="text"
								width={50}
								height={35}
							/>
						)}
					</Box>
				</Box>
				<Stack direction="row" justifyContent="space-between" sx={{ pt: 2 }}>
					<Stack sx={styles.colorWrapper}>
						<Box sx={[styles.color, styles.blue]}></Box>
						<Typography component="p">Total Members</Typography>
					</Stack>
					<Stack sx={styles.colorWrapper}>
						<Box sx={[styles.color, styles.sky]}></Box>
						<Typography component="p">New Members</Typography>
					</Stack>
					<Stack sx={styles.colorWrapper}>
						<Box sx={[styles.color, styles.orange]}></Box>
						<Typography component="p">Traffic</Typography>
					</Stack>
				</Stack>
			</Box>
		</>
	);
}

export default MembersCard;
