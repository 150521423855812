import React, { useState } from "react";
import {
  Grid,
  Typography,
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import {
  ADMIN_GET_MEETUP_BY_ID,
  ADMIN_GET_MEETUP_COMMENT,
  ADMIN_DELETE_MEETUP_COMMENT,
} from "../data/constants/apiRoutes";
import { get, destroy } from "../server";
import BoxModal from "../components/Modals/BoxModal";
import PageHeader from "../components/ui/PageHeader";
import queryNames from "../data/constants/queryNames";
import Listing from "../components/ui/Listing";
import GoBackButton from "../components/Buttons/GoBackButton";
import CommentBoxModal from "../components/Modals/CommentBoxModal";
import styles from "../assets/styles/events/eventDetails.styles";

//SVG
// import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";
import LoadingOverlay from "../components/overlay/LoadingOverlay";

const MeetupDetailPage = () => {
  //USESTATE
  const [meetupData, setMeetupData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [descriptionPopupVisible, setDescriptionPopupVisible] = useState(false);
  const [descriptionPopupData, setDescriptionPopupData] = useState("");

  //CLIENT AND MEETUPID
  const { meetupId } = useParams();
  const client = useQueryClient();
  const navigate = useNavigate();

  //COLUMNS FOR GRID
  const columns = [
    {
      field: "full_name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.owner?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.owner?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.owner?.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Comment",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const handleDescriptionClick = () => {
          setDescriptionPopupData(params.row.description);
          setDescriptionPopupVisible(true);
        };

        return (
          <div style={{ cursor: "pointer" }} onClick={handleDescriptionClick}>
            {params.row.description}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.owner?.email,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setCommentId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        {isReadMore ? text?.slice(0, 250) : text}
        {!isReadMore && (
          <div onClick={toggleReadMore} className="read">
            {isReadMore ? "Read more" : " Read less"}
          </div>
        )}
      </>
    );
  };

  //PROFILE DETAIL SCREEN
  const handleProfileClick = (data) => {
    let id;
    if(data?.row?.user_id && data?.field === "full_name"){
      id = data?.row?.user_id;
      navigate(`/profile/details/${id}`);
    } else if(data?.user_id){
      id = data?.user_id;
      navigate(`/profile/details/${id}`);
    }
  };

  //GET DATA OF POST
  const fetchMeetupDetail = async () => {
    const { status, data, message } = await get(
      `${ADMIN_GET_MEETUP_BY_ID}/${meetupId}`
    );
    if (status === true) {
      setMeetupData(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const MeetupDetailQuery = useQuery([queryNames.POSTS], fetchMeetupDetail, {
    keepPreviousData: true,
  });

  //HANDLE INVALIDE
  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.MEETUPS);
  };

  //DELETE MODAL
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setCommentId(null);
  };

  //CALLING METHOD TO GET MEETUP COMMENT
  const fetchComments = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_MEETUP_COMMENT}/${meetupId}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //DELETE COMMENT API
  const deleteComment = async () => {
    const { status, message } = await destroy(
      `${ADMIN_DELETE_MEETUP_COMMENT}/${commentId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  // MUTATION AND ITS METHODS
  const mutationDelete = useMutation(deleteComment);

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  //RETURN
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="Meetup Detail" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <LoadingOverlay
          loading={MeetupDetailQuery.isLoading || MeetupDetailQuery.isFetching}
        />
        <Box sx={styles.wrapper}>
          <Grid container>
            <Grid md={12} item sx={styles.leftWrapper}>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={styles.header}
              >
                <Stack direction="row" columnGap={1.5} alignItems="center">
                  <Box>
                    <Typography component="p">Meetup Title</Typography>
                    <Typography component="h3">
                      {meetupData?.meetup_title}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" columnGap={1.5} alignItems="center">
                  <Box>
                    <Typography component="p">Meetup Address</Typography>
                    <Typography component="h3">
                      {meetupData?.meetup_address}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Box sx={styles.detail}>
                <Typography component="h2">Description</Typography>
                <Typography component="p">
                  <ReadMore> {meetupData?.description}</ReadMore>
                </Typography>

                <Typography component="h6">
                  {moment(meetupData?.created_at).format("D-MMM-YYYY | h:mm A")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={[styles.wrapper, { mt: 3, padding: 1 }]}>
          <Typography
            variant="h4"
            sx={{
              fontSize: "22px",
              fontWeight: 600,
              color: "#1E1E1F",
              marginTop: 2,
              marginLeft: 2,
            }}
          >
            Member List
          </Typography>
          {meetupData?.members?.map((member, index) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                sx={[styles.header, { ml: 4, cursor: "pointer" }]}
                onClick={()=> handleProfileClick(member)}
              >
                <Avatar
                src={member?.memberprofile?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {member?.memberprofile?.full_name.charAt(0).toUpperCase()}
              </Avatar>
                <Stack direction="row" alignItems="center">
                  <Box>
                    <Typography component="p">
                      {member?.memberprofile?.full_name}
                    </Typography>
                    <Typography component="p">
                      {member?.memberprofile?.email}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            );
          })}
        </Box>
      </Box>

      <Listing
        columns={columns}
        queryName={queryNames.MEETUPS}
        handleCellClick={handleProfileClick}
        fetchRecords={fetchComments}
        className="meetups"
      />

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this meetup comment"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />

      {descriptionPopupVisible && (
        <CommentBoxModal
          header="Comment"
          title={descriptionPopupData}
          open={descriptionPopupVisible}
          handleClose={() => setDescriptionPopupVisible(false)}
        />
      )}
    </>
  );
};

export default MeetupDetailPage;
