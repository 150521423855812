import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Grid, MenuItem } from "@mui/material";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";
import { useMutation, useQuery } from "react-query";
import { validator } from "../../utils/helpers/validator";
import PromoCodeSchema from "../../utils/schemas/PromoCodeSchema";
import { get, post } from "../../server";
import {
	ADMIN_PROMO_CODE_ADD,
	ADMIN_PROMO_CODE_DETAIL,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import SelectInput from "../../components/Forms/SelectInput";
import LoadingButton from "../../components/Buttons/LoadingButton";
import queryNames from "../../data/constants/queryNames";
import LoadingOverlay from "../overlay/LoadingOverlay";

const styles = {
	textTransform: "capitalize",
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F9F9F9",
		borderRadius: "6px",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #E2E3E4",
	},
	"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "#2B2A69 !important",
		borderWidth: "1px !important",
	},

	"& input": {
		py: "13px",
		px: 2,
		"&::placeholder": {
			color: "#767D90",
			fontWeight: 400,
			opacity: 1,
			fontSize: 14,
		},
	},
	select: {
		"& .MuiOutlinedInput-input": {
			py: "13px",
			px: 2,
		},
	},
	modal: {
		position: "absolute",
		bgcolor: "#fff",
		top: 0,
		right: 0,
		maxWidth: 800,
		width: "100%",
		height: "100%",
		overflowY: "auto",
		transition: "all 0.3s ease",
		p: 6,
		"& h1": {
			fontSize: 22,
			color: "#1E1E1F",
			pb: 3,
			fontWeight: 600,
		},
		"& h3": {
			fontSize: 16,
			color: "#313131",
			fontWeight: 600,
			pt: 5,
			pb: 3,
		},
		"& input": {
			maxWidth: "320px",
		},
	},
	subHeader: {
		color: "#767D90",
		marginBottom: "7px",
	},
	doneBtn: {
		bgcolor: "#2B2A69",
		color: "#FFF",
		py: 1.5,
		px: 4,
		textTransform: "capitalize",
		mt: 5,
		"&:hover": { bgcolor: "#2B2A69" },
	},
};

const types = ["fixed", "percentage"];
// const types = [
// 	{ id: "fixed", name: "fixed" },
// 	{ id: "percentage", name: "percentage" },
// ];
const duration = ["forever", "once", "repeating"];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const currencies = [
	{ name: "US Dollar", code: "usd" },
	{ name: "EURO", code: "eur" },
	{ name: "UAE Dirham", code: "aed" },
	{ name: "British Pound Sterling", code: "gbp" },
];
const forUser = ["all", "members"];

const initialState = {
	duration_in_months: "",
	name: "",
	type: "",
	currency: "usd",
	amount_off: null,
	percent_off: null,
	discount: "",
	for: "",
};

export default function PromoCodeDetails({
	modal,
	setModal,
	promoCodeId = null,
}) {
	const [details, setDetails] = useState(initialState);

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const isId = promoCodeId !== null && typeof promoCodeId !== "undefined";

	const validateForm = () => {
		let newErrors = validator(details, PromoCodeSchema);
		setErrors(newErrors);
		if (details.name === " ") {
			setErrors({ ...newErrors, name: "Promo Code Name is required" });
		}
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleClose = () => {
		setModal(false);
	};

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;

		value = value.replace(/\s{2,}/g, " ");

		if (name === "percent_off") {
			let newValue = parseFloat(value);
			if (isNaN(newValue)) {
				setDetails({ ...details, [name]: "" });
			} else {
				newValue = Math.min(Math.floor(newValue), 100);
				setDetails({ ...details, [name]: newValue });
			}
		} else if (name === "amount_off") {
			setDetails({ ...details, [name]: value, percent_off: null });
		} else {
			setDetails({ ...details, [name]: value });
		}
	};

	const fetchPromoCode = async () => {
		const { status, message, data } = await get(
			`${ADMIN_PROMO_CODE_DETAIL}/${promoCodeId}`
		);
		if (status === true) {
			const type = data.amount_off ? "fixed" : "percentage";
			setDetails({ ...data, type: type, for: data.metadata.for });
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const PromoCodeQuery = useQuery(
		[queryNames.PROMO_CODE, promoCodeId],
		fetchPromoCode,
		{ enabled: isId && modal, refetchOnWindowFocus: false }
	);

	const handleSavePromoCode = async () => {
		const { status, message, data } = await post(ADMIN_PROMO_CODE_ADD, details);
		if (status === true) {
			enqueueSnackbar(
				isId
					? "Promo Code updated Susccessfully"
					: "Promo Code created Susccessfully",
				{ variant: "success" }
			);
			setModal(false);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const mutation = useMutation(handleSavePromoCode);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	useEffect(() => {
		if (modal === false) {
			setDetails(initialState);
			setErrors({});
		}
	}, [modal]);

	useEffect(() => {
		if (details?.duration !== "repeating") {
			setDetails({ ...details, duration_in_months: null });
		}
	}, [details?.duration]);

	return (
		<>
			<Modal open={modal} onClose={handleClose} sx={styles}>
				<Box sx={styles.modal} role="presentation">
					<Close className="closeIcon" onClick={handleClose} />
					<Typography component="h1">Promo Code Details</Typography>
					<LoadingOverlay loading={PromoCodeQuery.isLoading} />

					<Grid
						container
						rowSpacing={2}
						direction="row"
						spacing={3}
						columnSpacing={3}
					>
						<Grid item xs={6}>
							<FormInput
								type="string"
								label="Promo Code Name"
								placeholder="Promo Code Name"
								value={details.name}
								name="name"
								onChange={handleChange}
								inputType="small"
								error={errors?.name}
								helperText={errors?.name || ""}
							/>
						</Grid>
					</Grid>
					<Typography component="h3">Discount Configuration</Typography>
					<Grid
						container
						rowSpacing={2}
						direction="row"
						spacing={3}
						columnSpacing={3}
					>
						<Grid item xs={6}>
							<SelectInput
								label="Type"
								onChange={handleChange}
								name="type"
								value={details.type}
								disabled={isId}
								renderValue={(selected) => {
									if (selected?.length === 0) {
										return <span>Select Discount Type</span>;
									}
									const found = types.find((single) => single === selected);

									return found;
								}}
								displayEmpty
								inputType="small"
								error={errors?.type}
								helperText={errors?.type || ""}
							>
								{types.map((type, key) => (
									<MenuItem
										value={type}
										key={key}
										sx={{ textTransform: "capitalize" }}
									>
										{type}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>

						{details.type === "fixed" ? (
							<>
								<Grid item xs={6}>
									{/* <SelectInput
										label="Currency"
										onChange={handleChange}
										name="currency"
										value={details.currency}
										disabled={isId}
										renderValue={(selected) => {
											if (selected?.length === 0) {
												return <span>Select Currency</span>;
											}
											const found = currencies.find(
												(single) => single.code === selected
											);

											// return `${found.name} - ${found.code}`;
											return (
												<Typography>
													{found.name} -{" "}
													<Typography
														textTransform="uppercase"
														display="inline-block"
													>
														{found.code}
													</Typography>
												</Typography>
											);
										}}
										displayEmpty
										inputType="small"
										error={errors?.currency}
										helperText={errors?.currency || ""}
									>
										{currencies.map((value, key) => (
											<MenuItem
												key={key}
												value={value.code}
												sx={{ textTransform: "capitalize" }}
											>
												<Typography>
													{value.name} -{" "}
													<Typography
														textTransform="uppercase"
														display="inline-block"
													>
														{value.code}
													</Typography>
												</Typography>
											</MenuItem>
										))}
									</SelectInput> */}
									<FormInput
										label="Currency"
										disabled
										value="Us Dollar - USD"
										inputType="small"
									/>
								</Grid>

								<Grid item xs={6}>
									<FormInput
										type="number"
										label="Amount Off"
										placeholder="Amount Off"
										value={details.amount_off}
										name="amount_off"
										onChange={handleChange}
										inputType="small"
										inputProps={{ min: 0, max: 9999 }}
										disabled={isId}
										error={errors?.amount_off}
										helperText={errors?.amount_off || ""}
									/>
								</Grid>
							</>
						) : (
							details.type === "percentage" && (
								<Grid item xs={6}>
									<FormInput
										type="number"
										label="Percentage Off"
										placeholder="Percentage Off"
										value={details.percent_off}
										name="percent_off"
										onChange={handleChange}
										inputType="small"
										disabled={isId}
										inputProps={{ min: 0, max: 100 }}
										error={errors?.percent_off}
										helperText={errors?.percent_off || ""}
									/>
								</Grid>
							)
						)}

						<Grid item xs={6}>
							<SelectInput
								label="Promo Code Duration"
								onChange={handleChange}
								name="duration"
								inputType="small"
								value={details.duration || ""}
								disabled={isId}
								renderValue={(selected) => {
									if (selected?.length === 0) {
										return <span>Select Duration</span>;
									}
									const found = duration.find((single) => single === selected);

									return found;
								}}
								displayEmpty
								error={errors?.duration}
								helperText={errors?.duration || ""}
							>
								{duration.map((value, key) => (
									<MenuItem
										key={key}
										value={value}
										sx={{ textTransform: "capitalize" }}
									>
										{value}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>

						{details.duration === "repeating" && (
							<Grid item xs={6}>
								<SelectInput
									label="Duration in Months"
									onChange={handleChange}
									name="duration_in_months"
									inputType="small"
									value={details.duration_in_months}
									disabled={isId}
									renderValue={(selected) => {
										if (selected?.length === 0) {
											return <span>Select Duration</span>;
										}
										const found = months.find((single) => single == selected);

										return found;
									}}
									displayEmpty
									error={errors?.duration_in_months}
									helperText={errors?.duration_in_months || ""}
								>
									{months.map((value, key) => (
										<MenuItem
											key={key}
											sx={{ textTransform: "capitalize" }}
											value={value}
										>
											{value}
										</MenuItem>
									))}
								</SelectInput>
							</Grid>
						)}

						<Grid item xs={6}>
							<SelectInput
								label="Promo Code For"
								onChange={handleChange}
								name="for"
								inputType="small"
								value={details.for}
								renderValue={(selected) => {
									if (selected?.length === 0) {
										return <span>Select Promo Code For</span>;
									}
									const found = forUser.find((single) => single === selected);

									return found;
								}}
								displayEmpty
								error={errors?.for}
								helperText={errors?.for || ""}
							>
								{forUser.map((value, key) => (
									<MenuItem
										key={key}
										value={value}
										sx={{ textTransform: "capitalize" }}
									>
										{value}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>
					</Grid>

					<LoadingButton
						variant="contained"
						sx={styles.doneBtn}
						onClick={handleSubmit}
						disableElevation
						isLoading={mutation.isLoading}
					>
						Done
					</LoadingButton>
				</Box>
			</Modal>
		</>
	);
}
