import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		p: 3,
		mb: "10px",
		position: "relative",
		overflow: "hidden",
		"& h2": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: { md: 24, xs: 20 },
		},
		"& p": {
			textTransform: "capitalize",
			color: "#767D90",
		},
	},
};

function StatsCard({ price, title, bgImage }) {
	const formatNumberWithCommas = (number) => {
		return number?.toLocaleString();
	};
	return (
		<>
			<Box sx={styles.wrapper}>
				{price ? (
					<Typography component="h2">
						${formatNumberWithCommas(price)}
					</Typography>
				) : (
					<Skeleton animation="wave" variant="text" width={150} height={35} />
				)}
				<Typography component="p">{title}</Typography>
				<Box
					sx={{
						background: `url(${bgImage})`,
						backgroundPosition: "center center",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						width: 132,
						height: 77,
						position: "absolute",
						bottom: -3,
						right: -1,
						borderRadius: "12px",
					}}
				></Box>
			</Box>
		</>
	);
}

export default StatsCard;
