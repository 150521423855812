import {
	Typography,
	Box,
	Stack,
	FormControl,
	Autocomplete,
	TextField,
	CircularProgress,
} from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		position: "relative",
		p: { md: 3, xs: 2 },
		"& .MuiOutlinedInput-root": {
			backgroundColor: "#F9F9F9",
			borderRadius: "6px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E2E3E4",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},

		"& input": {
			py: "13px",
			px: 2,
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	header: {
		pb: 1,
		"& h3": {
			color: "#1E1E1F",
			fontSize: 18,
			fontWeight: 600,
		},
	},
	select: {
		width: 200,
		height: 40,
		pr: 1.25,
		pl: 2.25,
		"& .MuiOutlinedInput-input": {
			py: 1.25,
			px: 2,
		},
	},
	tooltip: {
		bgcolor: "red",
		py: 0.875,
		px: 3.25,
		"& p": {
			color: "#767D90",
			fontSize: 11,
		},
		"& h2": {
			color: "#293277",
			fontSize: 18,
		},
	},
	loaderContainer: {
		width: "100%",
		height: "100%",
		position: "absolute",
		left: 0,
		top: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 9999,
		bgcolor: "rgba(255, 255, 255, 0.75)",
	},
	loader: {
		height: "47px !important",
		width: "47px !important",
	},
};

function EventsStats({
	events = [],
	eventId,
	setEventId,
	isLoadingEvents,
	eventStats,
	isLoadingEventStats,
}) {
	const xAxis = eventStats?.ticket_title;
	const yAxis = eventStats?.total_price;

	const Series = [
		{
			color: "#293277",
			width: "10px",
			data: yAxis,
		},
		// {
		// 	name: "nothing",
		// 	color: "#E5D442",
		// 	data: [25, 45, 20, 20, 30, 50, 45, 50, 40, 67, 45, 25],
		// },
	];

	const options = {
		chart: {
			height: 300,
			type: "area",
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
			width: 2,
		},
		xaxis: {
			type: "month",
			categories: xAxis,
			tooltip: {
				enabled: false,
			},
		},
		tooltip: {
			shared: false,
			intersect: false,
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				return renderToString(
					<div className="courseGraphContainer">
						<p className="courseMonth">
							{w.globals.categoryLabels[dataPointIndex]}
						</p>
						<h3 className="courseMonthValue">
							${series[seriesIndex][dataPointIndex]}
						</h3>
					</div>
				);
			},
		},
		legend: {
			show: false,
		},
		fill: {
			opacity: 1,
			gradient: {
				type: "vertical",
				shadeIntensity: 0,
				opacityFrom: 0.6,
				opacityTo: 0,
				stops: [0, 100],
			},
		},
	};

	const handleEventChange = (event, newValue) => {
		if (newValue) {
			setEventId(newValue.id);
		} else setEventId(null);
	};

	return (
		<>
			<Box sx={styles.wrapper}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={styles.header}
				>
					<Typography component="h3" flex={1}>
						Events
					</Typography>
					<FormControl variant="outlined" fullWidth sx={styles.select}>
						<Autocomplete
							renderInput={(params) => (
								<TextField {...params} placeholder="Events" />
							)}
							disableClearable
							size="small"
							options={events}
							value={events.find((event) => event.id === eventId) || ""}
							onChange={handleEventChange}
							loading={isLoadingEvents}
							isOptionEqualToValue={(option, value) => option?.id === value?.id}
						/>
					</FormControl>
				</Stack>

				{isLoadingEventStats && (
					<Box sx={styles.loaderContainer}>
						<CircularProgress sx={styles.loader} />
					</Box>
				)}
				
				<ReactApexChart
					options={options}
					series={Series}
					type="area"
					height={300}
				/>
			</Box>
		</>
	);
}

export default EventsStats;
