import React, { useState } from "react";
import {
	DataGrid,
	gridClasses,
	GridPagination,
	useGridApiContext,
	useGridSelector,
	gridPageSizeSelector,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
const MemoizedCustomPagination = React.memo(CustomPagination);

const styles = {
	backgroundColor: "#fff",
	borderRadius: "12px",
	color: "#333",
	fontFamily: "'Rubik Variable', sans-serif",

	[`& .${gridClasses.row}.even`]: {
		backgroundColor: "#FCFCFC",
		"&:hover, &.Mui-hovered": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
		"&.Mui-selected": {
			backgroundColor: "#FCFCFC",
			"&:hover, &.Mui-hovered": {
				backgroundColor: "rgba(0, 0, 0, 0.04)",
			},
		},
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		color: "#767D90",
	},
	"&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
		borderColor: "#F1F1F1",
	},
	"& .MuiDataGrid-footerContainer": {
		justifyContent: "center",
	},
	"& .MuiTablePagination-displayedRows": {
		display: "none",
	},
	"& .MuiDataGrid-virtualScroller": {
		minHeight: 100,
	},
};

function Pagination({ page, onPageChange, className, count }) {
	const apiRef = useGridApiContext();
	const limit = useGridSelector(apiRef, gridPageSizeSelector);

	return (
		<MuiPagination
			className={className}
			page={page + 1}
			count={Math.ceil(count / limit)}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

function CustomPagination(props) {
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default function EventDetailListings({
	rows,
	columns,
	className,
	handleCellClick,
	loading = false
}) {
	const [paginationModel, setPaginationModel] = useState({
		limit: 10,
		page: 0,
	});

	return (
		<div style={{ width: "100%" }}>
			<DataGrid
				rows={rows || []}
				columns={columns}
				sx={styles}
				disableRowSelectionOnClick
				className={className}
				disableColumnMenu
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				slots={{
					pagination: MemoizedCustomPagination,
					// noRowsOverlay: CustomNoRowsOverlay,
				}}
				slotProps={{
					pagination: {
						page: paginationModel.page,
						onPageChange: (event, newPage) => {
							setPaginationModel({ ...paginationModel, page: newPage });
						},
						// count: data?.totalCount || 0,
					},
				}}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
				}
				onCellClick={handleCellClick}
				hideFooter
				loading={loading}
			/>
		</div>
	);
}
