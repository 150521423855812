import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import Listing from "../../components/ui/Listing";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL } from "../../data/constants/apiRoutes";

import ProfilePage from "../ProfilePage";


const TicketSessionDetail = () => {
	const [session, setSession] = useState(null);

	const { ticketId } = useParams();
	const userData = useLocation();
  	const receivedData = userData?.state?.purchase_tickets.find(ticket => ticket.id === Number(ticketId));
	const isId = ticketId !== null && typeof ticketId !== "undefined";

	const columns = [
		{
			field: "ticket_type",
			headerName: "Ticket Type",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "session_type",
			headerName: "Session Type",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "quantity",
			headerName: "Quantity",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.quantity || "-",
		},
	];

	const fetchTicketDetail = async (pageNo, limit) => {
        let url = new URL(`${ADMIN_EVENT_TICKET_SESSION_SALE_DETAIL}/${ticketId}`);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	}

	return (
		<>
			<ProfilePage  id={receivedData.user_id}/>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<PageHeader
					title={`Session Details`}
				/>
			</Stack>

			<Listing
				columns={columns}
				type="courses"
				fetchRecords={fetchTicketDetail}
                queryName={queryNames.TICKET_SESSION}
			/>
		</>
	);
};

export default TicketSessionDetail;
