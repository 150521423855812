import { useState, useEffect } from "react";
// import CustomInputField from "./CustomInputField";
import FormInput from "./FormInput";

import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	CircularProgress,
	InputAdornment,
	Tooltip,
	IconButton,
} from "@mui/material";

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import styles from "../../assets/styles/forms/locationAutoComplete.styles";
// import useGoogleMapsApi from "../../utils/helpers/useGoogleMapsApi";
// import { useJsApiLoader } from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { setDefaults, geocode, RequestType } from "react-geocode";
import useGoogleMapsApi from "../../utils/helpers/useGoogleMapsApi";

const LocationAutoComplete = ({
	label,
	placeholder,
	locationType,
	handleAddressChange,
	address,
	setAddress,
	selectedLocation,
	setSelectedLocation,
	error,
	helperText,
	isFilledInput,
	types,
}) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const isLoaded = useGoogleMapsApi();

	const handleChange = (newAddress) => {
		setOpen(true);
		setAddress(newAddress);

		if (locationType === "address" && newAddress.length === 0) {
			handleAddressChange(newAddress);
		}
	};

	const getCityAndStateAndCountry = async () => {
		setLoading(true);
		geocodeByAddress(address)
			.then((results) => {
				return getLatLng(results[0]);
			})
			.then((latLng) => {
				const addressObj = {
					address: address,
					lat: latLng?.lat || null,
					long: latLng?.lng || null,
				};

				handleAddressChange(addressObj);
			})
			.catch((error) => {
				console.error("Error in getting lat and lng", error);
			});
		setLoading(false);
	};

	useEffect(() => {
		if (selectedLocation) {
			getCityAndStateAndCountry();
		}
	}, [selectedLocation]);

	setDefaults({
		key: process.env.REACT_APP_GOOGLE_MAPS_API,
		language: "en",
		region: "es",
	});

	const handleCenterMap = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				reverseGeocode(latitude, longitude);
			},
			(error) => {
				console.error("Error getting geolocation:", error);
			}
		);
	};

	const reverseGeocode = (lat, lng) => {
		geocode(RequestType.LATLNG, `${lat},${lng}`, {
			location_type: "ROOFTOP",
			enable_address_descriptor: true,
		})
			.then(({ results }) => {
				const formattedAddress = results[0].formatted_address;
				setAddress(formattedAddress);
				handleAddressChange({ address: formattedAddress, lat: lat, long: lng });
			})
			.catch((e) => console.log(e));
	};

	const renderInput = ({
		getInputProps,
		getSuggestionItemProps,
		suggestions,
	}) => {
		return (
			<>
				<Box sx={styles.inputWrapper}>
					<FormInput
						{...getInputProps({
							disabled:
								!locationType || locationType === "country" ? true : false,
							withasterisk: true,
							label: label ? label : null,
							type: "text",
							placeholder: placeholder ? placeholder : "Enter Location",
							error: error,
							helperText: helperText,
							isFilledInput: isFilledInput,
							InputProps: {
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title="Get Current Location">
											<IconButton>
												<MyLocationIcon
													onClick={handleCenterMap}
													sx={{
														color: "#2B2A69",
													}}
												/>
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							},
							sx: styles.input
						})}
					/>
					{open && (
						<List sx={styles.list} disablePadding>
							{suggestions?.map((suggestion) => {
								// Add a style of "suggestion" to the suggested locations
								return (
									<ListItemButton
										{...getSuggestionItemProps(suggestion)}
										key={suggestion.placeId}
										onClick={() => {
											setSelectedLocation(suggestion);

											setAddress(suggestion.description);

											setOpen(false);
										}}
									>
										<ListItem disablePadding>
											<ListItemText>{suggestion.description}</ListItemText>
										</ListItem>
									</ListItemButton>
								);
							})}
						</List>
					)}
				</Box>
			</>
		);
	};

	const searchOptions = {
		types: types,
	};
	return (
		<>
			{isLoaded && (
				<Box sx={{ width: "100%" }}>
					<PlacesAutocomplete
						value={address}
						onChange={handleChange}
						searchOptions={searchOptions}
					>
						{renderInput}
					</PlacesAutocomplete>

					{locationType === "address" && loading && (
						<Box sx={[styles.loaderWrapper]} mt={loading ? 2 : 0}>
							<CircularProgress sx={styles.loader} />
						</Box>
					)}
				</Box>
			)}
		</>
	);
};

export default LocationAutoComplete;
