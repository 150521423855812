import React, { useState } from "react";
import PageHeader from "../../components/ui/PageHeader";
import Listing from "../../components/ui/Listing";
import { destroy, get } from "../../server";
import {
	ADMIN_SCHEDULE_EVENTS_LISTING,
	ADMIN_EVENTS_LISTING_SEARCH,
	ADMIN_EVENT_DRAFT_CHECK,
	ADMIN_EVENT_DELETE,
} from "../../data/constants/apiRoutes";
import queryNames from "../../data/constants/queryNames";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import { useMutation, useQueryClient } from "react-query";
import BoxModal from "../../components/Modals/BoxModal";
import { enqueueSnackbar } from "notistack";

const ScheduledEvents = () => {
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [finalSearch, setFinalSearch] = useState("");
	const [isSearch, setIsSearch] = useState(false);
	const [modal, setModal] = useState(false);
	const [draft, setDraft] = useState({});
	const [eventId, setEventId] = useState("");

	const client = useQueryClient();

	const columns = [
		{
			field: "event_name",
			headerName: "Event Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						component="div"
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.event_name}
					</Box>
				);
			},
		},
		{
			field: "is_featured",
			headerName: "Status",
			sortable: false,
			width: 150,
			renderCell: (params) => {
				return (
					<Box
						sx={{
							bgcolor: params.row.is_featured ? "#E3F2F3" : "#FCF9EC",
							color: params.row.is_featured ? "#03989E" : "#F2D048",
							textTransform: "capitalize",
							width: "auto",
							px: 1,
							height: 30,
							fontSize: 12,
							lineHeight: "30px",
							textAlign: "center",
							borderRadius: "4px",
						}}
					>
						{params.row.is_featured ? "Featured" : "Not-Featured"}
					</Box>
				);
			},
		},
		{
			field: "company_name",
			headerName: "Company Name",
			sortable: false,
			flex: 1,
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex: 1,
		},
		{
			field: "event_status",
			headerName: "Event Status",
			sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Typography component="label" textTransform="capitalize">
						{params.row.event_status}
					</Typography>
				);
			},
		},
		{
			field: "start_time",
			headerName: "Start Time",
			sortable: false,
			width: 180,
		},
		{
			field: "end_time",
			headerName: "End Time",
			sortable: false,
			width: 180,
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={2}>
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => navigate(`/events/edit/${params.row.id}`)}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						{/* <Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setEventId(params.row.id);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip> */}
					</Stack>
				);
			},
		},
	];

	const fetchEvents = async (pageNo, limit, searchParam, isSearchParam) => {
		let url;

		if (isSearchParam === false) {
			url = new URL(ADMIN_SCHEDULE_EVENTS_LISTING);
		} else if (isSearchParam && searchParam.length) {
			url = new URL(ADMIN_EVENTS_LISTING_SEARCH);
			url.searchParams.append("search", searchParam);
			url.searchParams.append("type", "schedule");
		}

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleCellClick = (data) => {
		if (data?.field === "event_name") {
			navigate(`/events/detail/${data.id}`);
		}
	};

	const handleSearchClick = () => {
		if (search.length) {
			setIsSearch(true);
			setFinalSearch(search);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearch("");
	};

	const checkDraft = async () => {
		const { status, data } = await get(ADMIN_EVENT_DRAFT_CHECK);
		if (status) {
			if (data.is_draft) {
				setModal("draft");
				setDraft(data);
			} else {
				navigate("/events/create");
			}
		}
	};

	const mutation = useMutation(checkDraft);

	const handleCheckDraft = () => {
		mutation.mutate();
	};

	const handleCancel = async () => {
		const { status } = await destroy(`${ADMIN_EVENT_DELETE}/${draft.id}`);
		if (status) {
			setModal(false);
			enqueueSnackbar("Draft deleted successfully	", { variant: "success" });
			navigate("/events/create");
		}
	};

	const handleClose = () => {
		setModal(false);
		setEventId(null);
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_EVENT_DELETE}/${eventId}`
		);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
			handleInvalidate();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const deleteMutation = useMutation(handleDelete);

	const handleConfirm = () => {
		deleteMutation.mutate();
	};

	const handleInvalidate = () => {
		client.invalidateQueries(`s${queryNames.EVENTS}`);
	};

	return (
		<>
			<PageHeader
				title="Scheduled Events"
				placeholder="Search Events"
				searchInput={true}
				search={search}
				setSearch={setSearch}
				isSearch={isSearch}
				handleCancleSearch={handleCancleSearch}
				handleSearchClick={handleSearchClick}
				createBtn={true}
				createBtnText="Create Event"
				// url="/events/create"
				btnFnc={handleCheckDraft}
				isLoading={mutation.isLoading}
			/>

			<Listing
				columns={columns}
				queryName={`s${queryNames.EVENTS}`}
				fetchRecords={fetchEvents}
				handleCellClick={handleCellClick}
				search={finalSearch}
				isSearch={isSearch}
			/>

			<BoxModal
				// header="Are you sure?"
				header="Are you sure?"
				title={
					modal === "draft"
						? `You already have an event (${draft?.event_name}) in your draft. Do you want to..`
						: "Do you really want to delete this event"
				}
				open={modal}
				handleClose={handleClose}
				handleConfirm={
					modal === "draft"
						? () => navigate(`/events/edit/${draft.id}`)
						: handleConfirm
				}
				handleCancel={handleCancel}
				confirmButton={modal === "draft" ? "Continue" : "Delete"}
				cancelButton={modal === "draft" ? "Delete Draft" : "Cancel"}
				confirmButtonColor={modal === "draft" ? "#2daf40" : "#E53935"}
				// isLoading={mutation.isLoading}
			/>
		</>
	);
};

export default ScheduledEvents;
