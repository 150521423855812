import React from "react";
import { Typography, Stack, Box, Avatar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PageHeader from "../../components/ui/PageHeader";
import GoBackButton from "../../components/Buttons/GoBackButton";
import styles from "../../assets/styles/events/eventDetails.styles";

const EventMemberDetails = () => {
  //GETTING DATA FROM EVENTDETAIL PAGE
  const navigate = useNavigate();
  const data = useLocation();
  const memberdata = data?.state;

  //CALLING THE PROFILE DETAIL PAGE
  const handleProfileClick = (data) => {
    navigate(`/profile/details/${data.event_member.id}`);
  };

  //UI
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <PageHeader title="Event Member Detail" />
        <GoBackButton url={-1} />
      </Box>

      <Box sx={[styles.wrapper, { mt: 3, padding: 1 }]}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "22px",
            fontWeight: 600,
            color: "#1E1E1F",
            marginTop: 2,
            marginLeft: 2,
          }}
        >
          Member List
        </Typography>
        {memberdata?.map((member, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              sx={[styles.header, { ml: 4 }]}
              onClick={() => handleProfileClick(member)}
            >
              <Avatar
                src={member?.event_member?.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {member?.event_member?.full_name.charAt(0).toUpperCase()}
              </Avatar>
              <Stack spacing={2}>
                <Box sx={{ marginLeft: 2, cursor: "pointer" }}>
                  <Typography component="p" variant="body1">
                    {member?.event_member?.full_name}
                  </Typography>
                  <Typography component="p" variant="body1">
                    {member?.event_member?.email}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          );
        })}
      </Box>
    </>
  );
};

export default EventMemberDetails;
