import React, { useEffect, useState } from "react";
import {
	Modal,
	Box,
	IconButton,
	Typography,
	Button,
	Stack,
	Avatar,
	CircularProgress,
	InputAdornment,
	Tooltip,
	List,
	ListItemButton,
	ListItem,
	ListItemText,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import Location from "../../assets/svg/eventIcons/Location.svg";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useSnackbar } from "notistack";
import CircleIcon from "@mui/icons-material/Circle";
import { setDefaults, geocode, RequestType } from "react-geocode";
import useGoogleMapsApi from "../../utils/helpers/useGoogleMapsApi";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import FormInput from "../Forms/FormInput";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 600,
		// height: 400,
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 15,
		p: 2,
		"& h1": {
			fontSize: 18,
			fontWeight: 600,
			color: "#1E1E1F",
		},
		"& label": {
			fontSize: 14,
			color: "#767D90",
		},
	},
	closeIconBtnWrapper: {
		textAlign: "end",
	},
	closeIconBtn: {
		textAlign: "end",
		width: "40px !important",
		height: 40,
	},
	centerButton: {
		position: "absolute",
		top: "10px",
		left: "10px",
		zIndex: 1,
	},
	button: {
		mt: 2,
		bgcolor: "#2B2A69",
		color: "#FFF",
		fontWeight: 600,
		textTransform: "capitalize",
		px: 3,
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "#FFF",
		},
	},
};

const initialState = {
	city: "",
	state: "",
	country: "",
};

const GetAddressModal = ({ modal, setModal, saveLocation, currentAddress }) => {
	const [location, setLocation] = useState(null);
	const [map, setMap] = useState(null);
	const [fetchingLocation, setFetchingLocation] = useState(false);
	const [address, setAddress] = useState(currentAddress);
	const [place, setPlace] = useState(initialState);
	const [open, setOpen] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState(null);

	const { enqueueSnackbar } = useSnackbar();

	const isLoaded = useGoogleMapsApi();

	setDefaults({
		key: process.env.REACT_APP_GOOGLE_MAPS_API,
		language: "en",
		region: "es",
	});

	const handleClose = () => setModal(false);

	useEffect(() => {
		if (modal && isLoaded && !location && !fetchingLocation) {
			setFetchingLocation(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setFetchingLocation(false);
					const { latitude, longitude } = position.coords;
					setLocation({ latitude, longitude });
					reverseGeocode(latitude, longitude);
				},
				(error) => {
					setFetchingLocation(false);
					console.error("Error getting geolocation:", error);
					setLocation(null);
				}
			);
		}
	}, [modal, isLoaded, location, fetchingLocation]);

	const handleCenterMap = () => {
		if (map) {
			setFetchingLocation(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setFetchingLocation(false);
					const { latitude, longitude } = position.coords;
					setLocation({ latitude, longitude });
					reverseGeocode(latitude, longitude);
					map.panTo({ lat: latitude, lng: longitude });
				},
				(error) => {
					setFetchingLocation(false);
					console.error("Error getting geolocation:", error);
				}
			);
		}
	};

	const handleMapClick = (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();
		setLocation({ latitude: lat, longitude: lng });
		reverseGeocode(lat, lng);
		if (map) {
			map.panTo({ lat, lng });
		}
	};

	const reverseGeocode = (lat, lng) => {
		geocode(RequestType.LATLNG, `${lat},${lng}`, {
			location_type: "ROOFTOP",
			enable_address_descriptor: true,
		})
			.then(({ results }) => {
				const formattedAddress = results[0].formatted_address;
				const { city, state, country } = results[0].address_components.reduce(
					(acc, component) => {
						if (component.types.includes("locality"))
							acc.city = component.long_name;
						else if (component.types.includes("administrative_area_level_1"))
							acc.state = component.long_name;
						else if (component.types.includes("country"))
							acc.country = component.long_name;
						return acc;
					},
					{}
				);
				setPlace({
					city,
					state,
					country,
				});
				setAddress(formattedAddress);
				setSelectedAddress(formattedAddress);
			})
			.catch(console.error);
	};

	const getCityAndStateAndCountry = async () => {
		setLoading(true);
		geocodeByAddress(address)
			.then((results) => {
				return getLatLng(results[0]);
			})
			.then((latLng) => {
				const addressObj = {
					address: address,
					lat: latLng?.lat || null,
					long: latLng?.lng || null,
				};
				setLocation({ latitude: latLng.lat, longitude: latLng.lng });
				setSelectedAddress(address);
			})
			.catch((error) => {
				console.error("Error in getting lat and lng", error);
			});
		setLoading(false);
	};

	const handleSaveLocation = () => {
		if (location) {
			saveLocation(address, location, place);
			setModal(false);
			setAddress();
		} else enqueueSnackbar("No location", { variant: "error" });
	};

	const handleChange = (newAddress, selectedFromSuggestions) => {
		setOpen(true);
		setAddress(newAddress);
	};

	useEffect(() => {
		if (selectedLocation) {
			getCityAndStateAndCountry();
		}
	}, [selectedLocation]);

	const renderInput = ({
		getInputProps,
		getSuggestionItemProps,
		suggestions,
	}) => {
		return (
			<>
				<Box sx={styles.inputWrapper}>
					<FormInput
						{...getInputProps({
							withasterisk: true,
							label: "Address",
							type: "text",
							placeholder: "Enter Location",
							InputProps: {
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title="Get Current Location">
											<IconButton>
												<MyLocationIcon
													onClick={handleCenterMap}
													sx={{
														color: "#2B2A69",
													}}
												/>
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							},
						})}
					/>
					{open && (
						<List sx={styles.list} disablePadding>
							{suggestions?.map((suggestion) => {
								// Add a style of "suggestion" to the suggested locations
								return (
									<ListItemButton
										{...getSuggestionItemProps(suggestion)}
										key={suggestion.placeId}
										onClick={() => {
											setSelectedLocation(suggestion);
											setAddress(suggestion.description);
											setSelectedAddress(suggestion.description);
											setOpen(false);
										}}
									>
										<ListItem disablePadding>
											<ListItemText>{suggestion.description}</ListItemText>
										</ListItem>
									</ListItemButton>
								);
							})}
						</List>
					)}
				</Box>
			</>
		);
	};

	const searchOptions = {
		types: ["establishment"],
	};

	return (
		<Modal open={modal} onClose={handleClose}>
			<Box sx={styles.container}>
				<Box sx={styles.closeIconBtnWrapper}>
					<IconButton onClick={handleClose} sx={styles.closeIconBtn}>
						<Close />
					</IconButton>
				</Box>

				{isLoaded ? (
					<Box>
						<PlacesAutocomplete
							value={address}
							onChange={handleChange}
							searchOptions={searchOptions}
						>
							{renderInput}
						</PlacesAutocomplete>

						{loading && (
							<Box sx={[styles.loaderWrapper]} mt={loading ? 2 : 0}>
								<CircularProgress sx={styles.loader} />
							</Box>
						)}

						<Box position="relative" mt={1}>
							<GoogleMap
								id="google-map"
								mapContainerStyle={{ width: "100%", height: "300px" }}
								center={{
									lat: location ? location.latitude : 0,
									lng: location ? location.longitude : 0,
								}}
								zoom={16}
								options={{
									disableDefaultUI: true,
									zoomControl: false,
									clickableIcons: false,
								}}
								onClick={handleMapClick}
								onLoad={(map) => setMap(map)}
							>
								{location && (
									<OverlayView
										position={{
											lat: location.latitude,
											lng: location.longitude,
										}}
										mapPaneName={OverlayView.OVERLAY_LAYER}
									>
										<CircleIcon
											sx={{
												color: "#2B2A69",
											}}
										/>
									</OverlayView>
								)}
							</GoogleMap>
							<MyLocationIcon
								onClick={handleCenterMap}
								sx={{
									position: "absolute",
									right: 15,
									bottom: 15,
									color: "#2B2A69",
									cursor: "pointer",
								}}
							/>
						</Box>
					</Box>
				) : (
					<Typography>Loading Google Maps...</Typography>
				)}

				{address && (
					<Box mt={2}>
						<Typography component="h1">Location detail</Typography>
						<Stack direction="row" mt={1} gap={2} alignItems="center">
							<Avatar
								src={Location}
								sx={{
									bgcolor: "#eaebf1",
									"& img": { width: "unset", height: "unset" },
								}}
							/>
							<Typography component="label">{selectedAddress}</Typography>
						</Stack>
						<Box textAlign="center">
							<Button
								variant="container"
								sx={styles.button}
								onClick={handleSaveLocation}
							>
								Choose Location
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default GetAddressModal;
