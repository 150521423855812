import { Box, CircularProgress } from "@mui/material";
import styles from "../../assets/styles/overlay/loadingOverlay.styles";

const LoadingOverlay = ({ loading = false }) => {
	if (loading) {
		return (
			<Box sx={styles.container}>
				<CircularProgress sx={styles.loader} />
			</Box>
		);
	} else {
		return <></>;
	}
};

export default LoadingOverlay;
