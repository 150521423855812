import {
	Modal,
	Box,
	Stack,
	Typography,
	Button,
	IconButton,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import LoadingButton from "../Buttons/LoadingButton";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		minWidth: 300,
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 15,
		p: 2,
		"& button": {
			width: 120,
			boxShadow: "none",
			fontWeight: 600,
		},
	},
	iconBox: {
		width: 60,
		height: 60,
		borderRadius: "50%",
		textAlign: "center",
		border: "3px solid #E53935",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	crossIcon: {
		width: 40,
		height: 40,
		color: "#E53935",
	},
	doneIcon: {
		width: 40,
		height: 40,
		color: "green",
	},
	closeIconBtnWrapper: {
		textAlign: "end",
	},
	closeIconBtn: {
		textAlign: "end",
		width: "40px !important",
		height: 40,
	},
	headerText: {
		fontSize: 18,
		fontWeight: 600,
		flexGrow: 1,
		textAlign: "center",
		color: "#636363",
	},
	confirmTitle: {
		color: "#838383",
		marginInline: "10px",
		fontSize: 14,
		wordBreak: "break-word",
	},
	cancelBtn: {
		bgcolor: "#2B2A69",
		color: "#FFF",
		height: 40,
		textTransform: "capitalize",
		"&:hover": { bgcolor: "#2B2A69" },
	},
	btnsWrapper: {
		mt: 1,
		mb: 3,
	},
};

const BoxModal = ({
	open,
	handleClose,
	handleConfirm,
	header,
	title,
	confirmButton,
	confirmButtonColor,
	isLoading,
	data,
	cancelButton,
	cancelButtonColor,
	handleCancel,
}) => {
	return (
		<Modal open={open} aria-describedby="modal-modal-description">
			<Box sx={styles.container}>
				<Box sx={styles.closeIconBtnWrapper}>
					<IconButton onClick={handleClose} sx={styles.closeIconBtn}>
						<Close />
					</IconButton>
				</Box>

				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					gap={2}
				>
					<Box
						component="div"
						sx={{
							...(open === "confirm"
								? { borderColor: "green !important" }
								: {}),
							...styles.iconBox,
							...(data?.company_logo && {
								border: "1px solid #eee !important",
							}),
						}}
					>
						{open === "confirm" || open === "enable" ? (
							<Done sx={styles.doneIcon} />
						) : data?.company_logo ? (
							<Box
								component="img"
								src={data?.company_logo}
								width={60}
								height={60}
								sx={{ borderRadius: "50%" }}
							/>
						) : (
							<Close sx={styles.crossIcon} />
						)}
					</Box>
					<Typography sx={styles.headerText}>{header && header}</Typography>
				</Stack>
				<Typography
					id="modal-modal-description"
					py={!data?.company_logo ? 3.5 : 2}
					fontSize={17}
					textAlign="center"
					sx={{
						...styles.confirmTitle,
						...(data?.company_logo && { color: "#636363 !important" }),
					}}
				>
					{title}
				</Typography>
				<Stack
					direction="row"
					sx={styles.btnsWrapper}
					justifyContent="center"
					spacing={2}
					textAlign="justify"
				>
					<Button
						variant="contained"
						sx={{
							...styles.cancelBtn,
							bgcolor: cancelButtonColor ? cancelButtonColor : "#2B2A69",
							"&:hover": {
								bgcolor: cancelButtonColor ? cancelButtonColor : "#2B2A69",
							},
							color: cancelButtonColor ? "#000" : "#FFF",
						}}
						onClick={handleCancel ? handleCancel : handleClose}
					>
						{cancelButton ? cancelButton : "Cancel"}
					</Button>

					<LoadingButton
						variant="contained"
						sx={{
							height: 40,
							textTransform: "capitalize",
							bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
							"&:hover": {
								bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
							},
						}}
						onClick={handleConfirm}
						isLoading={isLoading}
					>
						{confirmButton}
					</LoadingButton>
				</Stack>
			</Box>
		</Modal>
	);
};

export default BoxModal;
