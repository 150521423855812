const styles = {
	container: {
		bgcolor: "#FFF",
		py: 4.5,
		px: "28px",
		borderRadius: "6px",
	},
	addButton: {
		width: 120,
		height: 44,
		bgcolor: "#2B2A69",
		fontWeight: 500,
		fontSize: 15,
		textTransform: "capitalize",
		px: 3,
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
	input: {
		width: 350,
		position: "relative",
		"& .MuiOutlinedInput-root": {
			backgroundColor: "transparent",
			minHeight: 40,
			borderRadius: 0,
			p: 0,
			"& fieldset": {
				border: "1px solid #EEE",
			},
			"&:hover fieldset": {
				borderColor: "#656DFC",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#656DFC",
			},
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #AEAEAE",
			borderRadius: "4px",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#656DFC",
			borderWidth: "1px !important",
		},
		"& input": {
			height: "30px",
			background: "rgba(226, 227, 228, 0.2)",
			border: "1px solid #E2E3E4",
			borderRadius: "4px",
			padding: "6px 10px",
			"&::placeholder": {
				color: "#B4B1B6",
				fontWeight: 400,
				opacity: 1,
				fontSize: 15,
			},
		},
	},
	interestContainer: {
		cursor: "pointer",
		position: "relative",
		bgcolor: "transparent",
		border: "1px solid var(--light-background-color-2, #E2E3E4)",
		borderRadius: "6px",
		py: "10px",
		px: "28px",
		"& label": {
			color: "#1E1E1F",
			fontWeight: 600,
			cursor: "pointer",
		},
		"& img": {
			position: "absolute",
			right: -6,
			top: -7,
			cursor: "pointer",
		},
	},
	interests: {
		mt: 4,
		display: "flex",
		flexWrap: "wrap",
		gap: "12px",
	},
	removeIcon: {
		position: "absolute",
		top: -6,
		right: -6,
		cursor: "pointer",
	}
};

export default styles;