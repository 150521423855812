import { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import { ADMIN_PROMO_CODE_LISTING } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";

const styles = {
	backgroundColor: "#fff",
	borderRadius: "12px",
	color: "#333",
	fontFamily: "'Rubik Variable', sans-serif",

	[`& .${gridClasses.row}.even`]: {
		backgroundColor: "#FCFCFC",
		"&:hover, &.Mui-hovered": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
		"&.Mui-selected": {
			backgroundColor: "#FCFCFC",
			"&:hover, &.Mui-hovered": {
				backgroundColor: "rgba(0, 0, 0, 0.04)",
			},
		},
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		color: "#767D90",
	},
	"&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
		borderColor: "#F1F1F1",
	},
	"& .MuiDataGrid-footerContainer": {
		justifyContent: "center",
	},
	"& .MuiTablePagination-displayedRows": {
		display: "none",
	},
	"& .MuiDataGrid-virtualScroller": {
		minHeight: 150,
	},
	pagination: {
		mt: 4,
		"& .MuiPagination-ul": {
			justifyContent: "center",
		},
		"& .MuiPaginationItem-root": {
			backgroundColor: "transparent", // Add background color to all page numbers
			border: "none",
			margin: "0 20px",
			color: "#000",
			fontSize: 17,
			"&:hover": {
				backgroundColor: "transparent",
				cursor: "default",
			},
		},
		"&  .MuiPaginationItem-previousNext": {
			width: 40,
			height: 40,
			backgroundColor: "#2B2A69",
			color: "#FFF",
			"&.Mui-disabled": {
				backgroundColor: "#FFF",
				color: "#B8B8B8",
				border: "1px solid #AEAEAE",
			},
			"& svg": {
				height: 25,
				width: 25,
			},
			"&:hover": {
				cursor: "pointer",
				backgroundColor: "#2B2A69",
				color: "#FFF",
			},
		},
		"& .MuiPaginationItem-page.Mui-selected": {
			color: "#293277",
		},
	},
};

function Pagination({ page, onPageChange, className, count, limit }) {
	return (
		<MuiPagination
			className={className}
			page={page + 1}
			count={Math.ceil(count / limit)}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
			variant="outlined"
			shape="rounded"
			sx={styles.pagination}
		/>
	);
}

export default function PromoCodeListing({
	columns,
	className,
	handleCellClick,
	fetchRecords,
	queryName,
}) {
	const [rows, setRows] = useState([]);
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
		first_coupon_id: "",
		last_coupon_id: "",
		direction: "",
	});

	const [totalCount, setTotalCount] = useState();

	const { enqueueSnackbar } = useSnackbar();

	const fetchData = async () => {
		const { status, message, data } = await fetchRecords(
			paginationModel.pageSize,
			paginationModel.direction,
			paginationModel.direction === "next"
				? paginationModel.last_coupon_id
				: paginationModel.first_coupon_id
		);
		if (status) {
			setRows(data);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const getTotalRows = async (limit = 20) => {
		let url = new URL(ADMIN_PROMO_CODE_LISTING);
		url.searchParams.append("limit", limit);
		const { status, message, data } = await get(url.toString());
		if (status) {
			if (data.data.has_more === true) await getTotalRows(limit + 10);
			else setTotalCount(data.data.data.length);
		}
	};

	const { isLoading } = useQuery([queryName, paginationModel.page], fetchData, {
		refetchOnWindowFocus: false,
	});

	const { isLoading: pageLoading } = useQuery(
		[queryNames.PROMO_CODE],
		() => getTotalRows(),
		{
			refetchOnWindowFocus: false,
		}
	);

	const rowData = rows?.data?.data;

	const handlePageChange = (event, newPage) => {
		const pageDifference = newPage - paginationModel.page;

		if (pageDifference === 1 || pageDifference === -1) {
			if (rows?.data?.has_more && newPage > paginationModel.page) {
				const lastItem = rowData[rowData.length - 1];
				setPaginationModel({
					...paginationModel,
					last_coupon_id: lastItem.id,
					direction: "next",
					page: newPage,
				});
			} else if (newPage < paginationModel.page) {
				setPaginationModel({
					...paginationModel,
					first_coupon_id: rowData[0].id,
					direction: "prev",
					page: newPage,
				});
			}
		}
	};

	return (
		<div style={{ width: "100%" }}>
			<DataGrid
				rows={rowData || []}
				columns={columns}
				sx={styles}
				pagination
				disableRowSelectionOnClick
				className={className}
				onPaginationChange={handlePageChange}
				disableColumnMenu
				hideFooter={true}
				loading={isLoading || pageLoading}
				slots={{
					noRowsOverlay: CustomNoRowsOverlay,
				}}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
				}
				slotProps={{
					pagination: {
						page: paginationModel.page,
						onPageChange: (event, newPage) => {
							setPaginationModel({ ...paginationModel, page: newPage });
						},
						count: totalCount || 0,
					},
				}}
				pageSizeOptions={[10]}
				onCellClick={handleCellClick}
			/>
			<Pagination
				page={paginationModel.page}
				onPageChange={handlePageChange}
				count={totalCount || 0}
				limit={paginationModel.pageSize}
			/>
		</div>
	);
}
