import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Message } from "../../assets/svg/Message.svg";
import moment from "moment/moment";

const styles = {
	wrapper: {
		overflowY: "auto",
		alignItems: "center",
		justifyContent: "space-between",
		borderBottom: "1px solid #F1F1F1",
		flexDirection: { md: "row", xs: "column" },
		// px: { md: 4, xs: 2 },
		// py: { md: 2.5, xs: 2 },
		p: 2,
		"& button": {
			height: 40,
			py: 1.25,
			fontWeight: 500,
			textTransform: "capitalize",
		},
		"& h3": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: 16,
		},
		"& h4": {
			color: "#1E1E1F",
			fontSize: 14,
			wordBreak: "break-word",
		},
		"& p": {
			color: "#ACACAC",
			fontSize: 12,
		},
	},
	iconWrapper: {
		width: 55,
		height: 55,
		minWidth: 55,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
		"& svg": {
			width: 22,
			height: 22,
		},
	},

	danger: {
		bgcolor: "#FFEEEE",
	},
	message: {
		bgcolor: "#F5F7FF",
	},
	logBtn: {
		bgcolor: "#2B2A69",
		color: "#FFFFF",
		"&:hover": {
			bgcolor: "#F2F2F2",
			color: "#1E1E1F",
		},
	},
	cancelBtn: {
		bgcolor: "#F2F2F2",
		color: "#1E1E1F",
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "white",
		},
	},
};

function NotificationRow({
	company = "",
	content = "",
	event = "",
	id = "",
	time = "",
	type = "",
	user = "",
	user_id = "",
	agenda = "",
}) {
	return (
		<>
			<Stack gap={2} sx={styles.wrapper}>
				<Stack direction="row" spacing={2}>
					<Box sx={[styles.iconWrapper, styles.message]}>
						<Message />
					</Box>
					{content === "New event" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">{content}</Typography>
							<Typography component="h4">
								{(company?.name ? company?.name + "  " : user?.name) +
									" created a new event: " +
									event?.name +
									"."}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : content === "Follow to" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">New Follower</Typography>
							<Typography component="h4">
								{user?.name + " started following " + company?.name + "."}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : content === "Purchase to" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">New Purchase</Typography>
							<Typography component="h4">
								{user?.name + " purchased " + event?.name + " ticket."}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : content === "Invite to" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">New Invite</Typography>
							<Typography component="h4">
								{user?.name +
									" has been invited" +
									(company?.name ? " to " + company?.name + " ." : ".")}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : content === "Approved" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">New Approval</Typography>
							<Typography component="h4">
								{"User " + user?.name + " has been approved."}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : content === "Like to" ? (
						<Box sx={styles.textWrapper}>
							<Typography component="h3">New Like</Typography>
							<Typography component="h4">
								{user?.name + " liked the agenda " + agenda?.name + "."}
							</Typography>
							<Typography component="p">{moment(time).fromNow()}</Typography>
						</Box>
					) : null}
				</Stack>
			</Stack>
		</>
	);
}

export default NotificationRow;
