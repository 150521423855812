import { FormControl, Select } from "@mui/material";
import styles from "../../assets/styles/forms/selectInput.styles";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "./FormLabel";

const SelectInput = ({
	label,
	error,
	helperText,
	children,
	isFilledInput,
	inputType = false,
	...other
}) => {
	const inputId = `input-${other.name}`;

	return (
		<>
			<FormLabel label={label} inputId={inputId} inputType={inputType} />
			<FormControl
				fullWidth
				error={error}
				sx={{
					...(error && {
						...styles.error,
					}),
				}}
			>
				<Select
					fullWidth
					{...other}
					sx={{ ...styles.select, ...(inputType && { height: 46 }) }}
				>
					{children}
				</Select>
				<FormHelperText sx={styles.helperText}>{helperText}</FormHelperText>
			</FormControl>
		</>
	);
};

export default SelectInput;
