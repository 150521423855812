import { useState } from "react";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import forgotImg from "../../assets/svg/ForgetPassword.svg";

import { ReactComponent as Lock } from "../../assets/svg/Lock.svg";
import { ReactComponent as Visibility } from "../../assets/svg/Visibility.svg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SplashForm from "../../components/ui/SplashForm";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { validator } from "../../utils/helpers/validator";
import { ResetPasswordSchema } from "../../utils/schemas/AuthSchema";
import { post } from "../../server";
import { ADMIN_RESET_PASSWORD } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import routes from "../../data/constants/routes";

const ResetPassword = () => {
	const [resetPasswordForm, setResetPasswordForm] = useState({
		password: "",
		password_confirmation: "",
	});

	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const validateForm = () => {
		let newErrors = validator(resetPasswordForm, ResetPasswordSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setResetPasswordForm({ ...resetPasswordForm, [name]: value });
	};

	const handleResetPassword = async () => {
		const { status, message, data } = await post(ADMIN_RESET_PASSWORD, {
			email: loggedUser.email,
			...resetPasswordForm,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.ADMIN_LOGIN);
		} else {
			enqueueSnackbar(data?.email[0] || data?.password[0], {
				variant: "error",
			});
		}
	};

	const mutation = useMutation(handleResetPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmaPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmaPassword((show) => !show);

	return (
		<>
			<SplashForm
				image={forgotImg}
				title="Create New Password"
				tagline="Enter your new strong password."
				btnText="Reset Password"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<TextField
					fullWidth
					variant="outlined"
					type={showPassword ? "text" : "password"}
					name="password"
					placeholder="Password"
					value={resetPasswordForm.password}
					onChange={handleChange}
					error={errors?.password}
					helperText={errors?.password || ""}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Lock />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowPassword}>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") handleSubmit(e);
					}}
				/>

				<TextField
					fullWidth
					variant="outlined"
					type={showConfirmPassword ? "text" : "password"}
					name="password_confirmation"
					value={resetPasswordForm.password_confirmation}
					placeholder="Confirm Password"
					onChange={handleChange}
					error={errors?.password_confirmation}
					helperText={errors?.password_confirmation || ""}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Lock />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowConfirmPassword}>
									{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") handleSubmit(e);
					}}
				/>
			</SplashForm>
		</>
	);
};

export default ResetPassword;
