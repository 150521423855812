import React, { useState } from "react";
import { Avatar } from "@mui/material";

import { DataGrid, gridClasses, GridPagination } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";

const styles = {
	backgroundColor: "#fff",
	borderRadius: "12px",
	color: "#333",
	fontFamily: "'Rubik Variable', sans-serif",
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: "#FCFCFC",
		"&:hover, &.Mui-hovered": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
		"&.Mui-selected": {
			backgroundColor: "#FCFCFC",
			"&:hover, &.Mui-hovered": {
				backgroundColor: "rgba(0, 0, 0, 0.04)",
			},
		},
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		color: "#767D90",
	},
	"&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
		borderColor: "#F1F1F1",
	},
	"& .MuiDataGrid-footerContainer": {
		justifyContent: "center",
	},
	"& .MuiTablePagination-displayedRows": {
		display: "none",
	},
	"& .MuiDataGrid-virtualScroller": {
		minHeight: 150,
	},
};

function Pagination({ page, onPageChange, className }) {
	return (
		<MuiPagination
			className={className}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

function CustomPagination(props) {
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const columns = [
	{
		field:"full_name",
		headerName: "Name",
		sortable: false,
		flex: 1,
		renderCell: (params) => {
			return (
				<>
					<Avatar
						src={params.row?.profile_image}
						sx={{ bgcolor: "gray", mr: 1.5, width: 35, height: 35 }}
					>
						{params.row?.full_name?.charAt(0)}
					</Avatar>
					{params.row?.full_name}
				</>
			);
		},
	},
	{
		field: "email",
		headerName: "Email",
		sortable: false,
		flex: 1,
		valueGetter: (params) => params.row?.email,
	},
	{
		field: "mobile_number",
		headerName: "Mobile Number",
		sortable: false,
		flex: 1,
		valueGetter: (params) => params.row?.mobile_number,
	},
];

function MemberTab({ members = [], isLoading }) {
	return (
		<>
			<DataGrid
				rows={members || []}
				columns={columns}
				sx={styles}
				pagination
				disableRowSelectionOnClick
				disableColumnMenu
				hideFooter
				loading={isLoading}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
				}
			/>
		</>
	);
}

export default MemberTab;
