import React, { useState, useRef } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Button,
  FormLabel,
  Grid,
  Drawer,
} from "@mui/material";
import _ from "lodash";
import { Close } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import {
  ADMIN_GET_ALL_RESOURCES,
  ADMIN_ADD_EDIT_RESOURCE,
  ADMIN_DELETE_RESOURCE_BY_ID,
} from "../data/constants/apiRoutes";
import { get, post, destroy } from "../server";
import BoxModal from "../components/Modals/BoxModal";
import PageHeader from "../components/ui/PageHeader";
import ResourcesSchema from "../utils/schemas/ResourcesSchema";
import awsFileUpload from "../utils/helpers/awsFileUpload";
import { validator } from "../utils/helpers/validator";
import { uploadImageType } from "../data/constants/uploadImageTypes";
import queryNames from "../data/constants/queryNames";
import Listing from "../components/ui/Listing";
import GoBackButton from "../components/Buttons/GoBackButton";
import LoadingButton from "../components/Buttons/LoadingButton";
import FormInput from "../components/Forms/FormInput";
import CropImage from "../components/Modals/CropImage";

//SVG
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";

//STYLES
const styles = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& h3": {
      fontSize: 16,
      fontWeight: 600,
      color: "#000",
      mb: 1,
    },
  },
  eventImageContainer: {
    borderRadius: "4px",
    border: "1px dashed var(--light-background-color-2, #E2E3E4)",
    bgcolor: "#FBFCFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 2,
    p: 3,
    height: 'auto',
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
  coverImageContainer: {
    borderRadius: "4px",
    border: "1px solid #eee",
    height: 'auto',
    width: "100%",
    objectFit: "cover",
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
  coverHover: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    gap: "10px",
    transition: "display 0.3s ease",
    "& button": {
      borderRadius: "4px",
      border: "1px solid #2B2A69",
      bgcolor: "#FFF",
      color: "#2B2A69",
      fontWeight: 500,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#FFF",
        border: "1px solid #2B2A69",
      },
    },
    "& label": {
      color: "#767D90",
      fontSize: 14,
    },
  },
};

//INITIAL STATE
const initialState = {
  book_title: "",
  book_url: "",
  image: "",
};

const ExternalResources = () => {
  //USESTATES
  const [newResource, setNewResource] = useState(initialState);
  const [resourceId, setResourceId] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cover_image, setCoverImage] = useState(null);
  const [cropModal, setCropModal] = useState(false);
  const [isCoverHovered, setIsCoverHovered] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  const client = useQueryClient();

  //USEREF
  const coverRef = useRef(null);

  //COLUMNS FOR GRID
  const columns = [
    {
      field: "book_title",
      headerName: "Book Title",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params?.row?.book_banner}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params?.row?.book_title.charAt(0).toUpperCase()}
              </Avatar>
              {params?.row?.book_title}
            </Stack>
          </>
        );
      },
    },
    {
      field: "book_url",
      headerName: "Book Url",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.book_url,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Edit" arrow placement="top">
              <IconButton onClick={() => handleEditModalOpen(params?.row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => {
                  setResourceId(params?.row?.id);
                  setDeleteModal(true);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  //HANDLE INVALIDE
  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.RESOURCES);
  };

  //FORM VALIDATION
  const validateForm = () => {
    const newErrors = validator(newResource, ResourcesSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    if (!newResource.image) {
      enqueueSnackbar("Please attach Cover Image", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

    if (file.size <= maxSizeInBytes) {
      setCoverImage(URL.createObjectURL(file));
      setCropModal("External Resources");
    } else {
      alert("Selected cover image exceeds the 15MB size limit.");
    }
  };

  //IMAGE HOVERING METHOD
  const handleCoverMouseEnter = () => {
    setIsCoverHovered(true);
  };

  const handleCoverMouseLeave = () => {
    setIsCoverHovered(false);
  };

  //FORM UPDATE METHOD
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setNewResource({ ...newResource, [name]: value });
  };

  //MODAL CLOSE
  const handleClose = () => {
    setModal(false);
    setIsEdit(false);
    setResourceId(null);
    setErrors({});
    setNewResource(initialState);
  };

  //MODAL OPENING AND API CALLING FOR PARTICULAR POST
  const handleEditModalOpen = async (row) => {
    setResourceId(row.id);
    setNewResource({
      book_title: row.book_title,
      image: row.book_banner,
      book_url: row.book_url,
    });
    setIsEdit(true);
    setModal(true);
  };

  //OPEN RESOURCE MODAL
  const handleOpenModal = () => {
    setModal(true);
  };

  //DELETE MODAL
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setResourceId(null);
  };

  //CALLING METHOD TO GET MEETUPS
  const fetchResources = async (pageNo) => {
    let url = new URL(`${ADMIN_GET_ALL_RESOURCES}`);

    url.searchParams.append("page", pageNo + 1);

    return await get(url.toString());
  };

  //DELETE RESOURCE
  const deleteResource = async () => {
    const { status, message } = await destroy(
      `${ADMIN_DELETE_RESOURCE_BY_ID}/${resourceId}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleDeleteModalClose();
      handleInvalidate();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //ADD NEW RESOURCE
  const saveResource = async () => {
    const payload = _.cloneDeep(newResource);
    let newStatus = true;

    const data = await awsFileUpload(
      payload.image,
      uploadImageType.createResource
    );
    if (data.status === true) payload.image = data.location;
    else {
      enqueueSnackbar("Error uploading", { variant: "error" });
      newStatus = false;
    }

    if (newStatus) {
      const { status, message } = await post(ADMIN_ADD_EDIT_RESOURCE, {
        book_title: payload?.book_title,
        book_banner: payload?.image,
        book_url: payload?.book_url,
      });
      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  //UPDATE RESOURCE
  const updateResource = async () => {
    const payload = _.cloneDeep(newResource);
    let newStatus = true;

    if (typeof payload.image !== "string") {
      const data = await awsFileUpload(
        payload.image,
        uploadImageType.createResource
      );
      if (data.status === true) payload.image = data.location;
      else {
        enqueueSnackbar("Error uploading", { variant: "error" });
        newStatus = false;
      }
    }

    if (newStatus) {
      const { status, message } = await post(ADMIN_ADD_EDIT_RESOURCE, {
        flipbook_id: resourceId,
        book_title: payload?.book_title,
        book_banner: payload?.image,
        book_url: payload?.book_url,
      });
      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        handleInvalidate();
        handleClose();
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  // MUTATION AND ITS METHODS
  const mutation = useMutation(saveResource);
  const mutationUpdate = useMutation(updateResource);
  const mutationDelete = useMutation(deleteResource);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (isEdit) {
      mutationUpdate.mutate();
    } else {
      mutation.mutate();
    }
  };

  const handleConfirm = () => {
    mutationDelete.mutate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box>
          <PageHeader title="External Resources" />
        </Box>

        <Box display="flex" alignItems="center">
          <GoBackButton url={-1} />
          <PageHeader
            createBtn
            createBtnText="Add Resource"
            btnFnc={handleOpenModal}
          />
        </Box>
      </Box>

      <Listing
        columns={columns}
        // handleCellClick={handleClick}
        queryName={queryNames.RESOURCES}
        fetchRecords={fetchResources}
        className="meetups"
      />

      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2">
            {!isEdit ? "Add new Resource" : "Update Resource"}
          </Typography>
          <Grid container spacing={3} mb={2}>
            <Grid item md={12} sm={12}>
              <FormInput
                label="Add Title"
                placeholder="Title"
                value={newResource.book_title}
                name="book_title"
                onChange={handleChange}
                error={errors?.book_title}
                helperText={errors?.book_title || ""}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <FormInput
                label="Book Url"
                placeholder="Book url"
                value={newResource.book_url}
                name="book_url"
                onChange={handleChange}
                error={errors?.book_url}
                helperText={errors?.book_url || ""}
              />
            </Grid>
          </Grid>
          <FormLabel label="Cover Image" />
          {!newResource.image ? (
            <Box sx={styles.eventImageContainer}>
              <Typography component="label">+Add Cover Image</Typography>
              <Button
                variant="outlined"
                onClick={() => coverRef.current.click()}
              >
                Browse Image
              </Button>
              <input
                type="file"
                hidden
                onChange={handleCoverChange}
                id={`documentUpload`}
                ref={coverRef}
                accept="image/*"
              />
            </Box>
          ) : (
            <Box
              position="relative"
              onMouseEnter={handleCoverMouseEnter}
              onMouseLeave={handleCoverMouseLeave}
            >
              <Box
                component="img"
                src={
                  typeof newResource.image === "string"
                    ? newResource.image
                    : URL.createObjectURL(newResource.image)
                }
                sx={{
                  ...styles.coverImageContainer,
                  opacity: isCoverHovered ? 0.3 : 1,
                  transition: "opacity 0.3s ease",
                }}
                loading="lazy"
              />
              <Stack
                sx={{
                  ...styles.coverHover,
                  display: isCoverHovered ? "flex" : "none", // Control visibility
                  opacity: isCoverHovered ? 1 : 0.5, // Control opacity
                }}
              >
                <Typography component="label">+Add Cover Image</Typography>
                <Button
                  variant="outlined"
                  onClick={() => coverRef.current.click()}
                >
                  Browse Image
                </Button>
                <input
                  type="file"
                  hidden
                  onChange={handleCoverChange}
                  id={`documentUpload`}
                  ref={coverRef}
                  accept="image/*"
                />
              </Stack>
            </Box>
          )}
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={mutation.isLoading || mutationUpdate.isLoading}
            sx={styles.button}
          >
            {!isEdit ? "Submit" : "Update"}
          </LoadingButton>
        </Box>

        <CropImage
          modal={cropModal}
          setModal={setCropModal}
          image={cover_image}
          user={newResource}
          setUser={setNewResource}
          ratio={10 / 13}
        />
      </Drawer>

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this meetup"}
        open={deleteModal}
        handleClose={handleDeleteModalClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export default ExternalResources;
