import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "../../../../assets/styles/events/createEvent.styles";

const SpeakerTab = ({ speakers }) => {
	return (
		<>
			<Box>
				{speakers?.map((speaker, index) => (
					<Stack direction="row" sx={styles.memberStack} key={index}>
						<Avatar
							sx={styles.memberAvatar}
							src={speaker?.event_speaker?.profile_image}
						/>
						<Stack sx={styles.memberNameStack} gap="5px">
							<Typography component="h4">
								{speaker?.event_speaker?.full_name}
							</Typography>
							<Typography component="label">
								{speaker?.event_speaker?.email}
							</Typography>
						</Stack>
					</Stack>
				))}
			</Box>
		</>
	);
};

export default SpeakerTab;
