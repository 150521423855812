import AWS from 'aws-sdk';

const awsFileUpload = async (file, folderName, setUploadProgress) => {

    const S3_BUCKET = "narebapp";
    const REGION = "us-east-2";

    AWS.config.update({
        accessKeyId: "AKIAV42YMPS76OL7LYES",
        secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const FolderName = folderName;
    const timestamp = new Date().getTime();
    // const fileName =  timestamp + file.name;
    const fileName =  `${timestamp}_${file.name}`;
    const objectKey = `${FolderName}/${fileName}`;

    const params = {
        Bucket: S3_BUCKET,
        Key: objectKey,
        Body: file,
    };

    var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
            if (setUploadProgress)
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: (evt.loaded * 100) / evt.total,
                }));
        })
        .promise();

    try {
        await upload;
        const objectUrl = `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${objectKey}`;

        // const thumbnailUrl = `Thumbnail generation in progress...`;
        // await triggerThumbnailGeneration(objectKey)

        // console.log(objectUrl, thumbnail)
        return {
            status: true, location: objectUrl
            // , thumbnail: thumbnailUrl 
        };
    } catch (err) {
        console.error('Error', err);
        return { status: false, location: null, thumbnail: null };
    }
}

// const triggerThumbnailGeneration = async (videoKey) => {
//     const lambda = new AWS.Lambda();
//     const lambdaParams = {
//         FunctionName: 'your-lambda-function-name', // Replace with your Lambda function name
//         InvocationType: 'Event',
//         Payload: JSON.stringify({ videoKey }), // Pass video key to Lambda
//     };

//     await lambda.invoke(lambdaParams).promise();
// }

export default awsFileUpload;
