import React from "react";
import Listing from "../../components/ui/Listing";
import { ADMIN_SALES_TICKETS } from "../../data/constants/apiRoutes";
import { get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import PageHeader from "../../components/ui/PageHeader";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TicketSales = () => {
	const navigate = useNavigate();

	const columns = [
		{
			field: "event_name",
			headerName: "Event Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						component="div"
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.event_name}
					</Box>
				);
			},
		},
		{
			field: "venue_name",
			headerName: "Venue Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "company_name",
			headerName: "Company Name",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.company_name || "-",
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.address || "-",
		},
		{
			field: "totalPurchaseTickets",
			headerName: "Total Purchase Tickets",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.totalPurchaseTickets || "-",
		},
		{
			field: "totalPurchasePrice",
			headerName: "Total Purchase Price",
			sortable: false,
			flex: 1,
			valueGetter: (params) => `$${params.row.totalPurchasePrice}` || "-",
		},
	];

	const fetchEventSales = async (pageNo, limit) => {
		let url = new URL(ADMIN_SALES_TICKETS);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleCellClick = (data) => {
		if (data?.field === "event_name") {
			navigate(`/sales/tickets/detail/${data.id}`);
		}
	};

	return (
		<>
			<PageHeader
				title="Event Tickets Sales"
				placeholder="Search Events"
				searchInput={true}
			/>

			<Listing
				columns={columns}
				queryName={queryNames.TICKET_SALES}
				fetchRecords={fetchEventSales}
				type="courses"
				handleCellClick={handleCellClick}
			/>
		</>
	);
};

export default TicketSales;
