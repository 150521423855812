import { Box } from "@mui/material";
import React from "react";

const styles = {
	wrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// minHeight: "80vh",
	},
	loader: {
		border: "3px solid transparent",
		borderRadius: "50%",
		borderTop: " 3px solid #2B2A69",
		borderBottom: "3px solid #2B2A69",
		width: 50,
		height: 50,
		WebkitAnimation: "spin 1s linear infinite",
		/* Safari */
		animation: "spin 1s linear infinite",
		margin: "0 auto",
		"@keyframes spin": {
			"0%": {
				transform: "rotate(0deg)",
			},
			"100%": {
				transform: "rotate(360deg)",
			},
		},
	},
};

function InnerLoader() {
	return (
		<>
			<Box sx={styles.wrapper}>
				<Box sx={styles.loader}></Box>
			</Box>
		</>
	);
}

export default InnerLoader;
