import React, { useState } from "react";
import PageHeader from "../components/ui/PageHeader";
import Courses from "../assets/svg/Courses.svg";
import { Grid, Stack, Typography } from "@mui/material";
import StatsCard from "../components/pageComponents/dashboard/StatsCard";
import MembersCard from "../components/pageComponents/dashboard/MembersCard";
import EventsStats from "../components/pageComponents/dashboard/EventsStats";
import { Link } from "react-router-dom";
import CoursesCard from "../components/pageComponents/dashboard/CoursesCard";

import FeaturedEvents from "../components/pageComponents/dashboard/FeaturedEvents";
import RecentMembers from "../components/pageComponents/dashboard/RecentMembers";
import { get } from "../server";
import {
	ADMIN_ALL_EVENTS_LISTING,
	ADMIN_DASHBOARD,
	ADMIN_DASHBOARD_EVENT_STATS,
} from "../data/constants/apiRoutes";
import { useQuery } from "react-query";
import queryNames from "../data/constants/queryNames";
import { useSnackbar } from "notistack";

const styles = {
	title: {
		pt: 3,
		flexDirection: "row",
		justifyContent: "space-between",
		"& p": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: 16,
		},
		"& a": {
			color: "#0F4590",
			fontWeight: 600,
		},
	},
};

function Dashboard() {
	const [dashboardData, setDashboardData] = useState({});

	const [events, setEvents] = useState([]);
	const [eventId, setEventId] = useState();

	const [eventStats, setEventStats] = useState();

	const { enqueueSnackbar } = useSnackbar();

	const fetchDashboardData = async () => {
		const { status, message, data } = await get(ADMIN_DASHBOARD);
		if (status === true) setDashboardData(data);
		else enqueueSnackbar(message, { variant: "error" });
	};

	const { isLoading } = useQuery([queryNames.DASHBOARD], fetchDashboardData, {
		refetchOnWindowFocus: false,
	});

	const fetchEvents = async () => {
		const { status, message, data } = await get(ADMIN_ALL_EVENTS_LISTING);
		if (status === true) {
			const newData = data?.data?.map((event) => ({
				id: event.id,
				label: event.event_name,
			}));
			setEvents(newData);
			setEventId(newData[0]?.id);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const { isLoading: isLoadingEvents } = useQuery(
		[queryNames.EVENTS],
		fetchEvents,
		{
			refetchOnWindowFocus: false,
		}
	);

	const fetchEventStats = async () => {
		const { status, message, data } = await get(
			`${ADMIN_DASHBOARD_EVENT_STATS}/${eventId}/states`
		);
		if (status === true) {
			setEventStats(data);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const { isLoading: isLoadingEventStats } = useQuery(
		[queryNames.EVENT_STATS, eventId],
		fetchEventStats,
		{
			refetchOnWindowFocus: false,
		}
	);

	return (
		<>
			<PageHeader title="Statistics" />
			<Grid container spacing={3} sx={{ pt: 3 }}>
				<Grid item xs={12} lg={8}>
					<EventsStats
						events={events}
						eventId={eventId}
						setEventId={setEventId}
						isLoadingEvents={isLoadingEvents}
						eventStats={eventStats}
						isLoadingEventStats={isLoadingEventStats}
					/>
					<FeaturedEvents
						events={dashboardData?.featured_event}
						loading={isLoading}
					/>
					<RecentMembers
						members={dashboardData?.recent_members}
						loading={isLoading}
					/>
				</Grid>

				<Grid item xs={12} lg={4}>
					<StatsCard
						title="Courses"
						price={dashboardData?.courses_revenue}
						bgImage={Courses}
					/>

					<MembersCard members={dashboardData?.members} />

					<Stack sx={styles.title}>
						<Typography component="p">Popular Courses</Typography>
						<Link to="/popular-courses">View All</Link>
					</Stack>

					{dashboardData?.popular_courses?.map((course) => (
						<CoursesCard {...course} key={course.id} />
					))}
				</Grid>
			</Grid>
		</>
	);
}

export default Dashboard;
