const styles = {
  label: {
    color: "#838383",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    marginBottom: "5px",
    display: "block",
    "& > *": {
      color: "#fa5252",
    },
  },
  select: {
    height: 54,
    backgroundColor: "rgba(226, 227, 228, 0.2)",
    textTransform: 'capitalize',
    "& .MuiSelect-select": {
      border: 0,
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
      backgroundColor: "#F9F9F9",
      borderRadius: "6px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#656DFC",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E2E3E4",
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#9c9c9c",
    },
  },
  helperText: {
    // marginLeft: 0,
    fontSize: 14
  },
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d32f2f !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f !important",
      borderWidth: "1px",
    },
  },
};

export default styles;
