import PhoneInput from "react-phone-input-2";
import { Box, FormHelperText } from "@mui/material";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import styles from "../../assets/styles/forms/phoneNumberInput.styles";
import FormLabel from "./FormLabel";

const PhoneNumberInput = ({ label, error, helperText, ...other }) => {
	const inputId = `input-${other.name}`;

	return (
		<>
			<Box component="div" className={"phoneInputWrapper"}>
				<FormLabel label={label} inputId={inputId} />
				<PhoneInput
					inputClass={
						error
							? other.inputClassName
								? "bigForm errorInput"
								: "smallForm errorInput"
							: other.inputClassName
							? "bigForm"
							: "smallForm"
					}
					country={"us"}
					// enableSearch
					disableDropdown
					countryCodeEditable={false}
					{...other}
					// inputStyle={other.className ? styles.input : ""}
				/>
			</Box>
			<FormHelperText sx={styles.errorMessage}>{helperText}</FormHelperText>
		</>
	);
};

export default PhoneNumberInput;
