import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormInput from "../../../../components/Forms/FormInput";
import { enqueueSnackbar } from "notistack";
import styles from "../../../../assets/styles/events/createEvent.styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ADMIN_EVENT_GUEST_SPEAKER_DELETE,
  ADMIN_EVENT_MEMBER_USER_EXISTS,
  ADMIN_EVENT_CREATE,
} from "../../../../data/constants/apiRoutes";
import { destroy, get, post } from "../../../../server";
import { useQuery, useMutation } from "react-query";
import { Close, Delete } from "@mui/icons-material";
import LocationAutoComplete from "../../../../components/Forms/LocationAutoComplete";
import FormLabel from "../../../../components/Forms/FormLabel";
import PhoneNumberInput from "../../../../components/Forms/PhoneNumberInput";
import { validator } from "../../../../utils/helpers/validator";
import { GuestSpeakerSchema } from "../../../../utils/schemas/EventSchema";
import LoadingButton from "../../../../components/Buttons/LoadingButton";
import { useRef } from "react";
import awsFileUpload from "../../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../../data/constants/uploadImageTypes";
import _ from "lodash";

const initialState = {
  name: "",
  email: "",
  bio: "",
  image: "",
  address: "",
  phone: null,
};

const SponsorTab = ({
  sponsors,
  currentStep,
  setCurrentStep,
  eventForm,
  setEventForm,
  loading = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [typedEmail, setTypedEmail] = useState("");
  const [searchedEmails, setSearchedEmails] = useState([]);
  const [enableSearch, setEnableSearch] = useState(false);
  const [selectedSponsors, setSelectedSponsors] = useState([]);

  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});

  const [address, setAddress] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const fileInputRef = useRef();



  useEffect(() => {
    setSelectedSponsors( eventForm.members?.sponsors.map((sponsorobj) => sponsorobj.company_sponser_id) );
  }, [eventForm.members?.sponsors]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckboxChange = (member, checked) => {
    if (checked) {
      setSelectedSponsors( selectedSponsors => [...selectedSponsors, member.id]);
    } else {
      setSelectedSponsors( selectedSponsors.filter( (sponsor) => sponsor !== member.id ));
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const validate = () => {
    const newSpeaker =
      eventForm?.members?.guest_speakers[
        eventForm?.members?.guest_speakers?.length - 1
      ];
    const newErrors = validator(newSpeaker, GuestSpeakerSchema);
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;
    return true;
  };


  const handleSkip = () => {
    setCurrentStep(currentStep + 1);
  };


  const handleSaveEventMembers = async () => {
    const eventPayload = _.cloneDeep(eventForm.members);

    const sponsorsPayLoad = {event_id: eventPayload.event_id, event_step: 2, selectedSponsors};

    const { status, message, data } = await post(
      ADMIN_EVENT_CREATE,
      sponsorsPayLoad
    );
    if (status === true) {
      const newSponsors = data?.sponsors?.map((sponsor) => ({
        id: sponsor?.company_sponsor?.id,
        label: sponsor?.company_sponsor?.company_name,
      }));
      setEventForm({
        ...eventForm,
        members: {
          ...eventForm.members,
          sponsors: newSponsors,
        },
      });

      // enqueueSnackbar(message, { variant: "success" });
      setCurrentStep(currentStep + 1);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const mutation = useMutation(handleSaveEventMembers);

	const handleApiSubmit = (e) => {
    e.preventDefault();
		mutation.mutate();
	};

  return (
    <>
      {/* <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ ...styles.continueBtn, width: 200, mb: 1 }}
          onClick={() => setModal(true)}
        >
          Add Guest Speaker
        </Button>
      </Stack> */}
      <Stack direction="row" sx={[styles.title, { mt: 3 }]}>
				<Typography component="h1">Add Speaker</Typography>
        <Button variant="contained" onClick={handleSkip} sx={{ mr: 1 }}>
          Skip{" "}
        </Button>
				<LoadingButton
				variant="contained"
				onClick={handleApiSubmit}
        isLoading={mutation.isLoading}
				>
				Continue
				</LoadingButton>
			</Stack>


      <Box mt={3}>
        {sponsors.length > 0 ? (
          sponsors
            // .filter((member) => member.type !== "not_exist")
            .map((member, index) => (
              <Stack direction="row" sx={styles.memberStack} key={index}>
                <Avatar sx={styles.memberAvatar} src={member.image}>
                  {member.company_name?.charAt(0)}
                </Avatar>
                <Stack sx={styles.memberNameStack} gap="5px">
                  <Typography component="h4">{member.company_name}</Typography>
                  <Typography component="label">
                    {member.email}
                  </Typography>
                </Stack>
                <Checkbox
                  icon={
                    <RadioButtonUncheckedIcon
                      sx={{ "& path": { color: "#E2E3E4" } }}
                    />
                  }
                  checkedIcon={
                    <CheckCircleIcon sx={{ "& path": { color: "#03989E" } }} />
                  }
                  sx={styles.memberCheckbox}
                  size="medium"
                  checked={
                    !!selectedSponsors.includes( member.id )   ||
                    false
                  }
                  onChange={(e) =>
                    handleCheckboxChange(member, e.target.checked)
                  }
                />
              </Stack>
            ))
        ) : (
          <Box textAlign="center">
            {loading ? (
              <CircularProgress sx={{ textAlign: "center" }} />
            ) : (
              <Typography>No Sponsors available.</Typography>
            )}
          </Box>
        )}
      </Box>

      {/* <Box>
        <Divider sx={styles.divider} />
        <FormLabel label="Guest Speakers" />
      </Box> */}

      <Fab
        sx={{
          ...styles.scrollButtonStyles,
          ...(isVisible && styles.visibleScrollButtonStyles),
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>


    </>
  );
};

export default SponsorTab;
