import { TextField } from "@mui/material";
import styles from "../../assets/styles/forms/formInput.styles";
import FormLabel from "./FormLabel";

const FormInput = ({
	label,
	labelSize,
	isFilledInput,
	isSmallFont,
	inputType = false,
	...other
}) => {
	const inputId = `input-${other.name}`;

	return (
		<>
			<FormLabel label={label} inputId={inputId} inputType={inputType} labelSize={labelSize}/>
			<TextField
				id={other?.id ? other.id : inputId}
				fullWidth
				variant="outlined"
				{...other}
				size="small"
				autoComplete="off"
				autoSave="off"
				sx={{
					...(inputType === false && styles.input),
					...other.sx,
					...(other.error && {
						...styles.error,
					}),
				}}
				onKeyDown={other.onKeyDown}
			/>
		</>
	);
};

export default FormInput;
