const styles = {
  input: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      minHeight: 50,
      borderRadius: 0,
      p: 0,
      "& fieldset": {
        border: "1px solid #EEE",
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "#EEE",
        },
      },
      "&:hover fieldset": {
        borderColor: "#656DFC",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "4px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      height: "40px",
      background: "rgba(226, 227, 228, 0.2)",
      // border: "1px solid #E2E3E4", 
      borderRadius: "4px",
      padding: "6px 10px",
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 15,
      },
    },
    "& textarea": {
      background: "rgba(226, 227, 228, 0.2)",
      p: "20px",
      height: 100,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 15,
      },
    },
    "& .MuiFormHelperText-root": {
      // marginLeft: 0,
    },
    "& .MuiInputAdornment-root": {
      ml: 0,
      bgcolor: "rgba(226, 227, 228, 0.2)",
      maxHeight: "100%",
      height: "100%",
      "& button": {
        padding: 2,
      }
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },

  error: {
    "& p": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d32f2f !important",
      borderRadius: "4px"
    },
  },
};

export default styles;
