// NewBoxModal.js
import React from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CommentBoxModal = ({ header, title, open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          width: "400px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          {header}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Typography variant="body2" sx={{ lineHeight: 1.5, fontSize: "15px" }}>
          {title}
        </Typography>
      </Box>
    </Modal>
  );
};

export default CommentBoxModal;
