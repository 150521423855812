import React, { useRef } from "react";
import PageHeader from "../../../components/ui/PageHeader";
import { Box, IconButton } from "@mui/material";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { enqueueSnackbar } from "notistack";
import { destroy, post } from "../../../server";
import { useMutation, useQueryClient } from "react-query";
import {
	ADMIN_EVENT_FILE_UPLOAD,
	ADMIN_EVENT_IMAGE_DELETE,
} from "../../../data/constants/apiRoutes";
import InnerLoader from "../../../components/overlay/InnerLoader";
import { useState } from "react";
import queryNames from "../../../data/constants/queryNames";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
	container: {
		bgcolor: "#FFF",
		borderRadius: "12px",
		p: "35px",
		display: "flex",
		flexWrap: "wrap",
		gap: "15px",
	},
	imageContainer: {
		borderRadius: "10px",
		width: 156,
		height: 143,
		objectFit: "cover",
		bgcolor:"#fff"
	},
	closeButton: {
		position: "absolute",
		top: 0,
		right: 0,
		bgcolor: "red",
		width: 25,
		height: 25,
		"&:hover": {
			bgcolor: "red",
		},
	},
};

const Photos = ({ images, loading, eventId, handleInvalidate }) => {
	const [modal, setModal] = useState(false);
	const imageRef = useRef();

	const client = useQueryClient();

	const handlePhotoChange = (e) => {
		const files = e.target.files;
		const newFiles = Array.from(files);
		const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

		// const initialProgress = newFiles.reduce((progress, file) => {
		// 	progress[file.name] = 0;
		// 	return progress;
		// }, {});

		const validFiles = newFiles.filter((file) => file.size <= maxSizeInBytes);

		if (validFiles.length < newFiles.length) {
			alert("Some files exceed the 15MB size limit.");
		}

		// setUploadProgress((prevProgress) => ({
		// 	...prevProgress,
		// 	...initialProgress,
		// }));

		// setEventForm({
		// 	...eventForm,
		// 	details: {
		// 		...eventForm.details,
		// 		image: [...eventForm.details.image, ...validFiles],
		// 	},
		// });
		mutation.mutate(validFiles);
	};

	const uploadPhotos = async (files) => {
		let newStatus = true;
		let uploadedPhotos = [];

		for (let i = 0; i < files.length; i++) {
			const photo = files[i];
			const data = await awsFileUpload(
				photo,
				uploadImageType.createEvent
				// setUploadProgress
			);
			if (data.status === true) {
				uploadedPhotos[i] = data.location;
			} else {
				enqueueSnackbar("Error uploading documents", { variant: "error" });
				return (newStatus = false);
			}
		}

		if (newStatus) {
			const { status, message, data } = await post(ADMIN_EVENT_FILE_UPLOAD, {
				event_id: eventId,
				file: uploadedPhotos,
				type: "image",
			});
			if (status === true) {
				handleInvalidate();
			}
		}
	};

	const mutation = useMutation(uploadPhotos);

	const handleAddPhoto = () => {
		imageRef.current.click();
	};

	const handleDelete = async (id) => {
		const { status, message } = await destroy(
			`${ADMIN_EVENT_IMAGE_DELETE}/${id}`
		);
		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			setModal(false);
			client.invalidateQueries([queryNames.EVENTS]);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const deleteMutation = useMutation(handleDelete);

	const handleConfirm = (image) => {
		deleteMutation.mutate(image.id);
	};

	return (
		<>
			<PageHeader
				title="Photos"
				createBtn
				createBtnText="Upload Photos"
				btnFnc={handleAddPhoto}
				isLoading={mutation.isLoading}
			/>

			<input
				type="file"
				multiple
				hidden
				onChange={handlePhotoChange}
				id={`photoUpload`}
				ref={imageRef}
				accept="image/*"
				size={1}
			/>

			<Box
				sx={{
					...styles.container,
					...(loading && {
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}),
				}}
			>
				{loading ? (
					<InnerLoader />
				) : (
					images?.map((image, index) => (
						<Box key={index} position="relative">
							<Box
								component="img"
								src={image.name}
								alt={image.name}
								key={index}
								sx={styles.imageContainer}
								loading="lazy"
							/>
							<IconButton
								sx={styles.closeButton}
								onClick={() => handleConfirm(image)}
							>
								<CloseIcon sx={{ color: "#fff" }} />
							</IconButton>
						</Box>
					))
				)}
			</Box>
		</>
	);
};

export default Photos;
