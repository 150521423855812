import Joi from "joi";

const ResourcesSchema = Joi.object({
  book_title: Joi.string().max(500).required().messages({
    "string.empty": `Please enter Book Title`,
    "string.max": `Book Title must be maximum 500 characters!`,
  }),
  book_url: Joi.string().uri({ scheme: ['http', 'https'] }).required().max(500).messages({
    "string.empty": `Please enter Book Url`,
    "string.max": `Company name must be maximum 500 characters!`,
    "string.uri": `Please enter a valid Book Url`,
  }),
});

export default ResourcesSchema;
