import React from "react";
import PageHeader from "../../../components/ui/PageHeader";
import EventDetailListings from "../../../components/ui/EventDetailListings";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import FormInput from "../../../components/Forms/FormInput";
import {
  ADMIN_DELETE_EVENT_SPEAKER,
  ADMIN_EVENT_CREATE,
  ADMIN_EVENT_MEMBER_USER_EXISTS,
} from "../../../data/constants/apiRoutes";
import { destroy, get, post } from "../../../server";
import { useMutation, useQuery } from "react-query";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { enqueueSnackbar } from "notistack";
import BoxModal from "../../../components/Modals/BoxModal";
import GuestSpeakers from "./GuestSpeakers";

const styles = {
  container: {
    position: "relative",
    width: 500,
    bgcolor: "#fff",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    p: 2,
    "& h2": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000",
      mt: 1,
      mb: 2,
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
  memberFullStack: {
    borderBottom: "1px solid #E2E3E4",
    "&:last-child": {
      borderBottom: "none", // Remove border for the last item
    },
    px: 1.5,
  },
  memberStack: {
    alignItems: "center",
    gap: 1.5,
    py: "12px",
    "& h4": {
      fontSize: 13,
      wordBreak: "break-all",
      maxWidth: 200,
    },
  },
  memberAvatar: {
    width: 40,
    height: 40,
    bgcolor: "gray",
  },
  addEmail: {
    bgcolor: "#2B2A69",
    color: "#FFF",
    fontWeight: 600,
    textTransform: "capitalize",
    px: 3,
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "#FFF",
    },
  },
  button: {
    mt: 2,
    width: 100,
    bgcolor: "#2B2A69",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
    },
  },
  addButton: {
    width: 200,
    bgcolor: "#2B2A69",
    color: "#FFF",
    fontWeight: 600,
    textTransform: "capitalize",
    px: 3,
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "#FFF",
    },
  },
  headerWrapper: {
    flexDirection: { md: "row", xs: "column" },
    gap: 2,
    justifyContent: "space-between",
    textWrap: "nowrap",
    py: 2,
    "& h1": {
      fontSize: { md: 22, xs: 18 },
      fontWeight: 600,
      color: "#1E1E1F",
      flex: 1,
    },
  },
};

const Speakers = ({ speakers, loading, event, handleInvalidate }) => {
  const [modal, setModal] = useState(false);
  const [guestSpeakerModal, setGuestSpeakerModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [typedEmail, setTypedEmail] = useState("");
  const [enableSearch, setEnableSearch] = useState(false);
  const [searchedEmails, setSearchedEmails] = useState([]);
  const [members, setMembers] = useState([]);

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={params.row.event_speaker.profile_image}
                sx={{
                  bgcolor: "gray",
                  mr: 1.5,
                  width: 35,
                  height: 35,
                }}
              >
                {params.row.event_speaker.full_name.charAt(0).toUpperCase()}
              </Avatar>
              {params.row.event_speaker.full_name}
            </Stack>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row.event_speaker.email || "-",
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row.event_speaker.mobile_number || "-",
    },
    {
      field: "is_guest_speaker",
      headerName: "Speaker Type",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.event_speaker.is_guest_speaker ? "Guest Speaker" : "Speaker",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete" arrow placement="top">
            <IconButton
              onClick={() => {
                setId(params.row.id);
                setDeleteModal("delete");
              }}
            >
              <Delete sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleClose = () => {
    setModal(false);
    setTypedEmail("");
    setEnableSearch(false);
    setSearchedEmails([]);
    setMembers([]);
  };

  const handleEmailAdd = (index) => {
    const selectedUser = searchedEmails[index];
    const newEmail = {
      profile_image: selectedUser.profile_image,
      full_name: selectedUser.full_name,
      email: selectedUser.email,
      id: selectedUser.id,
    };

    setMembers([...members, newEmail]);

    const updatedSearchedEmails = searchedEmails.filter(
      (user, i) => i !== index
    );
    setSearchedEmails(updatedSearchedEmails);
  };

  const findMember = async () => {
    let url;
    url = new URL(ADMIN_EVENT_MEMBER_USER_EXISTS);
    url.searchParams.append("email", typedEmail);

    const { status, message, data } = await get(url.toString());
    if (status) {
      const filteredData = data.filter(
        (newMember) =>
          !speakers.some(
            (member) => member.event_speaker.email === newMember.email
          ) && !members.some((member) => member.email === newMember.email)
      );
      setSearchedEmails(filteredData);
    }
    if (!data.length) {
      if (isValidEmail(typedEmail)) {
        const isEmailInGlobalMembers = speakers.some(
          (member) => member.email === typedEmail
        );

        const isEmailInMembers = members.some(
          (member) => member.email === typedEmail
        );

        if (isEmailInMembers || isEmailInGlobalMembers) {
          setSearchedEmails([]);
        } else {
          const newEmail = {
            profile_image: null,
            full_name: typedEmail,
            email: typedEmail,
            type: "not_exist",
          };
          setSearchedEmails([newEmail]);
        }
      }
    }
    setEnableSearch(false);
  };

  const memberQuery = useQuery(["membersM", enableSearch], findMember, {
    refetchOnWindowFocus: false,
  });
  const saveSpeaker = async () => {
    const { status, message, data } = await post(ADMIN_EVENT_CREATE, {
      event_step: 2,
      event_id: event.id,
      member_id: event?.members?.map((member) => member.event_member.id),
      speaker_id: [
        ...event.speakers.map((member) => member.event_speaker.id),
        ...members
          .filter((member) => member.type !== "not_exist")
          .map((member) => member.id),
      ],
      speakers_email: members
        .filter((member) => member.type === "not_exist")
        .map((member) => member.email),
      sponsors: [{ company_sponser_id: event.sponsors[0]?.company_sponsor.id }],
    });
    if (status === true) {
      enqueueSnackbar("Speakers Added Successfully", { variant: "success" });
      handleInvalidate();
      handleClose();
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const mutation = useMutation(saveSpeaker);

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const handleDelete = async () => {
    // const newSpeakers = speakers.filter(
    //   (speaker) => speaker.event_speaker.id !== id
    // );
    // const { status, message, data } = await post(ADMIN_EVENT_CREATE, {
    //   event_step: 2,
    //   event_id: event.id,
    //   member_id: event?.members?.map((member) => member.event_member.id),
    //   speaker_id: newSpeakers.map((member) => member.event_speaker.id),
    //   speakers_email: members
    //     .filter((member) => member.type === "not_exist")
    //     .map((member) => member.email),
    //   sponsors: [{ company_sponser_id: event.sponsors[0]?.company_sponsor.id }],
    // });
    const { status, message, data } = await destroy(
      `${ADMIN_DELETE_EVENT_SPEAKER}/${id}`
    );

    if (status === true) {
      enqueueSnackbar("Speaker Removed Successfully", { variant: "success" });
      handleInvalidate();
      setDeleteModal();
      setId(null);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const deleteMutation = useMutation(handleDelete);

  const handleConfirm = async () => {
    deleteMutation.mutate();
  };

  return (
    <>
      <Stack sx={styles.headerWrapper}>
        <Typography component="h1">Speakers</Typography>

        <LoadingButton
          variant="contained"
          onClick={() => setModal(true)}
          sx={styles.addButton}
          isLoading={mutation.isLoading}
        >
          Add Speakers
        </LoadingButton>

        {/* <LoadingButton
          variant="contained"
          onClick={() => setGuestSpeakerModal(true)}
          sx={styles.addButton}
          isLoading={mutation.isLoading}
        >
          Add Guest Speakers
        </LoadingButton> */}
      </Stack>

      <EventDetailListings
        rows={speakers}
        columns={columns}
        loading={loading}
        event={event}
      />

      <GuestSpeakers
        modal={guestSpeakerModal}
        setModal={setGuestSpeakerModal}
        handleInvalidate={handleInvalidate}
        event={event}
      />

      <Drawer anchor="right" open={modal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Close className="closeIcon" onClick={handleClose} />
          <Typography variant="h2">Add new Speakers</Typography>

          <FormInput
            label="Invite Members"
            placeholder="example@yourmail.com"
            id="invite-link-input"
            value={typedEmail}
            onChange={(e) => {
              setTypedEmail(e.target.value);
              if (e.target.value.length > 2) {
                setEnableSearch(true);
              }
              if (e.target.value.length === 0) {
                setSearchedEmails([]);
              }
            }}
          />

          {(memberQuery.isLoading || memberQuery.isFetching) && (
            <Box width="100%" textAlign="center">
              <LinearProgress />
            </Box>
          )}

          {searchedEmails.length > 0 && (
            <Box sx={{ border: "1px solid #eee", mt: 2, borderRadius: "10px" }}>
              {searchedEmails.map((member, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                  sx={styles.memberFullStack}
                >
                  <Stack direction="row" sx={styles.memberStack}>
                    <Avatar
                      sx={styles.memberAvatar}
                      src={member.profile_image}
                    />
                    <Stack>
                      <Typography component="h4">{member.full_name}</Typography>
                      <Typography component="h4" flex={1}>
                        {member.email}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="contained"
                    sx={styles.addEmail}
                    onClick={() => handleEmailAdd(index)}
                  >
                    {member.type === "not_exist" ? "Invite" : "Add Email"}
                  </Button>
                </Stack>
              ))}
            </Box>
          )}

          {members.length > 0 &&
            members.map((member, index) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={index}
                sx={{
                  ...styles.memberFullStack,
                  borderBottom: "1px solid #E2E3E4",
                  "& last-child": { borderBottom: "none" },
                }}
              >
                <Stack direction="row" sx={styles.memberStack}>
                  <Avatar sx={styles.memberAvatar} src={member.profile_image} />
                  <Stack>
                    <Typography component="h4">{member.full_name}</Typography>
                    <Typography component="h4" flex={1}>
                      {member.email}
                    </Typography>
                  </Stack>
                </Stack>

                <IconButton
                  onClick={() => {
                    const updatedMembers = members.filter(
                      (member, i) => i !== index
                    );
                    setMembers(updatedMembers);
                  }}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
            sx={styles.button}
          >
            Submit
          </LoadingButton>
        </Box>
      </Drawer>

      <BoxModal
        header="Are you sure?"
        title={"Do you really want to delete this record"}
        open={deleteModal === "delete"}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={deleteMutation.isLoading}
      />
    </>
  );
};

export default Speakers;
