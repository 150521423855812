import { useState } from "react";
import { InputAdornment,  Box, TextField } from "@mui/material";
import forgotImg from "../../assets/svg/ForgetPassword.svg";
import { ReactComponent as Lock } from "../../assets/svg/Lock.svg";
import SplashForm from "../../components/ui/SplashForm";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { validator } from "../../utils/helpers/validator";
import { OTPSchema } from "../../utils/schemas/AuthSchema";
import { post } from "../../server";
import { ADMIN_VERIFY_OTP } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import routes from "../../data/constants/routes";
import styles from "../../assets/styles/auth/verifyOTP.styles";

const VerifyOTP = () => {
	const [OTP, setOTP] = useState({ otp: "" });

	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		if (!/^\d{0,4}$/.test(value)) {
			value = value.slice(0, 4);
		}
		setOTP({ ...OTP, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(OTP, OTPSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleOTPVerification = async () => {
		const { status, message } = await post(ADMIN_VERIFY_OTP, {
			email: loggedUser.email,
			...OTP,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.ADMIN_RESET_PASSWORD);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleOTPVerification);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<SplashForm
				image={forgotImg}
				title="OTP Verification"
				tagline="Enter the OTP code sent to you on the email address associated to your account."
				btnText="Verify OTP"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<TextField
					fullWidth
					variant="outlined"
					size="small"
					type="password"
					name="otp"
					value={OTP.otp}
					onChange={handleChange}
					placeholder="Verification Code"
					error={errors?.otp}
					helperText={errors?.otp || ""}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Lock />
							</InputAdornment>
						),
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") handleSubmit(e);
					}}
				/>

				<Box sx={styles.email}>
					<Box
						component="label"
						// onClick={handleResendEmail}
					>
						Didn't receive an email?
					</Box>
				</Box>
			</SplashForm>
		</>
	);
};

export default VerifyOTP;
