
import Joi from "joi";


const UserSchema = Joi.object({
    full_name: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Full name`,
        "string.max": `Full name must be maximum 50 characters!`,
        "string.pattern.base": `Please enter Full Name`,
    }),
    email: Joi.string()
        .max(50)
        .email({ tlds: { allow: false } })

        .required()
        .messages({
            "string.empty": `Please enter email address`,
            "string.email": `Please enter valid email address`,
            "string.max": `Email must be maximum 50 characters!`,
            "string.pattern.base": `Please enter email address`,
        }),
    mobile_number: Joi.string().allow("", null).messages({
        "string.empty": `Please enter Mobile number `,
    }),
    date_of_birth: Joi.date().allow("", null).messages({
        "string.empty": `Please select Date of Birth`,
    }),
    password: Joi.string()
        .min(6)
        .allow("", null)
        .when(Joi.exist(), {
            then: Joi.required(),
            otherwise: Joi.optional(),
        })
        .messages({
            "string.empty": `Please enter password`,
            "string.min": `Password must be minimum 6 characters!`,
            "any.required": `Password is required`,
        }),
    password_confirmation: Joi.string()
        .allow("", null)
        .when("password", {
            is: Joi.exist(),
            then: Joi.valid(Joi.ref("password")).required(),
            otherwise: Joi.optional(),
        })
        .messages({
            "string.empty": `Please enter confirm password`,
            "any.only": `Confirm password must match password`,
            "any.required": `Confirm password is required`,
        }),

});

export default UserSchema;
