import React, { useRef, useState } from "react";
import Listing from "../../components/ui/Listing";
import {
	ADMIN_SUB_ADMIN_DELETE,
	ADMIN_SUB_ADMIN_LISTING,
	ADMIN_SUB_ADMIN_STATUS,
} from "../../data/constants/apiRoutes";
import { destroy, get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import {
	Avatar,
	Button,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import ManageSubadminModal from "../../components/Modals/ManageSubadminModal";
import BoxModal from "../../components/Modals/BoxModal";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const styles = {
	wrapper: {
		flexDirection: { md: "row", xs: "column" },
		gap: 2,
		justifyContent: "space-between",
		textWrap: "nowrap",
		py: 2,
		"& h1": {
			fontSize: { md: 22, xs: 18 },
			fontWeight: 600,
			color: "#1E1E1F",
		},
	},
	button: {
		width: 200,
		fontWeight: 600,
		textTransform: "capitalize",
		// borderRadius: "4px",
		bgcolor: "#2B2A69",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
};

const Subadmins = () => {
	const [modal, setModal] = useState(false);

	const [adminId, setAdminId] = useState(null);

	const client = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const adminRef = useRef();

	const fetchMembers = async (pageNo, limit) => {
		let url = new URL(ADMIN_SUB_ADMIN_LISTING);

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.SUB_ADMIN);
	};

	const handleClick = () => setModal("invite");

	const handleClose = () => {
		setModal(false);
		setAdminId(null);
		handleInvalidate();
	};

	const handleStatus = async () => {
		const { status, message } = await get(
			`${ADMIN_SUB_ADMIN_STATUS}/${adminId}}`
		);
		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleStatus, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = () => {
		mutation.mutate();
	};

	const columns = [
		{
			field: "full_name",
			headerName: "Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Avatar
							src={params.row.profile_image && params.row.profile_image}
							sx={{ bgcolor: "gray", mr: 1.5, width: 35, height: 35 }}
						>
							{params.row.full_name.charAt(0)}
						</Avatar>
						{params.row.full_name}
					</>
				);
			},
		},
		{
			field: "type",
			headerName: "Role",
			sortable: false,
			flex: 1,
			valueGetter: (params) =>
				params.row.type === "superadmin" ? "Super Admin" : "Sub Admin",
		},
		{
			field: "email",
			headerName: "Email",
			sortable: false,
			flex: 1,
		},
		{
			field: "is_active",
			headerName: "Status",
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<Typography textTransform="capitalize">
					{params.row.is_active ? "Enabled" : "Disabled"}
				</Typography>
			),
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={0.5}>
						<IconButton
							onClick={() => {
								adminRef.current = {
									id: params.row.id,
									email: params.row.email,
									name: params.row.full_name,
								};
								setModal("invite");
							}}
						>
							<Edit />
						</IconButton>
						{!params.row.is_active ? (
							<Tooltip title="Enable">
								<IconButton
									sx={{ color: "green" }}
									onClick={() => {
										setAdminId(params.row.id);
										setModal("enable");
									}}
								>
									<DoneOutlinedIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Disable">
								<IconButton
									sx={{ color: "red" }}
									onClick={() => {
										setAdminId(params.row.id);
										setModal("disable");
									}}
								>
									<CloseOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
					</Stack>
				);
			},
		},
	];

	return (
		<>
			<Stack sx={styles.wrapper}>
				<Typography component="h1">Sub Admins</Typography>
				<Button variant="contained" sx={styles.button} onClick={handleClick}>
					Invite Sub Admin
				</Button>
			</Stack>

			<Listing
				columns={columns}
				queryName={queryNames.SUB_ADMIN}
				fetchRecords={fetchMembers}
				type="courses"
			/>

			<BoxModal
				header="Are you sure?"
				title={
					modal === "enable"
						? "Do you want to Enable this Sub Admin?"
						: "Do you want to Disable this Sub Admin?"
				}
				open={modal === "enable" ? "confirm" : modal}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton={modal === "enable" ? "Enable" : "Disable"}
				confirmButtonColor={modal === "enable" ? "green" : "red"}
				isLoading={mutation.isLoading}
			/>

			<ManageSubadminModal
				modal={modal === "invite"}
				handleClose={handleClose}
				adminData={adminRef.current}
			/>
		</>
	);
};

export default Subadmins;
