import React, { useState } from "react";
import CustomTabs from "../../../components/ui/CustomTabs";
import Documents from "./Documents";
import Photos from "./Photos";
import Sponsors from "./Sponsors";
import Speakers from "./Speakers";
import Agenda from "./Agenda";
import Post from "./Post";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { get } from "../../../server";
import { ADMIN_EVENT_DETAIL , ADMIN_FORUM_DETAIL } from "../../../data/constants/apiRoutes";
import queryNames from "../../../data/constants/queryNames";
import { useQuery, useQueryClient } from "react-query";
import Forums from "./Forums";

const EventResources = () => {
  const [eventDetail, setEventDetail] = useState();
  const [fourmsDetail, setFourmsDetail] = useState();


  const { eventId, tab } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();

  const isId = eventId !== null && typeof eventId !== "undefined";

  const fetchEventDetail = async () => {
    const { status, message, data } = await get(
      `${ADMIN_EVENT_DETAIL}/${eventId}`
    );
    if (status === true) {
      setEventDetail(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const fetchForumsDetail = async () => {
    const { status, message, data } = await get(
      `${ADMIN_FORUM_DETAIL}/${eventId}`
    );
    console.log("fetchForumsDetail", { status, message, data })
    if (status === true) {
      setFourmsDetail(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const eventDetailQuery = useQuery([queryNames.EVENTS], fetchEventDetail, {
    enabled: isId,
    keepPreviousData: true,
  });
  const fourmsDetailQuery = useQuery([queryNames.FORUMS], fetchForumsDetail, {
    enabled: isId,
    keepPreviousData: true,
  });


  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.EVENTS);
    client.invalidateQueries(queryNames.FOURMS);
    client.invalidateQueries(queryNames.POSTS);

  };

  const tabList = [
    {
      label: "Document",
      url: "document",
      component: (
        <Documents
          documents={eventDetail?.event?.documents}
          loading={eventDetailQuery.isLoading || eventDetailQuery.isFetching}
          eventId={eventId}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    {
      label: "Photos",
      url: "photo",
      component: (
        <Photos
          images={eventDetail?.event?.images}
          loading={eventDetailQuery.isLoading}
          eventId={eventId}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    {
      label: "Sponsors",
      url: "sponsor",
      component: (
        <Sponsors
          sponsors={eventDetail?.event?.sponsors}
          loading={eventDetailQuery.isLoading || eventDetailQuery.isFetching}
          event={eventDetail?.event}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    {
      label: "Speakers",
      url: "speaker",
      component: (
        <Speakers
          speakers={eventDetail?.event?.speakers}
          loading={eventDetailQuery.isLoading || eventDetailQuery.isFetching}
          event={eventDetail?.event}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    {
      label: "Agenda",
      url: "agenda",
      component: (
        <Agenda
          agendas={eventDetail?.event?.agendas}
          loading={eventDetail?.event?.speakers || eventDetailQuery.isFetching}
        />
      ),
    },
    {
      label: "Forums",
      url: "forums",
      component: (
        <Forums
          fourms={fourmsDetail?.data}
          loading={fourmsDetailQuery.isLoading || fourmsDetailQuery.isFetching}
          event={eventDetail?.event}
          eventId={eventId}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    {
      label: "Posts",
      url: "posts",
      component: (
        <Post
          event={eventDetail?.event}
          eventId={eventId}
          handleInvalidate={handleInvalidate}
        />
      ),
    },
    // {
    //   label: "Guest Speakers",
    //   url: "guest-speaker",
    //   component: (
    //     <GuestSpeakers
    //       speakers={eventDetail?.event?.guest_speakers}
    //       loading={eventDetailQuery.isLoading || eventDetailQuery.isFetching}
    //       event={eventDetail?.event}
    //       handleInvalidate={handleInvalidate}
    //     />
    //   ),
    // },
  ];

  const defaultTabIndex = tabList.findIndex((tabItem) => tabItem.url === tab);

  return (
    <>
      <CustomTabs
        tabList={tabList}
        defaultTabIndex={defaultTabIndex}
        bgcolor="#FFF"
      />
    </>
  );
};

export default EventResources;
