import { useState } from "react";
import {
	Box,
	Stack,
	Typography,
	Grid,
	IconButton,
	FormControl,
	Select,
	MenuItem,
	TextField,
	Checkbox,
} from "@mui/material";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";
import { get, post } from "../../server";
import {
	ADMIN_EMAIL_SEND,
	ADMIN_SCHEDULE_EVENTS_LISTING,
} from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import queryNames from "../../data/constants/queryNames";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LoadingButton from "../Buttons/LoadingButton";
import { validator } from "../../utils/helpers/validator";
import EmailSchema from "../../utils/schemas/EmailSchema";
import GetAddressModal from "../Modals/GetAddressModal";

const styles = {
	modal: {
		"& .MuiPaper-root ": {
			width: 822,
			left: "unset",
			mr: 3,
			minHeight: 600,
		},
		// position: "absolute",
		// top: 0,
		// right: 0,
		// maxWidth: 800,
		// width: "100%",
		// height: "100%",
		// overflowY: "auto",
		// transition: "all 0.3s ease",
	},
	container: {
		bgcolor: "#fff",
		px: { md: 3, xs: 2 },
		py: 2,
	},
	logoHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		pt: 3,
		pb: 2,
	},
	logo: {
		width: { md: 80, xs: 60 },
		height: { md: 80, xs: 60 },
		borderRadius: "50%",
	},
	iconWrapper: {
		flexDirection: "row",
		alignItems: "center",
		columnGap: 1,
		"& svg": {
			width: 22,
			height: 22,
		},
	},
	topStack: {
		justifyContent: "space-between",
		alignItems: "center",
		bgcolor: "rgba(226, 227, 228, 0.20)",
		pl: "31px",
		py: "12px",
		pr: "20px",
		borderBottom: "1px solid var(--light-background-color-2, #E2E3E4)",
		"& h1": {
			color: "#1E1E1F",
			fontSize: 24,
			fontWeight: 500,
			lineHeight: "36px",
		},
	},
	select: {
		positon: "relative",
		"& .MuiInputBase-root": {
			"&:before": {
				borderBottom: "1px solid #E2E3E4",
			},
			"&:hover:not(.Mui-disabled):before": {
				borderBottom: "1px solid #E2E3E4",
			},
		},
		"& .MuiInputBase-input": {
			cursor: "pointer",
			py: 1.5,
			pl: 8,
		},
		"& label": {
			fontSize: 16,
			color: "#767D90",
			position: "absolute",
			top: 10,
		},
	},
	subject: {
		pt: "10px",
		"& .MuiInputBase-root": {
			"&:before": {
				borderBottom: "1px solid #E2E3E4",
			},
			"&:hover:not(.Mui-disabled):before": {
				borderBottom: "1px solid #E2E3E4",
			},
		},
		"& .MuiInputBase-input": {
			py: 1.5,
		},
		"& input": {
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 16,
			},
		},
	},
	city: {
		pt: 0,
		"& input": {
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 16,
			},
			cursor: "pointer",
		},
	},
	message: {
		pt: "15px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: 0,
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
		"& .MuiOutlinedInput-root": {
			px: 0,
			pt: 0,
		},
		"& input": {
			fontSize: 16,
		},
		"& .MuiInputBase-input::placeholder": {
			color: "#767D90",
			fontWeight: 400,
			opacity: 1,
			fontSize: 16,
		},
		"& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
	},
	push_message: {
		pt: "15px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: 0,
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
		"& .MuiOutlinedInput-root": {
			px: 0,
			pt: 2,
			"& fieldset": {
				borderTop: "1px solid #E2E3E4",
			},
		},
		"& input": {
			fontSize: 16,
		},
		"& .MuiInputBase-input::placeholder": {
			color: "#767D90",
			fontWeight: 400,
			opacity: 1,
			fontSize: 16,
		},
		"& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
	},
	button: {
		width: 120,
		textTransform: "capitalize",
		borderRadius: "4px",
		bgcolor: "#2B2A69",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
	closeIcon: {
		cursor: "pointer",
	},
	pushStack: {
		"& label": {
			fontSize: 16,
			color: "#767D90",
		},
	},
};

const initialState = {
	send_to: "",
	subject: "",
	city: "",
	state: "",
	event_id: "",
	message: "",
	push_message: "",
	latitude: null,
	longitude: null,
};

const send_to = [
	{ value: "company", label: "Company" },
	{ value: "members", label: "Members" },
	{ value: "attendee", label: "Attendees" },
];

const EmailDrawer = ({ modal, setModal, handleInvalidate }) => {
	const [email, setEmail] = useState(initialState);
	const [push, setPush] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [events, setEvents] = useState([]);

	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setModal(false);
		setEmail(initialState);
		handleInvalidate();
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value =
			name === "push_message" ? e.target.value.slice(0, 200) : e.target.value;

		setEmail({ ...email, [name]: value });
	};

	const fetchEvents = async () => {
		const { status, message, data } = await get(ADMIN_SCHEDULE_EVENTS_LISTING);
		if (status === true) {
			const newData = data.data.map((single) => ({
				value: single.id,
				label: single.event_name,
			}));
			setEvents(newData);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const eventQuery = useQuery(queryNames.EVENTS, fetchEvents, {
		refetchOnWindowFocus: false,
	});

	const handleSelectChange = (event) => {
		const {
			target: { value, name },
		} = event;
		setEmail({
			...email,
			[name]: value,
		});
	};

	const validateForm = () => {
		const newErrors = validator(email, EmailSchema);

		if (Object.keys(newErrors).length) {
			for (const key in newErrors) {
				if (newErrors.hasOwnProperty(key)) {
					const errorMessage = newErrors[key];
					enqueueSnackbar(errorMessage, { variant: "error" });
				}
			}
			return false;
		}
		return true;
	};

	const handleSendEmail = async () => {
		const { status, message, data } = await post(ADMIN_EMAIL_SEND, email);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			setModal(false);
		} else enqueueSnackbar(message, { variant: "error" });
	};

	const mutation = useMutation(handleSendEmail);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const saveLocation = (address, location, place) => {
		setEmail({
			...email,
			latitude: location.latitude,
			longitude: location.longitude,
			city: place?.city,
			state: place?.state,
		});
	};

	return (
		<>
			<SwipeableDrawer
				open={modal}
				onClose={handleClose}
				anchor="bottom"
				sx={styles.modal}
			>
				<Stack direction="row" sx={styles.topStack}>
					<Typography component="h1">New Message</Typography>
					<Close style={styles.closeIcon} onClick={handleClose} />
				</Stack>
				<Box sx={styles.container} role="presentation">
					<Stack direction="row" alignItems="center" sx={styles.pushStack}>
						<Checkbox
							size="small"
							value={push}
							inputProps={{ readOnly: true }}
							onChange={(e) => setPush(e.target.checked)}
						/>
						<Typography component="label">Push Notification</Typography>
					</Stack>

					<FormControl fullWidth variant="standard" sx={styles.select}>
						<Select
							value={email?.send_to}
							name="send_to"
							onChange={handleSelectChange}
							renderValue={(selected) => {
								if (selected?.length === 0) {
									return <span>To</span>;
								}
								const found = send_to.find(
									(single) => single?.value === selected
								);

								return found?.label;
							}}
						>
							{send_to.map((to, index) => (
								<MenuItem value={to.value}>{to.label}</MenuItem>
							))}
						</Select>
						<Typography component="label">To</Typography>
						{email?.send_to && (
							<IconButton
								sx={{
									width: 30,
									height: 30,
									position: "absolute",
									right: 20,
									top: 8,
									"& svg": {
										height: 11,
										width: 15,
										"& path": {
											color: "#767D90",
										},
									},
								}}
								onClick={() => setEmail({ ...email, send_to: "" })}
							>
								<Close />
							</IconButton>
						)}
					</FormControl>

					<TextField
						placeholder="Subject"
						value={email?.subject}
						onChange={handleChange}
						name="subject"
						variant="standard"
						sx={styles.subject}
						fullWidth
					/>

					<Grid container spacing={4} pt="10px">
						<Grid item md={4} sm={12}>
							<TextField
								placeholder="City"
								value={email?.city}
								variant="standard"
								sx={{ ...styles.subject, ...styles.city }}
								fullWidth
								inputProps={{ readOnly: true }}
								onClick={() => setAddressModal(true)}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<TextField
								placeholder="State"
								value={email?.state}
								variant="standard"
								sx={{ ...styles.subject, ...styles.city }}
								fullWidth
								inputProps={{ readOnly: true }}
								onClick={() => setAddressModal(true)}
							/>
						</Grid>

						<Grid item md={4} sm={12}>
							<FormControl fullWidth variant="standard" sx={styles.select}>
								<Select
									value={email?.event_id}
									name="event_id"
									onChange={handleSelectChange}
									renderValue={(selected) => {
										if (selected?.length === 0) {
											return <span>To</span>;
										}
										const found = events.find(
											(single) => single.value === selected
										);

										return found.label;
									}}
								>
									{events.map((event, index) => (
										<MenuItem value={event.value} key={index}>
											{event.label}
										</MenuItem>
									))}
								</Select>
								<Typography component="label">Event</Typography>
							</FormControl>
						</Grid>
					</Grid>

					<TextField
						placeholder="Message here..."
						value={email?.message}
						onChange={handleChange}
						name="message"
						variant="outlined"
						sx={styles.message}
						fullWidth
						multiline
						rows={15}
					/>
					{push && (
						<TextField
							placeholder="Push Message here... (Max 200 Chars)"
							value={email?.push_message}
							onChange={handleChange}
							name="push_message"
							variant="outlined"
							sx={styles.push_message}
							fullWidth
							multiline
							rows={3}
						/>
					)}

					<Stack
						direction="row"
						justifyContent="flex-end"
						borderTop="1px solid #E2E3E4"
						pt="13px"
					>
						<LoadingButton
							variant="contained"
							sx={styles.button}
							onClick={handleSubmit}
							isLoading={mutation.isLoading}
						>
							Send
						</LoadingButton>
					</Stack>
				</Box>
			</SwipeableDrawer>

			{/* <EmailToModal modal={addressModal} setModal={setAddressModal} /> */}
			<GetAddressModal
				modal={addressModal}
				setModal={setAddressModal}
				saveLocation={saveLocation}
			/>
		</>
	);
};

export default EmailDrawer;
