import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import LoadingButton from "../../components/Buttons/LoadingButton";
import GoBackButton from "../../components/Buttons/GoBackButton";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import FormInput from "../../components/Forms/FormInput";
import FormLabel from "../../components/Forms/FormLabel";
import { useRef } from "react";
import GetAddressModal from "../../components/Modals/GetAddressModal";
import CropImage from "../../components/Modals/CropImage";
import { useMutation, useQuery } from "react-query";
import { validator } from "../../utils/helpers/validator";
import _ from "lodash";
import awsFileUpload from "../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../data/constants/uploadImageTypes";
import { get, post } from "../../server";
import routes from "../../data/constants/routes";
import {
	ADMIN_SPONSOR_ADD_OR_UPDATE,
	ADMIN_SPONSOR_DETAIL,
} from "../../data/constants/apiRoutes";
import SponsorSchema from "../../utils/schemas/SponsorSchema";
import queryNames from "../../data/constants/queryNames";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";

const styles = {
	title: {
		pb: 2.5,
		flexWrap: "wrap",
		"& h1": {
			fontSize: 22,
			fontWeight: 600,
			color: "#1E1E1F",
			flex: 1,
		},
		"& button": {
			width: 150,
			bgcolor: "#2B2A69",
			color: "#FFF",
			fontWeight: 600,
			textTransform: "capitalize",
			px: 3,
			"&:hover": {
				bgcolor: "#2B2A69",
				color: "#FFF",
			},
		},
		"& h4": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 500,
			lineHeight: "36px",
			mb: 1,
		},
	},
	constainer: {
		bgcolor: "#FFF",
		borderRadius: "12px",
		py: 4,
		px: "30px",
		"& p": {
			color: "#0F4590",
			fontSize: 16,
			fontWeight: 500,
			mt: 1,
			cursor: "pointer",
		},
		"& h4": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 500,
			lineHeight: "36px",
			mb: 1,
		},
	},
	imageContainer: {
		borderRadius: "4px",
		border: "1px dashed var(--light-background-color-2, #E2E3E4)",
		bgcolor: "#FBFCFF",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: 2,
		p: 3,
		height: 200,
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
	coverImageContainer: {
		borderRadius: "4px",
		border: "1px solid #eee",
		height: 200,
		width: "100%",
		objectFit: "cover",
	},
	coverHover: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		gap: "10px",
		transition: "display 0.3s ease",
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
};

const initialState = {
	sponser_name: "",
	email: "",
	company_name: "",
	website: "",
	address: "",
	latitude: "",
	longitude: "",
	description: "",
	image: "",
};

const AddEditSponsor = () => {
	const [sponsor, setSponsor] = useState(initialState);
	const [modal, setModal] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [coverImage, setCoverImage] = useState(null);

	const [errors, setErrors] = useState({});
	const [isCoverHovered, setIsCoverHovered] = useState(false);

	const { sponsorId } = useParams();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const isId = sponsorId !== null && typeof sponsorId !== "undefined";

	const coverRef = useRef(null);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		const sanitizedValue = value.replace(/\s{2,}/g, " ");
		setSponsor({ ...sponsor, [name]: sanitizedValue });
	};

	const handleAddressClick = () => {
		setAddressModal("address");
	};

	const handleCoverClick = () => {
		coverRef.current.click();
	};

	const handleCoverChange = (e) => {
		const file = e.target.files[0];
		setCoverImage(URL.createObjectURL(file));
		setModal("eventSponsor");
	};

	const handleCoverMouseEnter = () => {
		setIsCoverHovered(true);
	};

	const handleCoverMouseLeave = () => {
		setIsCoverHovered(false);
	};

	const saveLocation = (address, location) => {
		setSponsor({
			...sponsor,
			address: address,
			latitude: location.latitude,
			longitude: location.longitude,
		});
	};

	const fetchSponsor = async () => {
		const { status, message, data } = await get(
			`${ADMIN_SPONSOR_DETAIL}/${sponsorId}`
		);
		if (status === true) {
			setSponsor(data);
		}
	};

	const sponsorQuery = useQuery(queryNames.SPONSOR, fetchSponsor, {
		enabled: isId,
	});

	const validateForm = () => {
		const newErrors = validator(sponsor, SponsorSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleSaveSponsor = async () => {
		let payload = _.cloneDeep(sponsor);

		let newStatus = true;

		if (typeof payload.image === "object") {
			const data = await awsFileUpload(
				payload.image,
				uploadImageType.createSponsor
			);
			if (data.status === true) payload.image = data.location;
			else enqueueSnackbar("Error uploading", { variant: "error" });
		}

		if (newStatus) {
			const { status, message, data } = await post(
				ADMIN_SPONSOR_ADD_OR_UPDATE,
				payload
			);
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				navigate(routes.ADMIN_SPONSOR);
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSaveSponsor);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h1">{isId ? "Edit" : "Add"} Sponsor</Typography>
				<GoBackButton url={routes.ADMIN_SPONSOR} />
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
				>
					Save Changes
				</LoadingButton>
			</Stack>
			
			<LoadingOverlay loading={sponsorQuery.isLoading} />

			<Box sx={styles.constainer}>
				<Grid container spacing={3} mb={2}>
					<Grid item md={4} sm={12}>
						<FormInput
							label="Add Company"
							placeholder="Company"
							value={sponsor.company_name}
							name="company_name"
							onChange={handleChange}
							error={errors?.company_name}
							helperText={errors?.company_name || ""}
						/>
					</Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Sponsor Name"
							placeholder="Sponsor name"
							value={sponsor.sponser_name}
							name="sponser_name"
							onChange={handleChange}
							error={errors?.sponser_name}
							helperText={errors?.sponser_name || ""}
						/>
					</Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Email"
							placeholder="Email"
							value={sponsor.email}
							name="email"
							type="email"
							onChange={handleChange}
							error={errors?.email}
							helperText={errors?.email || ""}
						/>
					</Grid>

					<Grid item md={8} sm={12}>
						<FormInput
							label="Description"
							placeholder="Description"
							value={sponsor.description}
							name="description"
							onChange={handleChange}
							multiline
							rows={4}
							error={errors?.description}
							helperText={errors?.description || ""}
						/>
					</Grid>
					<Grid item md={4} sm={12}></Grid>

					<Grid item md={4} sm={12}>
						<FormInput
							label="Website"
							placeholder="Website"
							value={sponsor.website}
							name="website"
							type="url"
							onChange={handleChange}
							error={errors?.website}
							helperText={errors?.website || ""}
						/>
					</Grid>

					<Grid item md={8} sm={12}>
						<FormInput
							label="Address"
							placeholder="Address"
							value={sponsor.address}
							name="address"
							onClick={handleAddressClick}
							InputProps={{
								readOnly: true,
							}}
							sx={{
								"& input": {
									cursor: "pointer",
									bgcolor: "rgba(226, 227, 228, 0.2)",
									height: 40,
									padding: "6px 10px",
								},
							}}
							error={errors?.address}
							helperText={errors?.address || ""}
						/>
					</Grid>
				</Grid>
				<FormLabel label="Cover Image" />
				{!sponsor.image ? (
					<Box sx={styles.imageContainer}>
						<Typography component="label">+Add Cover Image</Typography>
						<Button variant="outlined" onClick={handleCoverClick}>
							Browse Image
						</Button>
						<input
							type="file"
							hidden
							onChange={handleCoverChange}
							id={`documentUpload`}
							ref={coverRef}
							accept="image/*"
						/>
					</Box>
				) : (
					<Box
						position="relative"
						onMouseEnter={handleCoverMouseEnter}
						onMouseLeave={handleCoverMouseLeave}
					>
						<Box
							component="img"
							src={
								typeof sponsor.image === "string"
									? sponsor.image
									: URL.createObjectURL(sponsor.image)
							}
							sx={{
								...styles.coverImageContainer,
								opacity: isCoverHovered ? 0.3 : 1,
								transition: "opacity 0.3s ease",
							}}
							loading="lazy"
						/>
						<Stack
							sx={{
								...styles.coverHover,
								display: isCoverHovered ? "flex" : "none", // Control visibility
								opacity: isCoverHovered ? 1 : 0.5, // Control opacity
							}}
						>
							<Typography component="label">+Add Cover Image</Typography>
							<Button variant="outlined" onClick={handleCoverClick}>
								Browse Image
							</Button>
							<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
							/>
						</Stack>
					</Box>
				)}
			</Box>

			<CropImage
				modal={modal}
				setModal={setModal}
				image={coverImage}
				user={sponsor}
				setUser={setSponsor}
				ratio={16 / 9}
			/>

			<GetAddressModal
				modal={addressModal === "address" || false}
				setModal={setAddressModal}
				saveLocation={saveLocation}
			/>
		</>
	);
};

export default AddEditSponsor;
