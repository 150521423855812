import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import routes from "../../data/constants/routes";
import PageLoader from "../PageLoader/PageLoader";

const CheckIfLoggedIn = () => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		// Simulate data loading (replace with your actual data loading logic)
		// Once the data is loaded, set dataLoaded to true
		setTimeout(() => {
			setDataLoaded(true);
		}, 1000); // Adjust the delay as needed

		return () => {
			// Cleanup if necessary
		};
	}, []);

	useEffect(() => {
		if (dataLoaded) {
			if (Object.keys(loggedUser).length !== 0) {
				if (pathname === "/" || pathname === routes.ADMIN_LOGIN)
					navigate(routes.ADMIN_HOME);
				// else navigate(pathname);
			} else {
				navigate(routes.ADMIN_LOGIN);
			}
		}
	}, [dataLoaded, loggedUser, pathname, navigate]);

	return dataLoaded ? <Outlet /> : <PageLoader />;
};

export default CheckIfLoggedIn;
