import {
	Box,
	Button,
	Stack,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../../assets/styles/events/createEvent.styles";
import { styled } from "@mui/material/styles";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import { ReactComponent as Detail } from "../../assets/svg/eventIcons/stepperIcons/Info.svg";
import { ReactComponent as Members } from "../../assets/svg/eventIcons/stepperIcons/Members.svg";
import { ReactComponent as Agenda } from "../../assets/svg/eventIcons/stepperIcons/Agenda.svg";
import { ReactComponent as Ticket } from "../../assets/svg/eventIcons/stepperIcons/Tickets.svg";
import { ReactComponent as FAQ } from "../../assets/svg/eventIcons/stepperIcons/FAQ.svg";
import EventDetailForm from "./CreateEventTabs/EventDetailForm";
import EventMembrs from "./CreateEventTabs/EventMembrs";
import EventAgenda from "./CreateEventTabs/EventAgenda";
import EventTickets from "./CreateEventTabs/EventTickets";
import EventFAQs from "./CreateEventTabs/EventFAQs";
import EventPreview from "./CreateEventTabs/EventPreview";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import queryNames from "../../data/constants/queryNames";
import { get } from "../../server";
import { ADMIN_EVENT_DETAIL } from "../../data/constants/apiRoutes";
import moment from "moment";
import LoadingOverlay from "../../components/overlay/LoadingOverlay";
import GoBackButton from "../../components/Buttons/GoBackButton";
import useGoogleMapsApi from "../../utils/helpers/useGoogleMapsApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#0F4590",
			transition: "background-color 0.3s ease", // Add a smooth transition
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#0F4590",
			transition: "background-color 0.3s ease", // Add a smooth transition
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EAECFF",
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#EAECFF",
	zIndex: 1,
	color: "#fff",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	"& path": {
		fill: "#0F4590",
	},
	...(ownerState.active && {
		backgroundColor: "#0F4590",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
		"& path": {
			fill: "#FFF",
		},
	}),
	...(ownerState.completed && {
		backgroundColor: "#0F4590",
		"& path": {
			fill: "#FFF",
		},
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <Detail />,
		2: <Members />,
		3: <Agenda />,
		4: <Ticket />,
		5: <FAQ />,
	};

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
		>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}
const initialTicketState = {
	ticket_type: "whole_event",
	title: "",
	quantity_available: "",
	type: "",
	price: "",
	generate_qr_code: false,
	ticket_sale_start: null,
	ticket_sale_end: null,
	ticket_description: "",
	agenda_id: "",
};

const initialGuestSpeakerState = {
	name: "",
	email: "",
	bio: "",
	image: "",
	address: "",
	mobile: null,
};

const initialState = {
	details: {
		event_step: 1,
		company_id: "",
		event_name: "",
		venue_name: "",
		address: "",
		latitude: null,
		longitude: null,
		event_type: "",
		document: [],
		image: [],
		start_time: null,
		end_time: null,
		description: "",
		hotel: false,
		hotel_reservation_url: "",
		hotel_description: "",
		hotel_reservation_end_date: null,
		cover_image: "",
	},
	members: {
		event_step: 2,
		member_id: [],
		speaker_id: [],
		members_email: [],
		speakers_email: [],
		sponsors: [],
		speakers: [],
		guest_speakers: [initialGuestSpeakerState],
	},
	agenda: {
		event_step: 3,
		agenda: [],
	},
	tickets: {
		event_step: 4,
		ticket_sessions: [{ ...initialTicketState }],
	},
	faq: {
		event_step: 5,
		event_FAQs: [],
	},
};

const CreateEvent = () => {
	const [eventForm, setEventForm] = useState(initialState);
	const [currentStep, setCurrentStep] = useState(0);
	const [finalEvent, setFinalEvent] = useState();

	const steps = ["Event Details", "Members", "Agenda", "Tickets", "FAQ"];

	const { eventId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const isId = eventId !== null && typeof eventId !== "undefined";

	const handleGoBack = () => {
		setCurrentStep(currentStep - 1);
	};

	const fetchEvent = async () => {
		const { status, message, data } = await get(
			`${ADMIN_EVENT_DETAIL}/${eventId}`
		);
		console.log(data);
		if (status === true) {
			const members = data?.event?.members?.map(
				(member) => member?.event_member?.id
			);
			const speakers = data?.event?.speakers?.map(
				(speaker) => speaker?.event_speaker?.id
			);
			const sponsors = data?.event?.sponsors?.map((sponsor) => ({
				company_sponser_id: sponsor?.company_sponsor.id,
				id: sponsor?.company_sponsor?.id,
			}));
			const newMembers = data?.event?.members?.map((member) => ({
				value: member?.event_member?.id,
				id: member?.event_member?.id,
				label: member?.event_member.full_name,
				full_name: member?.event_member.full_name,
				email: member?.event_member.email,
				profile_image: member?.event_member.profile_image,
			}));
			const newSpeakers = data?.event?.speakers
				?.filter(
					(speaker) => speaker?.event_speaker?.is_guest_speaker === false
				)
				.map((speaker) => ({
					value: speaker?.id,
					id: speaker?.event_speaker?.id,
					label: speaker?.event_speaker.full_name,
					full_name: speaker?.event_speaker.full_name,
					email: speaker?.event_speaker.email,
					profile_image: speaker?.event_speaker.profile_image,
				}));
			const newGuestSpeakers = data?.event?.speakers
				?.filter((speaker) => speaker?.event_speaker?.is_guest_speaker === true)
				.map((speaker) => ({
					value: speaker?.id,
					id: speaker?.event_speaker?.id,
					label: speaker?.event_speaker.full_name,
					name: speaker?.event_speaker.full_name,
					email: speaker?.event_speaker.email,
					image: speaker?.event_speaker.profile_image,
					bio: speaker?.event_speaker?.description,
					phone: speaker?.event_speaker?.mobile_number,
					address: speaker?.event_speaker?.address,
				}));
			const newAgendas = data?.event?.agendas?.map((agenda) => ({
				start_session_time: moment(agenda?.start_session_time),
				end_session_time: moment(agenda?.end_session_time),
				speaker_id: agenda?.agenda_speaker?.id,
				sponser_id: agenda?.agenda_sponsor?.id,
				description: agenda?.description,
				title: agenda?.title,
				id: agenda?.id,
			}));
			let newTickets = data?.event?.tickets?.map((ticket) => ({
				...ticket,
				ticket_sale_start: moment(ticket?.ticket_sale_start),
				ticket_sale_end: moment(ticket?.ticket_sale_end),
			}));

			if (!newTickets.length) newTickets = [{ ...initialTicketState }];

			const documents = data?.event?.documents?.map(
				(document) => document.name
			);
			const images = data?.event?.images?.map((image) => image.name);

			const modifiedData = {
				details: {
					...initialState.details,
					company_id: data?.event?.company_id,
					event_name: data?.event?.event_name,
					venue_name: data?.event?.venue_name,
					address: data?.event?.address,
					latitude: data?.event?.latitude,
					longitude: data?.event?.longitude,
					event_type: data?.event?.event_type,
					document: documents,
					image: images,
					start_time: moment(data?.event?.start_time),
					end_time: moment(data?.event?.end_time),
					description: data?.event?.description,
					hotel_reservation_url: data?.event?.hotel_reservation_url,
					hotel_description: data?.event?.hotel_description,
					hotel_reservation_end_date: moment(
						data?.event?.hotel_reservation_end_date
					),
					cover_image: data?.event?.cover_image,
					id: Number(eventId),
					hotel:
						data?.event?.hotel_description &&
						data?.event?.hotel_reservation_end_date &&
						data?.event?.hotel_reservation_url
							? true
							: false,
				},
				members: {
					...initialState.members,
					member_id: members,
					speaker_id: speakers,
					sponsors: sponsors,
					members: newMembers,
					speakers: newSpeakers,
					event_id: Number(eventId),
					guest_speakers: newGuestSpeakers?.length
						? newGuestSpeakers
						: [initialGuestSpeakerState],
				},
				agenda: {
					...initialState.agenda,
					agenda: newAgendas,
					event_id: Number(eventId),
				},
				tickets: {
					...initialState.tickets,
					ticket_sessions: newTickets,
					event_id: Number(eventId),
				},
				faq: {
					...initialState.faq,
					event_FAQs: data?.event?.faqs,
					event_id: Number(eventId),
				},
				isFeatured: data?.event?.is_featured,
			};
			setEventForm(modifiedData);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const eventQuery = useQuery([queryNames.EVENTS, eventId], fetchEvent, {
		enabled: isId,
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<LoadingOverlay loading={eventQuery.isLoading} />

			{currentStep !== 5 && (
				<Box>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography component="h1" sx={styles.header}>
							Create Event
						</Typography>
						<GoBackButton url={-1} text="Cancel" />
						{/* <Button
							variant="contained"
							sx={styles.btn}
							onClick={() => navigate(-1)}
						>
							<ArrowBackIcon sx={{ mr: 1 }} />
							Cancel
						</Button> */}
					</Stack>

					<Stepper
						alternativeLabel
						activeStep={currentStep}
						connector={<ColorlibConnector />}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
			)}
			{currentStep === 0 && (
				<EventDetailForm
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					eventId={eventId}
				/>
			)}

			{currentStep === 1 && (
				<EventMembrs
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					handleGoBack={handleGoBack}
				/>
			)}

			{currentStep === 2 && (
				<EventAgenda
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					handleGoBack={handleGoBack}
				/>
			)}

			{currentStep === 3 && (
				<EventTickets
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					handleGoBack={handleGoBack}
				/>
			)}

			{currentStep === 4 && (
				<EventFAQs
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					handleGoBack={handleGoBack}
					setFinalEvent={setFinalEvent}
				/>
			)}

			{currentStep === 5 && (
				<EventPreview
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					eventForm={eventForm}
					setEventForm={setEventForm}
					handleGoBack={handleGoBack}
					finalEvent={finalEvent}
				/>
			)}
		</>
	);
};

export default CreateEvent;
