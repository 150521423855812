import { Stack, Typography, Button, Box } from "@mui/material";
import React from "react";
import { subscriptionColors } from "../../../data/constant";
import Listing from "../../ui/Listing";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";

const styles = {
	header: {
		bgcolor: "#fff",
		borderTopLeftRadius: "12px",
		borderTopRightRadius: "12px",
		p: 3,
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		borderBottom: "1px solid #F1F1F1",
		mt: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 18,
			fontWeight: 600,
		},
		"& button": {
			borderColor: "#293277",
			color: "#293277",
			textTransform: "capitalize",
			fontWeight: 600,
		},
	},
};

const columns = [
	{
		field: "event_name",
		headerName: "Event Name",
		sortable: false,
		flex: 1,
	},
	{
		field: "company_name",
		headerName: "Company Name",
		sortable: false,
		flex: 1,
	},
	{
		field: "start_time",
		headerName: "Start Time",
		sortable: false,
		flex: 1,
	},
	{
		field: "venue_name",
		headerName: "Venue",
		sortable: false,
		flex: 1,
	},
];

function FeaturedEvents({ events, loading }) {
	const navigate = useNavigate();
	return (
		<>
			<Stack sx={styles.header}>
				<Typography component="h2">Featured Events</Typography>
				<Button
					variant="outlined"
					onClick={() => navigate(routes.ADMIN_FEATURED_EVENTS)}
				>
					View All
				</Button>
			</Stack>
			<Listing
				rows={events || []}
				columns={columns}
				className="courses"
				mode="client"
				loading={loading}
			/>
		</>
	);
}

export default FeaturedEvents;
