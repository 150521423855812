import { Avatar, Stack, Typography, Checkbox } from "@mui/material";
import moment from "moment/moment";
import React from "react";

const styles = {
	wrapper: {
		overflowY: "auto",
		alignItems: "center",
		bgcolor: "white",
		justifyContent: "space-between",
		pl: 1.125,
		py: 1.5,
		pr: 3.625,
		borderBottom: "1px solid #F1F1F1",
		"& h2": {
			color: "#333",
			pl: 1.5,
			pr: 2.5,
			width: 150,
		},
		"& h3": {
			color: "#333",
		},
		"& p": {
			color: "#767D90",
			pr: 4,
			display: "-webkit-box",
			"-webkitBoxOrient": "vertical",
			"-webkitLineClamp": "1",
			overflow: "hidden",
		},
	},
	All: {
		flexDirection: { md: "row", xs: "column" },
		// alignItems: 'center',
	},
	avatar: {
		ml: 0.5,
		width: 36,
		height: 36,
		borderRadius: "50%",
	},
	unread: {
		bgcolor: "#FAFAFA",
	},
};

function EmailRow({
	avatar = "",
	user_name = "",
	message = "",
	time = "",
	status = "",
	isSelected = false,
	onSelect,
	id,
	key
}) {
	const formattedTime = formatTime(time);

	function formatTime(time) {
		const now = moment();
		const messageTime = moment(time);

		if (now.isSame(messageTime, "day")) {
			return `Today, ${messageTime.format("hh:mm A")}`;
		} else {
			return messageTime.format("D MMM");
		}
	}

	return (
		<>
			<Stack
				direction="row"
				sx={{ ...styles.wrapper, ...(status === "read" && styles.unread) }}
				key={key}
			>
				<Stack sx={styles.All} gap={2}>
					<Stack direction="row" alignItems="center">
						{/* <Checkbox
							// checked={check}
							// onChange={() => setCheck(!check)}
							checked={isSelected}
							onChange={() => onSelect(id)}
							inputProps={{ "aria-label": "controlled" }}
							sx={styles.checkbox}
						/> */}
						<Avatar src={avatar ? avatar : ""} sx={styles.avatar} />
						<Typography component="h2">{user_name}</Typography>
					</Stack>
					<Typography component="p">{message}</Typography>
				</Stack>

				<Typography component="h3" width={120}>
					{formattedTime}
				</Typography>
			</Stack>
		</>
	);
}

export default EmailRow;
