
const styles = {
	mainHeader: {
		borderTopLeftRadius: "12px",
		borderTopRightRadius: "12px",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		borderBottom: "1px solid #F1F1F1",
		pb: 3,
		"& h1": {
			color: "#1E1E1F",
			fontSize: "22px",
			fontWeight: 600,
		},
		"& button": {
			bgcolor: "#2b2a69",
			textTransform: "capitalize",
			padding: "8px 19px",
		},
	},
	wrapper: {
		bgcolor: "#fff",
		borderRadius: 4,
		p: { md: 4, xs: 2 },
		"& .MuiOutlinedInput-root": {
			backgroundColor: "#F9F9F9",
			borderRadius: "6px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E2E3E4",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},

		"& input": {
			py: "13px",
			px: 2,
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	select: {
		"& .MuiOutlinedInput-input": {
			py: "13px",
			px: 2,
			"&:first-child": {
				color: "#767D90",
			},
		},
	},
	topWrapper: {
		// mb: 4,
		// borderBottom: "1px solid #e2e3e4",
		// "@media (max-width: 1050px)": {
		// 	fontSize: 8,
		// 	maxWidth: 45,
		// },
	},
	avatar: {
		height: { md: 110, xs: 80 },
		width: { md: 110, xs: 80 },
	},
	btnWrapper: {
		flexDirection: { md: "column", xs: "row" },
		gap: { md: 2, xs: 1.5 },
	},
	avatarWrapper: {
		columnGap: { md: 3, xs: 2 },
	},
	editBtn: {
		bgcolor: "#e2d7e5",
		color: "#2B2A69",
		py: 1.5,
		px: 3,
		fontSize: { md: 12, xs: 10 },
		fontWeight: 600,
		textTransform: "capitalize",
		whiteSpace: "nowrap",
		maxWidth: { md: "100%", xs: 100 },
	},
	deleteBtn: {
		bgcolor: "#ffeeee",
		color: "#FF5757",
		py: 1.5,
		fontSize: { md: 12, xs: 10 },
		px: 3,
		fontWeight: 600,
		textTransform: "capitalize",
		maxWidth: { md: "100%", xs: 100 },
		whiteSpace: "nowrap",
	},

	fileUpload: {
		height: "130px",
		bgcolor: "#FBFCFF",
		border: "2px dashed #E2E3E4",
		borderRadius: "4px",
	},
	fileText: {
		color: "#767D90",
		fontWeight: 400,
		cursor: "pointer",
		maxWidth: 270,
		textAlign: { md: "left", xs: "center" },
		fontSize: { md: 14, xs: 12 },
	},
	imgBtn: {
		bgcolor: "#FFFFFF",
		color: "#2B2A69",
		border: " 1px solid #2B2A69",
		cursor: "pointer",
		textTransform: "capitalize",
		fontWeight: 600,
		"&:hover": {
			bgcolor: "#E2E3E4",
		},
	},
	header: {
		fontWeight: 600,
		fontSize: 18,
		color: "#1E1E1F",
		mb: 3,
	},
	subHeader: {
		color: "#767D90",
		marginBottom: "7px",
	},
	input: {
		"& input": {
			height: "40px",
			background: "rgba(226, 227, 228, 0.2)",
			border: "1px solid #E2E3E4",
			borderRadius: "4px",
			padding: "6px 10px",
			fontWeight: "600",
		},
	},
	fileBox: {
		height: 130,
		p: { md: "0 56px", xs: 2.5 },
		bgcolor: " #fbfcff",
		border: "2px dashed #e2e3e4",
		borderRadius: "4px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: { md: "row", xs: "column" },
	},
	searchInput: {
		"& input": {
			height: "28px",
		}
	}
};

export default styles