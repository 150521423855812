import { Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const styles = {
  pb: 1,
  "& h3": {
    color: "#1E1E1F",
    fontSize: 16,
    fontWeight: 600,
  },
  "& a": {
    color: "#0F4590",
  },
};

function CardHeader({ title, url }) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles}
      >
        <Typography component="h3">{title}</Typography>
        <Link to={url}>View All</Link>
      </Stack>
    </>
  );
}

export default CardHeader;
