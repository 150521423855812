import React from "react";
import Logo from "../../assets/images/Logo.png";
import { Box, LinearProgress } from "@mui/material";

const styles = {
	container: {
		display: "flex",
		height: "100vh",
		justifyContent: "center",
		flexDirection: "column",
		gap: "5px",
		alignItems: "center",
		transition: "opacity 0.4s ease-in-out",
		opacity: 1,
	},
	logo: {
		width: 150,
	},
	progressBar: {
		width: 150,
		"& .MuiLinearProgress-bar2": {
			bgcolor: "#2B2A69",
		},
	},
};

const PageLoader = () => {
	return (
		<>
			<Box sx={styles.container}>
				<Box component="img" src={Logo} sx={styles.logo} />
				<LinearProgress sx={styles.progressBar} />
			</Box>
		</>
	);
};

export default PageLoader;
