import * as React from "react";
import Listing from "../components/ui/Listing";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";
import { useNavigate } from "react-router-dom";

const styles = {
  mainHeader: {
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    borderBottom: "1px solid #F1F1F1",
    pb: 3,
    "& h1": {
      color: "#1E1E1F",
      fontSize: "22px",
      fontWeight: 600,
    },
    "& button": {
      bgcolor: "#2b2a69",
      textTransform: "capitalize",
      fontWeight: 500,
      padding: "8px 28px",
    },
  },
};

const rows = [
  {
    id: 1,
    name: '"About Me" Poll',
    type: "Poll",
    status: "Activated",
    modified: "02/02/2023",
  },
  {
    id: 2,
    name: "Pop Quiz?",
    type: "Quiz",
    status: "Activated",
    modified: "02/02/2023",
  },
  {
    id: 3,
    name: '"About Me" Poll',
    type: "Poll",
    status: "Activated",
    modified: "02/02/2023",
  },
  {
    id: 4,
    name: "Pop Quiz?",
    type: "Quiz",
    status: "Activated",
    modified: "02/02/2023",
  },
  {
    id: 5,
    name: '"About Me" Poll',
    type: "Poll",
    status: "Activated",
    modified: "02/02/2023",
  },
  {
    id: 6,
    name: "Pop Quiz?",
    type: "Quiz",
    status: "Activated",
    modified: "02/02/2023",
  },
];

const columns = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    flex: 1,
  },
  {
    field: "modified",
    headerName: "Modified",
    sortable: false,
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 100,
    renderCell: () => {
      return (
        <Stack direction="row" spacing={0.5}>
          <IconButton>
            <Edit />
          </IconButton>
          <IconButton>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
];

function ManagePolls() {
  const navigate = useNavigate();

  return (
    <>
      <Stack sx={styles.mainHeader}>
        <Typography component="h1">Manage Polls</Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate("/create-poll")}
        >
          + Create Poll
        </Button>
      </Stack>
      <Listing rows={rows} columns={columns} />
    </>
  );
}

export default ManagePolls;
