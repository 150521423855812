import React from "react";
import PageHeader from "../../components/ui/PageHeader";
import Listing from "../../components/ui/Listing";
import { get } from "../../server";
import { ADMIN_EVENTS_LISTING_SEARCH, ADMIN_FEATURED_EVENTS_LISTING } from "../../data/constants/apiRoutes";
import queryNames from "../../data/constants/queryNames";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const FeaturedEvents = () => {
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [finalSearch, setFinalSearch] = useState("");
	const [isSearch, setIsSearch] = useState(false);


	const columns = [
		{
			field: "event_name",
			headerName: "Event Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						component="div"
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.event_name}
					</Box>
				);
			},
		},
		{
			field: "is_featured",
			headerName: "Status",
			sortable: false,
			width: 150,
			renderCell: (params) => {
				return (
					<Box
						sx={{
							// bgcolor:
							// statusColors[params.row.subscription_status]?.bgcolor,
							// color: statusColors[params.row.subscription_status]?.color,
							bgcolor: params.row.is_featured ? "#E3F2F3" : "#FCF9EC",
							color: params.row.is_featured ? "#03989E" : "#F2D048",
							textTransform: "capitalize",
							width: 70,
							height: 30,
							fontSize: 12,
							lineHeight: "30px",
							textAlign: "center",
							borderRadius: "4px",
						}}
					>
						{params.row.is_featured ? "Featured" : "Not-Featured"}
					</Box>
				);
			},
		},
		{
			field: "company_name",
			headerName: "Company Name",
			sortable: false,
			flex: 1,
		},
		{
			field: "address",
			headerName: "Address",
			sortable: false,
			flex: 1,
		},
		{
			field: "event_status",
			headerName: "Event Status",
			sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Typography component="label" textTransform="capitalize">
						{params.row.event_status}
					</Typography>
				);
			},
		},
		{
			field: "start_time",
			headerName: "Start Time",
			sortable: false,
			width: 180,
		},
		{
			field: "end_time",
			headerName: "End Time",
			sortable: false,
			width: 180,
		},
	];

	const fetchEvents = async (pageNo, limit, searchParam, isSearchParam) => {
		let url;

		if (isSearchParam === false) {
			url = new URL(ADMIN_FEATURED_EVENTS_LISTING);
		} else if (isSearchParam && searchParam.length) {
			url = new URL(ADMIN_EVENTS_LISTING_SEARCH);
			url.searchParams.append("search", searchParam);
			url.searchParams.append("type", "feature");
		}

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleCellClick = (data) => {
		if (data?.field === "event_name") {
			navigate(`/events/detail/${data.id}`);
		}
	};

	const handleSearchClick = () => {
		if (search.length) {
			setIsSearch(true);
			setFinalSearch(search);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearch("");
	};

	return (
		<>
			<PageHeader
				title="Featured Events"
				placeholder="Search Events"
				searchInput={true}
				search={search}
				setSearch={setSearch}
				isSearch={isSearch}
				handleCancleSearch={handleCancleSearch}
				handleSearchClick={handleSearchClick}
			/>
			<Listing
				columns={columns}
				queryName={`f${queryNames.EVENTS}`}
				fetchRecords={fetchEvents}
				handleCellClick={handleCellClick}
				search={finalSearch}
				isSearch={isSearch}
			/>
		</>
	);
};

export default FeaturedEvents;
