import React from "react";
import { Avatar, Box } from "@mui/material";

import CompanyLogo from "../../assets/svg/companyLogo.svg";
import { subscriptionColors } from "../../data/constant";
import Listing from "../ui/Listing";
import SearchHeader from "../ui/SearchHeader";

const rows = [
    {
        id: 1,
        companyName: "ExpertsDesk.net",
        plan: "basic",
        subscription: "inactive",
        price: 19,
        paymentDue: "Not Valid",
        profileImg: CompanyLogo,
    },
    {
        id: 2,
        companyName: "Systems.co",
        plan: "standard",
        subscription: "active",
        price: 104,
        paymentDue: "2 Days ago",
        profileImg: null,
    },
    {
        id: 3,
        companyName: "Infinity.net",
        plan: "premium",
        subscription: "active",
        price: 50,
        paymentDue: "15 Days ago",
    },
    {
        id: 4,
        companyName: "Relianceindustrie.Ltd",
        plan: "basic",
        subscription: "stopped",
        price: 19,
        paymentDue: "Not Valid",
    },
    {
        id: 5,
        companyName: "Oilandnatural.com",
        plan: "standard",
        subscription: "active",
        price: 104,
        paymentDue: "2 Days ago",
    },
    {
        id: 6,
        companyName: "LifeInsurance.net",
        plan: "premium",
        subscription: "active",
        price: 104,
        paymentDue: "2 Days ago",
    },
];

const columns = [
    {
        field: "companyName",
        headerName: "Company Name",
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <>
                    <Avatar
                        src={params.row.profileImg}
                        sx={{ bgcolor: "gray", mr: 1.5, width: 35, height: 35 }}
                    >
                        {params.row.companyName.charAt(0)}
                    </Avatar>
                    {params.row.companyName}
                </>
            );
        },
    },
    {
        field: "plan",
        headerName: "Plan",
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <Box
                    sx={{
                        textTransform: "capitalize",
                    }}
                >
                    {params.row.plan} Plan
                </Box>
            );
        },
    },
    {
        field: "subscription",
        headerName: "Subscription",
        sortable: false,
        width: 120,
        renderCell: (params) => {
            return (
                <Box
                    sx={{
                        bgcolor: subscriptionColors[params.row.subscription].bgcolor,
                        color: subscriptionColors[params.row.subscription].color,
                        textTransform: "capitalize",
                        width: 80,
                        height: 30,
                        lineHeight: "30px",
                        textAlign: "center",
                        borderRadius: "4px",
                    }}
                >
                    {params.row.subscription}
                </Box>
            );
        },
    },
    {
        field: "price",
        headerName: "Price",
        sortable: false,
        width: 100,
        renderCell: (params) => {
            return (
                <Box                >
                    $ {params.row.price}
                </Box>
            );
        },
    },
    {
        field: "paymentDue",
        headerName: "Payment Due",
        sortable: false,
        width: 150,
    },
];

function CompanyTab() {
    return (
        <>
            <SearchHeader title="Subscriptions" searchInput={true} placeholder="Search Subscriptions" />
            <Listing rows={rows} columns={columns} className="courses" />
        </>
    );
}

export default CompanyTab;
