import React, { useState } from "react";
import { useQuery } from "react-query";

import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { get } from "../server";

import { ADMIN_GET_USER_PROFILE } from "../data/constants/apiRoutes";
import LoadingOverlay from "../components/overlay/LoadingOverlay";
import queryNames from "../data/constants/queryNames";
import ViewProfile from "../components/ui/ViewProfile";

const ProfilePage = ({id}) => {
  const [userProfile, setUserProfile] = useState(null);

  //GETTING USERID FROM PARAMS 
  const { userId } = useParams();

  //FETCHING USER PROFILE API
  const fetchProfileDetails = async () => {
    const { status, message, data } = await get(
      `${ADMIN_GET_USER_PROFILE}/${userId ? userId: id}`
    );
    if (status === true) {
      setUserProfile(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  //CALLING API
  const profileDetailQuery = useQuery(
    [queryNames.EVENTS],
    fetchProfileDetails,
    {
      keepPreviousData: true,
    }
  );

  //UI
  return (
    <>
      <LoadingOverlay
        loading={profileDetailQuery.isLoading || profileDetailQuery.isFetching}
      />
      <ViewProfile receivedData={userProfile} />
    </>
  );
};

export default ProfilePage;
