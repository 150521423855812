import React from "react";
import PageHeader from "../../components/ui/PageHeader";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ImagePreviewModal from "../../components/Modals/ImagePreviewModal";
import { useQuery, useQueryClient } from "react-query";
import { get } from "../../server";
import { ADMIN_EVENT_IMAGES } from "../../data/constants/apiRoutes";
import queryNames from "../../data/constants/queryNames";
import Loader from "../../components/overlay/Loader";
import CustomPaginations from "../../components/ui/CustomPaginations";

const styles = {
	container: {
		bgcolor: "#FFF",
		py: 4.5,
		px: "28px",
		borderRadius: "6px",
		"& h4": {
			fontSize: 18,
			fontWeight: 500,
			color: "#1E1E1F",
			// flex: 1,
		},
		"& h1": {
			fontSize: 24,
			fontWeight: 500,
			color: "#E5E5E5",
			// flex: 1,
		},
	},
	imageContainer: {
		mt: 2,
		display: "flex",
		flexWrap: "wrap",
		gap: "15px",
		mb: 2,
		"& img": {
			width: 156,
			height: 143,
			borderRadius: "10px",
			objectFit: "cover",
			cursor: "pointer",
			border: "1px solid #E5E5E5",
		},
		"& last-child": {
			mb: 0,
		},
	},
};

const UploadedImages = () => {
	const [events, setEvents] = useState([]);
	const [previewImage, setPreviewImage] = useState();
	const [modal, setModal] = useState(false);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		limit: 10,
	});
	const [totalCount, setTotalCount] = useState(0);

	const client = useQueryClient();

	const handleImageClick = (image, id) => {
		setModal(true);
		setPreviewImage({ image, id });
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.EVENT_IMAGES);
	};

	const fetchEvents = async () => {
		const url = new URL(ADMIN_EVENT_IMAGES);

		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.limit);

		const { status, message, data } = await get(url.toString());
		if (status) {
			setTotalCount(data.total_count);
			setEvents(data.eventImages);
		}
	};

	const eventQuery = useQuery(
		[queryNames.EVENT_IMAGES, paginationModel.page],
		fetchEvents,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (eventQuery.isLoading || eventQuery.isFetching) return <Loader />;

	return (
		<>
			<PageHeader title="Images Uploaded by Customer" />
			<Box sx={styles.container}>
				{totalCount > 0 ? (
					events?.map((event) => (
						<Box key={event.id}>
							<Typography variant="h4">{event?.event_name}</Typography>
							<Box sx={styles.imageContainer}>
								{event?.images?.map((image) => (
									<Box
										component="img"
										src={image?.name}
										alt=""
										onClick={() => handleImageClick(image?.name, image?.id)}
										loading="lazy"
									/>
								))}
							</Box>
						</Box>
					))
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography variant="h1">No Records</Typography>
					</Box>
				)}
			</Box>

			<CustomPaginations
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				totalCount={totalCount}
			/>

			<ImagePreviewModal
				modal={modal}
				setModal={setModal}
				previewImage={previewImage}
				handleInvalidate={handleInvalidate}
			/>
		</>
	);
};

export default UploadedImages;
