import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Star } from "../../assets/svg/Star.svg";
import { ReactComponent as Info } from "../../assets/svg/Info.svg";
import { ReactComponent as WaitList } from "../../assets/svg/WaitList.svg";

const styles = {
  wrapper: {
    overflowY: "auto",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #F1F1F1",
    flexDirection: { md: "row", xs: "column" },
    px: { md: 4, xs: 2 },
    py: { md: 2.5, xs: 2 },
    "& button": {
      width: 120,
      height: 40,
      py: 1.25,
      fontWeight: 500,
      textTransform: "capitalize",
    },
  },
  iconWrapper: {
    width: { md: 70, xs: 50 },
    height: { md: 70, xs: 50 },
    minWidth: { md: 70, xs: 50 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  textWrapper: {
    overflowY: "auto",
    "& h3": {
      color: "#1E1E1F",
      fontFamilt: "Rubik",
      fontSize: 19,
      lineHeight: "24px",
      display: "-webkit-box",
      "-webkitLineClamp": "1",
      "-webkitBoxOrient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      mb: 0.5,
    },
    "& h4": {
      color: "#1E1E1F",
      fontSize: 13,
      lineHeight: "20px",
    },
    "& p": {
      color: "#767D90",
      fontSize: 11,
      lineHeight: "18px",
    },
  },
  "& h4": {
    color: "#1E1E1F",
    fontSize: 14,
  },
  "& p": {
    color: "#ACACAC",
    fontSize: 12,
  },
  danger: {
    bgcolor: "#FFEEEE",
  },
  message: {
    bgcolor: "#F5F7FF",
  },
  viewBtn: {
    bgcolor: "#2B2A69",
    color: "#FFFFF",
    "&:hover": {
      bgcolor: "#F2F2F2",
      color: "#1E1E1F",
    },
  },
  cancelBtn: {
    bgcolor: "#F2F2F2",
    color: "#1E1E1F",
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "white",
    },
  },
  by: {
    color: "#0F4590 !important",
  },
};

function StudentQueryRow({
  type = "",
  message = "",
  detail = "",
  time = "",
  by = "",
}) {
  return (
    <>
      <Stack gap={2} sx={styles.wrapper}>
        <Stack direction="row" spacing={2.5}>
          <Box sx={[styles.iconWrapper, styles.message]}>
            {type === "waitList" ? (
              <WaitList />
            ) : type === "info" ? (
              <Info />
            ) : (
              type === "star" && <Star />
            )}
          </Box>
          <Box sx={styles.textWrapper} flex={1}>
            <Typography component="h3">{message}</Typography>
            <Typography component="h4">{detail}</Typography>
            <Stack direction="row" columnGap={2}>
              <Typography component="p">{time}</Typography>
              <Typography component="p" sx={styles.by}>
                /By {by}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row" columnGap={1.625}>
          <Button variant="contained" sx={styles.viewBtn} disableElevation>
            View
          </Button>
          <Button variant="contained" sx={styles.cancelBtn} disableElevation>
            Decline
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default StudentQueryRow;
