import React from "react";
import PageHeader from "../../../components/ui/PageHeader";
import EventDetailListings from "../../../components/ui/EventDetailListings";
import {
	Avatar,
	Box,
	Button,
	Drawer,
	FormLabel,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import { useState } from "react";
import _ from "lodash";
import { ADMIN_EVENT_CREATE } from "../../../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";
import FormInput from "../../../components/Forms/FormInput";
import { useRef } from "react";
import CropImage from "../../../components/Modals/CropImage";
import GetAddressModal from "../../../components/Modals/GetAddressModal";
import { Close } from "@mui/icons-material";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { NewSponsorSchema } from "../../../utils/schemas/EventSchema";
import { validator } from "../../../utils/helpers/validator";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { post } from "../../../server";

const styles = {
	container: {
		position: "relative",
		width: 500,
		bgcolor: "#fff",
		borderTopLeftRadius: "10px",
		borderBottomLeftRadius: "10px",
		p: 2,
		"& h2": {
			fontSize: 20,
			fontWeight: 600,
			color: "#000",
			mt: 1,
			mb: 2,
			textAlign: "center",
			textTransform: "capitalize",
		},
		"& h3": {
			fontSize: 16,
			fontWeight: 600,
			color: "#000",
			mb: 1,
		},
	},
	eventImageContainer: {
		borderRadius: "4px",
		border: "1px dashed var(--light-background-color-2, #E2E3E4)",
		bgcolor: "#FBFCFF",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: 2,
		p: 3,
		height: 200,
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
	coverImageContainer: {
		borderRadius: "4px",
		border: "1px solid #eee",
		height: 200,
		width: "100%",
		objectFit: "cover",
	},
	button: {
		mt: 2,
		width: 100,
		bgcolor: "#2B2A69",
		color: "#fff",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
	coverHover: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		gap: "10px",
		transition: "display 0.3s ease",
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
};

const initialState = {
	sponser_name: "",
	email: "",
	company_name: "",
	website: "",
	address: "",
	latitude: "",
	longitude: "",
	description: "",
	image: "",
};

const Sponsors = ({ sponsors, loading, event, handleInvalidate }) => {
	const [modal, setModal] = useState(false);
	const [cropModal, setCropModal] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [newSponsor, setNewSponsor] = useState(initialState);
	const [cover_image, setCoverImage] = useState(null);

	const [errors, setErrors] = useState({});

	const coverRef = useRef(null);

	const [isCoverHovered, setIsCoverHovered] = useState(false);

	const handleChange = (e) => {
		const {
			target: { name, value },
		} = e;
		setNewSponsor({ ...newSponsor, [name]: value });
	};

	const validateForm = () => {
		const newErrors = validator(newSponsor, NewSponsorSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		if (!newSponsor.image) {
			enqueueSnackbar("Please attach Cover Image", { variant: "error" });
			return false;
		}
		return true;
	};

	const handleCoverChange = (e) => {
		const file = e.target.files[0];
		const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

		if (file.size <= maxSizeInBytes) {
			setCoverImage(URL.createObjectURL(file));
			setCropModal("eventSponsor");
		} else {
			alert("Selected cover image exceeds the 15MB size limit.");
		}
	};

	const saveLocation = (address, location) => {
		setNewSponsor({
			...newSponsor,
			address: address,
			latitude: location.latitude,
			longitude: location.longitude,
		});
	};

	const columns = [
		{
			field: "name",
			headerName: "Sponsor Name",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Stack
							direction="row"
							alignItems="center"
							sx={{ cursor: "pointer" }}
						>
							<Avatar
								src={params.row.company_sponsor.image}
								sx={{
									bgcolor: "gray",
									mr: 1.5,
									width: 35,
									height: 35,
								}}
							>
								{params.row.company_sponsor.sponser_name
									.charAt(0)
									.toUpperCase()}
							</Avatar>
							{params.row.company_sponsor.sponser_name}
						</Stack>
					</>
				);
			},
		},
		{
			field: "company_name",
			headerName: "Company Name",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.company_sponsor.company_name,
		},
		{
			field: "email",
			headerName: "Email",
			sortable: false,
			width: 180,
			valueGetter: (params) => params.row.company_sponsor.email,
		},
		{
			field: "website",
			headerName: "Website",
			sortable: false,
			flex: 1,
			valueGetter: (params) => params.row.company_sponsor.website,
		},
	];

	const handleOpenModal = () => {
		setModal(true);
	};

	const handleCoverMouseEnter = () => {
		setIsCoverHovered(true);
	};

	const handleCoverMouseLeave = () => {
		setIsCoverHovered(false);
	};

	const handleClose = () => {
		setModal(false);
		setNewSponsor(initialState);
		setCoverImage(null);
	};

	const saveSponsor = async () => {
		const payload = _.cloneDeep(newSponsor);
		let newStatus = true;

		const data = await awsFileUpload(
			payload.image,
			uploadImageType.sponsorProfile
		);
		if (data.status === true) payload.image = data.location;
		else {
			enqueueSnackbar("Error uploading", { variant: "error" });
			newStatus = false;
		}

		if (newStatus) {
			const { status, message, data } = await post(ADMIN_EVENT_CREATE, {
				event_step: 2,
				event_id: event.id,
				member_id: event?.members?.map((member) => member.event_member.id),
				speaker_id: event?.speakers?.map((speaker) => speaker.event_speaker.id),
				sponsors: [{ ...payload }],
			});
			if (status === true) {
				enqueueSnackbar("Sponsor Created Successfully", { variant: "success" });
				handleInvalidate();
				handleClose();
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(saveSponsor);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<PageHeader
				title="Sponsors"
				createBtn
				createBtnText="Change Sponsor"
				btnFnc={handleOpenModal}
			/>
			<EventDetailListings
				rows={sponsors}
				columns={columns}
				loading={loading}
			/>

			<Drawer anchor="right" open={modal} onClose={handleClose}>
				<Box sx={styles.container}>
					<Close className="closeIcon" onClick={handleClose} />
					<Typography variant="h2">Add new Sponsor</Typography>
					<Grid container spacing={3} mb={2}>
						<Grid item md={6} sm={12}>
							<FormInput
								label="Add Company"
								placeholder="Company"
								value={newSponsor.company_name}
								name="company_name"
								onChange={handleChange}
								error={errors?.company_name}
								helperText={errors?.company_name || ""}
							/>
						</Grid>

						<Grid item md={6} sm={12}>
							<FormInput
								label="Sponsor Name"
								placeholder="Sponsor name"
								value={newSponsor.sponser_name}
								name="sponser_name"
								onChange={handleChange}
								error={errors?.sponser_name}
								helperText={errors?.sponser_name || ""}
							/>
						</Grid>

						<Grid item md={6} sm={12}>
							<FormInput
								label="Email"
								placeholder="Email"
								value={newSponsor.email}
								name="email"
								type="email"
								onChange={handleChange}
								error={errors?.email}
								helperText={errors?.email || ""}
							/>
						</Grid>

						<Grid item md={6} sm={12}>
							<FormInput
								label="Website"
								placeholder="Website"
								value={newSponsor.website}
								name="website"
								type="url"
								onChange={handleChange}
								error={errors?.website}
								helperText={errors?.website || ""}
							/>
						</Grid>

						<Grid item md={12} sm={12}>
							<FormInput
								label="Description"
								placeholder="Description"
								value={newSponsor.description}
								name="description"
								onChange={handleChange}
								multiline
								rows={4}
								error={errors?.description}
								helperText={errors?.description || ""}
							/>
						</Grid>

						<Grid item md={12} sm={12}>
							<FormInput
								label="Address"
								placeholder="Address"
								value={newSponsor.address}
								name="address"
								onClick={() => setAddressModal(true)}
								InputProps={{
									readOnly: true,
								}}
								sx={{
									"& input": {
										cursor: "pointer",
										bgcolor: "rgba(226, 227, 228, 0.2)",
										height: 40,
										boderRadius: "4px",
									},
								}}
								error={errors?.address}
								helperText={errors?.address || ""}
							/>
						</Grid>
					</Grid>
					<FormLabel label="Cover Image" />
					{!newSponsor.image ? (
						<Box sx={styles.eventImageContainer}>
							<Typography component="label">+Add Cover Image</Typography>
							<Button
								variant="outlined"
								onClick={() => coverRef.current.click()}
							>
								Browse Image
							</Button>
							<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
							/>
						</Box>
					) : (
						<Box
							position="relative"
							onMouseEnter={handleCoverMouseEnter}
							onMouseLeave={handleCoverMouseLeave}
						>
							<Box
								component="img"
								src={
									typeof newSponsor.image === "string"
										? newSponsor.image
										: URL.createObjectURL(newSponsor.image)
								}
								sx={{
									...styles.coverImageContainer,
									opacity: isCoverHovered ? 0.3 : 1,
									transition: "opacity 0.3s ease",
								}}
								loading="lazy"
							/>
							<Stack
								sx={{
									...styles.coverHover,
									display: isCoverHovered ? "flex" : "none", // Control visibility
									opacity: isCoverHovered ? 1 : 0.5, // Control opacity
								}}
							>
								<Typography component="label">+Add Cover Image</Typography>
								<Button
									variant="outlined"
									onClick={() => coverRef.current.click()}
								>
									Browse Image
								</Button>
							</Stack>
						</Box>
					)}
					<LoadingButton
						variant="contained"
						onClick={handleSubmit}
						isLoading={mutation.isLoading}
						sx={styles.button}
					>
						Submit
					</LoadingButton>
				</Box>

				<CropImage
					modal={cropModal}
					setModal={setCropModal}
					image={cover_image}
					user={newSponsor}
					setUser={setNewSponsor}
					ratio={16 / 9}
				/>

				<GetAddressModal
					modal={addressModal}
					setModal={setAddressModal}
					saveLocation={saveLocation}
				/>
			</Drawer>
		</>
	);
};

export default Sponsors;
