import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import TopImg from "../../assets/svg/TopImg.svg";
import Logo from "../../assets/images/Logo.png"
import { Link } from "react-router-dom";
import LoadingButton from "../Buttons/LoadingButton";

const styles = {
	sideImg: {
		width: "100%",
		height: "100vh",
		objectFit: "cover",
	},
	leftSide: {
		height: "100vh",
		overflow: "hidden",
		display: { md: "flex", sm: "none", xs: "none" },
	},
	rightSide: {
		height: "100vh",
		overflowY: "auto",
		pt: { md: "85px", sm: "50px", xs: "50px" },
		px: { lg: "130px", md: "65px", xs: "20px" },
		pb: 2,
		position: "relative",
		"& h2": {
			fontWeight: 600,
			color: "#1E1E1F",
			pt: 2.5,
			fontSize: 24,
			lineHeight: "30px",
		},
		"& h6": {
			color: "#5F6368",
			fontSize: 16,
			fontWeight: 400,
			pt: 1,
		},
		"& p": {
			fontSize: 14,
		},
	},
	topImg: {
		position: "fixed",
		top: 0,
		right: 0,
		height: 120,
	},
	form: {
		pt: 3,
		"& .MuiFormControl-root": {
			mb: "15px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#E2E3E4",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},
		"& input": {
			py: "13.5px",
			pl: "5px",
			"&::placeholder": {
				color: "#767D90",
				opacity: 1,
			},
		},
	},
	submitBtn: {
		backgroundColor: "#2B2A69",
		height: 50,
		textTransform: "capitalize",
		fontWeight: 500,
		mt: "30px",
		"&:hover": {
			backgroundColor: "#2B2A69",
		},
	},
	newAccount: {
		color: "#1E1E1F",
		pt: "20px",
		textAlign: "center",
		"& a": {
			textDecoration: "none",
			color: "#0F4590",
			fontWeight: 600,
		},
	},
	forgetPassword: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		"& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
			color: "#767D90",
		},
		"& svg": {
			fill: "#EAEAEA",
		},
		"& a": {
			color: "#0F4590",
			textDecoration: "none",
		},
	},
};

function SplashForm({
	image,
	title,
	tagline = null,
	btnText,
	children,
	linkTaglinePlainText,
	linkTaglineText,
	linkTaglineHref,
	handleSubmit,
	isLoading,
}) {
	return (
		<>
			<Grid container justifyContent="center">
				<Grid item md={6} sx={styles.leftSide}>
					<Box
						component="img"
						src={image}
						alt="Login-img"
						sx={styles.sideImg}
					/>
				</Grid>

				<Grid item md={6} sx={styles.rightSide}>
					<Box component="img" src={TopImg} alt="Top-img" sx={styles.topImg} />
					<Box
						sx={{
							textAlign: {
								md: "unset",
								sm: "-webkit-center",
								xs: "-webkit-center",
							},
						}}
					>
						<Box component="img" src={Logo} alt="Logo" height="90px" />
					</Box>
					<Box component="h2">{title}</Box>
					{tagline ? <Box component="h6">{tagline}</Box> : null}

					<Box component="form" sx={styles.form}>
						{children}
					</Box>

					<LoadingButton
						variant="contained"
						fullWidth
						disableElevation
						sx={styles.submitBtn}
						type="submit"
						onClick={handleSubmit}
						isLoading={isLoading}
					>
						{btnText}
					</LoadingButton>

					<Typography component="p" sx={styles.newAccount}>
						{linkTaglinePlainText}
						<Link to={linkTaglineHref}>{linkTaglineText}</Link>
					</Typography>
				</Grid>
			</Grid>
		</>
	);
}

export default SplashForm;
