import Joi from "joi";

const InterestSchema = Joi.object({
    name: Joi.string().max(50).required().messages({
        "string.empty": `Please enter Interest`,
        "string.max": `Interest name must be maximum 50 characters!`,
        "string.pattern.base": `Please enter Interest`,
    }),

});

export default InterestSchema;