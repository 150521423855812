const styles = {
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    bgcolor: "rgba(255, 255, 255, 0.75)",
    minHeight: "calc(100vh - 128px)",
  },
  loader: {
    height: "47px !important",
    width: "47px !important",
  },
};

export default styles;
