import {
	Modal,
	Box,
	Stack,
	Typography,
	Button,
	IconButton,
	Divider,
} from "@mui/material";
import LoadingButton from "../Buttons/LoadingButton";
import { ReactComponent as Published } from "../../assets/svg/eventIcons/EventPublished.svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { get } from "../../server";
import { ADMIN_EVENT_FEATURE } from "../../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 15,
		p: 2,
		maxHeight: "90vh",
		overflow: "auto",
		"& h1": {
			fontSize: 20,
			fontWeight: 600,
			textAlign: "center",
			color: "#1E1E1F",
		},
	},
	published: {
		textAlign: "center",
		mt: 3,
		"& h2": {
			fontSize: 24,
			fontWeight: 600,
			color: "#1E1E1F",
			mt: 2,
		},
		"& label": {
			fontSize: 16,
			color: "#929497",
		},
	},
	divider: {
		color: "#EEE",
		my: 2,
	},
	qr: {
		textAlign: "center",
		"& svg": {
			mt: 3,
		},
		"& label": {
			fontSize: 15,
			color: "#929497",
			display: "block",
		},
		"& button": {
			mt: 3,
			width: 250,
			color: "#fff",
			bgcolor: "#2B2A69",
			"&:hover": { bgcolor: "#2B2A69" },
		},
		"& h5": {
			color: "#1E1E1F",
			mt: 1,
			cursor: "pointer",
			display: "inline-block",
		},
	},
};

const PublishedEventModal = ({ modal, setModal, id, qrCode, featured }) => {
	const navigate = useNavigate();

	const featureEvent = async () => {
		const { status, message } = await get(`${ADMIN_EVENT_FEATURE}/${id}`);
		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			navigate("/events/scheduled-events");
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(featureEvent);

	const handleFeature = () => {
		mutation.mutate();
	};
	return (
		<Modal open={modal}>
			<Box sx={styles.container}>
				<Typography component="h1">Event Published</Typography>
				<Box sx={styles.published}>
					<Published />
					<Typography component="h2">Congratulation!</Typography>
					<Typography component="label">Your event is Published</Typography>
				</Box>

				<Divider sx={styles.divider} />

				<Box sx={styles.qr}>
					<Box component="img" src={qrCode} alt="" sx={styles.qr}></Box>
					<Typography component="label">
						Share your event with your Members
					</Typography>

					{!featured ? (
						<>
							<LoadingButton
								variant="outline"
								onClick={handleFeature}
								isLoading={mutation.isLoading}
							>
								Feature This Event
							</LoadingButton>
							<Box>
								<Typography
									component="h5"
									onClick={() => navigate("/events/scheduled-events")}
								>
									Skip for now
								</Typography>
							</Box>
						</>
					) : (
						<LoadingButton
							variant="outline"
							onClick={() => navigate("/events/scheduled-events")}
							// isLoading={mutation.isLoading}
						>
							Continue
						</LoadingButton>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default PublishedEventModal;
