import { Box, Button, Modal, Stack } from "@mui/material";
import React from "react";
import LoadingButton from "../Buttons/LoadingButton";
import { useMutation } from "react-query";
import { destroy, get } from "../../server";
import { ADMIN_EVENT_IMAGES_STATUS, ADMIN_EVENT_IMAGE_DELETE } from "../../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";

const styles = {
	modal: {
		bgcolor: "#FFF",
		borderRadius: "20px",
		position: "absolute",
		left: "50%",
		top: "50%",
		// width: { md: 600, sm: "calc(100% - 20px)" },

		transform: "translate(-50%,-50%)",
		py: 2.5,
		px: 2.5,
	},
	image: {
		height: 500,
		width: 500,
		cursor: "pointer",
		borderRadius: "20px",
		// objectFit: "cover",
	},
	cancelBtn: {
		fontSize: 14,
		fontWidth: 500,
		bgcolor: "#FF0000",
		color: "#FFF",
		width:"100%",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#FF0000",
		},
	},
	approveBtn: {
		fontSize: 14,
		fontWidth: 500,
		bgcolor: "#135099",
		color: "#FFF",
		// width: 150,
		width:"100%",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#135099",
		},
	},
};

const ImagePreviewModal = ({ modal, setModal, previewImage,handleInvalidate }) => {
	const handleClose = () => setModal(false);

	const handleStatusChange = async () => {
		const { status, message } = await get(
			`${ADMIN_EVENT_IMAGES_STATUS}/${previewImage?.id}`
		);
		if(status){
			enqueueSnackbar(message, { variant: "success" });
			setModal(false);
			handleInvalidate()
		}
	};

	const mutation = useMutation(handleStatusChange);

	const handleClick = () => {
		mutation.mutate();
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_EVENT_IMAGE_DELETE}/${previewImage?.id}`
		);
		if(status){
			enqueueSnackbar(message, { variant: "success" });
			setModal(false);
			handleInvalidate()
		}
	};

	const deleteMutation = useMutation(handleDelete);

	const handleDeleteClick = () => {
		deleteMutation.mutate();
	};

	return (
		<Modal open={modal} onClose={handleClose}>
			<Box sx={styles.modal}>
				<Box
					component="img"
					src={previewImage?.image}
					alt=""
					sx={styles.image}
					onClick={() => window.open(previewImage?.image)}
				/>

				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					gap="10px"
					mt={2}
				>
					<LoadingButton
						variant="contained"
						sx={styles.cancelBtn}
						fullWidth
						onClick={handleDeleteClick}
						isLoading={deleteMutation.isLoading}
					>
						Delete
					</LoadingButton>
					<LoadingButton
						variant="contained"
						sx={styles.approveBtn}
						onClick={handleClick}
						isLoading={mutation.isLoading}
					>
						Approve
					</LoadingButton>
				</Stack>
			</Box>
		</Modal>
	);
};

export default ImagePreviewModal;
