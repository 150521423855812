
const styles = {
	title: {
		fontSize: 22,
		color: "#1E1E1F",
		fontWeight: 600,
		pb: 2,
	},
	wrapper: {
		bgcolor: "#fff",
		borderRadius: 3,
	},
	banner: {
		position: "relative",
		"& img": {
			width: "100%",
			height: 200,
			objectFit: "cover",
			borderRadius: 2.5,
			bgcolor: "#fff"
		},
	},
	leftWrapper: {
		p: 3,
		borderRight: "1px solid #E2E3E4",
	},
	bannerBtn: {
		// flexDirection: "row",
		// justifyContent: "space-between",
		// position: "absolute",
		// top: 0,
		width: "100%",
		// p: 2,	
		"& .MuiIconButton-root": {
			bgcolor: "#E7E7E9",
			borderRadius: 1,
			width: 30,
			height: 30,
			p: "7px",
		},
	},
	eventType: {
		bgcolor: "#fff",
		px: 2,
		height: 30,
		lineHeight: "30px",
		color: "#0F4590",
		fontSize: 16,
		borderRadius: 1,
		fontWeight: 600,
		border: "1px solid #0F4590",
	},
	header: {
		py: 3,
		borderBottom: "1px solid #E2E3E4",
		"& img": {
			width: 50,
			height: 50,
			objectFit: "cover",
			borderRadius: 1,
			bgcolor: "#fff"
		},
		"& p": {
			color: "#767D90",
			lineHeight: 1,
		},
		"& h3": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: 18,
		},
		"& button": {
			bgcolor: "#EEEEFF",
			color: "#2B2A69",
			textTransform: "capitalize",
			fontWeight: 600,
			"&:hover": {
				bgcolor: "#EEEEFF",
			},
		},
	},
	detail: {
		py: 3,
		borderBottom: "1px solid #e2e3e4",
		"& h6": {
			color: "#767D90",
		},
		"& h2": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: 20,
		},
		"& button": {
			bgcolor: "#2B2A69",
			color: "#fff",
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#2B2A69",
			},
		},
	},
	additional: {
		"& h2": {
			color: "#1E1E1F",
			fontSize: 18,
			fontWeight: 600,
			py: 2.5,
		},
		"& p": {
			color: "#767D90",
			letterSpacing: 0,
			fontSize: 15,
		},
	},
	btnGrid: {
		display: "grid",
		gridTemplateColumns: { lg: "repeat(3,1fr)", md: "repeat(2,1fr)" },
		gap: 2,
		"& button": {
			bgcolor: "#FBFBFD",
			color: "#1E1E1F",
			borderRadius: 3,
			border: "1px solid #ECECF4",
			textTransform: "capitalize",
			fontSize: 15,
			py: "10px",
			px: 2,
			justifyContent: "flex-start",
			letterSpacing: 0,
			"& .MuiButton-startIcon": {
				ml: 0,
			},
			"&:hover": {
				bgcolor: "#FBFBFD",
			},
		},
	},
	reservationBtn: {
		bgcolor: "#2B2A69",
		color: "#fff",
		textTransform: "capitalize",
		mt: 1,
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
	featureBtn: {
		bgcolor: "#2B2A69 !important",
		color: "#fff  !important" ,
		textTransform: "capitalize",
		fontWeight: 600,
		"&:hover": {
			bgcolor: "#2B2A69",
		},
		"& svg":{
			color:"#fff !important"
		}
	}
};

export default styles;