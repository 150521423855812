import React from "react";
import { Avatar, Box } from "@mui/material";

import CompanyLogo from "../../assets/svg/companyLogo.svg";
import { subscriptionColors } from "../../data/constant";
import Listing from "../ui/Listing";
import SearchHeader from "../ui/SearchHeader";

const rows = [
    {
        id: 1,
        companyName: "ExpertsDesk.net",
        subscription: "inactive",
        email: "johndoe@example.com",
        role: "Executive Director",
        profileImg: CompanyLogo,
    },
    {
        id: 2,
        companyName: "Systems.co",
        subscription: "active",
        email: "michelohn@example.com",
        role: "Operation Manager",
        profileImg: null,
    },
    {
        id: 3,
        companyName: "Infinity.net",
        subscription: "active",
        email: "adammick@example.com",
        role: "Membership Director",
    },
    {
        id: 4,
        companyName: "Relianceindustrie.Ltd",
        subscription: "stopped",
        email: "michelohn@example.com",
        role: "Chief of Staff External",
    },
    {
        id: 5,
        companyName: "Oilandnatural.com",
        subscription: "active",
        email: "michelohn@example.com",
        role: "Admin",
    },
    {
        id: 6,
        companyName: "LifeInsurance.net",
        subscription: "stopped",
        email: "michelohn@example.com",
        role: "Operation Manager",
    },
];

const columns = [
    {
        field: "companyName",
        headerName: "Company Name",
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <>
                    <Avatar
                        src={params.row.profileImg}
                        sx={{ bgcolor: "gray", mr: 1.5, width: 35, height: 35 }}
                    >
                        {params.row.companyName.charAt(0)}
                    </Avatar>
                    {params.row.companyName}
                </>
            );
        },
    },
    {
        field: "email",
        headerName: "Email",
        sortable: false,
        flex: 1,
    },
    {
        field: "subscription",
        headerName: "Subscription",
        sortable: false,
        width: 120,
        renderCell: (params) => {
            return (
                <Box
                    sx={{
                        bgcolor: subscriptionColors[params.row.subscription].bgcolor,
                        color: subscriptionColors[params.row.subscription].color,
                        textTransform: "capitalize",
                        width: 80,
                        height: 30,
                        lineHeight: "30px",
                        textAlign: "center",
                        borderRadius: "4px",
                    }}
                >
                    {params.row.subscription}
                </Box>
            );
        },
    },
    {
        field: "role",
        headerName: "Role",
        sortable: false,
        flex: 1,
    },
];

function MemberTab() {
    return (
        <>
            <SearchHeader title="Members" searchInput={true} placeholder="Search Members" />
            <Listing rows={rows} columns={columns} className="courses" />
        </>
    );
}

export default MemberTab;
