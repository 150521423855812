import { Box, Typography } from "@mui/material";
import React from "react";

const styles = {
	color: "#767D90",
	fontSize: 16,
	lineHeight: "12.5px",
};

function Footer({ drawerWidth }) {
	return (
		<>
			<Box
				sx={{
					// width: { sm: `calc(100% - ${drawerWidth}px)` },
					// ml: { sm: `${drawerWidth}px` },
					textAlign: "center",
					my: "31px",
					position: "relative",
					bottom: 0,
				}}
			>
				<Typography sx={styles}>Terms of use. Privacy Policy</Typography>
			</Box>
		</>
	);
}

export default Footer;
