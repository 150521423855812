import React, { useEffect, useState } from "react";
import PromoCodeListing from "../components/ui/PromoCodeListing";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";
import PromoCodeDetails from "../components/pageComponents/PromoCodeDetails";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../data/constants/queryNames";
import { destroy, get } from "../server";
import {
	ADMIN_PROMO_CODE_DELETE,
	ADMIN_PROMO_CODE_LISTING,
} from "../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import BoxModal from "../components/Modals/BoxModal";

const styles = {
	mainHeader: {
		borderTopLeftRadius: "12px",
		borderTopRightRadius: "12px",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		borderBottom: "1px solid #F1F1F1",
		pb: 2,
		"& h1": {
			color: "#1E1E1F",
			fontSize: 22,
			fontWeight: 600,
		},
		"& button": {
			bgcolor: "#2b2a69",
			textTransform: "capitalize",
			padding: "8px 28px",
		},
	},
};

function PromoCode() {
	const [modal, setModal] = useState(false);
	const [promoCodeId, setPromoCodeId] = useState(null);

	const { enqueueSnackbar } = useSnackbar();

	const client = useQueryClient();

	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: true,
			flex: 1,
		},
		{
			headerName: "Discount",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return params.row.percent_off ? (
					<Typography>{params.row.percent_off} %</Typography>
				) : (
					<Typography textTransform="uppercase">
						{params.row.amount_off} {params.row.currency}
					</Typography>
				);
			},
		},
		{
			field: "duration",
			headerName: "Duration",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return params.row.duration === "repeating" ? (
					<Typography textTransform="capitalize">
						{params.row.duration_in_months} month
					</Typography>
				) : (
					<Typography textTransform="capitalize">
						{params.row.duration}{" "}
					</Typography>
				);
			},
		},
		{
			field: "metadata",
			headerName: "For",
			sortable: false,
			renderCell: (params) => (
				<Typography textTransform="capitalize">
					{params.row.metadata.for}
				</Typography>
			),
			flex: 1,
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={0.5}>
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => {
									setPromoCodeId(params.row.id);
									setModal("detail");
								}}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setPromoCodeId(params.row.id);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</Stack>
				);
			},
		},
	];

	const fetchPromoCodes = async (limit, direction = null, id) => {
		let url = new URL(ADMIN_PROMO_CODE_LISTING);

		url.searchParams.append(direction, id);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const { isLoading } = useQuery([queryNames.PROMO_CODE], fetchPromoCodes, {
		enabled: !modal,
		refetchOnWindowFocus: false,
	});

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.PROMO_CODE);
	};

	useEffect(() => {
		if (modal === false) {
			setPromoCodeId(null);
			handleInvalidate();
		}
	}, [modal]);

	const handleClose = () => {
		setPromoCodeId(null);
		setModal(false);
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_PROMO_CODE_DELETE}/${promoCodeId}`
		);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleDelete, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = () => {
		mutation.mutate();
	};

	return (
		<>
			<Stack sx={styles.mainHeader}>
				<Typography component="h1">Promo Code</Typography>
				<Button
					variant="contained"
					disableElevation
					onClick={() => setModal("detail")}
				>
					+ Add New
				</Button>
			</Stack>

			<PromoCodeListing
				columns={columns}
				queryName={queryNames.PROMO_CODE}
				fetchRecords={fetchPromoCodes}
			/>

			<PromoCodeDetails
				modal={modal === "detail"}
				setModal={setModal}
				promoCodeId={promoCodeId}
			/>
			<BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>
		</>
	);
}

export default PromoCode;
