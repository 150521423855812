import axios from "axios";
import useHandleLogout from "../utils/helpers/useHandleLogout";
import { ADMIN_TOKEN_REFRESH } from "../data/constants/apiRoutes";

export const get = async (url) => {
  let data = [];
  let message = "";
  let status;
  let headers = {};

  let token = JSON.parse(localStorage.getItem("loggedUser"))?.token;
  let rememberMe = JSON.parse(localStorage.getItem("loggedUser"))?.rememberMe;

  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .get(url, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.code === 200;
    })
    .catch((error) => {
      if (error.response) {
        data = error.response.data.data;
        message = error.response.data.message;
        status = error.response.data.code;
        if (message === "Unauthenticated.") {
          if (rememberMe) refreshToken();
          else {
            useHandleLogout();
            return { status: false, message, data: null };
          }
        }
      } else {
        data = null;
        message = "No server response";
        status = false;
      }
    });

  return { status, message, data };
};

export const post = async (url, payload = {}) => {
  let data = [];
  let message = "";
  let status;
  let headers = {};

  let token = JSON.parse(localStorage.getItem("loggedUser"))?.token;
  let rememberMe = JSON.parse(localStorage.getItem("loggedUser"))?.rememberMe;

  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .post(url, payload, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.code === 200;
    })
    .catch((error) => {
      if (error.response) {
        data = error.response.data.data;
        message = error.response.data.message;
        status = error.response.data.code;
        if (message === "Unauthenticated.") {
          if (rememberMe) refreshToken();
          else {
            useHandleLogout();
            return { status: false, message, data: null };
          }
        }
      } else {
        data = null;
        message = "No server response";
        status = false;
      }
    });

  return { status, message, data };
};

export const put = async (url, payload = {}) => {
  let data = [];
  let message = "";
  let status;
  let headers = {};

  let token = JSON.parse(localStorage.getItem("loggedUser"))?.token;
  let rememberMe = JSON.parse(localStorage.getItem("loggedUser"))?.rememberMe;

  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .put(url, payload, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.code === 200;
    })
    .catch((error) => {
      if (error.response) {
        data = error.response.data.data;
        message = error.response.data.message;
        status = error.response.data.code;
        if (message === "Unauthenticated.") {
          if (rememberMe) refreshToken();
          else {
            useHandleLogout();
            return { status: false, message, data: null };
          }
        }
      } else {
        data = null;
        message = "No server response";
        status = false;
      }
    });

  return { status, message, data };
};

export const destroy = async (url, payload = {}) => {
  let data = [];
  let message = "";
  let status;
  let headers = {};

  let token = JSON.parse(localStorage.getItem("loggedUser"))?.token;
  let rememberMe = JSON.parse(localStorage.getItem("loggedUser"))?.rememberMe;

  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await refreshToken();

  await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.code === 200;
    })
    .catch((error) => {
      if (error.response) {
        data = error.response.data.data;
        message = error.response.data.message;
        status = error.response.data.code;
        if (message === "Unauthenticated.") {
          if (rememberMe) refreshToken();
          else {
            useHandleLogout();
            return { status: false, message, data: null };
          }
        }
      } else {
        data = null;
        message = "No server response";
        status = false;
      }
    });

  return { status, message, data };
};

export const refreshToken = async () => {
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const id = loggedUser?.data?.id;
  const rememberMe = loggedUser?.rememberMe;

  let url = new URL(ADMIN_TOKEN_REFRESH);
  url.searchParams.append("user_id", id);

  if (rememberMe) {
    await axios.get(url.toString()).then((res) => {
      loggedUser.token = res.data.data;
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
    });
  }
};
