import React from "react";
import PageHeader from "../../../components/ui/PageHeader";
import EventDetailListings from "../../../components/ui/EventDetailListings";
import Listing from "../../../components/ui/Listing";
import queryNames from "../../../data/constants/queryNames";
import {
	Avatar,
	Box,
	Button,
	Drawer,
	FormLabel,
	Grid,
	Stack,
	Typography,
	IconButton, Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import _ from "lodash";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ADMIN_FORUM_ADD, ADMIN_FORUM_UPDATE, ADMIN_FORUM_DELETE, ADMIN_FORUM_DETAIL } from "../../../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormInput from "../../../components/Forms/FormInput";
import { useRef } from "react";
import CropImage from "../../../components/Modals/CropImage";
import GetAddressModal from "../../../components/Modals/GetAddressModal";
import BoxModal from "../../../components/Modals/BoxModal";
import { Close } from "@mui/icons-material";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { NewEventSchema } from "../../../utils/schemas/EventSchema";
import { validator } from "../../../utils/helpers/validator";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { post, destroy, get } from "../../../server";
import { useNavigate } from "react-router-dom";
import GoBackButton from "../../../components/Buttons/GoBackButton";
const styles = {
	container: {
		position: "relative",
		width: 500,
		bgcolor: "#fff",
		borderTopLeftRadius: "10px",
		borderBottomLeftRadius: "10px",
		p: 2,
		"& h2": {
			fontSize: 20,
			fontWeight: 600,
			color: "#000",
			mt: 1,
			mb: 2,
			textAlign: "center",
			textTransform: "capitalize",
		},
		"& h3": {
			fontSize: 16,
			fontWeight: 600,
			color: "#000",
			mb: 1,
		},
	},
	eventImageContainer: {
		borderRadius: "4px",
		border: "1px dashed var(--light-background-color-2, #E2E3E4)",
		bgcolor: "#FBFCFF",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: 2,
		p: 3,
		height: 200,
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
	coverImageContainer: {
		borderRadius: "4px",
		border: "1px solid #eee",
		height: 200,
		width: "100%",
		objectFit: "cover",
	},
	button: {
		mt: 2,
		width: 100,
		bgcolor: "#2B2A69",
		color: "#fff",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#2B2A69",
		},
	},
	coverHover: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		gap: "10px",
		transition: "display 0.3s ease",
		"& button": {
			borderRadius: "4px",
			border: "1px solid #2B2A69",
			bgcolor: "#FFF",
			color: "#2B2A69",
			fontWeight: 500,
			textTransform: "capitalize",
			"&:hover": {
				bgcolor: "#FFF",
				border: "1px solid #2B2A69",
			},
		},
		"& label": {
			color: "#767D90",
			fontSize: 14,
		},
	},
};

const initialState = {
	
	description: "",
	image: "",
};




const Forums = ({ fourms, loading,eventId, event }) => {
	// console.log({ event })
	const navigate = useNavigate();
	const [deleteItem, setDeleteItem] = useState(null);
	const [modal, setModal] = useState(false);
	const [cropModal, setCropModal] = useState(false);
	const [addressModal, setAddressModal] = useState(false);
	const [newForums, setNewForums] = useState(initialState);
	const [cover_image, setCoverImage] = useState(null);
	const [updateForums, setUpdateForums] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const client = useQueryClient();
	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.FORUMS);
	  };


	const [errors, setErrors] = useState({});

	const coverRef = useRef(null);

	const [isCoverHovered, setIsCoverHovered] = useState(false);

	const handleChange = (e) => {
		const {
			target: { name, value },
		} = e;
		setNewForums({ ...newForums, [name]: value });
	};

	const navigateToDetailPage = (rowData) => {
		navigate(`/forums/detail/${rowData.id}`, { state: { forumData: rowData } });
	};

	const validateForm = () => {
		const newErrors = validator(newForums, NewEventSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		// if (!newForums.image) {
		// 	enqueueSnackbar("Please attach Cover Image", { variant: "error" });
		// 	return false;
		// }
		return true;
	};
	// const validateForm = () => {
	// 	const newErrors = validator(newForums, NewEventSchema);
	// 	setErrors(newErrors);
	// 	if (Object.keys(newErrors).length) return false;
	// 	return true;
	// };
	
	const handleCellClick = (data) => {
		if (data?.field === "event_name") {
			navigate(`/forums/detail/${data.id}`);
		}
	};

	const handleCoverChange = (e) => {
		const file = e.target.files[0];
		const maxSizeInBytes = 15 * 1024 * 1024; // 15MB
		// setNewForums({ ...newForums, image: file });
		if(file){

		if (file.size <= maxSizeInBytes) {
			setNewForums({ ...newForums, image: file });
			setCoverImage(URL.createObjectURL(file));
			setCropModal("eventSponsor");
		} else {
			alert("Selected cover image exceeds the 15MB size limit.");
		}
	}
	};

	const saveLocation = (address, location) => {
		setNewForums({
			...newForums,
			address: address,
			latitude: location.latitude,
			longitude: location.longitude,
		});
	};

	const formatDate = (date) => {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
		let hour = date.getHours().toString().padStart(2, '0');
		let minute = date.getMinutes().toString().padStart(2, '0');

		return `${year}-${month}-${day} ${hour}:${minute}`;
	}

	const columns = [
		{
			field: "description",
			headerName: "Description",
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						onClick={() => navigateToDetailPage(params.row)}
						style={{
							cursor: "pointer",
						}}
					>
						{params.row.description}
					</Box>
				);
			},
		},
		{
			field: "viewCount",
			headerName: "View",
			sortable: false,
			width: 180,
			// valueGetter: (params) => params.row.company_sponsor.email,
		},
		{
			field: "isFav",
			headerName: "Like",
			sortable: false,
			flex: 1,
			// valueGetter: (params) => params.row.company_sponsor.website,
		},
		{
			field: "commentCount",
			headerName: "Comment",
			sortable: false,
			flex: 1,
			// valueGetter: (params) => params.row.company_sponsor.website,
		},
		{
			field: "created_at",
			headerName: "Created Date",
			sortable: false,
			flex: 1,
			valueFormatter: (params) => formatDate(new Date(params.value)),
		},

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Stack direction="row">
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => {
									handleEdit(params.row);
									setModal("Edit");
								}}

							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setDeleteItem(params.row);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
						{/* <Tooltip title="View Details" arrow placement="top">
							<IconButton
								onClick={() => navigateToDetailPage(params.row)}
							>
								<Edit />

							</IconButton>
						</Tooltip> */}
					</Stack>
				);
			},
		},

		// {
		// 	field: "action",
		// 	headerName: "Action",
		// 	sortable: false,
		// 	width: 100,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Stack direction="row" spacing={0.5}>
		// 				<Tooltip title="Edit" arrow placement="top">
		// 					<IconButton
		// 						onClick={() => {
		// 							// setPromoCodeId(params.row.id);
		// 							setModal("detail");
		// 						}}
		// 					>
		// 						<Edit />
		// 					</IconButton>
		// 				</Tooltip>
		// 				<Tooltip title="Delete" arrow placement="top">
		// 					<IconButton
		// 						// onClick={() => {
		// 						// 	// setPromoCodeId(params.row.id);
		// 						// 	 setAction("delete");
		// 						// }}
		// 						onClick={() => handleConfirmDelete(params.row)}

		// 					>
		// 						<Delete />
		// 					</IconButton>
		// 				</Tooltip>
		// 			</Stack>
		// 		);
		// 	},
		// },


	];

	const handleEdit = (row) => {
	
		// Check if assets array and asset_url property are defined before accessing them
		const image = row.assets && row.assets.length > 0 ? row.assets[0].asset_url ?? null : null;
	
		setNewForums({
			description: row.description, // Update with respective row properties
			image: image, // Image URL, set to null if not found or undefined
		});
	
		// Open the modal for editing
		setModal("Edit");
		setForumIdForUpdate(row.id);
	};

	const handleOpenModal = () => {
		setModal("create");
	};

	const handleCoverMouseEnter = () => {
		setIsCoverHovered(true);
	};

	const handleCoverMouseLeave = () => {
		setIsCoverHovered(false);
	};

	const handleClose = () => {
		setModal(false);
		// setAction(false);
		setNewForums(initialState);
		setCoverImage(null);
		setUpdateForums(null);
	};
	const [forumIdForUpdate, setForumIdForUpdate] = useState(null);
	// const handleDelete = async () => {
	// 	const { status, message } = await destroy(
	// 		`${ADMIN_FORUM_DELETE}/${event.id}`
	// 	);

	// 	if (status) {
	// 		enqueueSnackbar(message, { variant: "success" });
	// 		handleClose();
	// 	} else {
	// 		enqueueSnackbar(message, { variant: "error" });
	// 	}
	// };
	// const mutationDelte = useMutation(handleDelete);

	// const handleConfirm = () => {
	// 	mutationDelte.mutate();
	// };
	const handleDelete = async (row) => {
		const { status, message } = await destroy(`${ADMIN_FORUM_DELETE}/${row.id}`);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutationDelete = useMutation(handleDelete);

	const handleConfirmDelete = () => {
		// Assuming you have the row available, pass it to the delete function
		mutationDelete.mutate(deleteItem);
	};
	


	const saveForums = async () => {
		const payload = _.cloneDeep(newForums);
		let newStatus = true;

		
		if (payload.image) {
            const data = await awsFileUpload(
              payload.image,
            //   uploadImageType.eventProfile
			'forums'
            );
        
            if (data.status === true) {
              payload.image = data.location;
            } else {
              enqueueSnackbar("Error uploading", { variant: "error" });
              return; 
            }
          }

		if (newStatus) {
			const { status, message, data } = await post(ADMIN_FORUM_ADD, {
				event_id: event.id,
				description: payload.description,
				assets: [{ asset_url: payload.image, asset_type: "url" }]
			});
			if (status === true) {
				enqueueSnackbar("Forums Created Successfully", { variant: "success" });
				handleInvalidate();
				handleClose();
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(saveForums);


	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};
	// ... other imports and declarations ...

const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (modal === "Edit") {
        
        const payload = _.cloneDeep(newForums);
        let newStatus = true;

		  if (payload.image && typeof payload.image === "string") {
            // If it's a string (URL), skip the image update part
        } else {
            // If it's not a string (not an URL), proceed to update the image
            const data = await awsFileUpload(payload.image, 'forum-list');

            if (data.status === true) {
                payload.image = data.location;
            } else {
                enqueueSnackbar("Error uploading", { variant: "error" });
                return; // Exit the function if the upload fails
            }
        }

        if (newStatus) {
			
            // const { status, message, data } = await post(`${ADMIN_FORUM_UPDATE}/${forumIdForUpdate}`, {
				const { status, message, data } = await post(ADMIN_FORUM_UPDATE, {
                forum_id: forumIdForUpdate, // Using the stored forum ID here
				event_id: event.id,
                description: payload.description,
                assets: [{ asset_url: payload.image, asset_type: "url" }]
				
            });
            if (status === true) {
                enqueueSnackbar("Forum Updated Successfully", { variant: "success" });
                handleInvalidate();
                handleClose();
            } else enqueueSnackbar(message, { variant: "error" });
        }
    } else {
        // This block handles the addition of forums
        mutation.mutate();
    }
};

	const fetchForums = async (pageNo, direction = null, id) => {
		let url = new URL(`${ADMIN_FORUM_DETAIL}/${eventId}`);
	
		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append(direction, id);
	
		return await get(url.toString());
	  };
	  const { isLoading }  = useQuery([queryNames.FORUMS], fetchForums, {
        // // enabled: isId,
        // keepPreviousData: true,
		enabled: !modal,
		refetchOnWindowFocus: false,
      });
	  useEffect(() => {
		if (modal === false) {
			setUpdateForums(null);
			handleInvalidate();
		}
	}, [modal]);

// ... rest of the component ...

	

	return (
		<>
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
		<Typography variant="h4" sx={{ textAlign: 'left',fontSize:"22px",fontWeight:"600",lineHeight:"33px",color:"#1E1E1F" }}>
			Forums
		</Typography>
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<GoBackButton url={-1} />
			<PageHeader
			createBtn
			createBtnText="Create Forums"
			btnFnc={handleOpenModal}
			/>
		</Box>
		</Box>
			{/* <PageHeader
				title="Forums"
				createBtn
				createBtnText="create Forums"
				btnFnc={handleOpenModal}
			/> */}
			{/* <EventDetailListings
				rows={fourms}
				columns={columns}
				handleCellClick={handleCellClick}
				loading={loading}

			/> */}
			<Listing
				// rows={forumDetail}
				columns={columns}
				handleCellClick={handleCellClick}
				fetchRecords={fetchForums}
				queryName={queryNames.FORUMS}
				// loading={commentsQuery.isLoading }
			/>
			
			<BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={handleClose}
				handleConfirm={handleConfirmDelete}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>

			<Drawer anchor="right" open={modal === "Edit"} onClose={handleClose}>
				<Box sx={styles.container}>
					<Close className="closeIcon" onClick={handleClose} />
					<Typography variant="h2">Update Forum</Typography>
					<Grid container spacing={3} mb={2}>
						<Grid item md={12} sm={12}>
							<FormInput
								label="Description"
								placeholder="Description"
								value={newForums.description}
								name="description"
								onChange={handleChange}
								multiline
								rows={4}
								error={errors?.description}
								helperText={errors?.description || ""}
							/>
						</Grid>
					</Grid>
					<FormLabel label="Cover Image" />
					{newForums.image && newForums.image !== null ? (
						<Box
							position="relative"
							onMouseEnter={handleCoverMouseEnter}
							onMouseLeave={handleCoverMouseLeave}
						>
							<Box
								component="img"
								src={
									typeof newForums.image === "string"
										? newForums.image
										: URL.createObjectURL(newForums.image)
								}
								sx={{
									...styles.coverImageContainer,
									opacity: isCoverHovered ? 0.3 : 1,
									transition: "opacity 0.3s ease",
								}}
								loading="lazy"
							/>
							<Stack
								sx={{
									...styles.coverHover,
									display: isCoverHovered ? "flex" : "none", // Control visibility
									opacity: isCoverHovered ? 1 : 0.5, // Control opacity
								}}
							>
								<Typography component="label">+Add Cover Image</Typography>
								<Button
									variant="outlined"
									// onClick={() => coverRef.current.click()}
									onClick={() => coverRef.current && coverRef.current.click()}
								>
									Browse Image
								</Button>
								<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
								/>
																
							</Stack>
						</Box>
					) : (
						<Box sx={styles.eventImageContainer}>
							<Typography component="label">Add Images</Typography>
							<Button
								variant="outlined"
								// onClick={() => coverRef.current.click()}
								onClick={() => coverRef.current && coverRef.current.click()}

							>
								Browse Image
							</Button>
							<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
							/>
						</Box>
					)}
					<LoadingButton
						variant="contained"
						onClick={handleSubmitUpdate}
						isLoading={mutation.isLoading}
						sx={styles.button}
					>
						Update
					</LoadingButton>
				</Box>

				<CropImage
					modal={cropModal}
					setModal={setCropModal}
					image={cover_image}
					user={newForums}
					setUser={setNewForums}
					ratio={16 / 9}
				/>

				<GetAddressModal
					modal={addressModal}
					setModal={setAddressModal}
					saveLocation={saveLocation}
				/>
			</Drawer>
			<Drawer anchor="right" open={modal === "create"} onClose={handleClose}>
				<Box sx={styles.container}>
					<Close className="closeIcon" onClick={handleClose} />
					<Typography variant="h2">Add new Forum</Typography>
					<Grid container spacing={3} mb={2}>
						<Grid item md={12} sm={12}>
							<FormInput
								label="Description"
								placeholder="Description"
								value={newForums.description}
								name="description"
								onChange={handleChange}
								multiline
								rows={4}
								error={errors?.description}
								helperText={errors?.description || ""}
							/>
						</Grid>
					</Grid>
					<FormLabel label="Cover Image" />
					{newForums.image && newForums.image !== null ? (
						<Box
							position="relative"
							onMouseEnter={handleCoverMouseEnter}
							onMouseLeave={handleCoverMouseLeave}
						>
							<Box
								component="img"
								src={
									typeof newForums.image === "string"
										? newForums.image
										: URL.createObjectURL(newForums.image)
								}
								sx={{
									...styles.coverImageContainer,
									opacity: isCoverHovered ? 0.3 : 1,
									transition: "opacity 0.3s ease",
								}}
								loading="lazy"
							/>
							<Stack
								sx={{
									...styles.coverHover,
									display: isCoverHovered ? "flex" : "none", // Control visibility
									opacity: isCoverHovered ? 1 : 0.5, // Control opacity
								}}
							>
								<Typography component="label">+Add Cover Image</Typography>
								<Button
									variant="outlined"
									// onClick={() => coverRef.current.click()}
									onClick={() => coverRef.current && coverRef.current.click()}
								>
									Browse Image
								</Button>
								<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
								/>
							</Stack>
						</Box>
					) : (
						<Box sx={styles.eventImageContainer}>
							<Typography component="label">Add Images</Typography>
							<Button
								variant="outlined"
								onClick={() => coverRef.current.click()}
							>
								Browse Image
							</Button>
							<input
								type="file"
								hidden
								onChange={handleCoverChange}
								id={`documentUpload`}
								ref={coverRef}
								accept="image/*"
							/>
						</Box>
					)}
					<LoadingButton
						variant="contained"
						onClick={handleSubmit}
						isLoading={mutation.isLoading}
						sx={styles.button}
					>
						Submit
					</LoadingButton>
				</Box>

				<CropImage
					modal={cropModal}
					setModal={setCropModal}
					image={cover_image}
					user={newForums}
					setUser={setNewForums}
					ratio={16 / 9}
				/>

				<GetAddressModal
					modal={addressModal}
					setModal={setAddressModal}
					saveLocation={saveLocation}
				/>
			</Drawer>
		</>
	);
};

export default Forums;
