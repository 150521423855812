import { Box, Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		px: 3,
		pt: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 16,
			fontWeight: 600,
		},
	},
};

function TopBuyer({ buyers = { amount: [], name: [] } }) {
	const { amount, name } = buyers;

	const Series = [
		{
			color: "#2B2A69",
			width: "10px",
			data: amount,
		},
	];

	const options = {
		chart: {
			height: "500px",
			width: "100%",
			type: "area",
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
			width: 2,
		},
		xaxis: {
			type: "category",
			categories: name,
			labels: {
				trim: true,
				rotateAlways: false,
				rotate: 0,
				offsetX: 5,
				styles: {
					fontSize: "9px",
				},
			},
			tooltip: {
				enabled: false,
			},
			axisBorder: {
				show: false,
			},
		},
		yaxis: {
			show: false,
			tickAmount: 4,
			forceNiceScale: true,
			axisTicks: {
				width: 10,
			},
			// max: 10000,
			// min: 1000,
		},
		tooltip: {
			shared: false,
			intersect: false,
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				// const xValue = series[seriesIndex].data[dataPointIndex][1];
				// const yValue = series[seriesIndex].data[dataPointIndex][1];
				// const tooltipStyle = {
				//   position: "absolute",
				//   top: `${w.globals.plotSize.height - yValue - 20}px`,
				//   left: `${w.globals.seriesX[dataPointIndex]}px`,
				//   backgroundcolor: "rgba(0, 0, 0, 0.8)",
				//   color: "#fff",
				//   padding: "8px",
				//   borderradius: "4px",
				//   pointerevents: "none",
				// }
				return renderToString(
					<div className="courseGraphContainer">
						<p className="courseMonth">{w.globals.categoryLabels[dataPointIndex]}</p>
						<h3 className="courseMonthValue">
							${series[seriesIndex][dataPointIndex]}
						</h3>
					</div>
				);
			},
		},
		legend: {
			show: false,
		},
		fill: {
			opacity: 1,
			gradient: {
				type: "vertical",
				shadeIntensity: 0,
				opacityFrom: 0.6,
				opacityTo: 0,
				stops: [0, 10000],
			},
		},
	};

	return (
		<Box sx={styles.wrapper}>
			<Typography component="h2">Top Buyers</Typography>
			<ReactApexChart
				options={options}
				series={Series}
				type="area"
				height={243}
			/>
		</Box>
	);
}

export default TopBuyer;
