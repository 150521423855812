const styles = {
	newAccount: {
		color: "#1E1E1F",
		pt: "20px",
		textAlign: "center",
		"& a": {
			textDecoration: "none",
			color: "#0F4590",
			fontWeight: 600,
		},
	},
	forgetPassword: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		"& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
			color: "#767D90",
			fontSize: { md: 15, sm: 13, xs: 13 },
		},

		"& a": {
			color: "#0F4590",
			textDecoration: "none",
			fontSize: { md: 15, sm: 13, xs: 13 },
		},
	},
};


export default styles;