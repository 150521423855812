import { Box } from "@mui/material";
import React from "react";
import CardHeader from "./CardHeader";
import Member from "./Member";

const styles = {
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		p: 3,
		height: 305,
		overflowY: "auto",
		mb: 3,
	},
};

function EnrolledMember({ members }) {
	return (
		<>
			<Box sx={styles.wrapper}>
				<CardHeader title="Enrolled Members" url="/courses/enrolled-members"/>
				{members?.slice(0, 4).map((member) => (
					<Member {...member} dataType="member" key={member.id} />
				))}
			</Box>
		</>
	);
}

export default EnrolledMember;
