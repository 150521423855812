const styles = {
	header: {
		bgcolor: "#fff",
		borderTopLeftRadius: "12px",
		borderTopRightRadius: "12px",
		p: 3,
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		borderBottom: "1px solid #F1F1F1",
		mt: "6px",
		"& h2": {
			color: "#1E1E1F",
			fontSize: 18,
			fontWeight: 600,
		},
		"& button": {
			borderColor: "#293277",
			color: "#293277",
			textTransform: "capitalize",
			fontWeight: 600,
		},
	},
	wrapper: {
		bgcolor: "#fff",
		borderRadius: "12px",
		p: 3,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 16,
			fontWeight: 600,
		},
		"& p": {
			fontSize: 12,
			color: "#767D90",
		},
		"& h6": {
			color: "#1E1E1F",
			fontWeight: 600,
			fontSize: 20,
		},
	},
};

export default styles;