import Joi from "joi";


const InviteSubAdminSchema = Joi.object({
  name: Joi.string().required().max(50).messages({
    "string.empty": `Please enter Lesson Name`,
    "string.max": `Lesson name must be maximum 50 characters!`,
    "string.pattern.base": `Please enter Lesson Name`,
  }),

  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })

    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
      "string.pattern.base": `Please enter email address`,
    }),
});

export default InviteSubAdminSchema