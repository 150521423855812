import React, {  useRef, useState } from "react";
import {
	Stack,
	IconButton,
	Typography,
	Button,
	FormControl,
	OutlinedInput,
	InputAdornment,
	Tooltip,
	Box,
} from "@mui/material";
import Listing from "../../components/ui/Listing";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import { useNavigate } from "react-router-dom";
import {
	ADMIN_COURSES_DELETE,
	ADMIN_COURSES_LISTING,
	ADMIN_COURSES_LISTING_SEARCH,
} from "../../data/constants/apiRoutes";
import { destroy, get } from "../../server";
import queryNames from "../../data/constants/queryNames";
import BoxModal from "../../components/Modals/BoxModal";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import CourseDrawer from "../../components/Drawers/CourseDrawer";
import { Clear } from "@mui/icons-material";
import styles from "../../assets/styles/course/allCourses.styles";

const AllCourses = () => {
	const navigate = useNavigate();

	const [modal, setModal] = useState(false);
	const [courseId, setCourseId] = useState(null);

	const [search, setSearch] = useState("");
	const [finalSearch, setFinalSearch] = useState("");
	const [isSearch, setIsSearch] = useState(false);

	const client = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const courseRef = useRef();

	const columns = [
		{
			field: "name",
			headerName: "Course  Name",
			sortable: false,
			minWidth: 200,
			flex: 1,
			renderCell: (param) => {
				return (
					<Box component="div" sx={{ cursor: "pointer" }}>
						{param.row.name}{" "}
					</Box>
				);
			},
		},
		{
			field: "created_by",
			headerName: "Created By",
			sortable: false,
			width: 200,
		},
		{
			field: "lessons",
			headerName: "Lessons",
			sortable: false,
			width: 150,
			valueGetter: (params) => params.row.lessons.length,
		},
		{
			field: "price",
			headerName: "Price",
			sortable: false,
			width: 150,
			renderCell: (param) => {
				return <Typography component="label"> $ {param.row.price} </Typography>;
			},
		},
		{
			field: "discount",
			headerName: "Discount",
			sortable: false,
			width: 120,
			renderCell: (param) => {
				return param.row.discount ? (
					<Typography component="label"> {param.row.discount} </Typography>
				) : (
					"-"
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 100,
			renderCell: (params) => {
				return (
					<Stack direction="row" spacing={2}>
						<Tooltip title="Edit" arrow placement="top">
							<IconButton
								onClick={() => navigate(`/courses/edit/${params.row.id}`)}
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete" arrow placement="top">
							<IconButton
								onClick={() => {
									setCourseId(params.row.id);
									setModal("delete");
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</Stack>
				);
			},
		},
	];

	const fetchCourses = async (pageNo, limit, searchParam, isSearchParam) => {
		let url;

		if (isSearchParam === false) {
			url = new URL(ADMIN_COURSES_LISTING);
		} else if (isSearchParam && searchParam.length) {
			url = new URL(ADMIN_COURSES_LISTING_SEARCH);
			url.searchParams.append("search", searchParam);
		}

		url.searchParams.append("page", pageNo + 1);
		url.searchParams.append("limit", limit);

		return await get(url.toString());
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.COURSES);
	};

	const handleClose = () => {
		setModal(false);
		setCourseId(null);
		handleInvalidate();
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(
			`${ADMIN_COURSES_DELETE}/${courseId}`
		);

		if (status) {
			enqueueSnackbar(message, { variant: "success" });
			handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleDelete, {
		onSuccess: () => {
			client.invalidateQueries([queryNames]);
		},
	});

	const handleConfirm = (e) => {
		e.preventDefault();
		mutation.mutate();
	};

	const handleCellClick = (data) => {
		if (data?.field === "name") {
			courseRef.current = data.row;
			setModal("detail");
		}
	};

	const handleSearchClick = () => {
		if (search.length) {
			setIsSearch(true);
			setFinalSearch(search);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearch("");
	};

	return (
		<>
			<Stack direction="row" sx={styles.wrapper} gap={1.25}>
				<Typography component="h1">Courses</Typography>
				{isSearch && (
					<IconButton onClick={handleCancleSearch}>
						<Clear />
					</IconButton>
				)}
				<FormControl variant="outlined" sx={styles.searchInput} fullWidth>
					<OutlinedInput
						size="small"
						type="text"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSearchClick();
							}
						}}
						endAdornment={
							<InputAdornment position="end">
								<Tooltip title="Search">
									<IconButton onClick={handleSearchClick}>
										<Search />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						}
						placeholder="Search Courses"
					/>
				</FormControl>
				<Button
					variant="contained"
					onClick={() => navigate("/courses/add")}
					sx={styles.button}
				>
					Add Courses
				</Button>
			</Stack>

			<Listing
				columns={columns}
				queryName={queryNames.COURSES}
				fetchRecords={fetchCourses}
				type="courses"
				handleCellClick={handleCellClick}
				search={finalSearch}
				isSearch={isSearch}
			/>

			<BoxModal
				header="Are you sure?"
				title={"Do you really want to delete this record"}
				open={modal === "delete"}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>

			<CourseDrawer
				modal={modal === "detail"}
				courseId={courseRef.current?.id}
				handleClose={handleClose}
			/>
		</>
	);
};

export default AllCourses;
