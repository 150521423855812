import React, { useState } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import Listing from "../components/ui/Listing";
import { Box } from "@mui/system";
import { subscriptionColors } from "../data/constant";
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../assets/svg/Delete.svg";
import EventsCalendar from "../components/pageComponents/events/EventsCalendar";

const styles = {
  wrapper: {
    justifyContent: "space-between",
    pb: 2.5,
    "& h1": {
      fontSize: 22,
      fontWeight: 600,
      color: "#1E1E1F",
    },
  },
  searchInput: {
    width: "auto",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "6px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2B2A69 !important",
      borderWidth: "1px !important",
    },
    "& input": {
      py: "9px",
      px: "16px",
      "&::placeholder": {
        color: "#767D90",
        fontWeight: 400,
        opacity: 1,
        fontSize: 14,
      },
    },
  },
  "& button": {
    py: 1,
  },
  active: {
    bgcolor: "#2B2A69",
    color: "#FFF",
    fontWeight: 600,
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "#FFF",
    },
  },
  inactive: {
    bgcolor: "#fff",
    fontWeight: 600,
    color: "#2B2A69",
    textTransform: "capitalize",
    border: "1px solid #2B2A69",
    "&:hover": {
      bgcolor: "#2B2A69",
      color: "#FFF",
    },
  },
  list: {
    width: 120,
  },
  calendar: {
    width: 160,
  },
};

const rows = [
  {
    id: 1,
    eventName: "The Property In...",
    eventId: 256780123,
    status: "pending",
    payBy: "Cheque",
    buyer: "John Doe",
    date: "02/02/2023",
  },
  {
    id: 2,
    eventName: "All EventsBlack Hous...",
    eventId: 256780120,
    status: "on going",
    payBy: "Cheque",
    buyer: "Michel John",
    date: "02/02/2023",
  },
  {
    id: 3,
    eventName: "The Property In...",
    eventId: 256780123,
    status: "pending",
    payBy: "Cheque",
    buyer: "John Doe",
    date: "02/02/2023",
  },
  {
    id: 4,
    eventName: "All EventsBlack Hous...",
    eventId: 256780120,
    status: "on going",
    payBy: "Cheque",
    buyer: "Michel John",
    date: "02/02/2023",
  },
  {
    id: 5,
    eventName: "The Property In...",
    eventId: 256780123,
    status: "pending",
    payBy: "Cheque",
    buyer: "John Doe",
    date: "02/02/2023",
  },
  {
    id: 6,
    eventName: "All EventsBlack Hous...",
    eventId: 256780120,
    status: "on going",
    payBy: "Cheque",
    buyer: "Michel John",
    date: "02/02/2023",
  },
  {
    id: 7,
    eventName: "The Property In...",
    eventId: 256780123,
    status: "pending",
    payBy: "Cheque",
    buyer: "John Doe",
    date: "02/02/2023",
  },
  {
    id: 8,
    eventName: "All EventsBlack Hous...",
    eventId: 256780120,
    status: "on going",
    payBy: "Cheque",
    buyer: "Michel John",
    date: "02/02/2023",
  },
  {
    id: 9,
    eventName: "The Property In...",
    eventId: 256780123,
    status: "pending",
    payBy: "Cheque",
    buyer: "John Doe",
    date: "02/02/2023",
  },
  {
    id: 10,
    eventName: "All EventsBlack Hous...",
    eventId: 256780120,
    status: "on going",
    payBy: "Cheque",
    buyer: "Michel John",
    date: "02/02/2023",
  },
];

const columns = [
  {
    field: "eventName",
    headerName: "Event Name",
    sortable: false,
    flex: 1,
  },
  {
    field: "eventId",
    headerName: "ID",
    sortable: false,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            bgcolor: subscriptionColors[params.row.status].bgcolor,
            color: subscriptionColors[params.row.status].color,
            textTransform: "capitalize",
            width: 90,
            height: 30,
            fontSize: 12,
            lineHeight: "30px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          {params.row.status}
        </Box>
      );
    },
  },
  {
    field: "payBy",
    headerName: "Pay By",
    sortable: false,
    width: 120,
  },
  {
    field: "buyer",
    headerName: "Buyer",
    sortable: false,
    width: 150,
  },
  {
    field: "date",
    headerName: "Event Date",
    sortable: false,
    width: 120,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 100,
    renderCell: () => {
      return (
        <Stack direction="row" spacing={0.5}>
          <IconButton>
            <Edit />
          </IconButton>
          <IconButton>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
];

function ScheduledEvents() {
  const [view, setView] = useState("list");

  return (
    <>
      <Stack direction="row" sx={styles.wrapper} flexWrap="wrap" gap={0.75}>
        <Typography component="h1">All Events</Typography>
        <Stack direction="row" flexWrap="wrap" gap={1.25}>
          <FormControl variant="outlined" sx={styles.searchInput} fullWidth>
            <OutlinedInput
              size="small"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              placeholder="Search Events"
            />
          </FormControl>
          <Stack direction="row" spacing={1.25} flexWrap="wrap">
            <Button
              variant="contained"
              disableElevation
              sx={{
                ...styles.list,
                ...(view === "list" ? styles.active : styles.inactive),
              }}
              onClick={() => setView("list")}
            >
              List View
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                ...styles.calendar,
                ...(view === "calendar" ? styles.active : styles.inactive),
              }}
              onClick={() => setView("calendar")}
            >
              Calendar View
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {view === "list" ? (
        <Listing rows={rows} columns={columns} />
      ) : (
        <EventsCalendar />
      )}
    </>
  );
}

export default ScheduledEvents;
