import { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";

const styles = {
	tabs: {
		minHeight: 0,
		mt: 2,

		"& .MuiTabs-indicator": {
			display: "none",
		},
		"& .MuiTabs-flexContainer": {
			columnGap: "10px",
		},
		"& .MuiTab-root": {
			textTransform: "capitalize",
			borderRadius: "6px 6px 0 0",
			bgcolor: "#FFF",
			color: "#767D90",
			fontWeight: 500,
			minHeight: 0,
			fontSize: { md: 15, xs: 12 },
		},
		"& .MuiTab-root.Mui-selected": {
			color: "#0F4590",
			borderBottom: "1px solid #0F4590",
		},
	},
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ py: 2 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const EventTabs = ({ tabList, variant = false }) => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "#F2F2F2",
					bgcolor: variant === "preview" && "#FAFAFF",
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					sx={styles.tabs}
				>
					{tabList.map((tab, index) => (
						<Tab
							key={`tab${tab.label}${index}`}
							label={tab.label}
							{...a11yProps(index)}
							sx={{
								...(variant === "preview" && {
									bgcolor: "#FAFAFF !important"
								}),
							}}
						/>
					))}
				</Tabs>
			</Box>

			{tabList.map((tab, index) => (
				<TabPanel
					key={`tabpanel${tab.label}${index}`}
					value={value}
					index={index}
				>
					{tab.component}
				</TabPanel>
			))}
		</Box>
	);
};

export default EventTabs;
