import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const styles = {
	btn: {
		display: "flex",
		alignItems: "center",
		mr: 2,
		width: 130,
		bgcolor: "#FFF",
		color: "#2B2A69",
		fontWeight: 600,
		textTransform: "capitalize",
		border: "1px solid #2B2A69",
		px: 2,
		"&:hover": {
			bgcolor: "#FFF",
			color: "#2B2A69",
		},
	},
};

const GoBackButton = ({ url, text }) => {
	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (url !== undefined) {
	// 		navigate(url);
	// 	}
	// }, [url]);

	const handleGoBack = (e) => {
		e.preventDefault();
		navigate(url);
	};

	return (
		<Button variant="contained" sx={styles.btn} onClick={handleGoBack}>
			{text ? (
				text
			) : (
				<>
					<ArrowBackIcon sx={{ mr: 1 }} />
					Go Back
				</>
			)}
		</Button>
	);
};

export default GoBackButton;
