import { useState } from "react";
import {
	InputAdornment,
	IconButton,
	FormControlLabel,
	Checkbox,
	TextField,
} from "@mui/material";
import LoginImg from "../../assets/svg/Login.svg";
import { ReactComponent as Lock } from "../../assets/svg/Lock.svg";
import { ReactComponent as Visibility } from "../../assets/svg/Visibility.svg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SplashForm from "../../components/ui/SplashForm";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { validator } from "../../utils/helpers/validator";
import { SignupSchema } from "../../utils/schemas/AuthSchema";
import { post } from "../../server";
import { ADMIN_SUB_ADMIN_SIGN_UP } from "../../data/constants/apiRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../assets/styles/auth/signup.styles";


const initialState = {
	token: "",
	password: "",
	confirm_password: "",
	terms: false,
};

function Signup() {
	const [signupForm, setSignupForm] = useState(initialState);

	const location = useLocation();
	const navigate = useNavigate();

	const [errors, setErrors] = useState({});

	const validateForm = () => {
		let newErrors = validator(signupForm, SignupSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleSignup = async () => {
		const { status, message } = await post(ADMIN_SUB_ADMIN_SIGN_UP, {
			token: location.search.slice(1),
			password: signupForm.password,
			password_confirmation: signupForm.confirm_password,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			navigate("/login");
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleSignup);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (name === "terms") {
			setSignupForm({ ...signupForm, [name]: checked });
		} else {
			setSignupForm({ ...signupForm, [name]: value });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		if (!signupForm.terms) {
			enqueueSnackbar("Please agree to the Terms & Conditions", {
				variant: "error",
			});
			return;
		}
		mutation.mutate();
	};

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmaPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmaPassword((show) => !show);

	return (
		<>
			<SplashForm
				image={LoginImg}
				title="Create an account"
				btnText="Sign up"
				linkTaglinePlainText="Already have an account?"
				linkTaglineText="Login"
				linkTaglineHref="/"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<TextField
					fullWidth
					variant="outlined"
					type={showPassword ? "text" : "password"}
					name="password"
					placeholder="Password"
					value={signupForm.password}
					onChange={handleChange}
					error={errors?.password}
					helperText={errors?.password || ""}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Lock />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowPassword}>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") handleSubmit(e);
					}}
				/>

				<TextField
					fullWidth
					variant="outlined"
					type={showConfirmPassword ? "text" : "password"}
					name="confirm_password"
					placeholder="Confirm Password"
					value={signupForm.confirm_password}
					onChange={handleChange}
					error={errors?.confirm_password}
					helperText={errors?.confirm_password || ""}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Lock />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowConfirmPassword}>
									{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") handleSubmit(e);
					}}
				/>

				<FormControlLabel
					sx={styles.checkbox}
					control={
						<Checkbox
							name="terms"
							checked={signupForm.terms}
							onChange={handleChange}
						/>
					}
					label="I agree to the terms & conditions"
				/>
			</SplashForm>
		</>
	);
}

export default Signup;
