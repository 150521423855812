
import Joi from "joi";


const PromoCodeSchema = Joi.object({
    name: Joi.string().required().max(50).messages({
        "string.empty": `Please enter Promo Code Name`,
        "string.max": `Promo Code name must be maximum 50 characters!`,
        "string.pattern.base": `Please enter Promo Code Name`,
    }),
    type: Joi.string()
        .valid("fixed", "percentage")
        .required()
        .messages({
            "any.required": "Please select Type of Discount",
            "string.valid": "Invalid type value",
        }),
    currency: Joi.when("type", {
        is: "fixed",
        then: Joi.string()
            .required()
            .messages({
                "number.base": "Please Select Currency",
                "any.required": "Amount Off is required for type 'fixed'",
            }),
    }),
    amount_off: Joi.when("type", {
        is: "fixed",
        then: Joi.number()
            .min(1)
            .max(9999)
            .required()
            .messages({
                "number.base": "Amount Off must be a number",
                "number.min": "Amount Off must be a minimum of 1",
                "number.max": "Amount Off must be a maximum of 9999",
                "any.required": "Amount Off is required for type 'fixed'",
            }),
    }),
    percent_off: Joi.when("type", {
        is: "percentage",
        then: Joi.number()
            .min(1)
            .max(100)
            .required()
            .messages({
                "number.base": "Percentage Off must be a number",
                "number.min": "Percentage Off must be a minimum of 1",
                "number.max": "Percentage Off must be a maximum of 100",
                "any.required": "Percentage Off is required for type 'percentage'",
            }),
    }),
    duration: Joi.string()
        .valid("forever", "once", "repeating")
        .required()
        .messages({
            "any.required": "Please Select Duration",
            "string.valid": "Invalid duration value",
        }),
    duration_in_months: Joi.when("duration", {
        is: "repeating",
        then: Joi.number()
            .integer()
            .min(1)
            .max(12)
            .required()
            .messages({
                "number.base": "Duration in months must be a number",
                "number.integer": "Duration in months must be an integer",
                "number.min": "Duration in months must be at least 1",
                "number.max": "Duration in months must be at most 12",
                "any.required": "Duration in months is required for repeating duration",
            }),
    }),
    for: Joi.string()
        .valid("all", "members")
        .required()
        .messages({
            "any.required": "Please Select Promo code for",
            "string.valid": "Invalid value",
        }),
});

export default PromoCodeSchema;
