import { Box } from "@mui/material";
import styles from "../../assets/styles/forms/formLabel.styles";

const FormLabel = ({ label, inputId, inputType, labelSize }) => {
	return (
		<>
			{label ? (
				<Box
					component="label"
					sx={{
						...styles.label,
						...(labelSize && { fontSize: `${labelSize} !important` }),
						...(inputType && !labelSize && styles.smallLabel),
					}}
					htmlFor={inputId}
				>
					{label}
				</Box>
			) : null}
		</>
	);
};

export default FormLabel;
