import Joi from "joi";



const LoginSchema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })

    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
      "string.pattern.base": `Please enter email address`,
    }),
  password: Joi.string().required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
});

const SignupSchema = Joi.object({
  password: Joi.string()
    .min(8)
    .required()
    .messages({
      "string.empty": "Please enter password",
      "string.base": "Please enter password",
      "string.min": "Password must be at least 8 characters long",
    }),
  confirm_password: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .messages({
      "string.empty": "Please enter confirm password",
      "any.only": "Password and confirm password do not match",
      "string.base": "Please enter confirm password",
    }),
});

const ForgetPasswordSchema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })

    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
      "string.pattern.base": `Please enter email address`,
    }),
});

const OTPSchema = Joi.object({
  otp: Joi.string().min(4).max(4).required().messages({
    "string.empty": `Please enter OTP code`,
    "string.min": `OTP must be minimum 4 characters`,
    "string.max": `OTP must be maximum 4 characters`,
  }),
});

const ResetPasswordSchema = Joi.object({
  password: Joi.string().min(6).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 6 characters!`,
  }),
  password_confirmation: Joi.string().required().valid(Joi.ref("password")).messages({
    "string.empty": `Please enter confirm password `,
    "any.only": `Confirm password must match password`,
  }),
});

export {
  LoginSchema,
  SignupSchema,
  ForgetPasswordSchema,
  OTPSchema,
  ResetPasswordSchema,
};
