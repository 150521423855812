const styles = {
	wrapper: {
		justifyContent: "space-between",
		alignItems: "center",
		pb: 2.5,
		"& h1": {
			fontSize: 22,
			fontWeight: 600,
			color: "#1E1E1F",
		},
	},
	compose: {
		bgcolor: "#2B2A69",
		color: "#FFF",
		width: 120,
		py: 1,
		textTransform: 'capitalize',
		"&:hover": {
			bgcolor: "#2B2A69",
			color: "#FFF",
		},
	},
	refreshButton: {
		width: 40,
		height: 40,
		bgcolor: "#FFFFFF",
		color: "#333333",
		borderRadius: "6px",
		"& svg": {
			fontSize: 24,
		},
	},
	select: {
		justifyContent: "center",
		"& .MuiInputBase-root": {
			"&:before, &:after, &:hover ": {
				borderBottom: "none",
			},
		},
	},
	checkbox: {},
	box: {
		"& .MuiStack-root": {
			"&:first-child": {
				borderTopLeftRadius: "12px",
				borderTopRightRadius: "12px",
			},
			"&:last-child": {
				borderBottomLeftRadius: "12px",
				borderBottomRightRadius: "12px",
			},
		},
	},
	pagination: {
		mt: 2.625,
		"& .MuiPagination-ul": {
			justifyContent: "center",
		},
	},
};

export default styles;