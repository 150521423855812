import { useJsApiLoader } from "@react-google-maps/api";

function useGoogleMapsApi() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: ["places"],
  });

  return isLoaded;
}

export default useGoogleMapsApi;