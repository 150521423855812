import React from "react";
import PageHeader from "../components/ui/PageHeader";
import Listing from "../components/ui/Listing";
import { Avatar, Stack, Typography, Tooltip } from "@mui/material";
import CompanyLogo from "../assets/svg/companyLogo.svg";

const styles = {
    header: {
        bgcolor: "#fff",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        px: 3.75,
        py: 2,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        borderBottom: "1px solid #F1F1F1",
        "& h2": {
            color: "#1E1E1F",
            fontSize: 18,
            fontWeight: 600,
        },
    },
    searchInput: {
        width: 350,
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        borderRadius: "6px",
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2B2A69 !important",
            borderWidth: "1px !important",
        },
        "& input": {
            py: "9px",
            px: "16px",
            "&::placeholder": {
                color: "#767D90",
                fontWeight: 400,
                opacity: 1,
                fontSize: 14,
            },
        },
    },
};

const rows = [
    {
        id: 1,
        companyName: "ExpertsDesk.net",
        eventName: "The Property In...",
        sponsor: "Systems",
        date: "02/02/2023",
        price: 19.00,
        profileImg: CompanyLogo,
    },
    {
        id: 2,
        companyName: "Systems.co",
        eventName: "All EventsBlack Hous...",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 140.00,
    },
    {
        id: 3,
        companyName: "Infinity.net",
        eventName: "The Property In....",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 50.00,
    },
    {
        id: 4,
        companyName: "Relianceindustrie.Ltd",
        eventName: "The Property In...",
        sponsor: "Systems",
        date: "02/02/2023",
        price: 19.00,
    },
    {
        id: 5,
        companyName: "Oilandnatural.com",
        eventName: "All EventsBlack Hous...",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 140.00,
    },
    {
        id: 6,
        companyName: "LifeInsurance.net",
        eventName: "The Property In....",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 50.00,
    },
    {
        id: 7,
        companyName: "Systems.co",
        eventName: "The Property In...",
        sponsor: "Systems",
        date: "02/02/2023",
        price: 19.00,
    },
    {
        id: 8,
        companyName: "LifeInsurance.net",
        eventName: "All EventsBlack Hous...",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 140.00,
    },
    {
        id: 9,
        companyName: "Oilandnatural.com",
        eventName: "The Property In....",
        sponsor: "XYZ",
        date: "02/02/2023",
        price: 50.00,
    },
    {
        id: 10,
        companyName: "Relianceindustrie.Ltd",
        eventName: "The Property In...",
        sponsor: "Systems",
        date: "02/02/2023",
        price: 19.00,
    },
];

const columns = [
    {
        field: "companyName",
        headerName: "Company Name",
        sortable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <>
                    <Avatar
                        src={params.row.profileImg}
                        sx={{ mr: 1.5, width: 35, height: 35 }}
                    >
                        {params.row.companyName.charAt(0)}
                    </Avatar>
                    {params.row.companyName}
                </>
            );
        },
    },
    {
        field: "eventName",
        headerName: "Event Name",
        sortable: false,
        flex: 1,
    },
    {
        field: "sponsor",
        headerName: "Sponsor",
        sortable: false,
        flex: 1
    },
    {
        field: "date",
        headerName: "Event Date",
        sortable: false,
        flex: 1,
        //         renderCell: (params) => {
        //             return (
        //                 <Tooltip title="Subscription
        // Expired">
        //                     {params.row.date}
        //                 </Tooltip>
        //             )
        //         }
    },
    {
        field: "price",
        headerName: "Price",
        sortable: false,
        width: 100,
        renderCell: (params) => {
            return (
                <Stack direction="row" spacing={0.5}>
                    ${params.row.price}
                </Stack>
            )
        }
    },
];

function Sponsor() {

    return <>
        <PageHeader
            title="Sponsor"
            placeholder="Search sponsors"
            searchInput={true}
        />
        <Stack sx={styles.header}>
            <Typography component="h2">Event Sponsors</Typography>
            {/* <FormControl variant="outlined" sx={styles.searchInput}>
                <OutlinedInput
                    size="small"
                    type="text"
                    endAdornment={
                        <InputAdornment position="end">
                            <Search />
                        </InputAdornment>
                    }
                    placeholder="Search notification"
                />
            </FormControl> */}
        </Stack>
        <Listing rows={rows} columns={columns} className="courses" />
    </>;
}

export default Sponsor;
