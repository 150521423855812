import { useState } from "react";
import {
	InputAdornment,
	IconButton,
	FormControlLabel,
	Checkbox,
	Stack,
	TextField,
} from "@mui/material";
import LoginImg from "../../assets/svg/Login.svg";
import { ReactComponent as Email } from "../../assets/svg/Email.svg";
import { ReactComponent as Lock } from "../../assets/svg/Lock.svg";
import { ReactComponent as Visibility } from "../../assets/svg/Visibility.svg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SplashForm from "../../components/ui/SplashForm";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { validator } from "../../utils/helpers/validator";
import { LoginSchema } from "../../utils/schemas/AuthSchema";
import { post } from "../../server";
import { ADMIN_LOGIN } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import routes from "../../data/constants/routes";
import styles from "../../assets/styles/auth/login.styles"

function Login() {
	const [loginForm, setLoginForm] = useState({
		email: "",
		password: "",
		rememberMe: false,
	});
	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const validateForm = () => {
		let newErrors = validator(loginForm, LoginSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleLogin = async () => {
		const { status, message, data } = await post(ADMIN_LOGIN, {
			email: loginForm.email,
			password: loginForm.password,
		});
		if (status === true) {
			const token = data.token;
			delete data.token;
			delete data.password;
			const newData = {
				token: token,
				data: data,
				rememberMe: loginForm.rememberMe,
			};
			setLoggedUser(newData);
			navigate(routes.ADMIN_HOME);
			enqueueSnackbar(message, { variant: "success" });
		} else {
			enqueueSnackbar(message === "errors" ? data?.email[0] : message, {
				variant: "error",
			});
		}
	};

	const mutation = useMutation(handleLogin);

	const handleChange = (e) => {
		const name = e.target.name;
		const value =
			name === "email" ? e.target.value.replace(/\s/g, "") : e.target.value;
		const checked = e.target.checked;

		if (name === "rememberMe") {
			setLoginForm({ ...loginForm, [name]: checked });
		} else {
			setLoginForm({ ...loginForm, [name]: value });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	return (
		<>
			<SplashForm
				image={LoginImg}
				title="Sign in to Admin Panel"
				tagline="Login your account"
				btnText="Login"
				// linkTaglinePlainText="Not registered yet?"
				// linkTaglineText="Create an Account"
				// linkTaglineHref="/signup"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<>
					<TextField
						fullWidth
						variant="outlined"
						size="small"
						type="email"
						name="email"
						value={loginForm.email}
						onChange={handleChange}
						placeholder="Email"
						error={errors?.email}
						helperText={errors?.email || ""}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Email />
								</InputAdornment>
							),
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>

					<TextField
						fullWidth
						variant="outlined"
						type={showPassword ? "text" : "password"}
						name="password"
						placeholder="Password"
						value={loginForm.password}
						onChange={handleChange}
						error={errors?.password}
						helperText={errors?.password || ""}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Lock />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClickShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>

					<Stack sx={styles.forgetPassword}>
						<FormControlLabel
							control={
								<Checkbox
									name="rememberMe"
									checked={loginForm.rememberMe}
									onChange={handleChange}
									size="small"
								/>
							}
							label="Remember me"
						/>
						<Link to={routes.ADMIN_FORGET_PASSWORD}>Forgot your password?</Link>
					</Stack>
				</>
			</SplashForm>
		</>
	);
}

export default Login;
