import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "../../../../assets/styles/events/createEvent.styles";

const SponsorTab = ({ sponsors }) => {
	return (
		<>
			<Box>
				{sponsors?.map((sponsor, index) => (
					<Stack direction="row" sx={styles.memberStack} key={index}>
						<Avatar
							sx={styles.memberAvatar}
							src={sponsor?.company_sponsor?.profile_image}
						/>
						<Stack sx={styles.memberNameStack} gap="5px">
							<Typography component="h4">
								{sponsor?.company_sponsor?.sponser_name}
							</Typography>
							<Typography component="label">
								{sponsor?.company_sponsor?.email}
							</Typography>
						</Stack>
					</Stack>
				))}
			</Box>
		</>
	);
};

export default SponsorTab;
